/**
 * = Backgrounds
 */

@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

// Backgrounds Overlay
@each $color, $value in $theme-colors {
    .overlay-#{$color}{ 
        &:before{
            position: absolute;
            content:"";
            background: $value;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.8;
            z-index: 0;
        }
    }

    .overlay-soft-#{$color}{ 
        &:before{
            position: absolute;
            content:"";
            background: $value;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.6;
            z-index: 0;
        }
    }
}

@each $color, $value in $theme-colors {
    @include bg-variant(".section-#{$color}", $value);
}

.section-image {
    @include background-image(no-repeat, cover); 
}

.outer-bg {
    position: relative;
    overflow: hidden;

    .inner-bg
    {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        transition: all .3s ease-in-out;

        &:before {
            transition: all .3s ease-in-out;
        }
    }

    &:hover {
        .inner-bg {
            transform: scale(1.1);

            &:before {
                background: transparent;
            }
        }
    }
}