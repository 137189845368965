/**
 * = Modals
 */

.modal{
    &.static-example{
        position: relative;
        display: block;
    }
}

.modal-header .close {
    padding: .825rem;
}

.modal-content {
    border: 0;
    @if $enable-rounded {
        border-radius: $modal-content-border-radius;
    } @else {
        border-radius: 0;
    }
}

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }
    .modal-content {
        border-radius: 0;
    }
}

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}

.modal-full {
    &.modal-dialog {
        max-width: none;
        width: calc(100% - 60px);

        @include media-breakpoint-down(lg) {
            width: calc(100% - 20px);
        }
    }
    .modal-content,
    .modal-body {
        height: calc(100vh - 60px);
    }
}
