/*

=========================================================
* Spaces - Bootstrap Directory Listing Template
=========================================================

* Product Page: https://themesberg.com/product/bootstrap-themes/spaces-bootstrap-directory-listing-template
* Copyright 2020 Themesberg EULA (https://www.themesberg.com/licensing)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");
/*
 * 	Default theme - Owl Carousel CSS File
 */
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0056B3;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #be2837;
  --orange: #fd7e14;
  --yellow: #F0B400;
  --green: #208163;
  --teal: #0056B3;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #93a5be;
  --gray-dark: #4E5079;
  --primary: #0d1b48;
  --secondary: #C33C54;
  --success: #208163;
  --info: #0056B3;
  --warning: #F0B400;
  --danger: #be2837;
  --light: #edf0f7;
  --dark: #0d1b48;
  --tertiary: #26408B;
  --lighten: #ffffff;
  --white: #ffffff;
  --gray: #424767;
  --neutral: #ffffff;
  --soft: #f5f9fe;
  --black: #0d1b48;
  --purple: #6f42c1;
  --soft-green: #ebf9ee;
  --gray-100: #ffffff;
  --gray-200: #fafbfe;
  --gray-300: #f5f9fe;
  --gray-400: #edf0f7;
  --gray-500: #B7C3D2;
  --gray-600: #93a5be;
  --gray-700: #52547a;
  --gray-800: #4E5079;
  --facebook: #3b5999;
  --twitter: #1da1f2;
  --google: #DB4337;
  --instagram: #e4405f;
  --pinterest: #bd081c;
  --youtube: #cd201f;
  --slack: #3aaf85;
  --dribbble: #ea4c89;
  --dropbox: #1E90FF;
  --twitch: #4B367C;
  --paypal: #ecb32c;
  --behance: #0057ff;
  --reddit: #E84422;
  --github: #222222;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(13, 27, 72, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #424767;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.75rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 600; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #C33C54;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #9c3043;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #52547a;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.75rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.3;
  color: #0d1b48; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.625rem + 4.5vw) ; } }

.display-2 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.425rem + 2.1vw) ; } }

.display-3 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.375rem + 1.5vw) ; } }

.display-4 {
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.3125rem + 0.75vw) ; } }

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 0.0625rem solid rgba(13, 27, 72, 0.05); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #93a5be; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 0.0625rem solid #f5f9fe;
  border-radius: 0.45rem;
  box-shadow: 0 1px 2px rgba(13, 27, 72, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #93a5be; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #424767;
  border-radius: 0.1rem;
  box-shadow: inset 0 -0.1rem 0 rgba(13, 27, 72, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #424767; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #424767;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 1rem;
    vertical-align: top;
    border-top: 0.0625rem solid #edf0f7; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 0.125rem solid #edf0f7; }
  .table tbody + tbody {
    border-top: 0.125rem solid #edf0f7; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 0.0625rem solid #edf0f7; }
  .table-bordered th,
  .table-bordered td {
    border: 0.0625rem solid #edf0f7; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 0.125rem; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(13, 27, 72, 0.05); }

.table-hover tbody tr:hover {
  color: #424767;
  background-color: rgba(13, 27, 72, 0.03); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bbbfcc; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8188a0; }

.table-hover .table-primary:hover {
  background-color: #acb1c1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #acb1c1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #eec8cf; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e09aa6; }

.table-hover .table-secondary:hover {
  background-color: #e8b5be; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e8b5be; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1dcd3; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8bbdae; }

.table-hover .table-success:hover {
  background-color: #b1d3c7; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1d3c7; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8d0ea; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7aa7d7; }

.table-hover .table-info:hover {
  background-color: #a4c3e4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a4c3e4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbeab8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7d87a; }

.table-hover .table-warning:hover {
  background-color: #fae3a0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fae3a0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edc3c7; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #dd8f97; }

.table-hover .table-danger:hover {
  background-color: #e7afb5; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e7afb5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafbfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f6f7fb; }

.table-hover .table-light:hover {
  background-color: #e8ecf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e8ecf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bbbfcc; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8188a0; }

.table-hover .table-dark:hover {
  background-color: #acb1c1; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #acb1c1; }

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #c2cadf; }

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #8e9cc3; }

.table-hover .table-tertiary:hover {
  background-color: #b1bbd6; }
  .table-hover .table-tertiary:hover > td,
  .table-hover .table-tertiary:hover > th {
    background-color: #b1bbd6; }

.table-lighten,
.table-lighten > th,
.table-lighten > td {
  background-color: white; }

.table-lighten th,
.table-lighten td,
.table-lighten thead th,
.table-lighten tbody + tbody {
  border-color: white; }

.table-hover .table-lighten:hover {
  background-color: #f2f2f2; }
  .table-hover .table-lighten:hover > td,
  .table-hover .table-lighten:hover > th {
    background-color: #f2f2f2; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #cacbd4; }

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #9d9fb0; }

.table-hover .table-gray:hover {
  background-color: #bcbdc9; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #bcbdc9; }

.table-neutral,
.table-neutral > th,
.table-neutral > td {
  background-color: white; }

.table-neutral th,
.table-neutral td,
.table-neutral thead th,
.table-neutral tbody + tbody {
  border-color: white; }

.table-hover .table-neutral:hover {
  background-color: #f2f2f2; }
  .table-hover .table-neutral:hover > td,
  .table-hover .table-neutral:hover > th {
    background-color: #f2f2f2; }

.table-soft,
.table-soft > th,
.table-soft > td {
  background-color: #fcfdff; }

.table-soft th,
.table-soft td,
.table-soft thead th,
.table-soft tbody + tbody {
  border-color: #fafcfe; }

.table-hover .table-soft:hover {
  background-color: #e3ecff; }
  .table-hover .table-soft:hover > td,
  .table-hover .table-soft:hover > th {
    background-color: #e3ecff; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #bbbfcc; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #8188a0; }

.table-hover .table-black:hover {
  background-color: #acb1c1; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #acb1c1; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d7caee; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #b49ddf; }

.table-hover .table-purple:hover {
  background-color: #c8b7e8; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #c8b7e8; }

.table-soft-green,
.table-soft-green > th,
.table-soft-green > td {
  background-color: #f9fdfa; }

.table-soft-green th,
.table-soft-green td,
.table-soft-green thead th,
.table-soft-green tbody + tbody {
  border-color: #f5fcf6; }

.table-hover .table-soft-green:hover {
  background-color: #e6f7ea; }
  .table-hover .table-soft-green:hover > td,
  .table-hover .table-soft-green:hover > th {
    background-color: #e6f7ea; }

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: white; }

.table-gray-100 th,
.table-gray-100 td,
.table-gray-100 thead th,
.table-gray-100 tbody + tbody {
  border-color: white; }

.table-hover .table-gray-100:hover {
  background-color: #f2f2f2; }
  .table-hover .table-gray-100:hover > td,
  .table-hover .table-gray-100:hover > th {
    background-color: #f2f2f2; }

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #fefeff; }

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #fcfdfe; }

.table-hover .table-gray-200:hover {
  background-color: #e5e5ff; }
  .table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #e5e5ff; }

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #fcfdff; }

.table-gray-300 th,
.table-gray-300 td,
.table-gray-300 thead th,
.table-gray-300 tbody + tbody {
  border-color: #fafcfe; }

.table-hover .table-gray-300:hover {
  background-color: #e3ecff; }
  .table-hover .table-gray-300:hover > td,
  .table-hover .table-gray-300:hover > th {
    background-color: #e3ecff; }

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #fafbfd; }

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #f6f7fb; }

.table-hover .table-gray-400:hover {
  background-color: #e8ecf6; }
  .table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #e8ecf6; }

.table-gray-500,
.table-gray-500 > th,
.table-gray-500 > td {
  background-color: #ebeef2; }

.table-gray-500 th,
.table-gray-500 td,
.table-gray-500 thead th,
.table-gray-500 tbody + tbody {
  border-color: #dae0e8; }

.table-hover .table-gray-500:hover {
  background-color: #dce1e8; }
  .table-hover .table-gray-500:hover > td,
  .table-hover .table-gray-500:hover > th {
    background-color: #dce1e8; }

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #e1e6ed; }

.table-gray-600 th,
.table-gray-600 td,
.table-gray-600 thead th,
.table-gray-600 tbody + tbody {
  border-color: #c7d0dd; }

.table-hover .table-gray-600:hover {
  background-color: #d1d9e3; }
  .table-hover .table-gray-600:hover > td,
  .table-hover .table-gray-600:hover > th {
    background-color: #d1d9e3; }

.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #cfcfda; }

.table-gray-700 th,
.table-gray-700 td,
.table-gray-700 thead th,
.table-gray-700 tbody + tbody {
  border-color: #a5a6ba; }

.table-hover .table-gray-700:hover {
  background-color: #c1c1cf; }
  .table-hover .table-gray-700:hover > td,
  .table-hover .table-gray-700:hover > th {
    background-color: #c1c1cf; }

.table-gray-800,
.table-gray-800 > th,
.table-gray-800 > td {
  background-color: #cdced9; }

.table-gray-800 th,
.table-gray-800 td,
.table-gray-800 thead th,
.table-gray-800 tbody + tbody {
  border-color: #a3a4b9; }

.table-hover .table-gray-800:hover {
  background-color: #bfc0ce; }
  .table-hover .table-gray-800:hover > td,
  .table-hover .table-gray-800:hover > th {
    background-color: #bfc0ce; }

.table-facebook,
.table-facebook > th,
.table-facebook > td {
  background-color: #c8d1e2; }

.table-facebook th,
.table-facebook td,
.table-facebook thead th,
.table-facebook tbody + tbody {
  border-color: #99a9ca; }

.table-hover .table-facebook:hover {
  background-color: #b7c3d9; }
  .table-hover .table-facebook:hover > td,
  .table-hover .table-facebook:hover > th {
    background-color: #b7c3d9; }

.table-twitter,
.table-twitter > th,
.table-twitter > td {
  background-color: #c0e5fb; }

.table-twitter th,
.table-twitter td,
.table-twitter thead th,
.table-twitter tbody + tbody {
  border-color: #89cef8; }

.table-hover .table-twitter:hover {
  background-color: #a8dbf9; }
  .table-hover .table-twitter:hover > td,
  .table-hover .table-twitter:hover > th {
    background-color: #a8dbf9; }

.table-google,
.table-google > th,
.table-google > td {
  background-color: #f5cac7; }

.table-google th,
.table-google td,
.table-google thead th,
.table-google tbody + tbody {
  border-color: #ec9d97; }

.table-hover .table-google:hover {
  background-color: #f1b6b1; }
  .table-hover .table-google:hover > td,
  .table-hover .table-google:hover > th {
    background-color: #f1b6b1; }

.table-instagram,
.table-instagram > th,
.table-instagram > td {
  background-color: #f7cad2; }

.table-instagram th,
.table-instagram td,
.table-instagram thead th,
.table-instagram tbody + tbody {
  border-color: #f19cac; }

.table-hover .table-instagram:hover {
  background-color: #f4b4bf; }
  .table-hover .table-instagram:hover > td,
  .table-hover .table-instagram:hover > th {
    background-color: #f4b4bf; }

.table-pinterest,
.table-pinterest > th,
.table-pinterest > td {
  background-color: #edbabf; }

.table-pinterest th,
.table-pinterest td,
.table-pinterest thead th,
.table-pinterest tbody + tbody {
  border-color: #dd7f89; }

.table-hover .table-pinterest:hover {
  background-color: #e8a6ac; }
  .table-hover .table-pinterest:hover > td,
  .table-hover .table-pinterest:hover > th {
    background-color: #e8a6ac; }

.table-youtube,
.table-youtube > th,
.table-youtube > td {
  background-color: #f1c1c0; }

.table-youtube th,
.table-youtube td,
.table-youtube thead th,
.table-youtube tbody + tbody {
  border-color: #e58b8b; }

.table-hover .table-youtube:hover {
  background-color: #ecacab; }
  .table-hover .table-youtube:hover > td,
  .table-hover .table-youtube:hover > th {
    background-color: #ecacab; }

.table-slack,
.table-slack > th,
.table-slack > td {
  background-color: #c8e9dd; }

.table-slack th,
.table-slack td,
.table-slack thead th,
.table-slack tbody + tbody {
  border-color: #99d5c0; }

.table-hover .table-slack:hover {
  background-color: #b6e2d2; }
  .table-hover .table-slack:hover > td,
  .table-hover .table-slack:hover > th {
    background-color: #b6e2d2; }

.table-dribbble,
.table-dribbble > th,
.table-dribbble > td {
  background-color: #f9cdde; }

.table-dribbble th,
.table-dribbble td,
.table-dribbble thead th,
.table-dribbble tbody + tbody {
  border-color: #f4a2c2; }

.table-hover .table-dribbble:hover {
  background-color: #f6b6cf; }
  .table-hover .table-dribbble:hover > td,
  .table-hover .table-dribbble:hover > th {
    background-color: #f6b6cf; }

.table-dropbox,
.table-dropbox > th,
.table-dropbox > td {
  background-color: #c0e0ff; }

.table-dropbox th,
.table-dropbox td,
.table-dropbox thead th,
.table-dropbox tbody + tbody {
  border-color: #8ac5ff; }

.table-hover .table-dropbox:hover {
  background-color: #a7d3ff; }
  .table-hover .table-dropbox:hover > td,
  .table-hover .table-dropbox:hover > th {
    background-color: #a7d3ff; }

.table-twitch,
.table-twitch > th,
.table-twitch > td {
  background-color: #cdc7da; }

.table-twitch th,
.table-twitch td,
.table-twitch thead th,
.table-twitch tbody + tbody {
  border-color: #a196bb; }

.table-hover .table-twitch:hover {
  background-color: #bfb8d0; }
  .table-hover .table-twitch:hover > td,
  .table-hover .table-twitch:hover > th {
    background-color: #bfb8d0; }

.table-paypal,
.table-paypal > th,
.table-paypal > td {
  background-color: #faeac4; }

.table-paypal th,
.table-paypal td,
.table-paypal thead th,
.table-paypal tbody + tbody {
  border-color: #f5d791; }

.table-hover .table-paypal:hover {
  background-color: #f8e2ac; }
  .table-hover .table-paypal:hover > td,
  .table-hover .table-paypal:hover > th {
    background-color: #f8e2ac; }

.table-behance,
.table-behance > th,
.table-behance > td {
  background-color: #b8d0ff; }

.table-behance th,
.table-behance td,
.table-behance thead th,
.table-behance tbody + tbody {
  border-color: #7aa8ff; }

.table-hover .table-behance:hover {
  background-color: #9fbfff; }
  .table-hover .table-behance:hover > td,
  .table-hover .table-behance:hover > th {
    background-color: #9fbfff; }

.table-reddit,
.table-reddit > th,
.table-reddit > td {
  background-color: #f9cbc1; }

.table-reddit th,
.table-reddit td,
.table-reddit thead th,
.table-reddit tbody + tbody {
  border-color: #f39e8c; }

.table-hover .table-reddit:hover {
  background-color: #f7b8aa; }
  .table-hover .table-reddit:hover > td,
  .table-hover .table-reddit:hover > th {
    background-color: #f7b8aa; }

.table-github,
.table-github > th,
.table-github > td {
  background-color: #c1c1c1; }

.table-github th,
.table-github td,
.table-github thead th,
.table-github tbody + tbody {
  border-color: #8c8c8c; }

.table-hover .table-github:hover {
  background-color: #b4b4b4; }
  .table-hover .table-github:hover > td,
  .table-hover .table-github:hover > th {
    background-color: #b4b4b4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(13, 27, 72, 0.03); }

.table-hover .table-active:hover {
  background-color: rgba(9, 19, 50, 0.03); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(9, 19, 50, 0.03); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #C33C54;
  border-color: #0d1b48; }

.table .thead-light th {
  color: #52547a;
  background-color: #fafbfe;
  border-color: #edf0f7; }

.table-dark {
  color: #ffffff;
  background-color: #C33C54; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #0d1b48; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.2625rem);
  padding: 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #424767;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #edf0f7;
  border-radius: 0.45rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #424767; }
  .form-control:focus {
    color: #424767;
    background-color: #ffffff;
    border-color: #B7C3D2;
    outline: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05); }
  .form-control::placeholder {
    color: #4E5079;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #fafbfe;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #424767;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: 0.6625rem;
  padding-bottom: 0.6625rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  font-size: 1.125rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #424767;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.8125rem);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1rem; }

.form-control-lg {
  height: 3.425rem;
  padding: 0.875rem 0.875rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #52547a; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #208163; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(32, 129, 99, 0.9);
  border-radius: 0.45rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #208163;
  padding-right: calc(1.5em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23208163' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.4875rem + 0.6rem) calc(0.4875rem + 0.6rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #208163;
    box-shadow: 0 0 0 0 rgba(32, 129, 99, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.2rem);
  background-position: top calc(0.375em + 0.3rem) right calc(0.375em + 0.3rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #208163;
  padding-right: calc(0.75em + 2.65rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234E5079' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23208163' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.4875rem + 0.6rem) calc(0.4875rem + 0.6rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #208163;
    box-shadow: 0 0 0 0 rgba(32, 129, 99, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #208163; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #208163; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #208163; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2aaa82;
  background-color: #2aaa82; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(32, 129, 99, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #208163; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #208163; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #208163;
  box-shadow: 0 0 0 0 rgba(32, 129, 99, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #be2837; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(190, 40, 55, 0.9);
  border-radius: 0.45rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #be2837;
  padding-right: calc(1.5em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23be2837' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23be2837' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.4875rem + 0.6rem) calc(0.4875rem + 0.6rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #be2837;
    box-shadow: 0 0 0 0 rgba(190, 40, 55, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.2rem);
  background-position: top calc(0.375em + 0.3rem) right calc(0.375em + 0.3rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #be2837;
  padding-right: calc(0.75em + 2.65rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234E5079' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23be2837' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23be2837' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.4875rem + 0.6rem) calc(0.4875rem + 0.6rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #be2837;
    box-shadow: 0 0 0 0 rgba(190, 40, 55, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #be2837; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #be2837; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #be2837; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d74251;
  background-color: #d74251; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(190, 40, 55, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #be2837; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #be2837; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #be2837;
  box-shadow: 0 0 0 0 rgba(190, 40, 55, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #424767;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.55rem 0.95rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #424767;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: none; }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #070f28;
    border-color: #050b1d; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #070f28;
    border-color: #050b1d;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #050b1d;
    border-color: #030712; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #C33C54;
  border-color: #C33C54;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #a63347;
    border-color: #9c3043; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #a63347;
    border-color: #9c3043;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(204, 89, 110, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #C33C54;
    border-color: #C33C54; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #9c3043;
    border-color: #922d3f; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(204, 89, 110, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #208163;
  border-color: #208163;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-success:hover {
    color: #ffffff;
    background-color: #18624b;
    border-color: #165844; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #18624b;
    border-color: #165844;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(65, 148, 122, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #208163;
    border-color: #208163; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #165844;
    border-color: #134e3c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(65, 148, 122, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #0056B3;
  border-color: #0056B3;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-info:hover {
    color: #ffffff;
    background-color: #00448d;
    border-color: #003d80; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #00448d;
    border-color: #003d80;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(38, 111, 190, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #0056B3;
    border-color: #0056B3; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #003d80;
    border-color: #003773; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 111, 190, 0.5); }

.btn-warning {
  color: #0d1b48;
  background-color: #F0B400;
  border-color: #F0B400;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #ca9700;
    border-color: #bd8e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #ca9700;
    border-color: #bd8e00;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(206, 157, 11, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #0d1b48;
    background-color: #F0B400;
    border-color: #F0B400; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #bd8e00;
    border-color: #b08400; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(206, 157, 11, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #be2837;
  border-color: #be2837;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #9e212e;
    border-color: #941f2b; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #9e212e;
    border-color: #941f2b;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(200, 72, 85, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #be2837;
    border-color: #be2837; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #941f2b;
    border-color: #891d28; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(200, 72, 85, 0.5); }

.btn-light {
  color: #0d1b48;
  background-color: #edf0f7;
  border-color: #edf0f7;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-light:hover {
    color: #0d1b48;
    background-color: #d3daeb;
    border-color: #cad3e7; }
  .btn-light:focus, .btn-light.focus {
    color: #0d1b48;
    background-color: #d3daeb;
    border-color: #cad3e7;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #0d1b48;
    background-color: #cad3e7;
    border-color: #c1cbe3; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #070f28;
    border-color: #050b1d; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #070f28;
    border-color: #050b1d;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #050b1d;
    border-color: #030712; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }

.btn-tertiary {
  color: #ffffff;
  background-color: #26408B;
  border-color: #26408B;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-tertiary:hover {
    color: #ffffff;
    background-color: #1e326d;
    border-color: #1b2e63; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    color: #ffffff;
    background-color: #1e326d;
    border-color: #1b2e63;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(71, 93, 156, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #ffffff;
    background-color: #26408B;
    border-color: #26408B; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #1b2e63;
    border-color: #182959; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(71, 93, 156, 0.5); }

.btn-lighten {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-lighten:hover {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-lighten:focus, .btn-lighten.focus {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-lighten.disabled, .btn-lighten:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-lighten:not(:disabled):not(.disabled):active, .btn-lighten:not(:disabled):not(.disabled).active,
  .show > .btn-lighten.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-lighten:not(:disabled):not(.disabled):active:focus, .btn-lighten:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lighten.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-white {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-white:hover {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-gray {
  color: #ffffff;
  background-color: #424767;
  border-color: #424767;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray:hover {
    color: #ffffff;
    background-color: #333750;
    border-color: #2e3248; }
  .btn-gray:focus, .btn-gray.focus {
    color: #ffffff;
    background-color: #333750;
    border-color: #2e3248;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(94, 99, 126, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #ffffff;
    background-color: #424767;
    border-color: #424767; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #2e3248;
    border-color: #292c40; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(94, 99, 126, 0.5); }

.btn-neutral {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-neutral:hover {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-neutral:focus, .btn-neutral.focus {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-neutral.disabled, .btn-neutral:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-neutral.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-soft {
  color: #0d1b48;
  background-color: #f5f9fe;
  border-color: #f5f9fe;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-soft:hover {
    color: #0d1b48;
    background-color: #d2e4fb;
    border-color: #c7ddf9; }
  .btn-soft:focus, .btn-soft.focus {
    color: #0d1b48;
    background-color: #d2e4fb;
    border-color: #c7ddf9;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }
  .btn-soft.disabled, .btn-soft:disabled {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
  .btn-soft:not(:disabled):not(.disabled):active, .btn-soft:not(:disabled):not(.disabled).active,
  .show > .btn-soft.dropdown-toggle {
    color: #0d1b48;
    background-color: #c7ddf9;
    border-color: #bbd6f8; }
    .btn-soft:not(:disabled):not(.disabled):active:focus, .btn-soft:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soft.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }

.btn-black {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-black:hover {
    color: #ffffff;
    background-color: #070f28;
    border-color: #050b1d; }
  .btn-black:focus, .btn-black.focus {
    color: #ffffff;
    background-color: #070f28;
    border-color: #050b1d;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #ffffff;
    background-color: #050b1d;
    border-color: #030712; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }

.btn-purple {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-purple:hover {
    color: #ffffff;
    background-color: #5e37a6;
    border-color: #59339d; }
  .btn-purple:focus, .btn-purple.focus {
    color: #ffffff;
    background-color: #5e37a6;
    border-color: #59339d;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(133, 94, 202, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #59339d;
    border-color: #533093; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(133, 94, 202, 0.5); }

.btn-soft-green {
  color: #0d1b48;
  background-color: #ebf9ee;
  border-color: #ebf9ee;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-soft-green:hover {
    color: #0d1b48;
    background-color: #cef0d5;
    border-color: #c4edcd; }
  .btn-soft-green:focus, .btn-soft-green.focus {
    color: #0d1b48;
    background-color: #cef0d5;
    border-color: #c4edcd;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(202, 216, 213, 0.5); }
  .btn-soft-green.disabled, .btn-soft-green:disabled {
    color: #0d1b48;
    background-color: #ebf9ee;
    border-color: #ebf9ee; }
  .btn-soft-green:not(:disabled):not(.disabled):active, .btn-soft-green:not(:disabled):not(.disabled).active,
  .show > .btn-soft-green.dropdown-toggle {
    color: #0d1b48;
    background-color: #c4edcd;
    border-color: #baeac4; }
    .btn-soft-green:not(:disabled):not(.disabled):active:focus, .btn-soft-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soft-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(202, 216, 213, 0.5); }

.btn-gray-100 {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-100:hover {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    color: #0d1b48;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-gray-200 {
  color: #0d1b48;
  background-color: #fafbfe;
  border-color: #fafbfe;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-200:hover {
    color: #0d1b48;
    background-color: #dae2f8;
    border-color: #d0d9f6; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    color: #0d1b48;
    background-color: #dae2f8;
    border-color: #d0d9f6;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(214, 217, 227, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #0d1b48;
    background-color: #fafbfe;
    border-color: #fafbfe; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #0d1b48;
    background-color: #d0d9f6;
    border-color: #c5d1f3; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(214, 217, 227, 0.5); }

.btn-gray-300 {
  color: #0d1b48;
  background-color: #f5f9fe;
  border-color: #f5f9fe;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-300:hover {
    color: #0d1b48;
    background-color: #d2e4fb;
    border-color: #c7ddf9; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    color: #0d1b48;
    background-color: #d2e4fb;
    border-color: #c7ddf9;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #0d1b48;
    background-color: #c7ddf9;
    border-color: #bbd6f8; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }

.btn-gray-400 {
  color: #0d1b48;
  background-color: #edf0f7;
  border-color: #edf0f7;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-400:hover {
    color: #0d1b48;
    background-color: #d3daeb;
    border-color: #cad3e7; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    color: #0d1b48;
    background-color: #d3daeb;
    border-color: #cad3e7;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #0d1b48;
    background-color: #cad3e7;
    border-color: #c1cbe3; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }

.btn-gray-500 {
  color: #0d1b48;
  background-color: #B7C3D2;
  border-color: #B7C3D2;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-500:hover {
    color: #0d1b48;
    background-color: #9fafc3;
    border-color: #98a9be; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    color: #0d1b48;
    background-color: #9fafc3;
    border-color: #98a9be;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(158, 170, 189, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #0d1b48;
    background-color: #B7C3D2;
    border-color: #B7C3D2; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #0d1b48;
    background-color: #98a9be;
    border-color: #90a2b9; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(158, 170, 189, 0.5); }

.btn-gray-600 {
  color: #0d1b48;
  background-color: #93a5be;
  border-color: #93a5be;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-600:hover {
    color: #ffffff;
    background-color: #7b91b0;
    border-color: #738aab; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    color: #ffffff;
    background-color: #7b91b0;
    border-color: #738aab;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(127, 144, 172, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #0d1b48;
    background-color: #93a5be;
    border-color: #93a5be; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #ffffff;
    background-color: #738aab;
    border-color: #6b84a6; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(127, 144, 172, 0.5); }

.btn-gray-700 {
  color: #ffffff;
  background-color: #52547a;
  border-color: #52547a;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-700:hover {
    color: #ffffff;
    background-color: #434463;
    border-color: #3e3f5c; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    color: #ffffff;
    background-color: #434463;
    border-color: #3e3f5c;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(108, 110, 142, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #ffffff;
    background-color: #52547a;
    border-color: #52547a; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #ffffff;
    background-color: #3e3f5c;
    border-color: #383a54; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 110, 142, 0.5); }

.btn-gray-800 {
  color: #ffffff;
  background-color: #4E5079;
  border-color: #4E5079;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-800:hover {
    color: #ffffff;
    background-color: #3f4162;
    border-color: #3a3b5a; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    color: #ffffff;
    background-color: #3f4162;
    border-color: #3a3b5a;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(105, 106, 141, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #ffffff;
    background-color: #4E5079;
    border-color: #4E5079; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #ffffff;
    background-color: #3a3b5a;
    border-color: #353652; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(105, 106, 141, 0.5); }

.btn-facebook {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-facebook:hover {
    color: #ffffff;
    background-color: #30497d;
    border-color: #2d4474; }
  .btn-facebook:focus, .btn-facebook.focus {
    color: #ffffff;
    background-color: #30497d;
    border-color: #2d4474;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(88, 114, 168, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #ffffff;
    background-color: #3b5999;
    border-color: #3b5999; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #ffffff;
    background-color: #2d4474;
    border-color: #293e6b; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(88, 114, 168, 0.5); }

.btn-twitter {
  color: #ffffff;
  background-color: #1da1f2;
  border-color: #1da1f2;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-twitter:hover {
    color: #ffffff;
    background-color: #0d8ddc;
    border-color: #0c85d0; }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #ffffff;
    background-color: #0d8ddc;
    border-color: #0c85d0;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(63, 175, 244, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #ffffff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(63, 175, 244, 0.5); }

.btn-google {
  color: #ffffff;
  background-color: #DB4337;
  border-color: #DB4337;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-google:hover {
    color: #ffffff;
    background-color: #c83024;
    border-color: #bd2d22; }
  .btn-google:focus, .btn-google.focus {
    color: #ffffff;
    background-color: #c83024;
    border-color: #bd2d22;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(224, 95, 85, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    color: #ffffff;
    background-color: #DB4337;
    border-color: #DB4337; }
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #ffffff;
    background-color: #bd2d22;
    border-color: #b22b20; }
    .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(224, 95, 85, 0.5); }

.btn-instagram {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-instagram:hover {
    color: #ffffff;
    background-color: #de1f44;
    border-color: #d31e40; }
  .btn-instagram:focus, .btn-instagram.focus {
    color: #ffffff;
    background-color: #de1f44;
    border-color: #d31e40;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(232, 93, 119, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #ffffff;
    background-color: #d31e40;
    border-color: #c81c3d; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(232, 93, 119, 0.5); }

.btn-pinterest {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-pinterest:hover {
    color: #ffffff;
    background-color: #980617;
    border-color: #8c0615; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    color: #ffffff;
    background-color: #980617;
    border-color: #8c0615;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(199, 45, 62, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #ffffff;
    background-color: #8c0615;
    border-color: #800513; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(199, 45, 62, 0.5); }

.btn-youtube {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-youtube:hover {
    color: #ffffff;
    background-color: #ac1b1a;
    border-color: #a11918; }
  .btn-youtube:focus, .btn-youtube.focus {
    color: #ffffff;
    background-color: #ac1b1a;
    border-color: #a11918;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(213, 65, 65, 0.5); }
  .btn-youtube.disabled, .btn-youtube:disabled {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-youtube.dropdown-toggle {
    color: #ffffff;
    background-color: #a11918;
    border-color: #961717; }
    .btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(213, 65, 65, 0.5); }

.btn-slack {
  color: #ffffff;
  background-color: #3aaf85;
  border-color: #3aaf85;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-slack:hover {
    color: #ffffff;
    background-color: #30926f;
    border-color: #2d8968; }
  .btn-slack:focus, .btn-slack.focus {
    color: #ffffff;
    background-color: #30926f;
    border-color: #2d8968;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(88, 187, 151, 0.5); }
  .btn-slack.disabled, .btn-slack:disabled {
    color: #ffffff;
    background-color: #3aaf85;
    border-color: #3aaf85; }
  .btn-slack:not(:disabled):not(.disabled):active, .btn-slack:not(:disabled):not(.disabled).active,
  .show > .btn-slack.dropdown-toggle {
    color: #ffffff;
    background-color: #2d8968;
    border-color: #2a7f61; }
    .btn-slack:not(:disabled):not(.disabled):active:focus, .btn-slack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(88, 187, 151, 0.5); }

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dribbble:hover {
    color: #ffffff;
    background-color: #e62a72;
    border-color: #e51e6b; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    color: #ffffff;
    background-color: #e62a72;
    border-color: #e51e6b;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(237, 103, 155, 0.5); }
  .btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #ffffff;
    background-color: #e51e6b;
    border-color: #dc1a65; }
    .btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(237, 103, 155, 0.5); }

.btn-dropbox {
  color: #ffffff;
  background-color: #1E90FF;
  border-color: #1E90FF;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dropbox:hover {
    color: #ffffff;
    background-color: #007df7;
    border-color: #0077ea; }
  .btn-dropbox:focus, .btn-dropbox.focus {
    color: #ffffff;
    background-color: #007df7;
    border-color: #0077ea;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(64, 161, 255, 0.5); }
  .btn-dropbox.disabled, .btn-dropbox:disabled {
    color: #ffffff;
    background-color: #1E90FF;
    border-color: #1E90FF; }
  .btn-dropbox:not(:disabled):not(.disabled):active, .btn-dropbox:not(:disabled):not(.disabled).active,
  .show > .btn-dropbox.dropdown-toggle {
    color: #ffffff;
    background-color: #0077ea;
    border-color: #0070dd; }
    .btn-dropbox:not(:disabled):not(.disabled):active:focus, .btn-dropbox:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dropbox.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(64, 161, 255, 0.5); }

.btn-twitch {
  color: #ffffff;
  background-color: #4B367C;
  border-color: #4B367C;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-twitch:hover {
    color: #ffffff;
    background-color: #3b2a61;
    border-color: #362758; }
  .btn-twitch:focus, .btn-twitch.focus {
    color: #ffffff;
    background-color: #3b2a61;
    border-color: #362758;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(102, 84, 144, 0.5); }
  .btn-twitch.disabled, .btn-twitch:disabled {
    color: #ffffff;
    background-color: #4B367C;
    border-color: #4B367C; }
  .btn-twitch:not(:disabled):not(.disabled):active, .btn-twitch:not(:disabled):not(.disabled).active,
  .show > .btn-twitch.dropdown-toggle {
    color: #ffffff;
    background-color: #362758;
    border-color: #302350; }
    .btn-twitch:not(:disabled):not(.disabled):active:focus, .btn-twitch:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitch.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(102, 84, 144, 0.5); }

.btn-paypal {
  color: #0d1b48;
  background-color: #ecb32c;
  border-color: #ecb32c;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-paypal:hover {
    color: #0d1b48;
    background-color: #dea214;
    border-color: #d29913; }
  .btn-paypal:focus, .btn-paypal.focus {
    color: #0d1b48;
    background-color: #dea214;
    border-color: #d29913;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 156, 48, 0.5); }
  .btn-paypal.disabled, .btn-paypal:disabled {
    color: #0d1b48;
    background-color: #ecb32c;
    border-color: #ecb32c; }
  .btn-paypal:not(:disabled):not(.disabled):active, .btn-paypal:not(:disabled):not(.disabled).active,
  .show > .btn-paypal.dropdown-toggle {
    color: #0d1b48;
    background-color: #d29913;
    border-color: #c69112; }
    .btn-paypal:not(:disabled):not(.disabled):active:focus, .btn-paypal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-paypal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 156, 48, 0.5); }

.btn-behance {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-behance:hover {
    color: #ffffff;
    background-color: #004ad9;
    border-color: #0046cc; }
  .btn-behance:focus, .btn-behance.focus {
    color: #ffffff;
    background-color: #004ad9;
    border-color: #0046cc;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(38, 112, 255, 0.5); }
  .btn-behance.disabled, .btn-behance:disabled {
    color: #ffffff;
    background-color: #0057ff;
    border-color: #0057ff; }
  .btn-behance:not(:disabled):not(.disabled):active, .btn-behance:not(:disabled):not(.disabled).active,
  .show > .btn-behance.dropdown-toggle {
    color: #ffffff;
    background-color: #0046cc;
    border-color: #0041bf; }
    .btn-behance:not(:disabled):not(.disabled):active:focus, .btn-behance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 112, 255, 0.5); }

.btn-reddit {
  color: #ffffff;
  background-color: #E84422;
  border-color: #E84422;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-reddit:hover {
    color: #ffffff;
    background-color: #ce3515;
    border-color: #c33214; }
  .btn-reddit:focus, .btn-reddit.focus {
    color: #ffffff;
    background-color: #ce3515;
    border-color: #c33214;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(235, 96, 67, 0.5); }
  .btn-reddit.disabled, .btn-reddit:disabled {
    color: #ffffff;
    background-color: #E84422;
    border-color: #E84422; }
  .btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-reddit.dropdown-toggle {
    color: #ffffff;
    background-color: #c33214;
    border-color: #b72f13; }
    .btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(235, 96, 67, 0.5); }

.btn-github {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-github:hover {
    color: #ffffff;
    background-color: #0f0f0f;
    border-color: #090909; }
  .btn-github:focus, .btn-github.focus {
    color: #ffffff;
    background-color: #0f0f0f;
    border-color: #090909;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(67, 67, 67, 0.5); }
  .btn-github.disabled, .btn-github:disabled {
    color: #ffffff;
    background-color: #222222;
    border-color: #222222; }
  .btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
  .show > .btn-github.dropdown-toggle {
    color: #ffffff;
    background-color: #090909;
    border-color: #020202; }
    .btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(67, 67, 67, 0.5); }

.btn-outline-primary {
  color: #0d1b48;
  border-color: #0d1b48; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0d1b48;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }

.btn-outline-secondary {
  color: #C33C54;
  border-color: #C33C54; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #C33C54;
    border-color: #C33C54; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.25rem rgba(195, 60, 84, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #C33C54;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #C33C54;
    border-color: #C33C54; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(195, 60, 84, 0.5); }

.btn-outline-success {
  color: #208163;
  border-color: #208163; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #208163;
    border-color: #208163; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.25rem rgba(32, 129, 99, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #208163;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #208163;
    border-color: #208163; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(32, 129, 99, 0.5); }

.btn-outline-info {
  color: #0056B3;
  border-color: #0056B3; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #0056B3;
    border-color: #0056B3; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 86, 179, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #0056B3;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #0056B3;
    border-color: #0056B3; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(0, 86, 179, 0.5); }

.btn-outline-warning {
  color: #F0B400;
  border-color: #F0B400; }
  .btn-outline-warning:hover {
    color: #0d1b48;
    background-color: #F0B400;
    border-color: #F0B400; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.25rem rgba(240, 180, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F0B400;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #0d1b48;
    background-color: #F0B400;
    border-color: #F0B400; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(240, 180, 0, 0.5); }

.btn-outline-danger {
  color: #be2837;
  border-color: #be2837; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #be2837;
    border-color: #be2837; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.25rem rgba(190, 40, 55, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #be2837;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #be2837;
    border-color: #be2837; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(190, 40, 55, 0.5); }

.btn-outline-light {
  color: #edf0f7;
  border-color: #edf0f7; }
  .btn-outline-light:hover {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.25rem rgba(237, 240, 247, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #edf0f7;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(237, 240, 247, 0.5); }

.btn-outline-dark {
  color: #0d1b48;
  border-color: #0d1b48; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #0d1b48;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }

.btn-outline-tertiary {
  color: #26408B;
  border-color: #26408B; }
  .btn-outline-tertiary:hover {
    color: #ffffff;
    background-color: #26408B;
    border-color: #26408B; }
  .btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0.25rem rgba(38, 64, 139, 0.5); }
  .btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
    color: #26408B;
    background-color: transparent; }
  .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #26408B;
    border-color: #26408B; }
    .btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 64, 139, 0.5); }

.btn-outline-lighten {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-lighten:hover {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-lighten:focus, .btn-outline-lighten.focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-lighten.disabled, .btn-outline-lighten:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-lighten:not(:disabled):not(.disabled):active, .btn-outline-lighten:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lighten.dropdown-toggle {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-lighten:not(:disabled):not(.disabled):active:focus, .btn-outline-lighten:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lighten.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.btn-outline-gray {
  color: #424767;
  border-color: #424767; }
  .btn-outline-gray:hover {
    color: #ffffff;
    background-color: #424767;
    border-color: #424767; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.25rem rgba(66, 71, 103, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #424767;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #424767;
    border-color: #424767; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 71, 103, 0.5); }

.btn-outline-neutral {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-neutral:hover {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-neutral:focus, .btn-outline-neutral.focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-neutral.disabled, .btn-outline-neutral:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-neutral:not(:disabled):not(.disabled):active, .btn-outline-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-outline-neutral.dropdown-toggle {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-neutral:not(:disabled):not(.disabled):active:focus, .btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.btn-outline-soft {
  color: #f5f9fe;
  border-color: #f5f9fe; }
  .btn-outline-soft:hover {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
  .btn-outline-soft:focus, .btn-outline-soft.focus {
    box-shadow: 0 0 0 0.25rem rgba(245, 249, 254, 0.5); }
  .btn-outline-soft.disabled, .btn-outline-soft:disabled {
    color: #f5f9fe;
    background-color: transparent; }
  .btn-outline-soft:not(:disabled):not(.disabled):active, .btn-outline-soft:not(:disabled):not(.disabled).active,
  .show > .btn-outline-soft.dropdown-toggle {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
    .btn-outline-soft:not(:disabled):not(.disabled):active:focus, .btn-outline-soft:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-soft.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(245, 249, 254, 0.5); }

.btn-outline-black {
  color: #0d1b48;
  border-color: #0d1b48; }
  .btn-outline-black:hover {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #0d1b48;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }

.btn-outline-purple {
  color: #6f42c1;
  border-color: #6f42c1; }
  .btn-outline-purple:hover {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.25rem rgba(111, 66, 193, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(111, 66, 193, 0.5); }

.btn-outline-soft-green {
  color: #ebf9ee;
  border-color: #ebf9ee; }
  .btn-outline-soft-green:hover {
    color: #0d1b48;
    background-color: #ebf9ee;
    border-color: #ebf9ee; }
  .btn-outline-soft-green:focus, .btn-outline-soft-green.focus {
    box-shadow: 0 0 0 0.25rem rgba(235, 249, 238, 0.5); }
  .btn-outline-soft-green.disabled, .btn-outline-soft-green:disabled {
    color: #ebf9ee;
    background-color: transparent; }
  .btn-outline-soft-green:not(:disabled):not(.disabled):active, .btn-outline-soft-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-soft-green.dropdown-toggle {
    color: #0d1b48;
    background-color: #ebf9ee;
    border-color: #ebf9ee; }
    .btn-outline-soft-green:not(:disabled):not(.disabled):active:focus, .btn-outline-soft-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-soft-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(235, 249, 238, 0.5); }

.btn-outline-gray-100 {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-gray-100:hover {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.btn-outline-gray-200 {
  color: #fafbfe;
  border-color: #fafbfe; }
  .btn-outline-gray-200:hover {
    color: #0d1b48;
    background-color: #fafbfe;
    border-color: #fafbfe; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    box-shadow: 0 0 0 0.25rem rgba(250, 251, 254, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #fafbfe;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #0d1b48;
    background-color: #fafbfe;
    border-color: #fafbfe; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(250, 251, 254, 0.5); }

.btn-outline-gray-300 {
  color: #f5f9fe;
  border-color: #f5f9fe; }
  .btn-outline-gray-300:hover {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    box-shadow: 0 0 0 0.25rem rgba(245, 249, 254, 0.5); }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #f5f9fe;
    background-color: transparent; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
    .btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(245, 249, 254, 0.5); }

.btn-outline-gray-400 {
  color: #edf0f7;
  border-color: #edf0f7; }
  .btn-outline-gray-400:hover {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    box-shadow: 0 0 0 0.25rem rgba(237, 240, 247, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #edf0f7;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(237, 240, 247, 0.5); }

.btn-outline-gray-500 {
  color: #B7C3D2;
  border-color: #B7C3D2; }
  .btn-outline-gray-500:hover {
    color: #0d1b48;
    background-color: #B7C3D2;
    border-color: #B7C3D2; }
  .btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    box-shadow: 0 0 0 0.25rem rgba(183, 195, 210, 0.5); }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #B7C3D2;
    background-color: transparent; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #0d1b48;
    background-color: #B7C3D2;
    border-color: #B7C3D2; }
    .btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(183, 195, 210, 0.5); }

.btn-outline-gray-600 {
  color: #93a5be;
  border-color: #93a5be; }
  .btn-outline-gray-600:hover {
    color: #0d1b48;
    background-color: #93a5be;
    border-color: #93a5be; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    box-shadow: 0 0 0 0.25rem rgba(147, 165, 190, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #93a5be;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #0d1b48;
    background-color: #93a5be;
    border-color: #93a5be; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(147, 165, 190, 0.5); }

.btn-outline-gray-700 {
  color: #52547a;
  border-color: #52547a; }
  .btn-outline-gray-700:hover {
    color: #ffffff;
    background-color: #52547a;
    border-color: #52547a; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    box-shadow: 0 0 0 0.25rem rgba(82, 84, 122, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #52547a;
    background-color: transparent; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #ffffff;
    background-color: #52547a;
    border-color: #52547a; }
    .btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(82, 84, 122, 0.5); }

.btn-outline-gray-800 {
  color: #4E5079;
  border-color: #4E5079; }
  .btn-outline-gray-800:hover {
    color: #ffffff;
    background-color: #4E5079;
    border-color: #4E5079; }
  .btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    box-shadow: 0 0 0 0.25rem rgba(78, 80, 121, 0.5); }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #4E5079;
    background-color: transparent; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #ffffff;
    background-color: #4E5079;
    border-color: #4E5079; }
    .btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(78, 80, 121, 0.5); }

.btn-outline-facebook {
  color: #3b5999;
  border-color: #3b5999; }
  .btn-outline-facebook:hover {
    color: #ffffff;
    background-color: #3b5999;
    border-color: #3b5999; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    box-shadow: 0 0 0 0.25rem rgba(59, 89, 153, 0.5); }
  .btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
    color: #3b5999;
    background-color: transparent; }
  .btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-outline-facebook.dropdown-toggle {
    color: #ffffff;
    background-color: #3b5999;
    border-color: #3b5999; }
    .btn-outline-facebook:not(:disabled):not(.disabled):active:focus, .btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(59, 89, 153, 0.5); }

.btn-outline-twitter {
  color: #1da1f2;
  border-color: #1da1f2; }
  .btn-outline-twitter:hover {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.25rem rgba(29, 161, 242, 0.5); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    color: #1da1f2;
    background-color: transparent; }
  .btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
    .btn-outline-twitter:not(:disabled):not(.disabled):active:focus, .btn-outline-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(29, 161, 242, 0.5); }

.btn-outline-google {
  color: #DB4337;
  border-color: #DB4337; }
  .btn-outline-google:hover {
    color: #ffffff;
    background-color: #DB4337;
    border-color: #DB4337; }
  .btn-outline-google:focus, .btn-outline-google.focus {
    box-shadow: 0 0 0 0.25rem rgba(219, 67, 55, 0.5); }
  .btn-outline-google.disabled, .btn-outline-google:disabled {
    color: #DB4337;
    background-color: transparent; }
  .btn-outline-google:not(:disabled):not(.disabled):active, .btn-outline-google:not(:disabled):not(.disabled).active,
  .show > .btn-outline-google.dropdown-toggle {
    color: #ffffff;
    background-color: #DB4337;
    border-color: #DB4337; }
    .btn-outline-google:not(:disabled):not(.disabled):active:focus, .btn-outline-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 67, 55, 0.5); }

.btn-outline-instagram {
  color: #e4405f;
  border-color: #e4405f; }
  .btn-outline-instagram:hover {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }
  .btn-outline-instagram:focus, .btn-outline-instagram.focus {
    box-shadow: 0 0 0 0.25rem rgba(228, 64, 95, 0.5); }
  .btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
    color: #e4405f;
    background-color: transparent; }
  .btn-outline-instagram:not(:disabled):not(.disabled):active, .btn-outline-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-outline-instagram.dropdown-toggle {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }
    .btn-outline-instagram:not(:disabled):not(.disabled):active:focus, .btn-outline-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(228, 64, 95, 0.5); }

.btn-outline-pinterest {
  color: #bd081c;
  border-color: #bd081c; }
  .btn-outline-pinterest:hover {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }
  .btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
    box-shadow: 0 0 0 0.25rem rgba(189, 8, 28, 0.5); }
  .btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
    color: #bd081c;
    background-color: transparent; }
  .btn-outline-pinterest:not(:disabled):not(.disabled):active, .btn-outline-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pinterest.dropdown-toggle {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }
    .btn-outline-pinterest:not(:disabled):not(.disabled):active:focus, .btn-outline-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(189, 8, 28, 0.5); }

.btn-outline-youtube {
  color: #cd201f;
  border-color: #cd201f; }
  .btn-outline-youtube:hover {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-outline-youtube:focus, .btn-outline-youtube.focus {
    box-shadow: 0 0 0 0.25rem rgba(205, 32, 31, 0.5); }
  .btn-outline-youtube.disabled, .btn-outline-youtube:disabled {
    color: #cd201f;
    background-color: transparent; }
  .btn-outline-youtube:not(:disabled):not(.disabled):active, .btn-outline-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-outline-youtube.dropdown-toggle {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f; }
    .btn-outline-youtube:not(:disabled):not(.disabled):active:focus, .btn-outline-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(205, 32, 31, 0.5); }

.btn-outline-slack {
  color: #3aaf85;
  border-color: #3aaf85; }
  .btn-outline-slack:hover {
    color: #ffffff;
    background-color: #3aaf85;
    border-color: #3aaf85; }
  .btn-outline-slack:focus, .btn-outline-slack.focus {
    box-shadow: 0 0 0 0.25rem rgba(58, 175, 133, 0.5); }
  .btn-outline-slack.disabled, .btn-outline-slack:disabled {
    color: #3aaf85;
    background-color: transparent; }
  .btn-outline-slack:not(:disabled):not(.disabled):active, .btn-outline-slack:not(:disabled):not(.disabled).active,
  .show > .btn-outline-slack.dropdown-toggle {
    color: #ffffff;
    background-color: #3aaf85;
    border-color: #3aaf85; }
    .btn-outline-slack:not(:disabled):not(.disabled):active:focus, .btn-outline-slack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-slack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(58, 175, 133, 0.5); }

.btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89; }
  .btn-outline-dribbble:hover {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-outline-dribbble:focus, .btn-outline-dribbble.focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 76, 137, 0.5); }
  .btn-outline-dribbble.disabled, .btn-outline-dribbble:disabled {
    color: #ea4c89;
    background-color: transparent; }
  .btn-outline-dribbble:not(:disabled):not(.disabled):active, .btn-outline-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dribbble.dropdown-toggle {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
    .btn-outline-dribbble:not(:disabled):not(.disabled):active:focus, .btn-outline-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(234, 76, 137, 0.5); }

.btn-outline-dropbox {
  color: #1E90FF;
  border-color: #1E90FF; }
  .btn-outline-dropbox:hover {
    color: #ffffff;
    background-color: #1E90FF;
    border-color: #1E90FF; }
  .btn-outline-dropbox:focus, .btn-outline-dropbox.focus {
    box-shadow: 0 0 0 0.25rem rgba(30, 144, 255, 0.5); }
  .btn-outline-dropbox.disabled, .btn-outline-dropbox:disabled {
    color: #1E90FF;
    background-color: transparent; }
  .btn-outline-dropbox:not(:disabled):not(.disabled):active, .btn-outline-dropbox:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dropbox.dropdown-toggle {
    color: #ffffff;
    background-color: #1E90FF;
    border-color: #1E90FF; }
    .btn-outline-dropbox:not(:disabled):not(.disabled):active:focus, .btn-outline-dropbox:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dropbox.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(30, 144, 255, 0.5); }

.btn-outline-twitch {
  color: #4B367C;
  border-color: #4B367C; }
  .btn-outline-twitch:hover {
    color: #ffffff;
    background-color: #4B367C;
    border-color: #4B367C; }
  .btn-outline-twitch:focus, .btn-outline-twitch.focus {
    box-shadow: 0 0 0 0.25rem rgba(75, 54, 124, 0.5); }
  .btn-outline-twitch.disabled, .btn-outline-twitch:disabled {
    color: #4B367C;
    background-color: transparent; }
  .btn-outline-twitch:not(:disabled):not(.disabled):active, .btn-outline-twitch:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitch.dropdown-toggle {
    color: #ffffff;
    background-color: #4B367C;
    border-color: #4B367C; }
    .btn-outline-twitch:not(:disabled):not(.disabled):active:focus, .btn-outline-twitch:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-twitch.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(75, 54, 124, 0.5); }

.btn-outline-paypal {
  color: #ecb32c;
  border-color: #ecb32c; }
  .btn-outline-paypal:hover {
    color: #0d1b48;
    background-color: #ecb32c;
    border-color: #ecb32c; }
  .btn-outline-paypal:focus, .btn-outline-paypal.focus {
    box-shadow: 0 0 0 0.25rem rgba(236, 179, 44, 0.5); }
  .btn-outline-paypal.disabled, .btn-outline-paypal:disabled {
    color: #ecb32c;
    background-color: transparent; }
  .btn-outline-paypal:not(:disabled):not(.disabled):active, .btn-outline-paypal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-paypal.dropdown-toggle {
    color: #0d1b48;
    background-color: #ecb32c;
    border-color: #ecb32c; }
    .btn-outline-paypal:not(:disabled):not(.disabled):active:focus, .btn-outline-paypal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-paypal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(236, 179, 44, 0.5); }

.btn-outline-behance {
  color: #0057ff;
  border-color: #0057ff; }
  .btn-outline-behance:hover {
    color: #ffffff;
    background-color: #0057ff;
    border-color: #0057ff; }
  .btn-outline-behance:focus, .btn-outline-behance.focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 87, 255, 0.5); }
  .btn-outline-behance.disabled, .btn-outline-behance:disabled {
    color: #0057ff;
    background-color: transparent; }
  .btn-outline-behance:not(:disabled):not(.disabled):active, .btn-outline-behance:not(:disabled):not(.disabled).active,
  .show > .btn-outline-behance.dropdown-toggle {
    color: #ffffff;
    background-color: #0057ff;
    border-color: #0057ff; }
    .btn-outline-behance:not(:disabled):not(.disabled):active:focus, .btn-outline-behance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(0, 87, 255, 0.5); }

.btn-outline-reddit {
  color: #E84422;
  border-color: #E84422; }
  .btn-outline-reddit:hover {
    color: #ffffff;
    background-color: #E84422;
    border-color: #E84422; }
  .btn-outline-reddit:focus, .btn-outline-reddit.focus {
    box-shadow: 0 0 0 0.25rem rgba(232, 68, 34, 0.5); }
  .btn-outline-reddit.disabled, .btn-outline-reddit:disabled {
    color: #E84422;
    background-color: transparent; }
  .btn-outline-reddit:not(:disabled):not(.disabled):active, .btn-outline-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-outline-reddit.dropdown-toggle {
    color: #ffffff;
    background-color: #E84422;
    border-color: #E84422; }
    .btn-outline-reddit:not(:disabled):not(.disabled):active:focus, .btn-outline-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(232, 68, 34, 0.5); }

.btn-outline-github {
  color: #222222;
  border-color: #222222; }
  .btn-outline-github:hover {
    color: #ffffff;
    background-color: #222222;
    border-color: #222222; }
  .btn-outline-github:focus, .btn-outline-github.focus {
    box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5); }
  .btn-outline-github.disabled, .btn-outline-github:disabled {
    color: #222222;
    background-color: transparent; }
  .btn-outline-github:not(:disabled):not(.disabled):active, .btn-outline-github:not(:disabled):not(.disabled).active,
  .show > .btn-outline-github.dropdown-toggle {
    color: #ffffff;
    background-color: #222222;
    border-color: #222222; }
    .btn-outline-github:not(:disabled):not(.disabled):active:focus, .btn-outline-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5); }

.btn-link {
  font-weight: 400;
  color: #C33C54;
  text-decoration: none; }
  .btn-link:hover {
    color: #9c3043;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #93a5be;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.875rem 0.875rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.7rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #424767;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(13, 27, 72, 0.15);
  border-radius: 0.45rem;
  box-shadow: 0 1rem 3rem rgba(13, 27, 72, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #fafbfe; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #424767;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #383c57;
    text-decoration: none;
    background-color: #ffffff; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #edf0f7; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #93a5be;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.7rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #424767;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #424767; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.7125rem;
  padding-left: 0.7125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: none; }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -0.0625rem; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -0.0625rem; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -0.0625rem; }

.input-group-prepend {
  margin-right: -0.0625rem; }

.input-group-append {
  margin-left: -0.0625rem; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4E5079;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 0.0625rem solid #edf0f7;
  border-radius: 0.45rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.425rem; }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.875rem 0.875rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.45rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.8125rem); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 3rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.25rem;
  height: 1.375rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #0d1b48;
    background-color: #0d1b48;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05), none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #B7C3D2; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #2e57d9;
    border-color: #0d1b48;
    box-shadow: none; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #4E5079; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #fafbfe; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.125rem;
    left: -3rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #B7C3D2 solid 1px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05); }
  .custom-control-label::after {
    position: absolute;
    top: 0.125rem;
    left: -3rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.85rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: "Font Awesome 5 Free"; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0d1b48;
  background-color: #0d1b48;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(13, 27, 72, 0.7); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(13, 27, 72, 0.7); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(13, 27, 72, 0.7); }

.custom-switch {
  padding-left: 3.9375rem; }
  .custom-switch .custom-control-label::before {
    left: -3.9375rem;
    width: 2.1875rem;
    pointer-events: all;
    border-radius: 0.625rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.125rem + 2px);
    left: calc(-3.9375rem + 2px);
    width: calc(1.25rem - 4px);
    height: calc(1.25rem - 4px);
    background-color: #B7C3D2;
    border-radius: 0.625rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.9375rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(13, 27, 72, 0.7); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.2625rem);
  padding: 0.6rem 1.75rem 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #424767;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234E5079' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 0.0625rem solid #edf0f7;
  border-radius: 0.45rem;
  box-shadow: inset 0 1px 2px rgba(13, 27, 72, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #B7C3D2;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(13, 27, 72, 0.075), 0 0 0 0 #0d1b48; }
    .custom-select:focus::-ms-value {
      color: #424767;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #93a5be;
    background-color: #fafbfe; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #424767; }

.custom-select-sm {
  height: calc(1.5em + 0.8125rem);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: 3.425rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 0.875rem;
  font-size: 1.125rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.2625rem);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.2625rem);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #B7C3D2;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #fafbfe; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.2625rem);
  padding: 0.6rem 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  color: #424767;
  background-color: #ffffff;
  border: 0.0625rem solid #edf0f7;
  border-radius: 0.45rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.2rem);
    padding: 0.6rem 0.75rem;
    line-height: 1.5;
    color: #424767;
    content: "Browse";
    background-color: #ffffff;
    border-left: inherit;
    border-radius: 0 0.45rem 0.45rem 0; }

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d1b48;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(13, 27, 72, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #2e57d9; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f5f9fe;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(13, 27, 72, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d1b48;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(13, 27, 72, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #2e57d9; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f5f9fe;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(13, 27, 72, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #0d1b48;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(13, 27, 72, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #2e57d9; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(13, 27, 72, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #f5f9fe;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #f5f9fe;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #B7C3D2; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #B7C3D2; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #B7C3D2; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.25rem 0.75rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #93a5be;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 0.0625rem solid #edf0f7; }
  .nav-tabs .nav-item {
    margin-bottom: -0.0625rem; }
  .nav-tabs .nav-link {
    border: 0.0625rem solid transparent;
    border-top-left-radius: 0.45rem;
    border-top-right-radius: 0.45rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #fafbfe #fafbfe #edf0f7; }
    .nav-tabs .nav-link.disabled {
      color: #93a5be;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #edf0f7; }
  .nav-tabs .dropdown-menu {
    margin-top: -0.0625rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.45rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #0d1b48; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.45rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(78, 80, 121, 0.8); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(78, 80, 121, 0.8); }

.navbar-light .navbar-nav .nav-link {
  color: #52547a; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #4E5079; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(78, 80, 121, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(78, 80, 121, 0.8); }

.navbar-light .navbar-toggler {
  color: #52547a;
  border-color: transparent; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2352547a' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: #52547a; }
  .navbar-light .navbar-text a {
    color: rgba(78, 80, 121, 0.8); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(78, 80, 121, 0.8); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #ffffff; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(255, 255, 255, 0.05);
  border-radius: 0.45rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.45rem - 0.0625rem);
      border-top-right-radius: calc(0.45rem - 0.0625rem); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.45rem - 0.0625rem);
      border-bottom-left-radius: calc(0.45rem - 0.0625rem); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem; }

.card-title {
  margin-bottom: 1.25rem; }

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.05); }
  .card-header:first-child {
    border-radius: calc(0.45rem - 0.0625rem) calc(0.45rem - 0.0625rem) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: #ffffff;
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.05); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.45rem - 0.0625rem) calc(0.45rem - 0.0625rem); }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.25rem;
  margin-left: -0.75rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.45rem - 0.0625rem);
  border-top-right-radius: calc(0.45rem - 0.0625rem); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.45rem - 0.0625rem);
  border-bottom-left-radius: calc(0.45rem - 0.0625rem); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.25rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -0.0625rem; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fafbfe;
  border-radius: 0.45rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #52547a;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #ffffff; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.45rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: #4E5079;
  background-color: #ffffff;
  border: 0.0625rem solid #edf0f7; }
  .page-link:hover {
    z-index: 2;
    color: #4E5079;
    text-decoration: none;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.45rem;
  border-bottom-left-radius: 0.45rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.45rem;
  border-bottom-right-radius: 0.45rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48; }

.page-item.disabled .page-link {
  color: #4E5079;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #f5f9fe; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.45rem;
  border-bottom-left-radius: 0.45rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.45rem;
  border-bottom-right-radius: 0.45rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem; }

.badge {
  display: inline-block;
  padding: 0.1rem 0.4rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.45rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #0d1b48; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #050b1d; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }

.badge-secondary {
  color: #ffffff;
  background-color: #C33C54; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #ffffff;
    background-color: #9c3043; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(195, 60, 84, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #208163; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #165844; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(32, 129, 99, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #0056B3; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #003d80; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 86, 179, 0.5); }

.badge-warning {
  color: #0d1b48;
  background-color: #F0B400; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #0d1b48;
    background-color: #bd8e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(240, 180, 0, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #be2837; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #941f2b; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(190, 40, 55, 0.5); }

.badge-light {
  color: #0d1b48;
  background-color: #edf0f7; }
  a.badge-light:hover, a.badge-light:focus {
    color: #0d1b48;
    background-color: #cad3e7; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(237, 240, 247, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #0d1b48; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #050b1d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }

.badge-tertiary {
  color: #ffffff;
  background-color: #26408B; }
  a.badge-tertiary:hover, a.badge-tertiary:focus {
    color: #ffffff;
    background-color: #1b2e63; }
  a.badge-tertiary:focus, a.badge-tertiary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(38, 64, 139, 0.5); }

.badge-lighten {
  color: #0d1b48;
  background-color: #ffffff; }
  a.badge-lighten:hover, a.badge-lighten:focus {
    color: #0d1b48;
    background-color: #e6e6e6; }
  a.badge-lighten:focus, a.badge-lighten.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.badge-white {
  color: #0d1b48;
  background-color: #ffffff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #0d1b48;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.badge-gray {
  color: #ffffff;
  background-color: #424767; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #ffffff;
    background-color: #2e3248; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(66, 71, 103, 0.5); }

.badge-neutral {
  color: #0d1b48;
  background-color: #ffffff; }
  a.badge-neutral:hover, a.badge-neutral:focus {
    color: #0d1b48;
    background-color: #e6e6e6; }
  a.badge-neutral:focus, a.badge-neutral.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.badge-soft {
  color: #0d1b48;
  background-color: #f5f9fe; }
  a.badge-soft:hover, a.badge-soft:focus {
    color: #0d1b48;
    background-color: #c7ddf9; }
  a.badge-soft:focus, a.badge-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(245, 249, 254, 0.5); }

.badge-black {
  color: #ffffff;
  background-color: #0d1b48; }
  a.badge-black:hover, a.badge-black:focus {
    color: #ffffff;
    background-color: #050b1d; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 27, 72, 0.5); }

.badge-purple {
  color: #ffffff;
  background-color: #6f42c1; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #ffffff;
    background-color: #59339d; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(111, 66, 193, 0.5); }

.badge-soft-green {
  color: #0d1b48;
  background-color: #ebf9ee; }
  a.badge-soft-green:hover, a.badge-soft-green:focus {
    color: #0d1b48;
    background-color: #c4edcd; }
  a.badge-soft-green:focus, a.badge-soft-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(235, 249, 238, 0.5); }

.badge-gray-100 {
  color: #0d1b48;
  background-color: #ffffff; }
  a.badge-gray-100:hover, a.badge-gray-100:focus {
    color: #0d1b48;
    background-color: #e6e6e6; }
  a.badge-gray-100:focus, a.badge-gray-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }

.badge-gray-200 {
  color: #0d1b48;
  background-color: #fafbfe; }
  a.badge-gray-200:hover, a.badge-gray-200:focus {
    color: #0d1b48;
    background-color: #d0d9f6; }
  a.badge-gray-200:focus, a.badge-gray-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(250, 251, 254, 0.5); }

.badge-gray-300 {
  color: #0d1b48;
  background-color: #f5f9fe; }
  a.badge-gray-300:hover, a.badge-gray-300:focus {
    color: #0d1b48;
    background-color: #c7ddf9; }
  a.badge-gray-300:focus, a.badge-gray-300.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(245, 249, 254, 0.5); }

.badge-gray-400 {
  color: #0d1b48;
  background-color: #edf0f7; }
  a.badge-gray-400:hover, a.badge-gray-400:focus {
    color: #0d1b48;
    background-color: #cad3e7; }
  a.badge-gray-400:focus, a.badge-gray-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(237, 240, 247, 0.5); }

.badge-gray-500 {
  color: #0d1b48;
  background-color: #B7C3D2; }
  a.badge-gray-500:hover, a.badge-gray-500:focus {
    color: #0d1b48;
    background-color: #98a9be; }
  a.badge-gray-500:focus, a.badge-gray-500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(183, 195, 210, 0.5); }

.badge-gray-600 {
  color: #0d1b48;
  background-color: #93a5be; }
  a.badge-gray-600:hover, a.badge-gray-600:focus {
    color: #0d1b48;
    background-color: #738aab; }
  a.badge-gray-600:focus, a.badge-gray-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(147, 165, 190, 0.5); }

.badge-gray-700 {
  color: #ffffff;
  background-color: #52547a; }
  a.badge-gray-700:hover, a.badge-gray-700:focus {
    color: #ffffff;
    background-color: #3e3f5c; }
  a.badge-gray-700:focus, a.badge-gray-700.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(82, 84, 122, 0.5); }

.badge-gray-800 {
  color: #ffffff;
  background-color: #4E5079; }
  a.badge-gray-800:hover, a.badge-gray-800:focus {
    color: #ffffff;
    background-color: #3a3b5a; }
  a.badge-gray-800:focus, a.badge-gray-800.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(78, 80, 121, 0.5); }

.badge-facebook {
  color: #ffffff;
  background-color: #3b5999; }
  a.badge-facebook:hover, a.badge-facebook:focus {
    color: #ffffff;
    background-color: #2d4474; }
  a.badge-facebook:focus, a.badge-facebook.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(59, 89, 153, 0.5); }

.badge-twitter {
  color: #ffffff;
  background-color: #1da1f2; }
  a.badge-twitter:hover, a.badge-twitter:focus {
    color: #ffffff;
    background-color: #0c85d0; }
  a.badge-twitter:focus, a.badge-twitter.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(29, 161, 242, 0.5); }

.badge-google {
  color: #ffffff;
  background-color: #DB4337; }
  a.badge-google:hover, a.badge-google:focus {
    color: #ffffff;
    background-color: #bd2d22; }
  a.badge-google:focus, a.badge-google.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(219, 67, 55, 0.5); }

.badge-instagram {
  color: #ffffff;
  background-color: #e4405f; }
  a.badge-instagram:hover, a.badge-instagram:focus {
    color: #ffffff;
    background-color: #d31e40; }
  a.badge-instagram:focus, a.badge-instagram.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(228, 64, 95, 0.5); }

.badge-pinterest {
  color: #ffffff;
  background-color: #bd081c; }
  a.badge-pinterest:hover, a.badge-pinterest:focus {
    color: #ffffff;
    background-color: #8c0615; }
  a.badge-pinterest:focus, a.badge-pinterest.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(189, 8, 28, 0.5); }

.badge-youtube {
  color: #ffffff;
  background-color: #cd201f; }
  a.badge-youtube:hover, a.badge-youtube:focus {
    color: #ffffff;
    background-color: #a11918; }
  a.badge-youtube:focus, a.badge-youtube.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(205, 32, 31, 0.5); }

.badge-slack {
  color: #ffffff;
  background-color: #3aaf85; }
  a.badge-slack:hover, a.badge-slack:focus {
    color: #ffffff;
    background-color: #2d8968; }
  a.badge-slack:focus, a.badge-slack.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(58, 175, 133, 0.5); }

.badge-dribbble {
  color: #ffffff;
  background-color: #ea4c89; }
  a.badge-dribbble:hover, a.badge-dribbble:focus {
    color: #ffffff;
    background-color: #e51e6b; }
  a.badge-dribbble:focus, a.badge-dribbble.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(234, 76, 137, 0.5); }

.badge-dropbox {
  color: #ffffff;
  background-color: #1E90FF; }
  a.badge-dropbox:hover, a.badge-dropbox:focus {
    color: #ffffff;
    background-color: #0077ea; }
  a.badge-dropbox:focus, a.badge-dropbox.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(30, 144, 255, 0.5); }

.badge-twitch {
  color: #ffffff;
  background-color: #4B367C; }
  a.badge-twitch:hover, a.badge-twitch:focus {
    color: #ffffff;
    background-color: #362758; }
  a.badge-twitch:focus, a.badge-twitch.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(75, 54, 124, 0.5); }

.badge-paypal {
  color: #0d1b48;
  background-color: #ecb32c; }
  a.badge-paypal:hover, a.badge-paypal:focus {
    color: #0d1b48;
    background-color: #d29913; }
  a.badge-paypal:focus, a.badge-paypal.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(236, 179, 44, 0.5); }

.badge-behance {
  color: #ffffff;
  background-color: #0057ff; }
  a.badge-behance:hover, a.badge-behance:focus {
    color: #ffffff;
    background-color: #0046cc; }
  a.badge-behance:focus, a.badge-behance.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 87, 255, 0.5); }

.badge-reddit {
  color: #ffffff;
  background-color: #E84422; }
  a.badge-reddit:hover, a.badge-reddit:focus {
    color: #ffffff;
    background-color: #c33214; }
  a.badge-reddit:focus, a.badge-reddit.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(232, 68, 34, 0.5); }

.badge-github {
  color: #ffffff;
  background-color: #222222; }
  a.badge-github:hover, a.badge-github:focus {
    color: #ffffff;
    background-color: #090909; }
  a.badge-github:focus, a.badge-github.focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #fafbfe;
  border-radius: 0.45rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.45rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 4.5rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1.5rem;
    color: inherit; }

.alert-primary {
  color: #0d1b48;
  background-color: #202d57;
  border-color: #0d1b48; }
  .alert-primary hr {
    border-top-color: #091332; }
  .alert-primary .alert-link {
    color: #050b1d; }

.alert-secondary {
  color: #a63752;
  background-color: #c84c62;
  border-color: #b43953; }
  .alert-secondary hr {
    border-top-color: #a1334a; }
  .alert-secondary .alert-link {
    color: #802a3f; }

.alert-success {
  color: #1d715f;
  background-color: #328b6f;
  border-color: #1e7961; }
  .alert-success hr {
    border-top-color: #196551; }
  .alert-success .alert-link {
    color: #13483d; }

.alert-info {
  color: #024da2;
  background-color: #1464b9;
  border-color: #0151aa; }
  .alert-info hr {
    border-top-color: #014591; }
  .alert-info .alert-link {
    color: #013570; }

.alert-warning {
  color: #cc9c0c;
  background-color: #f1ba14;
  border-color: #dea806; }
  .alert-warning hr {
    border-top-color: #c59505; }
  .alert-warning .alert-link {
    color: #9c7709; }

.alert-danger {
  color: #a2263a;
  background-color: #c33947;
  border-color: #b02738; }
  .alert-danger hr {
    border-top-color: #9b2231; }
  .alert-danger .alert-link {
    color: #791c2b; }

.alert-light {
  color: #c9cedb;
  background-color: #eef1f8;
  border-color: #dbdfe9; }
  .alert-light hr {
    border-top-color: #cbd1df; }
  .alert-light .alert-link {
    color: #aab2c7; }

.alert-dark {
  color: #0d1b48;
  background-color: #202d57;
  border-color: #0d1b48; }
  .alert-dark hr {
    border-top-color: #091332; }
  .alert-dark .alert-link {
    color: #050b1d; }

.alert-tertiary {
  color: #223a80;
  background-color: #374f94;
  border-color: #243d86; }
  .alert-tertiary hr {
    border-top-color: #1f3472; }
  .alert-tertiary .alert-link {
    color: #172858; }

.alert-lighten {
  color: #d8dbe2;
  background-color: white;
  border-color: #ecedf0; }
  .alert-lighten hr {
    border-top-color: #dee0e5; }
  .alert-lighten .alert-link {
    color: #bbc0cc; }

.alert-white {
  color: #d8dbe2;
  background-color: white;
  border-color: #ecedf0; }
  .alert-white hr {
    border-top-color: #dee0e5; }
  .alert-white .alert-link {
    color: #bbc0cc; }

.alert-gray {
  color: #3a4062;
  background-color: #515673;
  border-color: #3e4365; }
  .alert-gray hr {
    border-top-color: #343955; }
  .alert-gray .alert-link {
    color: #272b42; }

.alert-neutral {
  color: #d8dbe2;
  background-color: white;
  border-color: #ecedf0; }
  .alert-neutral hr {
    border-top-color: #dee0e5; }
  .alert-neutral .alert-link {
    color: #bbc0cc; }

.alert-soft {
  color: #d0d5e1;
  background-color: #f6f9fe;
  border-color: #e2e7ef; }
  .alert-soft hr {
    border-top-color: #d2d9e6; }
  .alert-soft .alert-link {
    color: #b1b9cd; }

.alert-black {
  color: #0d1b48;
  background-color: #202d57;
  border-color: #0d1b48; }
  .alert-black hr {
    border-top-color: #091332; }
  .alert-black .alert-link {
    color: #050b1d; }

.alert-purple {
  color: #5f3cae;
  background-color: #7b51c6;
  border-color: #673fb7; }
  .alert-purple hr {
    border-top-color: #5c38a4; }
  .alert-purple .alert-link {
    color: #4a2f88; }

.alert-soft-green {
  color: #c7d5d3;
  background-color: #edf9ef;
  border-color: #d9e7e1; }
  .alert-soft-green hr {
    border-top-color: #c9ddd5; }
  .alert-soft-green .alert-link {
    color: #aabfbc; }

.alert-gray-100 {
  color: #d8dbe2;
  background-color: white;
  border-color: #ecedf0; }
  .alert-gray-100 hr {
    border-top-color: #dee0e5; }
  .alert-gray-100 .alert-link {
    color: #bbc0cc; }

.alert-gray-200 {
  color: #d4d7e1;
  background-color: #fafbfe;
  border-color: #e7e9ef; }
  .alert-gray-200 hr {
    border-top-color: #d8dbe5; }
  .alert-gray-200 .alert-link {
    color: #b6bbcc; }

.alert-gray-300 {
  color: #d0d5e1;
  background-color: #f6f9fe;
  border-color: #e2e7ef; }
  .alert-gray-300 hr {
    border-top-color: #d2d9e6; }
  .alert-gray-300 .alert-link {
    color: #b1b9cd; }

.alert-gray-400 {
  color: #c9cedb;
  background-color: #eef1f8;
  border-color: #dbdfe9; }
  .alert-gray-400 hr {
    border-top-color: #cbd1df; }
  .alert-gray-400 .alert-link {
    color: #aab2c7; }

.alert-gray-500 {
  color: #9ca8bc;
  background-color: #bdc8d6;
  border-color: #a9b6c7; }
  .alert-gray-500 hr {
    border-top-color: #9aa9bd; }
  .alert-gray-500 .alert-link {
    color: #7e8da7; }

.alert-gray-600 {
  color: #7e8fab;
  background-color: #9cacc3;
  border-color: #889ab5; }
  .alert-gray-600 hr {
    border-top-color: #788dab; }
  .alert-gray-600 .alert-link {
    color: #617595; }

.alert-gray-700 {
  color: #474b72;
  background-color: #606285;
  border-color: #4c4f76; }
  .alert-gray-700 hr {
    border-top-color: #424566; }
  .alert-gray-700 .alert-link {
    color: #333653; }

.alert-gray-800 {
  color: #444871;
  background-color: #5c5e84;
  border-color: #494c75; }
  .alert-gray-800 hr {
    border-top-color: #3f4265; }
  .alert-gray-800 .alert-link {
    color: #313451; }

.alert-facebook {
  color: #344f8c;
  background-color: #4b66a1;
  border-color: #375493; }
  .alert-facebook hr {
    border-top-color: #304980; }
  .alert-facebook .alert-link {
    color: #263a67; }

.alert-twitter {
  color: #1a8cd7;
  background-color: #2fa9f3;
  border-color: #1c96e4; }
  .alert-twitter hr {
    border-top-color: #1987ce; }
  .alert-twitter .alert-link {
    color: #146eaa; }

.alert-google {
  color: #ba3d3a;
  background-color: #de5247;
  border-color: #cb4038; }
  .alert-google hr {
    border-top-color: #b93830; }
  .alert-google .alert-link {
    color: #93302e; }

.alert-instagram {
  color: #c23a5b;
  background-color: #e64f6c;
  border-color: #d33d5d; }
  .alert-instagram hr {
    border-top-color: #c92e4f; }
  .alert-instagram .alert-link {
    color: #9b2e49; }

.alert-pinterest {
  color: #a10b23;
  background-color: #c21c2e;
  border-color: #af0a20; }
  .alert-pinterest hr {
    border-top-color: #97091c; }
  .alert-pinterest .alert-link {
    color: #710819; }

.alert-youtube {
  color: #ae1f26;
  background-color: #d13231;
  border-color: #be2022; }
  .alert-youtube hr {
    border-top-color: #a81c1e; }
  .alert-youtube .alert-link {
    color: #83171d; }

.alert-slack {
  color: #33977b;
  background-color: #4ab58f;
  border-color: #36a380; }
  .alert-slack hr {
    border-top-color: #309071; }
  .alert-slack .alert-link {
    color: #26715c; }

.alert-dribbble {
  color: #c7447f;
  background-color: #ec5a92;
  border-color: #d84884; }
  .alert-dribbble hr {
    border-top-color: #d43376; }
  .alert-dribbble .alert-link {
    color: #a63266; }

.alert-dropbox {
  color: #1b7de2;
  background-color: #3099ff;
  border-color: #1d87f0; }
  .alert-dropbox hr {
    border-top-color: #0f7ae4; }
  .alert-dropbox .alert-link {
    color: #1664b4; }

.alert-twitch {
  color: #413274;
  background-color: #594686;
  border-color: #463478; }
  .alert-twitch hr {
    border-top-color: #3c2c66; }
  .alert-twitch .alert-link {
    color: #2d2350; }

.alert-paypal {
  color: #c89b30;
  background-color: #eeb93d;
  border-color: #daa72e; }
  .alert-paypal hr {
    border-top-color: #cb9924; }
  .alert-paypal .alert-link {
    color: #9f7b26; }

.alert-behance {
  color: #024de2;
  background-color: #1464ff;
  border-color: #0152f0; }
  .alert-behance hr {
    border-top-color: #0149d7; }
  .alert-behance .alert-link {
    color: #023caf; }

.alert-reddit {
  color: #c53d28;
  background-color: #ea5334;
  border-color: #d64125; }
  .alert-reddit hr {
    border-top-color: #c03a21; }
  .alert-reddit .alert-link {
    color: #9b301f; }

.alert-github {
  color: #1f2128;
  background-color: #343434;
  border-color: #202125; }
  .alert-github hr {
    border-top-color: #141517; }
  .alert-github .alert-link {
    color: #09090b; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #fafbfe;
  border-radius: 0.45rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(13, 27, 72, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d1b48;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.45rem; }

.list-group-item-action {
  width: 100%;
  color: #424767;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #424767;
    text-decoration: none;
    background-color: #f5f9fe; }
  .list-group-item-action:active {
    color: #424767;
    background-color: #fafbfe; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.0625rem solid #ffffff; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #52547a;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #C33C54;
    background-color: #ffffff;
    border-color: #edf0f7; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -0.0625rem;
      border-top-width: 0.0625rem; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.45rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.45rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -0.0625rem;
      border-left-width: 0.0625rem; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.45rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -0.0625rem;
        border-left-width: 0.0625rem; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.45rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -0.0625rem;
        border-left-width: 0.0625rem; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.45rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -0.0625rem;
        border-left-width: 0.0625rem; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.45rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -0.0625rem;
        border-left-width: 0.0625rem; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 0.0625rem; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #0d1b48;
  background-color: #bbbfcc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0d1b48;
    background-color: #acb1c1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }

.list-group-item-secondary {
  color: #6c2c4e;
  background-color: #eec8cf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #6c2c4e;
    background-color: #e8b5be; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #6c2c4e;
    border-color: #6c2c4e; }

.list-group-item-success {
  color: #175056;
  background-color: #c1dcd3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #175056;
    background-color: #b1d3c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #175056;
    border-color: #175056; }

.list-group-item-info {
  color: #063a80;
  background-color: #b8d0ea; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #063a80;
    background-color: #a4c3e4; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #063a80;
    border-color: #063a80; }

.list-group-item-warning {
  color: #836b23;
  background-color: #fbeab8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #836b23;
    background-color: #fae3a0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #836b23;
    border-color: #836b23; }

.list-group-item-danger {
  color: #69223f;
  background-color: #edc3c7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #69223f;
    background-color: #e7afb5; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #69223f;
    border-color: #69223f; }

.list-group-item-light {
  color: #818aa3;
  background-color: #fafbfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818aa3;
    background-color: #e8ecf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818aa3;
    border-color: #818aa3; }

.list-group-item-dark {
  color: #0d1b48;
  background-color: #bbbfcc; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0d1b48;
    background-color: #acb1c1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }

.list-group-item-tertiary {
  color: #1a2e6b;
  background-color: #c2cadf; }
  .list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
    color: #1a2e6b;
    background-color: #b1bbd6; }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #ffffff;
    background-color: #1a2e6b;
    border-color: #1a2e6b; }

.list-group-item-lighten {
  color: #8b92a7;
  background-color: white; }
  .list-group-item-lighten.list-group-item-action:hover, .list-group-item-lighten.list-group-item-action:focus {
    color: #8b92a7;
    background-color: #f2f2f2; }
  .list-group-item-lighten.list-group-item-action.active {
    color: #ffffff;
    background-color: #8b92a7;
    border-color: #8b92a7; }

.list-group-item-white {
  color: #8b92a7;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #8b92a7;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #ffffff;
    background-color: #8b92a7;
    border-color: #8b92a7; }

.list-group-item-gray {
  color: #293258;
  background-color: #cacbd4; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #293258;
    background-color: #bcbdc9; }
  .list-group-item-gray.list-group-item-action.active {
    color: #ffffff;
    background-color: #293258;
    border-color: #293258; }

.list-group-item-neutral {
  color: #8b92a7;
  background-color: white; }
  .list-group-item-neutral.list-group-item-action:hover, .list-group-item-neutral.list-group-item-action:focus {
    color: #8b92a7;
    background-color: #f2f2f2; }
  .list-group-item-neutral.list-group-item-action.active {
    color: #ffffff;
    background-color: #8b92a7;
    border-color: #8b92a7; }

.list-group-item-soft {
  color: #868ea7;
  background-color: #fcfdff; }
  .list-group-item-soft.list-group-item-action:hover, .list-group-item-soft.list-group-item-action:focus {
    color: #868ea7;
    background-color: #e3ecff; }
  .list-group-item-soft.list-group-item-action.active {
    color: #ffffff;
    background-color: #868ea7;
    border-color: #868ea7; }

.list-group-item-black {
  color: #0d1b48;
  background-color: #bbbfcc; }
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: #0d1b48;
    background-color: #acb1c1; }
  .list-group-item-black.list-group-item-action.active {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }

.list-group-item-purple {
  color: #402f87;
  background-color: #d7caee; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #402f87;
    background-color: #c8b7e8; }
  .list-group-item-purple.list-group-item-action.active {
    color: #ffffff;
    background-color: #402f87;
    border-color: #402f87; }

.list-group-item-soft-green {
  color: #808e9e;
  background-color: #f9fdfa; }
  .list-group-item-soft-green.list-group-item-action:hover, .list-group-item-soft-green.list-group-item-action:focus {
    color: #808e9e;
    background-color: #e6f7ea; }
  .list-group-item-soft-green.list-group-item-action.active {
    color: #ffffff;
    background-color: #808e9e;
    border-color: #808e9e; }

.list-group-item-gray-100 {
  color: #8b92a7;
  background-color: white; }
  .list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #8b92a7;
    background-color: #f2f2f2; }
  .list-group-item-gray-100.list-group-item-action.active {
    color: #ffffff;
    background-color: #8b92a7;
    border-color: #8b92a7; }

.list-group-item-gray-200 {
  color: #888fa7;
  background-color: #fefeff; }
  .list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
    color: #888fa7;
    background-color: #e5e5ff; }
  .list-group-item-gray-200.list-group-item-action.active {
    color: #ffffff;
    background-color: #888fa7;
    border-color: #888fa7; }

.list-group-item-gray-300 {
  color: #868ea7;
  background-color: #fcfdff; }
  .list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
    color: #868ea7;
    background-color: #e3ecff; }
  .list-group-item-gray-300.list-group-item-action.active {
    color: #ffffff;
    background-color: #868ea7;
    border-color: #868ea7; }

.list-group-item-gray-400 {
  color: #818aa3;
  background-color: #fafbfd; }
  .list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
    color: #818aa3;
    background-color: #e8ecf6; }
  .list-group-item-gray-400.list-group-item-action.active {
    color: #ffffff;
    background-color: #818aa3;
    border-color: #818aa3; }

.list-group-item-gray-500 {
  color: #657290;
  background-color: #ebeef2; }
  .list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
    color: #657290;
    background-color: #dce1e8; }
  .list-group-item-gray-500.list-group-item-action.active {
    color: #ffffff;
    background-color: #657290;
    border-color: #657290; }

.list-group-item-gray-600 {
  color: #536385;
  background-color: #e1e6ed; }
  .list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
    color: #536385;
    background-color: #d1d9e3; }
  .list-group-item-gray-600.list-group-item-action.active {
    color: #ffffff;
    background-color: #536385;
    border-color: #536385; }

.list-group-item-gray-700 {
  color: #313962;
  background-color: #cfcfda; }
  .list-group-item-gray-700.list-group-item-action:hover, .list-group-item-gray-700.list-group-item-action:focus {
    color: #313962;
    background-color: #c1c1cf; }
  .list-group-item-gray-700.list-group-item-action.active {
    color: #ffffff;
    background-color: #313962;
    border-color: #313962; }

.list-group-item-gray-800 {
  color: #2f3761;
  background-color: #cdced9; }
  .list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
    color: #2f3761;
    background-color: #bfc0ce; }
  .list-group-item-gray-800.list-group-item-action.active {
    color: #ffffff;
    background-color: #2f3761;
    border-color: #2f3761; }

.list-group-item-facebook {
  color: #253b72;
  background-color: #c8d1e2; }
  .list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
    color: #253b72;
    background-color: #b7c3d9; }
  .list-group-item-facebook.list-group-item-action.active {
    color: #ffffff;
    background-color: #253b72;
    border-color: #253b72; }

.list-group-item-twitter {
  color: #1561a0;
  background-color: #c0e5fb; }
  .list-group-item-twitter.list-group-item-action:hover, .list-group-item-twitter.list-group-item-action:focus {
    color: #1561a0;
    background-color: #a8dbf9; }
  .list-group-item-twitter.list-group-item-action.active {
    color: #ffffff;
    background-color: #1561a0;
    border-color: #1561a0; }

.list-group-item-google {
  color: #78303f;
  background-color: #f5cac7; }
  .list-group-item-google.list-group-item-action:hover, .list-group-item-google.list-group-item-action:focus {
    color: #78303f;
    background-color: #f1b6b1; }
  .list-group-item-google.list-group-item-action.active {
    color: #ffffff;
    background-color: #78303f;
    border-color: #78303f; }

.list-group-item-instagram {
  color: #7d2e54;
  background-color: #f7cad2; }
  .list-group-item-instagram.list-group-item-action:hover, .list-group-item-instagram.list-group-item-action:focus {
    color: #7d2e54;
    background-color: #f4b4bf; }
  .list-group-item-instagram.list-group-item-action.active {
    color: #ffffff;
    background-color: #7d2e54;
    border-color: #7d2e54; }

.list-group-item-pinterest {
  color: #691131;
  background-color: #edbabf; }
  .list-group-item-pinterest.list-group-item-action:hover, .list-group-item-pinterest.list-group-item-action:focus {
    color: #691131;
    background-color: #e8a6ac; }
  .list-group-item-pinterest.list-group-item-action.active {
    color: #ffffff;
    background-color: #691131;
    border-color: #691131; }

.list-group-item-youtube {
  color: #711e33;
  background-color: #f1c1c0; }
  .list-group-item-youtube.list-group-item-action:hover, .list-group-item-youtube.list-group-item-action:focus {
    color: #711e33;
    background-color: #ecacab; }
  .list-group-item-youtube.list-group-item-action.active {
    color: #ffffff;
    background-color: #711e33;
    border-color: #711e33; }

.list-group-item-slack {
  color: #246868;
  background-color: #c8e9dd; }
  .list-group-item-slack.list-group-item-action:hover, .list-group-item-slack.list-group-item-action:focus {
    color: #246868;
    background-color: #b6e2d2; }
  .list-group-item-slack.list-group-item-action.active {
    color: #ffffff;
    background-color: #246868;
    border-color: #246868; }

.list-group-item-dribbble {
  color: #80346a;
  background-color: #f9cdde; }
  .list-group-item-dribbble.list-group-item-action:hover, .list-group-item-dribbble.list-group-item-action:focus {
    color: #80346a;
    background-color: #f6b6cf; }
  .list-group-item-dribbble.list-group-item-action.active {
    color: #ffffff;
    background-color: #80346a;
    border-color: #80346a; }

.list-group-item-dropbox {
  color: #1658a7;
  background-color: #c0e0ff; }
  .list-group-item-dropbox.list-group-item-action:hover, .list-group-item-dropbox.list-group-item-action:focus {
    color: #1658a7;
    background-color: #a7d3ff; }
  .list-group-item-dropbox.list-group-item-action.active {
    color: #ffffff;
    background-color: #1658a7;
    border-color: #1658a7; }

.list-group-item-twitch {
  color: #2d2963;
  background-color: #cdc7da; }
  .list-group-item-twitch.list-group-item-action:hover, .list-group-item-twitch.list-group-item-action:focus {
    color: #2d2963;
    background-color: #bfb8d0; }
  .list-group-item-twitch.list-group-item-action.active {
    color: #ffffff;
    background-color: #2d2963;
    border-color: #2d2963; }

.list-group-item-paypal {
  color: #816a39;
  background-color: #faeac4; }
  .list-group-item-paypal.list-group-item-action:hover, .list-group-item-paypal.list-group-item-action:focus {
    color: #816a39;
    background-color: #f8e2ac; }
  .list-group-item-paypal.list-group-item-action.active {
    color: #ffffff;
    background-color: #816a39;
    border-color: #816a39; }

.list-group-item-behance {
  color: #063aa7;
  background-color: #b8d0ff; }
  .list-group-item-behance.list-group-item-action:hover, .list-group-item-behance.list-group-item-action:focus {
    color: #063aa7;
    background-color: #9fbfff; }
  .list-group-item-behance.list-group-item-action.active {
    color: #ffffff;
    background-color: #063aa7;
    border-color: #063aa7; }

.list-group-item-reddit {
  color: #7f3034;
  background-color: #f9cbc1; }
  .list-group-item-reddit.list-group-item-action:hover, .list-group-item-reddit.list-group-item-action:focus {
    color: #7f3034;
    background-color: #f7b8aa; }
  .list-group-item-reddit.list-group-item-action.active {
    color: #ffffff;
    background-color: #7f3034;
    border-color: #7f3034; }

.list-group-item-github {
  color: #181f34;
  background-color: #c1c1c1; }
  .list-group-item-github.list-group-item-action:hover, .list-group-item-github.list-group-item-action:focus {
    color: #181f34;
    background-color: #b4b4b4; }
  .list-group-item-github.list-group-item-action.active {
    color: #ffffff;
    background-color: #181f34;
    border-color: #181f34; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(13, 27, 72, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #93a5be;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(13, 27, 72, 0.2);
  border-radius: 0.45rem;
  box-shadow: 0 0.25rem 0.5rem rgba(13, 27, 72, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #0d1b48; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.16; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem;
  border-bottom: 1px solid #edf0f7;
  border-top-left-radius: calc(0.45rem - 1px);
  border-top-right-radius: calc(0.45rem - 1px); }
  .modal-header .close {
    padding: 1.25rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.1; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 1px solid #edf0f7;
  border-bottom-right-radius: calc(0.45rem - 1px);
  border-bottom-left-radius: calc(0.45rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.5); }
  .modal-sm {
    max-width: 380px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #ffffff; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #ffffff; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #ffffff; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #ffffff; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #0d1b48;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0.45rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(13, 27, 72, 0.05);
  border-radius: 0.45rem;
  box-shadow: 0px 0.5rem 2rem 0px rgba(13, 27, 72, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1.5rem;
    height: 0.75rem;
    margin: 0 0.45rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.75rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.75rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.75rem 0.75rem 0;
      border-top-color: transparent; }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.75rem 0.75rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.75rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.75rem - 1px);
    width: 0.75rem;
    height: 1.5rem;
    margin: 0.45rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.75rem 0.75rem 0.75rem 0;
      border-right-color: transparent; }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.75rem 0.75rem 0.75rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.75rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.75rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.75rem 0.75rem 0.75rem;
      border-bottom-color: transparent; }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.75rem 0.75rem 0.75rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1.5rem;
    margin-left: -0.75rem;
    content: "";
    border-bottom: 1px solid #ffffff; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.75rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.75rem - 1px);
    width: 0.75rem;
    height: 1.5rem;
    margin: 0.45rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.75rem 0 0.75rem 0.75rem;
      border-left-color: transparent; }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.75rem 0 0.75rem 0.75rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.75rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #0d1b48;
  background-color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.45rem - 1px);
  border-top-right-radius: calc(0.45rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.75rem 0.75rem;
  color: #424767; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0d1b48 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #050b1d !important; }

.bg-secondary {
  background-color: #C33C54 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #9c3043 !important; }

.bg-success {
  background-color: #208163 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #165844 !important; }

.bg-info {
  background-color: #0056B3 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #003d80 !important; }

.bg-warning {
  background-color: #F0B400 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #bd8e00 !important; }

.bg-danger {
  background-color: #be2837 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #941f2b !important; }

.bg-light {
  background-color: #edf0f7 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cad3e7 !important; }

.bg-dark {
  background-color: #0d1b48 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #050b1d !important; }

.bg-tertiary {
  background-color: #26408B !important; }

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #1b2e63 !important; }

.bg-lighten {
  background-color: #ffffff !important; }

a.bg-lighten:hover, a.bg-lighten:focus,
button.bg-lighten:hover,
button.bg-lighten:focus {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #ffffff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray {
  background-color: #424767 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #2e3248 !important; }

.bg-neutral {
  background-color: #ffffff !important; }

a.bg-neutral:hover, a.bg-neutral:focus,
button.bg-neutral:hover,
button.bg-neutral:focus {
  background-color: #e6e6e6 !important; }

.bg-soft {
  background-color: #f5f9fe !important; }

a.bg-soft:hover, a.bg-soft:focus,
button.bg-soft:hover,
button.bg-soft:focus {
  background-color: #c7ddf9 !important; }

.bg-black {
  background-color: #0d1b48 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #050b1d !important; }

.bg-purple {
  background-color: #6f42c1 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important; }

.bg-soft-green {
  background-color: #ebf9ee !important; }

a.bg-soft-green:hover, a.bg-soft-green:focus,
button.bg-soft-green:hover,
button.bg-soft-green:focus {
  background-color: #c4edcd !important; }

.bg-gray-100 {
  background-color: #ffffff !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #e6e6e6 !important; }

.bg-gray-200 {
  background-color: #fafbfe !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #d0d9f6 !important; }

.bg-gray-300 {
  background-color: #f5f9fe !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c7ddf9 !important; }

.bg-gray-400 {
  background-color: #edf0f7 !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #cad3e7 !important; }

.bg-gray-500 {
  background-color: #B7C3D2 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #98a9be !important; }

.bg-gray-600 {
  background-color: #93a5be !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #738aab !important; }

.bg-gray-700 {
  background-color: #52547a !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #3e3f5c !important; }

.bg-gray-800 {
  background-color: #4E5079 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #3a3b5a !important; }

.bg-facebook {
  background-color: #3b5999 !important; }

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #2d4474 !important; }

.bg-twitter {
  background-color: #1da1f2 !important; }

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0c85d0 !important; }

.bg-google {
  background-color: #DB4337 !important; }

a.bg-google:hover, a.bg-google:focus,
button.bg-google:hover,
button.bg-google:focus {
  background-color: #bd2d22 !important; }

.bg-instagram {
  background-color: #e4405f !important; }

a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #d31e40 !important; }

.bg-pinterest {
  background-color: #bd081c !important; }

a.bg-pinterest:hover, a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
  background-color: #8c0615 !important; }

.bg-youtube {
  background-color: #cd201f !important; }

a.bg-youtube:hover, a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
  background-color: #a11918 !important; }

.bg-slack {
  background-color: #3aaf85 !important; }

a.bg-slack:hover, a.bg-slack:focus,
button.bg-slack:hover,
button.bg-slack:focus {
  background-color: #2d8968 !important; }

.bg-dribbble {
  background-color: #ea4c89 !important; }

a.bg-dribbble:hover, a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
  background-color: #e51e6b !important; }

.bg-dropbox {
  background-color: #1E90FF !important; }

a.bg-dropbox:hover, a.bg-dropbox:focus,
button.bg-dropbox:hover,
button.bg-dropbox:focus {
  background-color: #0077ea !important; }

.bg-twitch {
  background-color: #4B367C !important; }

a.bg-twitch:hover, a.bg-twitch:focus,
button.bg-twitch:hover,
button.bg-twitch:focus {
  background-color: #362758 !important; }

.bg-paypal {
  background-color: #ecb32c !important; }

a.bg-paypal:hover, a.bg-paypal:focus,
button.bg-paypal:hover,
button.bg-paypal:focus {
  background-color: #d29913 !important; }

.bg-behance {
  background-color: #0057ff !important; }

a.bg-behance:hover, a.bg-behance:focus,
button.bg-behance:hover,
button.bg-behance:focus {
  background-color: #0046cc !important; }

.bg-reddit {
  background-color: #E84422 !important; }

a.bg-reddit:hover, a.bg-reddit:focus,
button.bg-reddit:hover,
button.bg-reddit:focus {
  background-color: #c33214 !important; }

.bg-github {
  background-color: #222222 !important; }

a.bg-github:hover, a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
  background-color: #090909 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 0.0625rem solid #edf0f7 !important; }

.border-top {
  border-top: 0.0625rem solid #edf0f7 !important; }

.border-right {
  border-right: 0.0625rem solid #edf0f7 !important; }

.border-bottom {
  border-bottom: 0.0625rem solid #edf0f7 !important; }

.border-left {
  border-left: 0.0625rem solid #edf0f7 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d1b48 !important; }

.border-secondary {
  border-color: #C33C54 !important; }

.border-success {
  border-color: #208163 !important; }

.border-info {
  border-color: #0056B3 !important; }

.border-warning {
  border-color: #F0B400 !important; }

.border-danger {
  border-color: #be2837 !important; }

.border-light {
  border-color: #edf0f7 !important; }

.border-dark {
  border-color: #0d1b48 !important; }

.border-tertiary {
  border-color: #26408B !important; }

.border-lighten {
  border-color: #ffffff !important; }

.border-white {
  border-color: #ffffff !important; }

.border-gray {
  border-color: #424767 !important; }

.border-neutral {
  border-color: #ffffff !important; }

.border-soft {
  border-color: #f5f9fe !important; }

.border-black {
  border-color: #0d1b48 !important; }

.border-purple {
  border-color: #6f42c1 !important; }

.border-soft-green {
  border-color: #ebf9ee !important; }

.border-gray-100 {
  border-color: #ffffff !important; }

.border-gray-200 {
  border-color: #fafbfe !important; }

.border-gray-300 {
  border-color: #f5f9fe !important; }

.border-gray-400 {
  border-color: #edf0f7 !important; }

.border-gray-500 {
  border-color: #B7C3D2 !important; }

.border-gray-600 {
  border-color: #93a5be !important; }

.border-gray-700 {
  border-color: #52547a !important; }

.border-gray-800 {
  border-color: #4E5079 !important; }

.border-facebook {
  border-color: #3b5999 !important; }

.border-twitter {
  border-color: #1da1f2 !important; }

.border-google {
  border-color: #DB4337 !important; }

.border-instagram {
  border-color: #e4405f !important; }

.border-pinterest {
  border-color: #bd081c !important; }

.border-youtube {
  border-color: #cd201f !important; }

.border-slack {
  border-color: #3aaf85 !important; }

.border-dribbble {
  border-color: #ea4c89 !important; }

.border-dropbox {
  border-color: #1E90FF !important; }

.border-twitch {
  border-color: #4B367C !important; }

.border-paypal {
  border-color: #ecb32c !important; }

.border-behance {
  border-color: #0057ff !important; }

.border-reddit {
  border-color: #E84422 !important; }

.border-github {
  border-color: #222222 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.1rem !important; }

.rounded {
  border-radius: 0.45rem !important; }

.rounded-top {
  border-top-left-radius: 0.45rem !important;
  border-top-right-radius: 0.45rem !important; }

.rounded-right {
  border-top-right-radius: 0.45rem !important;
  border-bottom-right-radius: 0.45rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.45rem !important;
  border-bottom-left-radius: 0.45rem !important; }

.rounded-left {
  border-top-left-radius: 0.45rem !important;
  border-bottom-left-radius: 0.45rem !important; }

.rounded-lg {
  border-radius: 0.45rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed, .headroom--pinned, .headroom--unpinned {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(13, 27, 72, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 5rem !important; }

.mt-6,
.my-6 {
  margin-top: 5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 5rem !important; }

.m-7 {
  margin: 8rem !important; }

.mt-7,
.my-7 {
  margin-top: 8rem !important; }

.mr-7,
.mx-7 {
  margin-right: 8rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 8rem !important; }

.ml-7,
.mx-7 {
  margin-left: 8rem !important; }

.m-8 {
  margin: 10rem !important; }

.mt-8,
.my-8 {
  margin-top: 10rem !important; }

.mr-8,
.mx-8 {
  margin-right: 10rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 10rem !important; }

.ml-8,
.mx-8 {
  margin-left: 10rem !important; }

.m-9 {
  margin: 11rem !important; }

.mt-9,
.my-9 {
  margin-top: 11rem !important; }

.mr-9,
.mx-9 {
  margin-right: 11rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 11rem !important; }

.ml-9,
.mx-9 {
  margin-left: 11rem !important; }

.m-10 {
  margin: 14rem !important; }

.mt-10,
.my-10 {
  margin-top: 14rem !important; }

.mr-10,
.mx-10 {
  margin-right: 14rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 14rem !important; }

.ml-10,
.mx-10 {
  margin-left: 14rem !important; }

.m-11 {
  margin: 16rem !important; }

.mt-11,
.my-11 {
  margin-top: 16rem !important; }

.mr-11,
.mx-11 {
  margin-right: 16rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 16rem !important; }

.ml-11,
.mx-11 {
  margin-left: 16rem !important; }

.m-12 {
  margin: 20rem !important; }

.mt-12,
.my-12 {
  margin-top: 20rem !important; }

.mr-12,
.mx-12 {
  margin-right: 20rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 20rem !important; }

.ml-12,
.mx-12 {
  margin-left: 20rem !important; }

.m-sm {
  margin: 1rem !important; }

.mt-sm,
.my-sm {
  margin-top: 1rem !important; }

.mr-sm,
.mx-sm {
  margin-right: 1rem !important; }

.mb-sm,
.my-sm {
  margin-bottom: 1rem !important; }

.ml-sm,
.mx-sm {
  margin-left: 1rem !important; }

.m-md {
  margin: 2rem !important; }

.mt-md,
.my-md {
  margin-top: 2rem !important; }

.mr-md,
.mx-md {
  margin-right: 2rem !important; }

.mb-md,
.my-md {
  margin-bottom: 2rem !important; }

.ml-md,
.mx-md {
  margin-left: 2rem !important; }

.m-lg {
  margin: 4rem !important; }

.mt-lg,
.my-lg {
  margin-top: 4rem !important; }

.mr-lg,
.mx-lg {
  margin-right: 4rem !important; }

.mb-lg,
.my-lg {
  margin-bottom: 4rem !important; }

.ml-lg,
.mx-lg {
  margin-left: 4rem !important; }

.m-xl {
  margin: 8rem !important; }

.mt-xl,
.my-xl {
  margin-top: 8rem !important; }

.mr-xl,
.mx-xl {
  margin-right: 8rem !important; }

.mb-xl,
.my-xl {
  margin-bottom: 8rem !important; }

.ml-xl,
.mx-xl {
  margin-left: 8rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 5rem !important; }

.pt-6,
.py-6 {
  padding-top: 5rem !important; }

.pr-6,
.px-6 {
  padding-right: 5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 5rem !important; }

.pl-6,
.px-6 {
  padding-left: 5rem !important; }

.p-7 {
  padding: 8rem !important; }

.pt-7,
.py-7 {
  padding-top: 8rem !important; }

.pr-7,
.px-7 {
  padding-right: 8rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 8rem !important; }

.pl-7,
.px-7 {
  padding-left: 8rem !important; }

.p-8 {
  padding: 10rem !important; }

.pt-8,
.py-8 {
  padding-top: 10rem !important; }

.pr-8,
.px-8 {
  padding-right: 10rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 10rem !important; }

.pl-8,
.px-8 {
  padding-left: 10rem !important; }

.p-9 {
  padding: 11rem !important; }

.pt-9,
.py-9 {
  padding-top: 11rem !important; }

.pr-9,
.px-9 {
  padding-right: 11rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 11rem !important; }

.pl-9,
.px-9 {
  padding-left: 11rem !important; }

.p-10 {
  padding: 14rem !important; }

.pt-10,
.py-10 {
  padding-top: 14rem !important; }

.pr-10,
.px-10 {
  padding-right: 14rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 14rem !important; }

.pl-10,
.px-10 {
  padding-left: 14rem !important; }

.p-11 {
  padding: 16rem !important; }

.pt-11,
.py-11 {
  padding-top: 16rem !important; }

.pr-11,
.px-11 {
  padding-right: 16rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 16rem !important; }

.pl-11,
.px-11 {
  padding-left: 16rem !important; }

.p-12 {
  padding: 20rem !important; }

.pt-12,
.py-12 {
  padding-top: 20rem !important; }

.pr-12,
.px-12 {
  padding-right: 20rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 20rem !important; }

.pl-12,
.px-12 {
  padding-left: 20rem !important; }

.p-sm {
  padding: 1rem !important; }

.pt-sm,
.py-sm {
  padding-top: 1rem !important; }

.pr-sm,
.px-sm {
  padding-right: 1rem !important; }

.pb-sm,
.py-sm {
  padding-bottom: 1rem !important; }

.pl-sm,
.px-sm {
  padding-left: 1rem !important; }

.p-md {
  padding: 2rem !important; }

.pt-md,
.py-md {
  padding-top: 2rem !important; }

.pr-md,
.px-md {
  padding-right: 2rem !important; }

.pb-md,
.py-md {
  padding-bottom: 2rem !important; }

.pl-md,
.px-md {
  padding-left: 2rem !important; }

.p-lg {
  padding: 4rem !important; }

.pt-lg,
.py-lg {
  padding-top: 4rem !important; }

.pr-lg,
.px-lg {
  padding-right: 4rem !important; }

.pb-lg,
.py-lg {
  padding-bottom: 4rem !important; }

.pl-lg,
.px-lg {
  padding-left: 4rem !important; }

.p-xl {
  padding: 8rem !important; }

.pt-xl,
.py-xl {
  padding-top: 8rem !important; }

.pr-xl,
.px-xl {
  padding-right: 8rem !important; }

.pb-xl,
.py-xl {
  padding-bottom: 8rem !important; }

.pl-xl,
.px-xl {
  padding-left: 8rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -5rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -5rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important; }

.m-n7 {
  margin: -8rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -8rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -8rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -8rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -8rem !important; }

.m-n8 {
  margin: -10rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -10rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -10rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -10rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -10rem !important; }

.m-n9 {
  margin: -11rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -11rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -11rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -11rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -11rem !important; }

.m-n10 {
  margin: -14rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -14rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -14rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -14rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -14rem !important; }

.m-n11 {
  margin: -16rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -16rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -16rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -16rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -16rem !important; }

.m-n12 {
  margin: -20rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -20rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -20rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -20rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -20rem !important; }

.m-nsm {
  margin: -1rem !important; }

.mt-nsm,
.my-nsm {
  margin-top: -1rem !important; }

.mr-nsm,
.mx-nsm {
  margin-right: -1rem !important; }

.mb-nsm,
.my-nsm {
  margin-bottom: -1rem !important; }

.ml-nsm,
.mx-nsm {
  margin-left: -1rem !important; }

.m-nmd {
  margin: -2rem !important; }

.mt-nmd,
.my-nmd {
  margin-top: -2rem !important; }

.mr-nmd,
.mx-nmd {
  margin-right: -2rem !important; }

.mb-nmd,
.my-nmd {
  margin-bottom: -2rem !important; }

.ml-nmd,
.mx-nmd {
  margin-left: -2rem !important; }

.m-nlg {
  margin: -4rem !important; }

.mt-nlg,
.my-nlg {
  margin-top: -4rem !important; }

.mr-nlg,
.mx-nlg {
  margin-right: -4rem !important; }

.mb-nlg,
.my-nlg {
  margin-bottom: -4rem !important; }

.ml-nlg,
.mx-nlg {
  margin-left: -4rem !important; }

.m-nxl {
  margin: -8rem !important; }

.mt-nxl,
.my-nxl {
  margin-top: -8rem !important; }

.mr-nxl,
.mx-nxl {
  margin-right: -8rem !important; }

.mb-nxl,
.my-nxl {
  margin-bottom: -8rem !important; }

.ml-nxl,
.mx-nxl {
  margin-left: -8rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important; }
  .m-sm-7 {
    margin: 8rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 8rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 8rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 8rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 8rem !important; }
  .m-sm-8 {
    margin: 10rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 10rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 10rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 10rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 10rem !important; }
  .m-sm-9 {
    margin: 11rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 11rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 11rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 11rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 11rem !important; }
  .m-sm-10 {
    margin: 14rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 14rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 14rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 14rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 14rem !important; }
  .m-sm-11 {
    margin: 16rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 16rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 16rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 16rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 16rem !important; }
  .m-sm-12 {
    margin: 20rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 20rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 20rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 20rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 20rem !important; }
  .m-sm-sm {
    margin: 1rem !important; }
  .mt-sm-sm,
  .my-sm-sm {
    margin-top: 1rem !important; }
  .mr-sm-sm,
  .mx-sm-sm {
    margin-right: 1rem !important; }
  .mb-sm-sm,
  .my-sm-sm {
    margin-bottom: 1rem !important; }
  .ml-sm-sm,
  .mx-sm-sm {
    margin-left: 1rem !important; }
  .m-sm-md {
    margin: 2rem !important; }
  .mt-sm-md,
  .my-sm-md {
    margin-top: 2rem !important; }
  .mr-sm-md,
  .mx-sm-md {
    margin-right: 2rem !important; }
  .mb-sm-md,
  .my-sm-md {
    margin-bottom: 2rem !important; }
  .ml-sm-md,
  .mx-sm-md {
    margin-left: 2rem !important; }
  .m-sm-lg {
    margin: 4rem !important; }
  .mt-sm-lg,
  .my-sm-lg {
    margin-top: 4rem !important; }
  .mr-sm-lg,
  .mx-sm-lg {
    margin-right: 4rem !important; }
  .mb-sm-lg,
  .my-sm-lg {
    margin-bottom: 4rem !important; }
  .ml-sm-lg,
  .mx-sm-lg {
    margin-left: 4rem !important; }
  .m-sm-xl {
    margin: 8rem !important; }
  .mt-sm-xl,
  .my-sm-xl {
    margin-top: 8rem !important; }
  .mr-sm-xl,
  .mx-sm-xl {
    margin-right: 8rem !important; }
  .mb-sm-xl,
  .my-sm-xl {
    margin-bottom: 8rem !important; }
  .ml-sm-xl,
  .mx-sm-xl {
    margin-left: 8rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important; }
  .p-sm-7 {
    padding: 8rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 8rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 8rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 8rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 8rem !important; }
  .p-sm-8 {
    padding: 10rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 10rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 10rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 10rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 10rem !important; }
  .p-sm-9 {
    padding: 11rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 11rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 11rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 11rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 11rem !important; }
  .p-sm-10 {
    padding: 14rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 14rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 14rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 14rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 14rem !important; }
  .p-sm-11 {
    padding: 16rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 16rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 16rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 16rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 16rem !important; }
  .p-sm-12 {
    padding: 20rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 20rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 20rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 20rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 20rem !important; }
  .p-sm-sm {
    padding: 1rem !important; }
  .pt-sm-sm,
  .py-sm-sm {
    padding-top: 1rem !important; }
  .pr-sm-sm,
  .px-sm-sm {
    padding-right: 1rem !important; }
  .pb-sm-sm,
  .py-sm-sm {
    padding-bottom: 1rem !important; }
  .pl-sm-sm,
  .px-sm-sm {
    padding-left: 1rem !important; }
  .p-sm-md {
    padding: 2rem !important; }
  .pt-sm-md,
  .py-sm-md {
    padding-top: 2rem !important; }
  .pr-sm-md,
  .px-sm-md {
    padding-right: 2rem !important; }
  .pb-sm-md,
  .py-sm-md {
    padding-bottom: 2rem !important; }
  .pl-sm-md,
  .px-sm-md {
    padding-left: 2rem !important; }
  .p-sm-lg {
    padding: 4rem !important; }
  .pt-sm-lg,
  .py-sm-lg {
    padding-top: 4rem !important; }
  .pr-sm-lg,
  .px-sm-lg {
    padding-right: 4rem !important; }
  .pb-sm-lg,
  .py-sm-lg {
    padding-bottom: 4rem !important; }
  .pl-sm-lg,
  .px-sm-lg {
    padding-left: 4rem !important; }
  .p-sm-xl {
    padding: 8rem !important; }
  .pt-sm-xl,
  .py-sm-xl {
    padding-top: 8rem !important; }
  .pr-sm-xl,
  .px-sm-xl {
    padding-right: 8rem !important; }
  .pb-sm-xl,
  .py-sm-xl {
    padding-bottom: 8rem !important; }
  .pl-sm-xl,
  .px-sm-xl {
    padding-left: 8rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -5rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important; }
  .m-sm-n7 {
    margin: -8rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -8rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -8rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -8rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -8rem !important; }
  .m-sm-n8 {
    margin: -10rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -10rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -10rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -10rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -10rem !important; }
  .m-sm-n9 {
    margin: -11rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -11rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -11rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -11rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -11rem !important; }
  .m-sm-n10 {
    margin: -14rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -14rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -14rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -14rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -14rem !important; }
  .m-sm-n11 {
    margin: -16rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -16rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -16rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -16rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -16rem !important; }
  .m-sm-n12 {
    margin: -20rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -20rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -20rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -20rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -20rem !important; }
  .m-sm-nsm {
    margin: -1rem !important; }
  .mt-sm-nsm,
  .my-sm-nsm {
    margin-top: -1rem !important; }
  .mr-sm-nsm,
  .mx-sm-nsm {
    margin-right: -1rem !important; }
  .mb-sm-nsm,
  .my-sm-nsm {
    margin-bottom: -1rem !important; }
  .ml-sm-nsm,
  .mx-sm-nsm {
    margin-left: -1rem !important; }
  .m-sm-nmd {
    margin: -2rem !important; }
  .mt-sm-nmd,
  .my-sm-nmd {
    margin-top: -2rem !important; }
  .mr-sm-nmd,
  .mx-sm-nmd {
    margin-right: -2rem !important; }
  .mb-sm-nmd,
  .my-sm-nmd {
    margin-bottom: -2rem !important; }
  .ml-sm-nmd,
  .mx-sm-nmd {
    margin-left: -2rem !important; }
  .m-sm-nlg {
    margin: -4rem !important; }
  .mt-sm-nlg,
  .my-sm-nlg {
    margin-top: -4rem !important; }
  .mr-sm-nlg,
  .mx-sm-nlg {
    margin-right: -4rem !important; }
  .mb-sm-nlg,
  .my-sm-nlg {
    margin-bottom: -4rem !important; }
  .ml-sm-nlg,
  .mx-sm-nlg {
    margin-left: -4rem !important; }
  .m-sm-nxl {
    margin: -8rem !important; }
  .mt-sm-nxl,
  .my-sm-nxl {
    margin-top: -8rem !important; }
  .mr-sm-nxl,
  .mx-sm-nxl {
    margin-right: -8rem !important; }
  .mb-sm-nxl,
  .my-sm-nxl {
    margin-bottom: -8rem !important; }
  .ml-sm-nxl,
  .mx-sm-nxl {
    margin-left: -8rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important; }
  .m-md-7 {
    margin: 8rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 8rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 8rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 8rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 8rem !important; }
  .m-md-8 {
    margin: 10rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 10rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 10rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 10rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 10rem !important; }
  .m-md-9 {
    margin: 11rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 11rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 11rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 11rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 11rem !important; }
  .m-md-10 {
    margin: 14rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 14rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 14rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 14rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 14rem !important; }
  .m-md-11 {
    margin: 16rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 16rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 16rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 16rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 16rem !important; }
  .m-md-12 {
    margin: 20rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 20rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 20rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 20rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 20rem !important; }
  .m-md-sm {
    margin: 1rem !important; }
  .mt-md-sm,
  .my-md-sm {
    margin-top: 1rem !important; }
  .mr-md-sm,
  .mx-md-sm {
    margin-right: 1rem !important; }
  .mb-md-sm,
  .my-md-sm {
    margin-bottom: 1rem !important; }
  .ml-md-sm,
  .mx-md-sm {
    margin-left: 1rem !important; }
  .m-md-md {
    margin: 2rem !important; }
  .mt-md-md,
  .my-md-md {
    margin-top: 2rem !important; }
  .mr-md-md,
  .mx-md-md {
    margin-right: 2rem !important; }
  .mb-md-md,
  .my-md-md {
    margin-bottom: 2rem !important; }
  .ml-md-md,
  .mx-md-md {
    margin-left: 2rem !important; }
  .m-md-lg {
    margin: 4rem !important; }
  .mt-md-lg,
  .my-md-lg {
    margin-top: 4rem !important; }
  .mr-md-lg,
  .mx-md-lg {
    margin-right: 4rem !important; }
  .mb-md-lg,
  .my-md-lg {
    margin-bottom: 4rem !important; }
  .ml-md-lg,
  .mx-md-lg {
    margin-left: 4rem !important; }
  .m-md-xl {
    margin: 8rem !important; }
  .mt-md-xl,
  .my-md-xl {
    margin-top: 8rem !important; }
  .mr-md-xl,
  .mx-md-xl {
    margin-right: 8rem !important; }
  .mb-md-xl,
  .my-md-xl {
    margin-bottom: 8rem !important; }
  .ml-md-xl,
  .mx-md-xl {
    margin-left: 8rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important; }
  .p-md-7 {
    padding: 8rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 8rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 8rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 8rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 8rem !important; }
  .p-md-8 {
    padding: 10rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 10rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 10rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 10rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 10rem !important; }
  .p-md-9 {
    padding: 11rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 11rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 11rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 11rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 11rem !important; }
  .p-md-10 {
    padding: 14rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 14rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 14rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 14rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 14rem !important; }
  .p-md-11 {
    padding: 16rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 16rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 16rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 16rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 16rem !important; }
  .p-md-12 {
    padding: 20rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 20rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 20rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 20rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 20rem !important; }
  .p-md-sm {
    padding: 1rem !important; }
  .pt-md-sm,
  .py-md-sm {
    padding-top: 1rem !important; }
  .pr-md-sm,
  .px-md-sm {
    padding-right: 1rem !important; }
  .pb-md-sm,
  .py-md-sm {
    padding-bottom: 1rem !important; }
  .pl-md-sm,
  .px-md-sm {
    padding-left: 1rem !important; }
  .p-md-md {
    padding: 2rem !important; }
  .pt-md-md,
  .py-md-md {
    padding-top: 2rem !important; }
  .pr-md-md,
  .px-md-md {
    padding-right: 2rem !important; }
  .pb-md-md,
  .py-md-md {
    padding-bottom: 2rem !important; }
  .pl-md-md,
  .px-md-md {
    padding-left: 2rem !important; }
  .p-md-lg {
    padding: 4rem !important; }
  .pt-md-lg,
  .py-md-lg {
    padding-top: 4rem !important; }
  .pr-md-lg,
  .px-md-lg {
    padding-right: 4rem !important; }
  .pb-md-lg,
  .py-md-lg {
    padding-bottom: 4rem !important; }
  .pl-md-lg,
  .px-md-lg {
    padding-left: 4rem !important; }
  .p-md-xl {
    padding: 8rem !important; }
  .pt-md-xl,
  .py-md-xl {
    padding-top: 8rem !important; }
  .pr-md-xl,
  .px-md-xl {
    padding-right: 8rem !important; }
  .pb-md-xl,
  .py-md-xl {
    padding-bottom: 8rem !important; }
  .pl-md-xl,
  .px-md-xl {
    padding-left: 8rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -5rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important; }
  .m-md-n7 {
    margin: -8rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -8rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -8rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -8rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -8rem !important; }
  .m-md-n8 {
    margin: -10rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -10rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -10rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -10rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -10rem !important; }
  .m-md-n9 {
    margin: -11rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -11rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -11rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -11rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -11rem !important; }
  .m-md-n10 {
    margin: -14rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -14rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -14rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -14rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -14rem !important; }
  .m-md-n11 {
    margin: -16rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -16rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -16rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -16rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -16rem !important; }
  .m-md-n12 {
    margin: -20rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -20rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -20rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -20rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -20rem !important; }
  .m-md-nsm {
    margin: -1rem !important; }
  .mt-md-nsm,
  .my-md-nsm {
    margin-top: -1rem !important; }
  .mr-md-nsm,
  .mx-md-nsm {
    margin-right: -1rem !important; }
  .mb-md-nsm,
  .my-md-nsm {
    margin-bottom: -1rem !important; }
  .ml-md-nsm,
  .mx-md-nsm {
    margin-left: -1rem !important; }
  .m-md-nmd {
    margin: -2rem !important; }
  .mt-md-nmd,
  .my-md-nmd {
    margin-top: -2rem !important; }
  .mr-md-nmd,
  .mx-md-nmd {
    margin-right: -2rem !important; }
  .mb-md-nmd,
  .my-md-nmd {
    margin-bottom: -2rem !important; }
  .ml-md-nmd,
  .mx-md-nmd {
    margin-left: -2rem !important; }
  .m-md-nlg {
    margin: -4rem !important; }
  .mt-md-nlg,
  .my-md-nlg {
    margin-top: -4rem !important; }
  .mr-md-nlg,
  .mx-md-nlg {
    margin-right: -4rem !important; }
  .mb-md-nlg,
  .my-md-nlg {
    margin-bottom: -4rem !important; }
  .ml-md-nlg,
  .mx-md-nlg {
    margin-left: -4rem !important; }
  .m-md-nxl {
    margin: -8rem !important; }
  .mt-md-nxl,
  .my-md-nxl {
    margin-top: -8rem !important; }
  .mr-md-nxl,
  .mx-md-nxl {
    margin-right: -8rem !important; }
  .mb-md-nxl,
  .my-md-nxl {
    margin-bottom: -8rem !important; }
  .ml-md-nxl,
  .mx-md-nxl {
    margin-left: -8rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important; }
  .m-lg-7 {
    margin: 8rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 8rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 8rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 8rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 8rem !important; }
  .m-lg-8 {
    margin: 10rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 10rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 10rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 10rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 10rem !important; }
  .m-lg-9 {
    margin: 11rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 11rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 11rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 11rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 11rem !important; }
  .m-lg-10 {
    margin: 14rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 14rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 14rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 14rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 14rem !important; }
  .m-lg-11 {
    margin: 16rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 16rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 16rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 16rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 16rem !important; }
  .m-lg-12 {
    margin: 20rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 20rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 20rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 20rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 20rem !important; }
  .m-lg-sm {
    margin: 1rem !important; }
  .mt-lg-sm,
  .my-lg-sm {
    margin-top: 1rem !important; }
  .mr-lg-sm,
  .mx-lg-sm {
    margin-right: 1rem !important; }
  .mb-lg-sm,
  .my-lg-sm {
    margin-bottom: 1rem !important; }
  .ml-lg-sm,
  .mx-lg-sm {
    margin-left: 1rem !important; }
  .m-lg-md {
    margin: 2rem !important; }
  .mt-lg-md,
  .my-lg-md {
    margin-top: 2rem !important; }
  .mr-lg-md,
  .mx-lg-md {
    margin-right: 2rem !important; }
  .mb-lg-md,
  .my-lg-md {
    margin-bottom: 2rem !important; }
  .ml-lg-md,
  .mx-lg-md {
    margin-left: 2rem !important; }
  .m-lg-lg {
    margin: 4rem !important; }
  .mt-lg-lg,
  .my-lg-lg {
    margin-top: 4rem !important; }
  .mr-lg-lg,
  .mx-lg-lg {
    margin-right: 4rem !important; }
  .mb-lg-lg,
  .my-lg-lg {
    margin-bottom: 4rem !important; }
  .ml-lg-lg,
  .mx-lg-lg {
    margin-left: 4rem !important; }
  .m-lg-xl {
    margin: 8rem !important; }
  .mt-lg-xl,
  .my-lg-xl {
    margin-top: 8rem !important; }
  .mr-lg-xl,
  .mx-lg-xl {
    margin-right: 8rem !important; }
  .mb-lg-xl,
  .my-lg-xl {
    margin-bottom: 8rem !important; }
  .ml-lg-xl,
  .mx-lg-xl {
    margin-left: 8rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important; }
  .p-lg-7 {
    padding: 8rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 8rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 8rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 8rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 8rem !important; }
  .p-lg-8 {
    padding: 10rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 10rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 10rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 10rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 10rem !important; }
  .p-lg-9 {
    padding: 11rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 11rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 11rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 11rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 11rem !important; }
  .p-lg-10 {
    padding: 14rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 14rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 14rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 14rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 14rem !important; }
  .p-lg-11 {
    padding: 16rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 16rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 16rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 16rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 16rem !important; }
  .p-lg-12 {
    padding: 20rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 20rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 20rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 20rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 20rem !important; }
  .p-lg-sm {
    padding: 1rem !important; }
  .pt-lg-sm,
  .py-lg-sm {
    padding-top: 1rem !important; }
  .pr-lg-sm,
  .px-lg-sm {
    padding-right: 1rem !important; }
  .pb-lg-sm,
  .py-lg-sm {
    padding-bottom: 1rem !important; }
  .pl-lg-sm,
  .px-lg-sm {
    padding-left: 1rem !important; }
  .p-lg-md {
    padding: 2rem !important; }
  .pt-lg-md,
  .py-lg-md {
    padding-top: 2rem !important; }
  .pr-lg-md,
  .px-lg-md {
    padding-right: 2rem !important; }
  .pb-lg-md,
  .py-lg-md {
    padding-bottom: 2rem !important; }
  .pl-lg-md,
  .px-lg-md {
    padding-left: 2rem !important; }
  .p-lg-lg {
    padding: 4rem !important; }
  .pt-lg-lg,
  .py-lg-lg {
    padding-top: 4rem !important; }
  .pr-lg-lg,
  .px-lg-lg {
    padding-right: 4rem !important; }
  .pb-lg-lg,
  .py-lg-lg {
    padding-bottom: 4rem !important; }
  .pl-lg-lg,
  .px-lg-lg {
    padding-left: 4rem !important; }
  .p-lg-xl {
    padding: 8rem !important; }
  .pt-lg-xl,
  .py-lg-xl {
    padding-top: 8rem !important; }
  .pr-lg-xl,
  .px-lg-xl {
    padding-right: 8rem !important; }
  .pb-lg-xl,
  .py-lg-xl {
    padding-bottom: 8rem !important; }
  .pl-lg-xl,
  .px-lg-xl {
    padding-left: 8rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -5rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important; }
  .m-lg-n7 {
    margin: -8rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -8rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -8rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -8rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -8rem !important; }
  .m-lg-n8 {
    margin: -10rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -10rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -10rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -10rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -10rem !important; }
  .m-lg-n9 {
    margin: -11rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -11rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -11rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -11rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -11rem !important; }
  .m-lg-n10 {
    margin: -14rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -14rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -14rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -14rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -14rem !important; }
  .m-lg-n11 {
    margin: -16rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -16rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -16rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -16rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -16rem !important; }
  .m-lg-n12 {
    margin: -20rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -20rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -20rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -20rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -20rem !important; }
  .m-lg-nsm {
    margin: -1rem !important; }
  .mt-lg-nsm,
  .my-lg-nsm {
    margin-top: -1rem !important; }
  .mr-lg-nsm,
  .mx-lg-nsm {
    margin-right: -1rem !important; }
  .mb-lg-nsm,
  .my-lg-nsm {
    margin-bottom: -1rem !important; }
  .ml-lg-nsm,
  .mx-lg-nsm {
    margin-left: -1rem !important; }
  .m-lg-nmd {
    margin: -2rem !important; }
  .mt-lg-nmd,
  .my-lg-nmd {
    margin-top: -2rem !important; }
  .mr-lg-nmd,
  .mx-lg-nmd {
    margin-right: -2rem !important; }
  .mb-lg-nmd,
  .my-lg-nmd {
    margin-bottom: -2rem !important; }
  .ml-lg-nmd,
  .mx-lg-nmd {
    margin-left: -2rem !important; }
  .m-lg-nlg {
    margin: -4rem !important; }
  .mt-lg-nlg,
  .my-lg-nlg {
    margin-top: -4rem !important; }
  .mr-lg-nlg,
  .mx-lg-nlg {
    margin-right: -4rem !important; }
  .mb-lg-nlg,
  .my-lg-nlg {
    margin-bottom: -4rem !important; }
  .ml-lg-nlg,
  .mx-lg-nlg {
    margin-left: -4rem !important; }
  .m-lg-nxl {
    margin: -8rem !important; }
  .mt-lg-nxl,
  .my-lg-nxl {
    margin-top: -8rem !important; }
  .mr-lg-nxl,
  .mx-lg-nxl {
    margin-right: -8rem !important; }
  .mb-lg-nxl,
  .my-lg-nxl {
    margin-bottom: -8rem !important; }
  .ml-lg-nxl,
  .mx-lg-nxl {
    margin-left: -8rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 5rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important; }
  .m-xl-7 {
    margin: 8rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 8rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 8rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 8rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 8rem !important; }
  .m-xl-8 {
    margin: 10rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 10rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 10rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 10rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 10rem !important; }
  .m-xl-9 {
    margin: 11rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 11rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 11rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 11rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 11rem !important; }
  .m-xl-10 {
    margin: 14rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 14rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 14rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 14rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 14rem !important; }
  .m-xl-11 {
    margin: 16rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 16rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 16rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 16rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 16rem !important; }
  .m-xl-12 {
    margin: 20rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 20rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 20rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 20rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 20rem !important; }
  .m-xl-sm {
    margin: 1rem !important; }
  .mt-xl-sm,
  .my-xl-sm {
    margin-top: 1rem !important; }
  .mr-xl-sm,
  .mx-xl-sm {
    margin-right: 1rem !important; }
  .mb-xl-sm,
  .my-xl-sm {
    margin-bottom: 1rem !important; }
  .ml-xl-sm,
  .mx-xl-sm {
    margin-left: 1rem !important; }
  .m-xl-md {
    margin: 2rem !important; }
  .mt-xl-md,
  .my-xl-md {
    margin-top: 2rem !important; }
  .mr-xl-md,
  .mx-xl-md {
    margin-right: 2rem !important; }
  .mb-xl-md,
  .my-xl-md {
    margin-bottom: 2rem !important; }
  .ml-xl-md,
  .mx-xl-md {
    margin-left: 2rem !important; }
  .m-xl-lg {
    margin: 4rem !important; }
  .mt-xl-lg,
  .my-xl-lg {
    margin-top: 4rem !important; }
  .mr-xl-lg,
  .mx-xl-lg {
    margin-right: 4rem !important; }
  .mb-xl-lg,
  .my-xl-lg {
    margin-bottom: 4rem !important; }
  .ml-xl-lg,
  .mx-xl-lg {
    margin-left: 4rem !important; }
  .m-xl-xl {
    margin: 8rem !important; }
  .mt-xl-xl,
  .my-xl-xl {
    margin-top: 8rem !important; }
  .mr-xl-xl,
  .mx-xl-xl {
    margin-right: 8rem !important; }
  .mb-xl-xl,
  .my-xl-xl {
    margin-bottom: 8rem !important; }
  .ml-xl-xl,
  .mx-xl-xl {
    margin-left: 8rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 5rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important; }
  .p-xl-7 {
    padding: 8rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 8rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 8rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 8rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 8rem !important; }
  .p-xl-8 {
    padding: 10rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 10rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 10rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 10rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 10rem !important; }
  .p-xl-9 {
    padding: 11rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 11rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 11rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 11rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 11rem !important; }
  .p-xl-10 {
    padding: 14rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 14rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 14rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 14rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 14rem !important; }
  .p-xl-11 {
    padding: 16rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 16rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 16rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 16rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 16rem !important; }
  .p-xl-12 {
    padding: 20rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 20rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 20rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 20rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 20rem !important; }
  .p-xl-sm {
    padding: 1rem !important; }
  .pt-xl-sm,
  .py-xl-sm {
    padding-top: 1rem !important; }
  .pr-xl-sm,
  .px-xl-sm {
    padding-right: 1rem !important; }
  .pb-xl-sm,
  .py-xl-sm {
    padding-bottom: 1rem !important; }
  .pl-xl-sm,
  .px-xl-sm {
    padding-left: 1rem !important; }
  .p-xl-md {
    padding: 2rem !important; }
  .pt-xl-md,
  .py-xl-md {
    padding-top: 2rem !important; }
  .pr-xl-md,
  .px-xl-md {
    padding-right: 2rem !important; }
  .pb-xl-md,
  .py-xl-md {
    padding-bottom: 2rem !important; }
  .pl-xl-md,
  .px-xl-md {
    padding-left: 2rem !important; }
  .p-xl-lg {
    padding: 4rem !important; }
  .pt-xl-lg,
  .py-xl-lg {
    padding-top: 4rem !important; }
  .pr-xl-lg,
  .px-xl-lg {
    padding-right: 4rem !important; }
  .pb-xl-lg,
  .py-xl-lg {
    padding-bottom: 4rem !important; }
  .pl-xl-lg,
  .px-xl-lg {
    padding-left: 4rem !important; }
  .p-xl-xl {
    padding: 8rem !important; }
  .pt-xl-xl,
  .py-xl-xl {
    padding-top: 8rem !important; }
  .pr-xl-xl,
  .px-xl-xl {
    padding-right: 8rem !important; }
  .pb-xl-xl,
  .py-xl-xl {
    padding-bottom: 8rem !important; }
  .pl-xl-xl,
  .px-xl-xl {
    padding-left: 8rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -5rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important; }
  .m-xl-n7 {
    margin: -8rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -8rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -8rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -8rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -8rem !important; }
  .m-xl-n8 {
    margin: -10rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -10rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -10rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -10rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -10rem !important; }
  .m-xl-n9 {
    margin: -11rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -11rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -11rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -11rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -11rem !important; }
  .m-xl-n10 {
    margin: -14rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -14rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -14rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -14rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -14rem !important; }
  .m-xl-n11 {
    margin: -16rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -16rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -16rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -16rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -16rem !important; }
  .m-xl-n12 {
    margin: -20rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -20rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -20rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -20rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -20rem !important; }
  .m-xl-nsm {
    margin: -1rem !important; }
  .mt-xl-nsm,
  .my-xl-nsm {
    margin-top: -1rem !important; }
  .mr-xl-nsm,
  .mx-xl-nsm {
    margin-right: -1rem !important; }
  .mb-xl-nsm,
  .my-xl-nsm {
    margin-bottom: -1rem !important; }
  .ml-xl-nsm,
  .mx-xl-nsm {
    margin-left: -1rem !important; }
  .m-xl-nmd {
    margin: -2rem !important; }
  .mt-xl-nmd,
  .my-xl-nmd {
    margin-top: -2rem !important; }
  .mr-xl-nmd,
  .mx-xl-nmd {
    margin-right: -2rem !important; }
  .mb-xl-nmd,
  .my-xl-nmd {
    margin-bottom: -2rem !important; }
  .ml-xl-nmd,
  .mx-xl-nmd {
    margin-left: -2rem !important; }
  .m-xl-nlg {
    margin: -4rem !important; }
  .mt-xl-nlg,
  .my-xl-nlg {
    margin-top: -4rem !important; }
  .mr-xl-nlg,
  .mx-xl-nlg {
    margin-right: -4rem !important; }
  .mb-xl-nlg,
  .my-xl-nlg {
    margin-bottom: -4rem !important; }
  .ml-xl-nlg,
  .mx-xl-nlg {
    margin-left: -4rem !important; }
  .m-xl-nxl {
    margin: -8rem !important; }
  .mt-xl-nxl,
  .my-xl-nxl {
    margin-top: -8rem !important; }
  .mr-xl-nxl,
  .mx-xl-nxl {
    margin-right: -8rem !important; }
  .mb-xl-nxl,
  .my-xl-nxl {
    margin-bottom: -8rem !important; }
  .ml-xl-nxl,
  .mx-xl-nxl {
    margin-left: -8rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-weight-bolder {
  font-weight: 600 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #0d1b48 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #010307 !important; }

.text-secondary {
  color: #C33C54 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #892a3b !important; }

.text-success {
  color: #208163 !important; }

a.text-success:hover, a.text-success:focus {
  color: #114434 !important; }

.text-info {
  color: #0056B3 !important; }

a.text-info:hover, a.text-info:focus {
  color: #003167 !important; }

.text-warning {
  color: #F0B400 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #a47b00 !important; }

.text-danger {
  color: #be2837 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #7f1b25 !important; }

.text-light {
  color: #edf0f7 !important; }

a.text-light:hover, a.text-light:focus {
  color: #b8c4df !important; }

.text-dark {
  color: #0d1b48 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #010307 !important; }

.text-tertiary {
  color: #26408B !important; }

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #16244f !important; }

.text-lighten {
  color: #ffffff !important; }

a.text-lighten:hover, a.text-lighten:focus {
  color: #d9d9d9 !important; }

.text-white {
  color: #ffffff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-gray {
  color: #424767 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #242738 !important; }

.text-neutral {
  color: #ffffff !important; }

a.text-neutral:hover, a.text-neutral:focus {
  color: #d9d9d9 !important; }

.text-soft {
  color: #f5f9fe !important; }

a.text-soft:hover, a.text-soft:focus {
  color: #afcff7 !important; }

.text-black {
  color: #0d1b48 !important; }

a.text-black:hover, a.text-black:focus {
  color: #010307 !important; }

.text-purple {
  color: #6f42c1 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #4e2d89 !important; }

.text-soft-green {
  color: #ebf9ee !important; }

a.text-soft-green:hover, a.text-soft-green:focus {
  color: #b0e7bc !important; }

.text-gray-100 {
  color: #ffffff !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #d9d9d9 !important; }

.text-gray-200 {
  color: #fafbfe !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bac8f1 !important; }

.text-gray-300 {
  color: #f5f9fe !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #afcff7 !important; }

.text-gray-400 {
  color: #edf0f7 !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #b8c4df !important; }

.text-gray-500 {
  color: #B7C3D2 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #889cb5 !important; }

.text-gray-600 {
  color: #93a5be !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #637da1 !important; }

.text-gray-700 {
  color: #52547a !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #33354c !important; }

.text-gray-800 {
  color: #4E5079 !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #30314a !important; }

.text-facebook {
  color: #3b5999 !important; }

a.text-facebook:hover, a.text-facebook:focus {
  color: #263962 !important; }

.text-twitter {
  color: #1da1f2 !important; }

a.text-twitter:hover, a.text-twitter:focus {
  color: #0b76b8 !important; }

.text-google {
  color: #DB4337 !important; }

a.text-google:hover, a.text-google:focus {
  color: #a7281e !important; }

.text-instagram {
  color: #e4405f !important; }

a.text-instagram:hover, a.text-instagram:focus {
  color: #bd1b39 !important; }

.text-pinterest {
  color: #bd081c !important; }

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #740511 !important; }

.text-youtube {
  color: #cd201f !important; }

a.text-youtube:hover, a.text-youtube:focus {
  color: #8b1615 !important; }

.text-slack {
  color: #3aaf85 !important; }

a.text-slack:hover, a.text-slack:focus {
  color: #277659 !important; }

.text-dribbble {
  color: #ea4c89 !important; }

a.text-dribbble:hover, a.text-dribbble:focus {
  color: #d11960 !important; }

.text-dropbox {
  color: #1E90FF !important; }

a.text-dropbox:hover, a.text-dropbox:focus {
  color: #006ad1 !important; }

.text-twitch {
  color: #4B367C !important; }

a.text-twitch:hover, a.text-twitch:focus {
  color: #2b1f47 !important; }

.text-paypal {
  color: #ecb32c !important; }

a.text-paypal:hover, a.text-paypal:focus {
  color: #bb8811 !important; }

.text-behance {
  color: #0057ff !important; }

a.text-behance:hover, a.text-behance:focus {
  color: #003db3 !important; }

.text-reddit {
  color: #E84422 !important; }

a.text-reddit:hover, a.text-reddit:focus {
  color: #ac2c12 !important; }

.text-github {
  color: #222222 !important; }

a.text-github:hover, a.text-github:focus {
  color: black !important; }

.text-body {
  color: #424767 !important; }

.text-muted {
  color: #52547a !important; }

.text-black-50 {
  color: rgba(13, 27, 72, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 0.0625rem solid #B7C3D2;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 0.0625rem solid #0d1b48; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f5f9fe !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #edf0f7; }
  .table .thead-dark th {
    color: inherit;
    border-color: #edf0f7; } }

.datepicker {
  border-radius: 4px;
  direction: ltr; }
  .datepicker-inline {
    width: 220px; }
  .datepicker-rtl {
    direction: rtl; }
    .datepicker-rtl.dropdown-menu {
      left: auto; }
    .datepicker-rtl table tr td span {
      float: right; }
  .datepicker-dropdown {
    top: 0;
    left: 0;
    padding: 20px 22px;
    box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15); }
    .datepicker-dropdown.datepicker-orient-left:before {
      left: 6px; }
    .datepicker-dropdown.datepicker-orient-left:after {
      left: 7px; }
    .datepicker-dropdown.datepicker-orient-right:before {
      right: 6px; }
    .datepicker-dropdown.datepicker-orient-right:after {
      right: 7px; }
    .datepicker-dropdown.datepicker-orient-bottom:before {
      top: -7px; }
    .datepicker-dropdown.datepicker-orient-bottom:after {
      top: -6px; }
    .datepicker-dropdown.datepicker-orient-top:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid white; }
    .datepicker-dropdown.datepicker-orient-top:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #ffffff; }
  .datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    user-select: none; }
    .datepicker table tr td {
      border-radius: 4px; }
    .datepicker table tr th {
      border-radius: 4px;
      font-weight: 700; }
    .datepicker table tr td,
    .datepicker table tr th {
      transition: all 0.2s ease;
      width: 36px;
      height: 36px;
      border: none;
      text-align: center;
      font-size: 0.875rem; }
  .table-striped .datepicker table tr td,
  .table-striped .datepicker table tr th {
    background-color: transparent; }
  .datepicker table tr td.old, .datepicker table tr td.new {
    color: #B7C3D2; }
  .datepicker table tr td.day:hover, .datepicker table tr td.focused {
    background: white;
    cursor: pointer; }
  .datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
    background: none;
    color: #f5f9fe;
    cursor: default; }
  .datepicker table tr td.highlighted {
    border-radius: 0; }
    .datepicker table tr td.highlighted.focused {
      background: #C33C54; }
    .datepicker table tr td.highlighted.disabled, .datepicker table tr td.highlighted.disabled:active {
      background: #C33C54;
      color: #edf0f7; }
  .datepicker table tr td.today {
    background: #f9ecee; }
    .datepicker table tr td.today.focused {
      background: white; }
    .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:active {
      background: white;
      color: #93a5be; }
  .datepicker table tr td.range {
    background: #C33C54;
    color: #ffffff;
    border-radius: 0; }
    .datepicker table tr td.range.focused {
      background: #a43247; }
    .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:active, .datepicker table tr td.range.day.disabled:hover {
      background: #9c3043;
      color: #cf6376; }
  .datepicker table tr td.range.highlighted.focused {
    background: #d0d9f6; }
  .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.highlighted.disabled:active {
    background: #fafbfe;
    color: #f5f9fe; }
  .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:active {
    background: #0056B3;
    color: #ffffff; }
  .datepicker table tr td.day.range-start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .datepicker table tr td.day.range-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .datepicker table tr td.day.range-start.range-end {
    border-radius: 4px; }
  .datepicker table tr td.selected, .datepicker table tr td.selected.highlighted, .datepicker table tr td.selected:hover, .datepicker table tr td.selected.highlighted:hover, .datepicker table tr td.day.range:hover {
    background: #C33C54;
    color: #ffffff; }
  .datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td.active:hover, .datepicker table tr td.active.highlighted:hover {
    background: #C33C54;
    color: #ffffff;
    box-shadow: none; }
  .datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px; }
    .datepicker table tr td span:hover, .datepicker table tr td span.focused {
      background: #fafbfe; }
    .datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
      background: none;
      color: #f5f9fe;
      cursor: default; }
    .datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }
    .datepicker table tr td span.old, .datepicker table tr td span.new {
      color: #93a5be; }
  .datepicker .datepicker-switch {
    width: 145px; }
  .datepicker .datepicker-switch,
  .datepicker .prev,
  .datepicker .next,
  .datepicker tfoot tr th {
    cursor: pointer; }
    .datepicker .datepicker-switch:hover,
    .datepicker .prev:hover,
    .datepicker .next:hover,
    .datepicker tfoot tr th:hover {
      background: #fafbfe; }
  .datepicker .prev.disabled,
  .datepicker .next.disabled {
    visibility: hidden; }
  .datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle; }

.bootstrap-tagsinput {
  background-color: #ffffff;
  border: 0.0625rem solid #edf0f7;
  display: inline-block;
  padding: .5rem .5rem;
  color: #ffffff;
  vertical-align: middle;
  border-radius: 0.45rem;
  max-width: 100%;
  cursor: text; }
  .bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    font-size: 0.75rem;
    max-width: inherit; }
    .bootstrap-tagsinput input:focus {
      border: none;
      box-shadow: none; }
  .bootstrap-tagsinput.form-control input::-moz-placeholder {
    color: #4E5079;
    opacity: 1; }
  .bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
    color: #4E5079; }
  .bootstrap-tagsinput.form-control input:-ms-input-placeholder {
    color: #4E5079; }
  .bootstrap-tagsinput .tag {
    background: #0d1b48;
    font-size: 0.75rem;
    padding: .15rem .5rem;
    border-radius: 0.45rem; }
  .bootstrap-tagsinput [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6); }
    .bootstrap-tagsinput [data-role="remove"]:after {
      content: "x";
      padding: 0 2px; }
    .bootstrap-tagsinput [data-role="remove"]:hover {
      color: white; }

.headroom {
  will-change: transform;
  background-color: inherit;
  transition: all 0.2s ease; }
  @media (prefers-reduced-motion: reduce) {
    .headroom {
      transition: none; } }

.headroom--pinned {
  transform: translateY(0%); }

.headroom--unpinned {
  transform: translateY(-100%); }

.headroom--not-top {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .headroom--not-top.navbar-theme-primary {
    background-color: #0d1b48; }
    .headroom--not-top.navbar-theme-primary .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-primary .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-primary .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-primary .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-secondary {
    background-color: #C33C54; }
    .headroom--not-top.navbar-theme-secondary .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-secondary .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-secondary .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-secondary .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-success {
    background-color: #208163; }
    .headroom--not-top.navbar-theme-success .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-success .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-success .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-success .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-info {
    background-color: #0056B3; }
    .headroom--not-top.navbar-theme-info .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-info .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-info .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-info .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-warning {
    background-color: #F0B400; }
    .headroom--not-top.navbar-theme-warning .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-warning .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-warning .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-warning .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-danger {
    background-color: #be2837; }
    .headroom--not-top.navbar-theme-danger .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-danger .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-danger .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-danger .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-light {
    background-color: #edf0f7; }
    .headroom--not-top.navbar-theme-light .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-light .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-light .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-light .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-dark {
    background-color: #0d1b48; }
    .headroom--not-top.navbar-theme-dark .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-dark .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-dark .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-dark .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-tertiary {
    background-color: #26408B; }
    .headroom--not-top.navbar-theme-tertiary .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-tertiary .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-tertiary .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-tertiary .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-lighten {
    background-color: #ffffff; }
    .headroom--not-top.navbar-theme-lighten .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-lighten .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-lighten .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-lighten .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-white {
    background-color: #ffffff; }
    .headroom--not-top.navbar-theme-white .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-white .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-white .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-white .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray {
    background-color: #424767; }
    .headroom--not-top.navbar-theme-gray .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-neutral {
    background-color: #ffffff; }
    .headroom--not-top.navbar-theme-neutral .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-neutral .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-neutral .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-neutral .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-soft {
    background-color: #f5f9fe; }
    .headroom--not-top.navbar-theme-soft .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-soft .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-soft .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-soft .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-black {
    background-color: #0d1b48; }
    .headroom--not-top.navbar-theme-black .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-black .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-black .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-black .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-purple {
    background-color: #6f42c1; }
    .headroom--not-top.navbar-theme-purple .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-purple .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-purple .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-purple .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-soft-green {
    background-color: #ebf9ee; }
    .headroom--not-top.navbar-theme-soft-green .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-soft-green .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-soft-green .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-soft-green .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-100 {
    background-color: #ffffff; }
    .headroom--not-top.navbar-theme-gray-100 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-100 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-100 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-100 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-200 {
    background-color: #fafbfe; }
    .headroom--not-top.navbar-theme-gray-200 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-200 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-200 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-200 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-300 {
    background-color: #f5f9fe; }
    .headroom--not-top.navbar-theme-gray-300 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-300 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-300 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-300 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-400 {
    background-color: #edf0f7; }
    .headroom--not-top.navbar-theme-gray-400 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-400 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-400 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-400 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-500 {
    background-color: #B7C3D2; }
    .headroom--not-top.navbar-theme-gray-500 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-500 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-500 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-500 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-600 {
    background-color: #93a5be; }
    .headroom--not-top.navbar-theme-gray-600 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-600 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-600 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-600 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-700 {
    background-color: #52547a; }
    .headroom--not-top.navbar-theme-gray-700 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-700 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-700 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-700 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-gray-800 {
    background-color: #4E5079; }
    .headroom--not-top.navbar-theme-gray-800 .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-gray-800 .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-gray-800 .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-gray-800 .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-facebook {
    background-color: #3b5999; }
    .headroom--not-top.navbar-theme-facebook .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-facebook .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-facebook .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-facebook .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-twitter {
    background-color: #1da1f2; }
    .headroom--not-top.navbar-theme-twitter .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-twitter .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-twitter .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-twitter .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-google {
    background-color: #DB4337; }
    .headroom--not-top.navbar-theme-google .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-google .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-google .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-google .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-instagram {
    background-color: #e4405f; }
    .headroom--not-top.navbar-theme-instagram .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-instagram .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-instagram .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-instagram .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-pinterest {
    background-color: #bd081c; }
    .headroom--not-top.navbar-theme-pinterest .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-pinterest .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-pinterest .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-pinterest .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-youtube {
    background-color: #cd201f; }
    .headroom--not-top.navbar-theme-youtube .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-youtube .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-youtube .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-youtube .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-slack {
    background-color: #3aaf85; }
    .headroom--not-top.navbar-theme-slack .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-slack .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-slack .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-slack .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-dribbble {
    background-color: #ea4c89; }
    .headroom--not-top.navbar-theme-dribbble .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-dribbble .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-dribbble .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-dribbble .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-dropbox {
    background-color: #1E90FF; }
    .headroom--not-top.navbar-theme-dropbox .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-dropbox .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-dropbox .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-dropbox .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-twitch {
    background-color: #4B367C; }
    .headroom--not-top.navbar-theme-twitch .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-twitch .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-twitch .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-twitch .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-paypal {
    background-color: #ecb32c; }
    .headroom--not-top.navbar-theme-paypal .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-paypal .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-paypal .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-paypal .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-behance {
    background-color: #0057ff; }
    .headroom--not-top.navbar-theme-behance .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-behance .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-behance .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-behance .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-reddit {
    background-color: #E84422; }
    .headroom--not-top.navbar-theme-reddit .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-reddit .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-reddit .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-reddit .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-theme-github {
    background-color: #222222; }
    .headroom--not-top.navbar-theme-github .navbar-brand-light {
      display: none; }
    .headroom--not-top.navbar-theme-github .navbar-brand-dark {
      display: block; }
    .headroom--not-top.navbar-theme-github .nav-link {
      color: rgba(255, 255, 255, 0.9); }
      .headroom--not-top.navbar-theme-github .nav-link:hover {
        color: #ffffff; }
  .headroom--not-top.navbar-light .navbar-nav > .nav-item.show > .nav-link {
    color: #ffffff; }
  .headroom--not-top.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform .3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 5px; }

.noUi-horizontal .noUi-handle {
  left: -17px;
  top: -10px; }

.noUi-vertical {
  width: 5px;
  height: 340px; }

.noUi-vertical .noUi-handle {
  width: 18px;
  height: 34px;
  left: -6px;
  top: -17px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #0d1b48; }

.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #ffffff;
  border-radius: 3px;
  background: #ffffff;
  cursor: default;
  outline: none; }
  .noUi-handle:hover {
    cursor: grab;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    background-color: #edf0f7; }
  .noUi-handle:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    background-color: #edf0f7;
    box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5); }
  .noUi-handle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5);
    background-color: #edf0f7; }

.noUi-active {
  outline: none; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #edf0f7; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #4E5079;
  font-size: 0.75rem; }

/* Values;
 *
 */
.noUi-value {
  margin-top: 5px;
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #edf0f7;
  font-size: 0.75rem; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #4E5079; }

.noUi-marker-sub {
  background: #4E5079; }

.noUi-marker-large {
  background: #4E5079; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 12px 0;
  height: auto;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 12px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  background: #ffffff;
  color: #4E5079;
  font-size: 0.75rem;
  font-weight: 400;
  display: block;
  position: absolute;
  padding: 5px 10px;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.45rem;
  border: 0.0625rem solid #edf0f7; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 30px; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-target {
  background: #edf0f7;
  border-radius: 5px;
  border: 0;
  box-shadow: inset 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
  margin: 15px 0;
  cursor: pointer; }

.noUi-horizontal {
  height: 5px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -10px; }

.noUi-vertical {
  width: 5px; }

.noUi-connect {
  background: #0d1b48;
  box-shadow: none; }

.noUi-handle {
  position: absolute;
  border: 1px solid #0d1b48;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06);
  background: #ffffff;
  transition: all 150ms cubic-bezier(0.17, 0.01, 0.28, 1.06); }

.noUi-vertical .noUi-handle {
  border: 1px solid #52547a;
  border-radius: 3px;
  background: #ffffff;
  cursor: default;
  box-shadow: inset 0 0 1px #ffffff, inset 0 1px 7px #ffffff, 0 3px 6px -3px #fafbfe;
  outline: none; }
  .noUi-vertical .noUi-handle:hover {
    cursor: grab;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    background-color: #edf0f7; }
  .noUi-vertical .noUi-handle:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    background-color: #edf0f7;
    box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5); }
  .noUi-vertical .noUi-handle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5);
    background-color: #edf0f7; }

.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
  transform: scale(1.2); }

.input-slider--cyan .noUi-connect {
  background: #17a2b8; }

.input-slider--cyan.noUi-horizontal .noUi-handle,
.input-slider--cyan.noUi-vertical .noUi-handle {
  background-color: #17a2b8; }

.input-slider--red .noUi-connect {
  background: #be2837; }

.input-slider--red.noUi-horizontal .noUi-handle,
.input-slider--red.noUi-vertical .noUi-handle {
  background-color: #be2837; }

.input-slider--green .noUi-connect {
  background: #208163; }

.input-slider--green.noUi-horizontal .noUi-handle,
.input-slider--green.noUi-vertical .noUi-handle {
  background-color: #208163; }

.input-slider--yellow .noUi-connect {
  background: #F0B400; }

.input-slider--yellow.noUi-horizontal .noUi-handle,
.input-slider--yellow.noUi-vertical .noUi-handle {
  background-color: #F0B400; }

.input-slider--pink .noUi-connect {
  background: #e83e8c; }

.input-slider--pink.noUi-horizontal .noUi-handle,
.input-slider--pink.noUi-vertical .noUi-handle {
  background-color: #e83e8c; }

/* Disabled state */
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #fafbfe; }

[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed; }

/* Range slider value labels */
.range-slider-value {
  font-size: 0.75rem;
  font-weight: 500;
  background-color: rgba(13, 27, 72, 0.7);
  color: #ffffff;
  border-radius: 10px;
  padding: .4em .8em .3em .85em; }

.range-slider-wrapper .upper-info {
  font-weight: 400;
  margin-bottom: 5px; }

.input-slider-value-output {
  background: #0d1b48;
  color: #ffffff;
  padding: 4px 8px;
  position: relative;
  top: 12px;
  font-size: 11px;
  border-radius: 2px; }

.input-slider-value-output:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #0d1b48;
  border-bottom-color: #333;
  border-width: 4px;
  margin-left: -4px; }

.input-slider-value-output.left:after {
  left: 10px;
  right: auto; }

.input-slider-value-output.right:after {
  right: 10px;
  left: auto; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    border: none;
    padding: 0 !important;
    font-size: 2rem; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * Breakpoint mixins to ease development conditions
 */
.submit-wizard form.slideform-form {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .submit-wizard form.slideform-form .slideform-track {
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .submit-wizard form.slideform-form .slideform-wrapper {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .submit-wizard form.slideform-form .slideform-slide {
    display: flex;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    overflow: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .submit-wizard form.slideform-form .slideform-slide.active {
      opacity: 1; }
    @media screen and (max-width: 767px) {
      .submit-wizard form.slideform-form .slideform-slide {
        display: block; } }
  .submit-wizard form.slideform-form .slideform-group {
    padding: 30px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      .submit-wizard form.slideform-form .slideform-group {
        margin: auto; } }
  .submit-wizard form.slideform-form .slideform-btn {
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #0d1b48;
    border: 0.0625rem solid transparent;
    padding: 0.55rem 0.95rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.45rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .submit-wizard form.slideform-form .slideform-btn {
        transition: none; } }
    .submit-wizard form.slideform-form .slideform-btn:disabled {
      background: #f5f9fe;
      border: 1px solid #f5f9fe;
      color: #f5f9fe; }
  .submit-wizard form.slideform-form input[type='text'], .submit-wizard form.slideform-form input[type='email'], .submit-wizard form.slideform-form input[type='tel'], .submit-wizard form.slideform-form input[type='number'], .submit-wizard form.slideform-form textarea {
    width: 100%;
    padding: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #edf0f7;
    outline: none;
    border-radius: none;
    background: #f5f9fe;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .submit-wizard form.slideform-form input[type='text']:focus, .submit-wizard form.slideform-form input[type='email']:focus, .submit-wizard form.slideform-form input[type='tel']:focus, .submit-wizard form.slideform-form input[type='number']:focus, .submit-wizard form.slideform-form textarea:focus {
      border-bottom: 1px solid #1d3b9e; }
  .submit-wizard form.slideform-form input[type='text'], .submit-wizard form.slideform-form input[type='email'], .submit-wizard form.slideform-form input[type='tel'], .submit-wizard form.slideform-form input[type=number] {
    height: 50px; }
    .submit-wizard form.slideform-form input[type='text'].small, .submit-wizard form.slideform-form input[type='email'].small, .submit-wizard form.slideform-form input[type='tel'].small, .submit-wizard form.slideform-form input[type=number].small {
      height: 40px;
      padding: 10px 15px;
      font-size: 16px; }
  .submit-wizard form.slideform-form .options-list label {
    display: block;
    margin: 0;
    padding: 10px 0; }
  .submit-wizard form.slideform-form .options-list input[type=checkbox], .submit-wizard form.slideform-form .options-list input[type=radio] {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    width: auto;
    height: auto; }
    .submit-wizard form.slideform-form .options-list input[type=checkbox]:checked + span:after, .submit-wizard form.slideform-form .options-list input[type=radio]:checked + span:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1); }
    .submit-wizard form.slideform-form .options-list input[type=checkbox] + span, .submit-wizard form.slideform-form .options-list input[type=radio] + span {
      display: inline-block;
      position: relative;
      font-weight: 600; }
      .submit-wizard form.slideform-form .options-list input[type=checkbox] + span:before, .submit-wizard form.slideform-form .options-list input[type=radio] + span:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        background: #f5f9fe;
        border: 1px solid #f5f9fe; }
      .submit-wizard form.slideform-form .options-list input[type=checkbox] + span:after, .submit-wizard form.slideform-form .options-list input[type=radio] + span:after {
        display: inline-block;
        font-size: 22px;
        color: #0d1b48;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        text-align: center;
        line-height: 30px;
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3);
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
  .submit-wizard form.slideform-form .options-list input[type=radio] + span:before {
    border-radius: 50%; }
  .submit-wizard form.slideform-form .options-list input[type=radio] + span:after {
    content: '';
    width: 16px;
    height: 16px;
    background: #0d1b48;
    border-radius: 50%;
    top: 7px;
    left: 7px; }
  .submit-wizard form.slideform-form .options-buttons label {
    margin: 0 5px 15px 0;
    display: inline-block;
    text-transform: none; }
  .submit-wizard form.slideform-form .options-buttons input[type=radio], .submit-wizard form.slideform-form .options-buttons input[type=checkbox] {
    position: absolute;
    left: -9999px; }
    .submit-wizard form.slideform-form .options-buttons input[type=radio]:checked + span, .submit-wizard form.slideform-form .options-buttons input[type=checkbox]:checked + span {
      background: #0d1b48;
      border: 1px solid #0d1b48;
      color: #ffffff; }
  .submit-wizard form.slideform-form .options-buttons input[type=checkbox] + span, .submit-wizard form.slideform-form .options-buttons input[type=radio] + span {
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    background: #f5f9fe;
    border: 1px solid #f5f9fe;
    padding: 5px 20px;
    width: auto;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border-radius: 4px;
    font-weight: 600; }
  .submit-wizard form.slideform-form label.error {
    color: #be2837;
    display: block;
    font-size: 14px;
    margin-top: 5px;
    text-transform: none; }
  .submit-wizard form.slideform-form .slideform-condition {
    display: none; }
  .submit-wizard form.slideform-form .slideform-footer {
    background: #ffffff;
    padding: 0 15px;
    border-top: 1px solid #f5f9fe;
    z-index: 1000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 65px; }
    .submit-wizard form.slideform-form .slideform-footer .slideform-btn {
      display: inline-block;
      font-size: 1rem; }
  .submit-wizard form.slideform-form .slideform-progress-bar {
    height: 7px;
    border-radius: 1px;
    background: #f5f9fe;
    display: inline-block;
    overflow: hidden;
    line-height: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 20px; }
    .submit-wizard form.slideform-form .slideform-progress-bar span {
      display: inline-block;
      width: 0%;
      height: 100%;
      background: #0d1b48;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
  .submit-wizard form.slideform-form .wizard-brand img {
    width: 70px; }

.ct-label {
  fill: #52547a;
  color: #52547a;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 400; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central; }

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: #edf0f7;
  stroke-width: 2px;
  stroke-dasharray: 2px; }

.ct-grid-background {
  fill: none; }

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 4px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.1; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #C33C54; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #C33C54; }

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #0d1b48; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: #0d1b48; }

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #26408B; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: #26408B; }

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #86BA90; }

.ct-series-d .ct-slice-pie, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-area {
  fill: #86BA90; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }
  .ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%; }
  .ct-square:after {
    content: "";
    display: table;
    clear: both; }
  .ct-square > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%; }
  .ct-minor-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.88889%; }
  .ct-major-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.33333%; }
  .ct-minor-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%; }
  .ct-major-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%; }
  .ct-perfect-fourth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fourth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.66667%; }
  .ct-perfect-fifth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fifth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%; }
  .ct-minor-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }
  .ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.8047%; }
  .ct-golden-section:after {
    content: "";
    display: table;
    clear: both; }
  .ct-golden-section > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%; }
  .ct-major-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%; }
  .ct-minor-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.33333%; }
  .ct-major-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }
  .ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%; }
  .ct-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%; }
  .ct-major-tenth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-tenth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%; }
  .ct-major-eleventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-eleventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.33333%; }
  .ct-major-twelfth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-twelfth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }
  .ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%; }
  .ct-double-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-double-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 1rem;
  font-weight: 500; }

iframe {
  border: 0; }

figcaption,
figure,
main {
  display: block;
  margin: 0; }

main {
  overflow: hidden; }

img {
  max-width: 100%; }

strong {
  font-weight: 500; }

button:focus {
  outline: 0; }

label {
  font-weight: 400;
  font-size: 1rem !important;
  color: #0d1b48; }

/**
 * = Backgrounds
 */
.bg-blue {
  background-color: #0056B3 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #003d80 !important; }

.bg-indigo {
  background-color: #6610f2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }

.bg-purple {
  background-color: #6f42c1 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important; }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.bg-red {
  background-color: #be2837 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #941f2b !important; }

.bg-orange {
  background-color: #fd7e14 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important; }

.bg-yellow {
  background-color: #F0B400 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #bd8e00 !important; }

.bg-green {
  background-color: #208163 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #165844 !important; }

.bg-teal {
  background-color: #0056B3 !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #003d80 !important; }

.bg-cyan {
  background-color: #17a2b8 !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important; }

.bg-white {
  background-color: #ffffff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray {
  background-color: #93a5be !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #738aab !important; }

.bg-gray-dark {
  background-color: #4E5079 !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #3a3b5a !important; }

.bg-gradient-primary {
  background: linear-gradient(87deg, #0d1b48 0, #09194c 100%) !important; }

.bg-gradient-secondary {
  background: linear-gradient(87deg, #C33C54 0, #d02f4c 100%) !important; }

.bg-gradient-success {
  background: linear-gradient(87deg, #208163 0, #188966 100%) !important; }

.bg-gradient-info {
  background: linear-gradient(87deg, #0056B3 0, #0056b3 100%) !important; }

.bg-gradient-warning {
  background: linear-gradient(87deg, #F0B400 0, #f0b400 100%) !important; }

.bg-gradient-danger {
  background: linear-gradient(87deg, #be2837 0, #ca1d2e 100%) !important; }

.bg-gradient-light {
  background: linear-gradient(87deg, #edf0f7 0, #eceff8 100%) !important; }

.bg-gradient-dark {
  background: linear-gradient(87deg, #0d1b48 0, #09194c 100%) !important; }

.bg-gradient-tertiary {
  background: linear-gradient(87deg, #26408B 0, #1d3c94 100%) !important; }

.bg-gradient-lighten {
  background: linear-gradient(87deg, #ffffff 0, white 100%) !important; }

.bg-gradient-white {
  background: linear-gradient(87deg, #ffffff 0, white 100%) !important; }

.bg-gradient-gray {
  background: linear-gradient(87deg, #424767 0, #3a416f 100%) !important; }

.bg-gradient-neutral {
  background: linear-gradient(87deg, #ffffff 0, white 100%) !important; }

.bg-gradient-soft {
  background: linear-gradient(87deg, #f5f9fe 0, #f4f9ff 100%) !important; }

.bg-gradient-black {
  background: linear-gradient(87deg, #0d1b48 0, #09194c 100%) !important; }

.bg-gradient-purple {
  background: linear-gradient(87deg, #6f42c1 0, #6b35ce 100%) !important; }

.bg-gradient-soft-green {
  background: linear-gradient(87deg, #ebf9ee 0, #eafaed 100%) !important; }

.bg-gradient-gray-100 {
  background: linear-gradient(87deg, #ffffff 0, white 100%) !important; }

.bg-gradient-gray-200 {
  background: linear-gradient(87deg, #fafbfe 0, #fafbfe 100%) !important; }

.bg-gradient-gray-300 {
  background: linear-gradient(87deg, #f5f9fe 0, #f4f9ff 100%) !important; }

.bg-gradient-gray-400 {
  background: linear-gradient(87deg, #edf0f7 0, #eceff8 100%) !important; }

.bg-gradient-gray-500 {
  background: linear-gradient(87deg, #B7C3D2 0, #b1c2d8 100%) !important; }

.bg-gradient-gray-600 {
  background: linear-gradient(87deg, #93a5be 0, #8aa4c7 100%) !important; }

.bg-gradient-gray-700 {
  background: linear-gradient(87deg, #52547a 0, #484b84 100%) !important; }

.bg-gradient-gray-800 {
  background: linear-gradient(87deg, #4E5079 0, #444783 100%) !important; }

.bg-gradient-facebook {
  background: linear-gradient(87deg, #3b5999 0, #3055a4 100%) !important; }

.bg-gradient-twitter {
  background: linear-gradient(87deg, #1da1f2 0, #11a4fe 100%) !important; }

.bg-gradient-google {
  background: linear-gradient(87deg, #DB4337 0, #e7392b 100%) !important; }

.bg-gradient-instagram {
  background: linear-gradient(87deg, #e4405f 0, #ef3558 100%) !important; }

.bg-gradient-pinterest {
  background: linear-gradient(87deg, #bd081c 0, #c50016 100%) !important; }

.bg-gradient-youtube {
  background: linear-gradient(87deg, #cd201f 0, #d91413 100%) !important; }

.bg-gradient-slack {
  background: linear-gradient(87deg, #3aaf85 0, #2ebb88 100%) !important; }

.bg-gradient-dribbble {
  background: linear-gradient(87deg, #ea4c89 0, #f44287 100%) !important; }

.bg-gradient-dropbox {
  background: linear-gradient(87deg, #1E90FF 0, dodgerblue 100%) !important; }

.bg-gradient-twitch {
  background: linear-gradient(87deg, #4B367C 0, #472d85 100%) !important; }

.bg-gradient-paypal {
  background: linear-gradient(87deg, #ecb32c 0, #f8b821 100%) !important; }

.bg-gradient-behance {
  background: linear-gradient(87deg, #0057ff 0, #0057ff 100%) !important; }

.bg-gradient-reddit {
  background: linear-gradient(87deg, #E84422 0, #f43c16 100%) !important; }

.bg-gradient-github {
  background: linear-gradient(87deg, #222222 0, #251f1f 100%) !important; }

.overlay-primary:before {
  position: absolute;
  content: "";
  background: #0d1b48;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-primary:before {
  position: absolute;
  content: "";
  background: #0d1b48;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-secondary:before {
  position: absolute;
  content: "";
  background: #C33C54;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-secondary:before {
  position: absolute;
  content: "";
  background: #C33C54;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-success:before {
  position: absolute;
  content: "";
  background: #208163;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-success:before {
  position: absolute;
  content: "";
  background: #208163;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-info:before {
  position: absolute;
  content: "";
  background: #0056B3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-info:before {
  position: absolute;
  content: "";
  background: #0056B3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-warning:before {
  position: absolute;
  content: "";
  background: #F0B400;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-warning:before {
  position: absolute;
  content: "";
  background: #F0B400;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-danger:before {
  position: absolute;
  content: "";
  background: #be2837;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-danger:before {
  position: absolute;
  content: "";
  background: #be2837;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-light:before {
  position: absolute;
  content: "";
  background: #edf0f7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-light:before {
  position: absolute;
  content: "";
  background: #edf0f7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-dark:before {
  position: absolute;
  content: "";
  background: #0d1b48;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-dark:before {
  position: absolute;
  content: "";
  background: #0d1b48;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-tertiary:before {
  position: absolute;
  content: "";
  background: #26408B;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-tertiary:before {
  position: absolute;
  content: "";
  background: #26408B;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-lighten:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-lighten:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-white:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-white:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray:before {
  position: absolute;
  content: "";
  background: #424767;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray:before {
  position: absolute;
  content: "";
  background: #424767;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-neutral:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-neutral:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-soft:before {
  position: absolute;
  content: "";
  background: #f5f9fe;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-soft:before {
  position: absolute;
  content: "";
  background: #f5f9fe;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-black:before {
  position: absolute;
  content: "";
  background: #0d1b48;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-black:before {
  position: absolute;
  content: "";
  background: #0d1b48;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-purple:before {
  position: absolute;
  content: "";
  background: #6f42c1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-purple:before {
  position: absolute;
  content: "";
  background: #6f42c1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-soft-green:before {
  position: absolute;
  content: "";
  background: #ebf9ee;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-soft-green:before {
  position: absolute;
  content: "";
  background: #ebf9ee;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-100:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-100:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-200:before {
  position: absolute;
  content: "";
  background: #fafbfe;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-200:before {
  position: absolute;
  content: "";
  background: #fafbfe;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-300:before {
  position: absolute;
  content: "";
  background: #f5f9fe;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-300:before {
  position: absolute;
  content: "";
  background: #f5f9fe;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-400:before {
  position: absolute;
  content: "";
  background: #edf0f7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-400:before {
  position: absolute;
  content: "";
  background: #edf0f7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-500:before {
  position: absolute;
  content: "";
  background: #B7C3D2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-500:before {
  position: absolute;
  content: "";
  background: #B7C3D2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-600:before {
  position: absolute;
  content: "";
  background: #93a5be;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-600:before {
  position: absolute;
  content: "";
  background: #93a5be;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-700:before {
  position: absolute;
  content: "";
  background: #52547a;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-700:before {
  position: absolute;
  content: "";
  background: #52547a;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-gray-800:before {
  position: absolute;
  content: "";
  background: #4E5079;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-gray-800:before {
  position: absolute;
  content: "";
  background: #4E5079;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-facebook:before {
  position: absolute;
  content: "";
  background: #3b5999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-facebook:before {
  position: absolute;
  content: "";
  background: #3b5999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-twitter:before {
  position: absolute;
  content: "";
  background: #1da1f2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-twitter:before {
  position: absolute;
  content: "";
  background: #1da1f2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-google:before {
  position: absolute;
  content: "";
  background: #DB4337;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-google:before {
  position: absolute;
  content: "";
  background: #DB4337;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-instagram:before {
  position: absolute;
  content: "";
  background: #e4405f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-instagram:before {
  position: absolute;
  content: "";
  background: #e4405f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-pinterest:before {
  position: absolute;
  content: "";
  background: #bd081c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-pinterest:before {
  position: absolute;
  content: "";
  background: #bd081c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-youtube:before {
  position: absolute;
  content: "";
  background: #cd201f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-youtube:before {
  position: absolute;
  content: "";
  background: #cd201f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-slack:before {
  position: absolute;
  content: "";
  background: #3aaf85;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-slack:before {
  position: absolute;
  content: "";
  background: #3aaf85;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-dribbble:before {
  position: absolute;
  content: "";
  background: #ea4c89;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-dribbble:before {
  position: absolute;
  content: "";
  background: #ea4c89;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-dropbox:before {
  position: absolute;
  content: "";
  background: #1E90FF;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-dropbox:before {
  position: absolute;
  content: "";
  background: #1E90FF;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-twitch:before {
  position: absolute;
  content: "";
  background: #4B367C;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-twitch:before {
  position: absolute;
  content: "";
  background: #4B367C;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-paypal:before {
  position: absolute;
  content: "";
  background: #ecb32c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-paypal:before {
  position: absolute;
  content: "";
  background: #ecb32c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-behance:before {
  position: absolute;
  content: "";
  background: #0057ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-behance:before {
  position: absolute;
  content: "";
  background: #0057ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-reddit:before {
  position: absolute;
  content: "";
  background: #E84422;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-reddit:before {
  position: absolute;
  content: "";
  background: #E84422;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.overlay-github:before {
  position: absolute;
  content: "";
  background: #222222;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0; }

.overlay-soft-github:before {
  position: absolute;
  content: "";
  background: #222222;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0; }

.section-primary {
  background-color: #0d1b48 !important; }

a.section-primary:hover, a.section-primary:focus,
button.section-primary:hover,
button.section-primary:focus {
  background-color: #050b1d !important; }

.section-secondary {
  background-color: #C33C54 !important; }

a.section-secondary:hover, a.section-secondary:focus,
button.section-secondary:hover,
button.section-secondary:focus {
  background-color: #9c3043 !important; }

.section-success {
  background-color: #208163 !important; }

a.section-success:hover, a.section-success:focus,
button.section-success:hover,
button.section-success:focus {
  background-color: #165844 !important; }

.section-info {
  background-color: #0056B3 !important; }

a.section-info:hover, a.section-info:focus,
button.section-info:hover,
button.section-info:focus {
  background-color: #003d80 !important; }

.section-warning {
  background-color: #F0B400 !important; }

a.section-warning:hover, a.section-warning:focus,
button.section-warning:hover,
button.section-warning:focus {
  background-color: #bd8e00 !important; }

.section-danger {
  background-color: #be2837 !important; }

a.section-danger:hover, a.section-danger:focus,
button.section-danger:hover,
button.section-danger:focus {
  background-color: #941f2b !important; }

.section-light {
  background-color: #edf0f7 !important; }

a.section-light:hover, a.section-light:focus,
button.section-light:hover,
button.section-light:focus {
  background-color: #cad3e7 !important; }

.section-dark {
  background-color: #0d1b48 !important; }

a.section-dark:hover, a.section-dark:focus,
button.section-dark:hover,
button.section-dark:focus {
  background-color: #050b1d !important; }

.section-tertiary {
  background-color: #26408B !important; }

a.section-tertiary:hover, a.section-tertiary:focus,
button.section-tertiary:hover,
button.section-tertiary:focus {
  background-color: #1b2e63 !important; }

.section-lighten {
  background-color: #ffffff !important; }

a.section-lighten:hover, a.section-lighten:focus,
button.section-lighten:hover,
button.section-lighten:focus {
  background-color: #e6e6e6 !important; }

.section-white {
  background-color: #ffffff !important; }

a.section-white:hover, a.section-white:focus,
button.section-white:hover,
button.section-white:focus {
  background-color: #e6e6e6 !important; }

.section-gray {
  background-color: #424767 !important; }

a.section-gray:hover, a.section-gray:focus,
button.section-gray:hover,
button.section-gray:focus {
  background-color: #2e3248 !important; }

.section-neutral {
  background-color: #ffffff !important; }

a.section-neutral:hover, a.section-neutral:focus,
button.section-neutral:hover,
button.section-neutral:focus {
  background-color: #e6e6e6 !important; }

.section-soft {
  background-color: #f5f9fe !important; }

a.section-soft:hover, a.section-soft:focus,
button.section-soft:hover,
button.section-soft:focus {
  background-color: #c7ddf9 !important; }

.section-black {
  background-color: #0d1b48 !important; }

a.section-black:hover, a.section-black:focus,
button.section-black:hover,
button.section-black:focus {
  background-color: #050b1d !important; }

.section-purple {
  background-color: #6f42c1 !important; }

a.section-purple:hover, a.section-purple:focus,
button.section-purple:hover,
button.section-purple:focus {
  background-color: #59339d !important; }

.section-soft-green {
  background-color: #ebf9ee !important; }

a.section-soft-green:hover, a.section-soft-green:focus,
button.section-soft-green:hover,
button.section-soft-green:focus {
  background-color: #c4edcd !important; }

.section-gray-100 {
  background-color: #ffffff !important; }

a.section-gray-100:hover, a.section-gray-100:focus,
button.section-gray-100:hover,
button.section-gray-100:focus {
  background-color: #e6e6e6 !important; }

.section-gray-200 {
  background-color: #fafbfe !important; }

a.section-gray-200:hover, a.section-gray-200:focus,
button.section-gray-200:hover,
button.section-gray-200:focus {
  background-color: #d0d9f6 !important; }

.section-gray-300 {
  background-color: #f5f9fe !important; }

a.section-gray-300:hover, a.section-gray-300:focus,
button.section-gray-300:hover,
button.section-gray-300:focus {
  background-color: #c7ddf9 !important; }

.section-gray-400 {
  background-color: #edf0f7 !important; }

a.section-gray-400:hover, a.section-gray-400:focus,
button.section-gray-400:hover,
button.section-gray-400:focus {
  background-color: #cad3e7 !important; }

.section-gray-500 {
  background-color: #B7C3D2 !important; }

a.section-gray-500:hover, a.section-gray-500:focus,
button.section-gray-500:hover,
button.section-gray-500:focus {
  background-color: #98a9be !important; }

.section-gray-600 {
  background-color: #93a5be !important; }

a.section-gray-600:hover, a.section-gray-600:focus,
button.section-gray-600:hover,
button.section-gray-600:focus {
  background-color: #738aab !important; }

.section-gray-700 {
  background-color: #52547a !important; }

a.section-gray-700:hover, a.section-gray-700:focus,
button.section-gray-700:hover,
button.section-gray-700:focus {
  background-color: #3e3f5c !important; }

.section-gray-800 {
  background-color: #4E5079 !important; }

a.section-gray-800:hover, a.section-gray-800:focus,
button.section-gray-800:hover,
button.section-gray-800:focus {
  background-color: #3a3b5a !important; }

.section-facebook {
  background-color: #3b5999 !important; }

a.section-facebook:hover, a.section-facebook:focus,
button.section-facebook:hover,
button.section-facebook:focus {
  background-color: #2d4474 !important; }

.section-twitter {
  background-color: #1da1f2 !important; }

a.section-twitter:hover, a.section-twitter:focus,
button.section-twitter:hover,
button.section-twitter:focus {
  background-color: #0c85d0 !important; }

.section-google {
  background-color: #DB4337 !important; }

a.section-google:hover, a.section-google:focus,
button.section-google:hover,
button.section-google:focus {
  background-color: #bd2d22 !important; }

.section-instagram {
  background-color: #e4405f !important; }

a.section-instagram:hover, a.section-instagram:focus,
button.section-instagram:hover,
button.section-instagram:focus {
  background-color: #d31e40 !important; }

.section-pinterest {
  background-color: #bd081c !important; }

a.section-pinterest:hover, a.section-pinterest:focus,
button.section-pinterest:hover,
button.section-pinterest:focus {
  background-color: #8c0615 !important; }

.section-youtube {
  background-color: #cd201f !important; }

a.section-youtube:hover, a.section-youtube:focus,
button.section-youtube:hover,
button.section-youtube:focus {
  background-color: #a11918 !important; }

.section-slack {
  background-color: #3aaf85 !important; }

a.section-slack:hover, a.section-slack:focus,
button.section-slack:hover,
button.section-slack:focus {
  background-color: #2d8968 !important; }

.section-dribbble {
  background-color: #ea4c89 !important; }

a.section-dribbble:hover, a.section-dribbble:focus,
button.section-dribbble:hover,
button.section-dribbble:focus {
  background-color: #e51e6b !important; }

.section-dropbox {
  background-color: #1E90FF !important; }

a.section-dropbox:hover, a.section-dropbox:focus,
button.section-dropbox:hover,
button.section-dropbox:focus {
  background-color: #0077ea !important; }

.section-twitch {
  background-color: #4B367C !important; }

a.section-twitch:hover, a.section-twitch:focus,
button.section-twitch:hover,
button.section-twitch:focus {
  background-color: #362758 !important; }

.section-paypal {
  background-color: #ecb32c !important; }

a.section-paypal:hover, a.section-paypal:focus,
button.section-paypal:hover,
button.section-paypal:focus {
  background-color: #d29913 !important; }

.section-behance {
  background-color: #0057ff !important; }

a.section-behance:hover, a.section-behance:focus,
button.section-behance:hover,
button.section-behance:focus {
  background-color: #0046cc !important; }

.section-reddit {
  background-color: #E84422 !important; }

a.section-reddit:hover, a.section-reddit:focus,
button.section-reddit:hover,
button.section-reddit:focus {
  background-color: #c33214 !important; }

.section-github {
  background-color: #222222 !important; }

a.section-github:hover, a.section-github:focus,
button.section-github:hover,
button.section-github:focus {
  background-color: #090909 !important; }

.section-image {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.outer-bg {
  position: relative;
  overflow: hidden; }
  .outer-bg .inner-bg {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: all .3s ease-in-out; }
    .outer-bg .inner-bg:before {
      transition: all .3s ease-in-out; }
  .outer-bg:hover .inner-bg {
    transform: scale(1.1); }
    .outer-bg:hover .inner-bg:before {
      background: transparent; }

/**
 * = Floating animations
 */
.floating-xs {
  animation: floating-xs 3s ease infinite;
  will-change: transform; }
  .floating-xs:hover {
    animation-play-state: paused; }

@media (min-width: 576px) {
  .floating-sm {
    animation: floating-sm 3s ease infinite;
    will-change: transform; }
    .floating-sm:hover {
      animation-play-state: paused; } }

@media (min-width: 768px) {
  .floating-md {
    animation: floating-md 3s ease infinite;
    will-change: transform; }
    .floating-md:hover {
      animation-play-state: paused; } }

@media (min-width: 992px) {
  .floating-lg {
    animation: floating-lg 3s ease infinite;
    will-change: transform; }
    .floating-lg:hover {
      animation-play-state: paused; } }

@media (min-width: 1200px) {
  .floating-xl {
    animation: floating-xl 3s ease infinite;
    will-change: transform; }
    .floating-xl:hover {
      animation-play-state: paused; } }

@keyframes floating-lg {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(15px); }
  100% {
    transform: translateY(0px); } }

@keyframes floating-md {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

@keyframes floating-sm {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0px); } }

/**
* = Helper classes
*/
.overflow-visible {
  overflow: visible !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-1 {
  opacity: 0.1 !important; }

.opacity-2 {
  opacity: 0.2 !important; }

.opacity-3 {
  opacity: 0.3 !important; }

.opacity-4 {
  opacity: 0.4 !important; }

.opacity-5 {
  opacity: 0.5 !important; }

.opacity-6 {
  opacity: 0.6 !important; }

.opacity-7 {
  opacity: 0.7 !important; }

.opacity-8 {
  opacity: 0.8 !important; }

.opacity-9 {
  opacity: 0.9 !important; }

.fill-opacity-0 {
  fill-opacity: 0 !important; }

.fill-opacity-1 {
  fill-opacity: 0.1 !important; }

.fill-opacity-2 {
  fill-opacity: 0.2 !important; }

.fill-opacity-3 {
  fill-opacity: 0.3 !important; }

.fill-opacity-4 {
  fill-opacity: 0.4 !important; }

.fill-opacity-5 {
  fill-opacity: 0.5 !important; }

.fill-opacity-6 {
  fill-opacity: 0.6 !important; }

.fill-opacity-7 {
  fill-opacity: 0.7 !important; }

.fill-opacity-8 {
  fill-opacity: 0.8 !important; }

.fill-opacity-9 {
  fill-opacity: 0.9 !important; }

.z-0 {
  position: relative;
  z-index: 0 !important; }

.z-1 {
  position: relative;
  z-index: 1 !important; }

.z-2 {
  position: relative;
  z-index: 2 !important; }

.z-3 {
  position: relative;
  z-index: 3 !important; }

.z-4 {
  position: relative;
  z-index: 4 !important; }

.z-5 {
  position: relative;
  z-index: 5 !important; }

.z-6 {
  position: relative;
  z-index: 6 !important; }

.z-7 {
  position: relative;
  z-index: 7 !important; }

.z-8 {
  position: relative;
  z-index: 8 !important; }

.z-9 {
  position: relative;
  z-index: 9 !important; }

.rounded-xl {
  border-radius: 0.85rem !important; }

.bw-md {
  border-width: 0.125rem !important; }

.bw-lg {
  border-width: 0.25rem !important; }

.bw-xl {
  border-width: 0.375rem !important; }

.border-top-left-radius-0 {
  border-top-left-radius: 0 !important; }

.border-top-right-radius-0 {
  border-top-right-radius: 0 !important; }

.border-bottom-left-radius-0 {
  border-bottom-left-radius: 0 !important; }

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0 !important; }

/**
 * = Spacing
 */
.top-0 {
  top: 0; }

.right-0 {
  right: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.top-1 {
  top: 0.25rem; }

.right-1 {
  right: 0.25rem; }

.bottom-1 {
  bottom: 0.25rem; }

.left-1 {
  left: 0.25rem; }

.top-2 {
  top: 0.5rem; }

.right-2 {
  right: 0.5rem; }

.bottom-2 {
  bottom: 0.5rem; }

.left-2 {
  left: 0.5rem; }

.top-3 {
  top: 1rem; }

.right-3 {
  right: 1rem; }

.bottom-3 {
  bottom: 1rem; }

.left-3 {
  left: 1rem; }

.top-4 {
  top: 1.5rem; }

.right-4 {
  right: 1.5rem; }

.bottom-4 {
  bottom: 1.5rem; }

.left-4 {
  left: 1.5rem; }

.top-5 {
  top: 3rem; }

.right-5 {
  right: 3rem; }

.bottom-5 {
  bottom: 3rem; }

.left-5 {
  left: 3rem; }

.top-6 {
  top: 5rem; }

.right-6 {
  right: 5rem; }

.bottom-6 {
  bottom: 5rem; }

.left-6 {
  left: 5rem; }

.top-7 {
  top: 8rem; }

.right-7 {
  right: 8rem; }

.bottom-7 {
  bottom: 8rem; }

.left-7 {
  left: 8rem; }

.top-8 {
  top: 10rem; }

.right-8 {
  right: 10rem; }

.bottom-8 {
  bottom: 10rem; }

.left-8 {
  left: 10rem; }

.top-9 {
  top: 11rem; }

.right-9 {
  right: 11rem; }

.bottom-9 {
  bottom: 11rem; }

.left-9 {
  left: 11rem; }

.top-10 {
  top: 14rem; }

.right-10 {
  right: 14rem; }

.bottom-10 {
  bottom: 14rem; }

.left-10 {
  left: 14rem; }

.top-11 {
  top: 16rem; }

.right-11 {
  right: 16rem; }

.bottom-11 {
  bottom: 16rem; }

.left-11 {
  left: 16rem; }

.top-12 {
  top: 20rem; }

.right-12 {
  right: 20rem; }

.bottom-12 {
  bottom: 20rem; }

.left-12 {
  left: 20rem; }

.top-sm {
  top: 1rem; }

.right-sm {
  right: 1rem; }

.bottom-sm {
  bottom: 1rem; }

.left-sm {
  left: 1rem; }

.top-md {
  top: 2rem; }

.right-md {
  right: 2rem; }

.bottom-md {
  bottom: 2rem; }

.left-md {
  left: 2rem; }

.top-lg {
  top: 4rem; }

.right-lg {
  right: 4rem; }

.bottom-lg {
  bottom: 4rem; }

.left-lg {
  left: 4rem; }

.top-xl {
  top: 8rem; }

.right-xl {
  right: 8rem; }

.bottom-xl {
  bottom: 8rem; }

.left-xl {
  left: 8rem; }

.center-vertical-absolute,
.center-horizontal-absolute {
  position: absolute; }

.center-vertical-absolute {
  top: 50%;
  transform: translateY(-50%); }

.center-horizontal-absolute {
  left: 50%;
  transform: translateX(-50%); }

/**
 * = Sizing
 */
.h-100vh {
  height: 100vh !important; }

.fh-170 {
  height: 170px !important; }

.fh-20 {
  height: 20px !important; }

.fh-40 {
  height: 40px !important; }

.fh-60 {
  height: 60px !important; }

.fh-80 {
  height: 80px !important; }

.fh-100 {
  height: 100px !important; }

.fh-120 {
  height: 120px !important; }

.fh-140 {
  height: 140px !important; }

.fh-160 {
  height: 160px !important; }

.fh-180 {
  height: 180px !important; }

.fh-200 {
  height: 200px !important; }

.fh-220 {
  height: 220px !important; }

.fh-240 {
  height: 240px !important; }

.fh-260 {
  height: 260px !important; }

.fh-280 {
  height: 280px !important; }

.fh-300 {
  height: 300px !important; }

.fh-320 {
  height: 320px !important; }

.fh-340 {
  height: 340px !important; }

.fh-360 {
  height: 360px !important; }

.fh-380 {
  height: 380px !important; }

.fh-400 {
  height: 400px !important; }

.fh-420 {
  height: 420px !important; }

.fh-440 {
  height: 440px !important; }

.fh-460 {
  height: 460px !important; }

.fh-480 {
  height: 480px !important; }

.fh-500 {
  height: 500px !important; }

.fh-520 {
  height: 520px !important; }

.fh-540 {
  height: 540px !important; }

.fh-560 {
  height: 560px !important; }

.fh-580 {
  height: 580px !important; }

.fh-600 {
  height: 600px !important; }

.fh-620 {
  height: 620px !important; }

.fh-640 {
  height: 640px !important; }

.fh-660 {
  height: 660px !important; }

.fh-680 {
  height: 680px !important; }

.fh-700 {
  height: 700px !important; }

.fh-720 {
  height: 720px !important; }

.fh-740 {
  height: 740px !important; }

.fh-760 {
  height: 760px !important; }

.fh-780 {
  height: 780px !important; }

.fw-20 {
  width: 20px !important; }

.fw-40 {
  width: 40px !important; }

.fw-60 {
  width: 60px !important; }

.fw-80 {
  width: 80px !important; }

.fw-100 {
  width: 100px !important; }

.fw-120 {
  width: 120px !important; }

.fw-140 {
  width: 140px !important; }

.fw-160 {
  width: 160px !important; }

.fw-180 {
  width: 180px !important; }

.fw-200 {
  width: 200px !important; }

.fw-220 {
  width: 220px !important; }

.fw-240 {
  width: 240px !important; }

.fw-260 {
  width: 260px !important; }

.fw-280 {
  width: 280px !important; }

.fw-300 {
  width: 300px !important; }

.fw-320 {
  width: 320px !important; }

.fw-340 {
  width: 340px !important; }

.fw-360 {
  width: 360px !important; }

.fw-380 {
  width: 380px !important; }

.fw-400 {
  width: 400px !important; }

.fw-420 {
  width: 420px !important; }

.fw-440 {
  width: 440px !important; }

.fw-460 {
  width: 460px !important; }

.fw-480 {
  width: 480px !important; }

.fw-500 {
  width: 500px !important; }

.fw-520 {
  width: 520px !important; }

.fw-540 {
  width: 540px !important; }

.fw-560 {
  width: 560px !important; }

.fw-580 {
  width: 580px !important; }

.fw-600 {
  width: 600px !important; }

.fw-620 {
  width: 620px !important; }

.fw-640 {
  width: 640px !important; }

.fw-660 {
  width: 660px !important; }

.fw-680 {
  width: 680px !important; }

.fw-700 {
  width: 700px !important; }

.fw-720 {
  width: 720px !important; }

.fw-740 {
  width: 740px !important; }

.fw-760 {
  width: 760px !important; }

.fw-780 {
  width: 780px !important; }

.fw-50 {
  width: 50px !important; }

.fw-100 {
  width: 100px !important; }

.fw-150 {
  width: 150px !important; }

.fw-200 {
  width: 200px !important; }

.fw-250 {
  width: 250px !important; }

.fw-300 {
  width: 300px !important; }

.fw-350 {
  width: 350px !important; }

.fw-400 {
  width: 400px !important; }

.fw-450 {
  width: 450px !important; }

.fw-500 {
  width: 500px !important; }

.fw-550 {
  width: 550px !important; }

.fw-600 {
  width: 600px !important; }

.fw-650 {
  width: 650px !important; }

.fw-700 {
  width: 700px !important; }

.fw-750 {
  width: 750px !important; }

.fw-800 {
  width: 800px !important; }

.fw-850 {
  width: 850px !important; }

.fw-900 {
  width: 900px !important; }

.fw-950 {
  width: 950px !important; }

.fw-1000 {
  width: 1000px !important; }

.fw-1050 {
  width: 1050px !important; }

.fw-1100 {
  width: 1100px !important; }

.fw-1150 {
  width: 1150px !important; }

.fw-1200 {
  width: 1200px !important; }

.fw-1250 {
  width: 1250px !important; }

.fw-1300 {
  width: 1300px !important; }

.fw-1350 {
  width: 1350px !important; }

.fw-1400 {
  width: 1400px !important; }

.fw-1450 {
  width: 1450px !important; }

.fw-1500 {
  width: 1500px !important; }

.fw-1550 {
  width: 1550px !important; }

.fw-1600 {
  width: 1600px !important; }

.fw-1650 {
  width: 1650px !important; }

.fw-1700 {
  width: 1700px !important; }

.fw-1750 {
  width: 1750px !important; }

.fw-1800 {
  width: 1800px !important; }

.fw-1850 {
  width: 1850px !important; }

.fw-1900 {
  width: 1900px !important; }

.fw-1950 {
  width: 1950px !important; }

.fmh-50 {
  min-height: 50px !important; }

.fmh-100 {
  min-height: 100px !important; }

.fmh-150 {
  min-height: 150px !important; }

.fmh-200 {
  min-height: 200px !important; }

.fmh-250 {
  min-height: 250px !important; }

.fmh-300 {
  min-height: 300px !important; }

.fmh-350 {
  min-height: 350px !important; }

.fmh-400 {
  min-height: 400px !important; }

.fmh-450 {
  min-height: 450px !important; }

.fmh-500 {
  min-height: 500px !important; }

.fmh-550 {
  min-height: 550px !important; }

.fmh-600 {
  min-height: 600px !important; }

.fmh-650 {
  min-height: 650px !important; }

.fmh-700 {
  min-height: 700px !important; }

.fmh-750 {
  min-height: 750px !important; }

.fmh-800 {
  min-height: 800px !important; }

.fmh-850 {
  min-height: 850px !important; }

.fmh-900 {
  min-height: 900px !important; }

.fmh-950 {
  min-height: 950px !important; }

.fmh-1000 {
  min-height: 1000px !important; }

.fmh-1050 {
  min-height: 1050px !important; }

.fmh-1100 {
  min-height: 1100px !important; }

.fmh-1150 {
  min-height: 1150px !important; }

.fmh-1200 {
  min-height: 1200px !important; }

.fmh-1250 {
  min-height: 1250px !important; }

.fmh-1300 {
  min-height: 1300px !important; }

.fmh-1350 {
  min-height: 1350px !important; }

.fmh-1400 {
  min-height: 1400px !important; }

.fmh-1450 {
  min-height: 1450px !important; }

.fmh-1500 {
  min-height: 1500px !important; }

.fmh-1550 {
  min-height: 1550px !important; }

.fmh-1600 {
  min-height: 1600px !important; }

.fmh-1650 {
  min-height: 1650px !important; }

.fmh-1700 {
  min-height: 1700px !important; }

.fmh-1750 {
  min-height: 1750px !important; }

.fmh-1800 {
  min-height: 1800px !important; }

.fmh-1850 {
  min-height: 1850px !important; }

.fmh-1900 {
  min-height: 1900px !important; }

.fmh-1950 {
  min-height: 1950px !important; }

.fmw-50 {
  min-width: 50px !important; }

.fmw-100 {
  min-width: 100px !important; }

.fmw-150 {
  min-width: 150px !important; }

.fmw-200 {
  min-width: 200px !important; }

.fmw-250 {
  min-width: 250px !important; }

.fmw-300 {
  min-width: 300px !important; }

.fmw-350 {
  min-width: 350px !important; }

.fmw-400 {
  min-width: 400px !important; }

.fmw-450 {
  min-width: 450px !important; }

.fmw-500 {
  min-width: 500px !important; }

.fmw-550 {
  min-width: 550px !important; }

.fmw-600 {
  min-width: 600px !important; }

.fmw-650 {
  min-width: 650px !important; }

.fmw-700 {
  min-width: 700px !important; }

.fmw-750 {
  min-width: 750px !important; }

.fmw-800 {
  min-width: 800px !important; }

.fmw-850 {
  min-width: 850px !important; }

.fmw-900 {
  min-width: 900px !important; }

.fmw-950 {
  min-width: 950px !important; }

.fmw-1000 {
  min-width: 1000px !important; }

.fmw-1050 {
  min-width: 1050px !important; }

.fmw-1100 {
  min-width: 1100px !important; }

.fmw-1150 {
  min-width: 1150px !important; }

.fmw-1200 {
  min-width: 1200px !important; }

.fmw-1250 {
  min-width: 1250px !important; }

.fmw-1300 {
  min-width: 1300px !important; }

.fmw-1350 {
  min-width: 1350px !important; }

.fmw-1400 {
  min-width: 1400px !important; }

.fmw-1450 {
  min-width: 1450px !important; }

.fmw-1500 {
  min-width: 1500px !important; }

.fmw-1550 {
  min-width: 1550px !important; }

.fmw-1600 {
  min-width: 1600px !important; }

.fmw-1650 {
  min-width: 1650px !important; }

.fmw-1700 {
  min-width: 1700px !important; }

.fmw-1750 {
  min-width: 1750px !important; }

.fmw-1800 {
  min-width: 1800px !important; }

.fmw-1850 {
  min-width: 1850px !important; }

.fmw-1900 {
  min-width: 1900px !important; }

.fmw-1950 {
  min-width: 1950px !important; }

.fmxh-50 {
  max-height: 50px !important; }

.fmxh-100 {
  max-height: 100px !important; }

.fmxh-150 {
  max-height: 150px !important; }

.fmxh-200 {
  max-height: 200px !important; }

.fmxh-250 {
  max-height: 250px !important; }

.fmxh-300 {
  max-height: 300px !important; }

.fmxh-350 {
  max-height: 350px !important; }

.fmxh-400 {
  max-height: 400px !important; }

.fmxh-450 {
  max-height: 450px !important; }

.fmxh-500 {
  max-height: 500px !important; }

.fmxh-550 {
  max-height: 550px !important; }

.fmxh-600 {
  max-height: 600px !important; }

.fmxh-650 {
  max-height: 650px !important; }

.fmxh-700 {
  max-height: 700px !important; }

.fmxh-750 {
  max-height: 750px !important; }

.fmxh-800 {
  max-height: 800px !important; }

.fmxh-850 {
  max-height: 850px !important; }

.fmxh-900 {
  max-height: 900px !important; }

.fmxh-950 {
  max-height: 950px !important; }

.fmxh-1000 {
  max-height: 1000px !important; }

.fmxh-1050 {
  max-height: 1050px !important; }

.fmxh-1100 {
  max-height: 1100px !important; }

.fmxh-1150 {
  max-height: 1150px !important; }

.fmxh-1200 {
  max-height: 1200px !important; }

.fmxh-1250 {
  max-height: 1250px !important; }

.fmxh-1300 {
  max-height: 1300px !important; }

.fmxh-1350 {
  max-height: 1350px !important; }

.fmxh-1400 {
  max-height: 1400px !important; }

.fmxh-1450 {
  max-height: 1450px !important; }

.fmxh-1500 {
  max-height: 1500px !important; }

.fmxh-1550 {
  max-height: 1550px !important; }

.fmxh-1600 {
  max-height: 1600px !important; }

.fmxh-1650 {
  max-height: 1650px !important; }

.fmxh-1700 {
  max-height: 1700px !important; }

.fmxh-1750 {
  max-height: 1750px !important; }

.fmxh-1800 {
  max-height: 1800px !important; }

.fmxh-1850 {
  max-height: 1850px !important; }

.fmxh-1900 {
  max-height: 1900px !important; }

.fmxh-1950 {
  max-height: 1950px !important; }

.fmxw-50 {
  max-width: 50px !important; }

.fmxw-100 {
  max-width: 100px !important; }

.fmxw-150 {
  max-width: 150px !important; }

.fmxw-200 {
  max-width: 200px !important; }

.fmxw-250 {
  max-width: 250px !important; }

.fmxw-300 {
  max-width: 300px !important; }

.fmxw-350 {
  max-width: 350px !important; }

.fmxw-400 {
  max-width: 400px !important; }

.fmxw-450 {
  max-width: 450px !important; }

.fmxw-500 {
  max-width: 500px !important; }

.fmxw-550 {
  max-width: 550px !important; }

.fmxw-600 {
  max-width: 600px !important; }

.fmxw-650 {
  max-width: 650px !important; }

.fmxw-700 {
  max-width: 700px !important; }

.fmxw-750 {
  max-width: 750px !important; }

.fmxw-800 {
  max-width: 800px !important; }

.fmxw-850 {
  max-width: 850px !important; }

.fmxw-900 {
  max-width: 900px !important; }

.fmxw-950 {
  max-width: 950px !important; }

.fmxw-1000 {
  max-width: 1000px !important; }

.fmxw-1050 {
  max-width: 1050px !important; }

.fmxw-1100 {
  max-width: 1100px !important; }

.fmxw-1150 {
  max-width: 1150px !important; }

.fmxw-1200 {
  max-width: 1200px !important; }

.fmxw-1250 {
  max-width: 1250px !important; }

.fmxw-1300 {
  max-width: 1300px !important; }

.fmxw-1350 {
  max-width: 1350px !important; }

.fmxw-1400 {
  max-width: 1400px !important; }

.fmxw-1450 {
  max-width: 1450px !important; }

.fmxw-1500 {
  max-width: 1500px !important; }

.fmxw-1550 {
  max-width: 1550px !important; }

.fmxw-1600 {
  max-width: 1600px !important; }

.fmxw-1650 {
  max-width: 1650px !important; }

.fmxw-1700 {
  max-width: 1700px !important; }

.fmxw-1750 {
  max-width: 1750px !important; }

.fmxw-1800 {
  max-width: 1800px !important; }

.fmxw-1850 {
  max-width: 1850px !important; }

.fmxw-1900 {
  max-width: 1900px !important; }

.fmxw-1950 {
  max-width: 1950px !important; }

/**
 * = Spacing grids
 */
.row.row-grid > [class*="col-"] + [class*="col-"] {
  margin-top: 3rem; }

.row.row-grid > [class*="col-xs-"] + [class*="col-xs-"] {
  margin-top: 0; }

@media (min-width: 576px) {
  .row.row-grid > [class*="col-sm-"] + [class*="col-sm-"] {
    margin-top: 0; } }

@media (min-width: 768px) {
  .row.row-grid > [class*="col-md-"] + [class*="col-md-"] {
    margin-top: 0; } }

@media (min-width: 992px) {
  .row.row-grid > [class*="col-lg-"] + [class*="col-lg-"] {
    margin-top: 0; } }

@media (min-width: 1200px) {
  .row.row-grid > [class*="col-xl-"] + [class*="col-xl-"] {
    margin-top: 0; } }

.row-grid + .row-grid {
  margin-top: 3rem; }

@media (min-width: 992px) {
  [class*="mt--"],
  [class*="mr--"],
  [class*="mb--"],
  [class*="ml--"] {
    position: relative;
    z-index: 5; }
  .mt--50 {
    margin-top: -50px !important; }
  .mr--50 {
    margin-right: -50px !important; }
  .mb--50 {
    margin-bottom: -50px !important; }
  .ml--50 {
    margin-left: -50px !important; }
  .mt--100 {
    margin-top: -100px !important; }
  .mr--100 {
    margin-right: -100px !important; }
  .mb--100 {
    margin-bottom: -100px !important; }
  .ml--100 {
    margin-left: -100px !important; }
  .mt--150 {
    margin-top: -150px !important; }
  .mr--150 {
    margin-right: -150px !important; }
  .mb--150 {
    margin-bottom: -150px !important; }
  .ml--150 {
    margin-left: -150px !important; }
  .mt--200 {
    margin-top: -200px !important; }
  .mr--200 {
    margin-right: -200px !important; }
  .mb--200 {
    margin-bottom: -200px !important; }
  .ml--200 {
    margin-left: -200px !important; }
  .mt--250 {
    margin-top: -250px !important; }
  .mr--250 {
    margin-right: -250px !important; }
  .mb--250 {
    margin-bottom: -250px !important; }
  .ml--250 {
    margin-left: -250px !important; }
  .mt--300 {
    margin-top: -300px !important; }
  .mr--300 {
    margin-right: -300px !important; }
  .mb--300 {
    margin-bottom: -300px !important; }
  .ml--300 {
    margin-left: -300px !important; }
  .mt--350 {
    margin-top: -350px !important; }
  .mr--350 {
    margin-right: -350px !important; }
  .mb--350 {
    margin-bottom: -350px !important; }
  .ml--350 {
    margin-left: -350px !important; }
  .mt--400 {
    margin-top: -400px !important; }
  .mr--400 {
    margin-right: -400px !important; }
  .mb--400 {
    margin-bottom: -400px !important; }
  .ml--400 {
    margin-left: -400px !important; }
  .mt--450 {
    margin-top: -450px !important; }
  .mr--450 {
    margin-right: -450px !important; }
  .mb--450 {
    margin-bottom: -450px !important; }
  .ml--450 {
    margin-left: -450px !important; }
  .mt--500 {
    margin-top: -500px !important; }
  .mr--500 {
    margin-right: -500px !important; }
  .mb--500 {
    margin-bottom: -500px !important; }
  .ml--500 {
    margin-left: -500px !important; }
  .mt--550 {
    margin-top: -550px !important; }
  .mr--550 {
    margin-right: -550px !important; }
  .mb--550 {
    margin-bottom: -550px !important; }
  .ml--550 {
    margin-left: -550px !important; }
  .mt--600 {
    margin-top: -600px !important; }
  .mr--600 {
    margin-right: -600px !important; }
  .mb--600 {
    margin-bottom: -600px !important; }
  .ml--600 {
    margin-left: -600px !important; }
  .mt-50 {
    margin-top: 50px !important; }
  .mr-50 {
    margin-right: 50px !important; }
  .mb-50 {
    margin-bottom: 50px !important; }
  .ml-50 {
    margin-left: 50px !important; }
  .mt-100 {
    margin-top: 100px !important; }
  .mr-100 {
    margin-right: 100px !important; }
  .mb-100 {
    margin-bottom: 100px !important; }
  .ml-100 {
    margin-left: 100px !important; }
  .mt-150 {
    margin-top: 150px !important; }
  .mr-150 {
    margin-right: 150px !important; }
  .mb-150 {
    margin-bottom: 150px !important; }
  .ml-150 {
    margin-left: 150px !important; }
  .mt-200 {
    margin-top: 200px !important; }
  .mr-200 {
    margin-right: 200px !important; }
  .mb-200 {
    margin-bottom: 200px !important; }
  .ml-200 {
    margin-left: 200px !important; }
  .mt-250 {
    margin-top: 250px !important; }
  .mr-250 {
    margin-right: 250px !important; }
  .mb-250 {
    margin-bottom: 250px !important; }
  .ml-250 {
    margin-left: 250px !important; }
  .mt-300 {
    margin-top: 300px !important; }
  .mr-300 {
    margin-right: 300px !important; }
  .mb-300 {
    margin-bottom: 300px !important; }
  .ml-300 {
    margin-left: 300px !important; }
  .mt-350 {
    margin-top: 350px !important; }
  .mr-350 {
    margin-right: 350px !important; }
  .mb-350 {
    margin-bottom: 350px !important; }
  .ml-350 {
    margin-left: 350px !important; }
  .mt-400 {
    margin-top: 400px !important; }
  .mr-400 {
    margin-right: 400px !important; }
  .mb-400 {
    margin-bottom: 400px !important; }
  .ml-400 {
    margin-left: 400px !important; }
  .mt-450 {
    margin-top: 450px !important; }
  .mr-450 {
    margin-right: 450px !important; }
  .mb-450 {
    margin-bottom: 450px !important; }
  .ml-450 {
    margin-left: 450px !important; }
  .mt-500 {
    margin-top: 500px !important; }
  .mr-500 {
    margin-right: 500px !important; }
  .mb-500 {
    margin-bottom: 500px !important; }
  .ml-500 {
    margin-left: 500px !important; }
  .mt-550 {
    margin-top: 550px !important; }
  .mr-550 {
    margin-right: 550px !important; }
  .mb-550 {
    margin-bottom: 550px !important; }
  .ml-550 {
    margin-left: 550px !important; }
  .mt-600 {
    margin-top: 600px !important; }
  .mr-600 {
    margin-right: 600px !important; }
  .mb-600 {
    margin-bottom: 600px !important; }
  .ml-600 {
    margin-left: 600px !important; }
  .pt-50 {
    padding-top: 50px !important; }
  .pr-50 {
    padding-right: 50px !important; }
  .pb-50 {
    padding-bottom: 50px !important; }
  .pl-50 {
    padding-left: 50px !important; }
  .pt-100 {
    padding-top: 100px !important; }
  .pr-100 {
    padding-right: 100px !important; }
  .pb-100 {
    padding-bottom: 100px !important; }
  .pl-100 {
    padding-left: 100px !important; }
  .pt-150 {
    padding-top: 150px !important; }
  .pr-150 {
    padding-right: 150px !important; }
  .pb-150 {
    padding-bottom: 150px !important; }
  .pl-150 {
    padding-left: 150px !important; }
  .pt-200 {
    padding-top: 200px !important; }
  .pr-200 {
    padding-right: 200px !important; }
  .pb-200 {
    padding-bottom: 200px !important; }
  .pl-200 {
    padding-left: 200px !important; }
  .pt-250 {
    padding-top: 250px !important; }
  .pr-250 {
    padding-right: 250px !important; }
  .pb-250 {
    padding-bottom: 250px !important; }
  .pl-250 {
    padding-left: 250px !important; }
  .pt-300 {
    padding-top: 300px !important; }
  .pr-300 {
    padding-right: 300px !important; }
  .pb-300 {
    padding-bottom: 300px !important; }
  .pl-300 {
    padding-left: 300px !important; }
  .pt-350 {
    padding-top: 350px !important; }
  .pr-350 {
    padding-right: 350px !important; }
  .pb-350 {
    padding-bottom: 350px !important; }
  .pl-350 {
    padding-left: 350px !important; }
  .pt-400 {
    padding-top: 400px !important; }
  .pr-400 {
    padding-right: 400px !important; }
  .pb-400 {
    padding-bottom: 400px !important; }
  .pl-400 {
    padding-left: 400px !important; }
  .pt-450 {
    padding-top: 450px !important; }
  .pr-450 {
    padding-right: 450px !important; }
  .pb-450 {
    padding-bottom: 450px !important; }
  .pl-450 {
    padding-left: 450px !important; }
  .pt-500 {
    padding-top: 500px !important; }
  .pr-500 {
    padding-right: 500px !important; }
  .pb-500 {
    padding-bottom: 500px !important; }
  .pl-500 {
    padding-left: 500px !important; }
  .pt-550 {
    padding-top: 550px !important; }
  .pr-550 {
    padding-right: 550px !important; }
  .pb-550 {
    padding-bottom: 550px !important; }
  .pl-550 {
    padding-left: 550px !important; }
  .pt-600 {
    padding-top: 600px !important; }
  .pr-600 {
    padding-right: 600px !important; }
  .pb-600 {
    padding-bottom: 600px !important; }
  .pl-600 {
    padding-left: 600px !important; } }

/**
 * = Shadows
 */
[class*="shadow"] {
  transition: all 0.2s ease; }

.shadow-soft {
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06) !important; }

.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15) !important; }

.shadow-sm-hover:hover {
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06) !important; }

.shadow-lg-hover:hover {
  box-shadow: 0 1rem 3rem rgba(13, 27, 72, 0.175) !important; }

.shadow-none-hover:hover {
  box-shadow: none !important; }

/**
 * = Text utility classes
 */
.text-underline {
  text-decoration: underline !important; }

.text-through {
  text-decoration: line-through !important; }

.display-1-xs {
  font-size: 5rem; }

.display-2-xs {
  font-size: 3rem; }

.display-3-xs {
  font-size: 2.5rem; }

.display-4-xs {
  font-size: 1.875rem; }

@media (min-width: 576px) {
  .display-1-sm {
    font-size: 5rem; }
  .display-2-sm {
    font-size: 3rem; }
  .display-3-sm {
    font-size: 2.5rem; }
  .display-4-sm {
    font-size: 1.875rem; } }

@media (min-width: 768px) {
  .display-1-md {
    font-size: 5rem; }
  .display-2-md {
    font-size: 3rem; }
  .display-3-md {
    font-size: 2.5rem; }
  .display-4-md {
    font-size: 1.875rem; } }

@media (min-width: 992px) {
  .display-1-lg {
    font-size: 5rem; }
  .display-2-lg {
    font-size: 3rem; }
  .display-3-lg {
    font-size: 2.5rem; }
  .display-4-lg {
    font-size: 1.875rem; } }

@media (min-width: 1200px) {
  .display-1-xl {
    font-size: 5rem; }
  .display-2-xl {
    font-size: 3rem; }
  .display-3-xl {
    font-size: 2.5rem; }
  .display-4-xl {
    font-size: 1.875rem; } }

.lh-100 {
  line-height: 1; }

.lh-110 {
  line-height: 1.1; }

.lh-120 {
  line-height: 1.2; }

.lh-130 {
  line-height: 1.3; }

.lh-140 {
  line-height: 1.4; }

.lh-150 {
  line-height: 1.5; }

.lh-160 {
  line-height: 1.6; }

.lh-170 {
  line-height: 1.7; }

.lh-180 {
  line-height: 1.8; }

.lh-190 {
  line-height: 1.9; }

.lh-200 {
  line-height: 2; }

.lh-210 {
  line-height: 2.1; }

.lh-220 {
  line-height: 2.2; }

.lh-230 {
  line-height: 2.3; }

.lh-240 {
  line-height: 2.4; }

.lh-250 {
  line-height: 2.5; }

.lh-260 {
  line-height: 2.6; }

.lh-270 {
  line-height: 2.7; }

.lh-280 {
  line-height: 2.8; }

.lh-290 {
  line-height: 2.9; }

.lh-300 {
  line-height: 3; }

.ls-1 {
  letter-spacing: .0625rem; }

.ls-2 {
  letter-spacing: .09375rem; }

.ls-3 {
  letter-spacing: 0.125rem; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-body {
  color: #424767 !important; }

.text-black-50 {
  color: rgba(13, 27, 72, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.list-style-none {
  margin: 0px;
  padding: 0px;
  list-style: none; }

/**
 * = Transform
 */
@media (min-width: 992px) {
  .transform-perspective-right {
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg); }
  .transform-perspective-left {
    transform: scale(1) perspective(900px) rotateY(27deg) rotateX(2deg) rotate(-2deg); } }

.t-none {
  transform: none !important; }

/**
 * = Animations
 */
.animate-up-1,
.animate-right-1,
.animate-down-1,
.animate-left-1,
.scale-up-1,
.scale-down-1 {
  transition: all 0.2s ease; }

.scale-up-1:hover {
  transform: scale(0.55556); }

.scale-up-hover-1:hover .scale {
  transform: scale(0.55556); }

.scale-down-1:hover {
  transform: scale(0.4); }

.animate-up-1:hover,
.animate-hover:hover .animate-up-1 {
  -moz-transform: translate(0, -1px);
  -o-transform: translate(0, -1px);
  -ms-transform: translate(0, -1px);
  -webkit-transform: translate(0, -1px);
  transform: translate(0, -1px); }

.animate-right-1:hover,
.animate-hover:hover .animate-right-1 {
  -moz-transform: translate(1px, 0);
  -o-transform: translate(1px, 0);
  -ms-transform: translate(1px, 0);
  -webkit-transform: translate(1px, 0);
  transform: translate(1px, 0); }

.animate-down-1:hover,
.animate-hover:hover .animate-down-1 {
  -moz-transform: translate(0, 1px);
  -o-transform: translate(0, 1px);
  -ms-transform: translate(0, 1px);
  -webkit-transform: translate(0, 1px);
  transform: translate(0, 1px); }

.animate-left-1:hover,
.animate-hover:hover .animate-left-1 {
  -moz-transform: translate(-1px, 0);
  -o-transform: translate(-1px, 0);
  -ms-transform: translate(-1px, 0);
  -webkit-transform: translate(-1px, 0);
  transform: translate(-1px, 0); }

.animate-up-2,
.animate-right-2,
.animate-down-2,
.animate-left-2,
.scale-up-2,
.scale-down-2 {
  transition: all 0.2s ease; }

.scale-up-2:hover {
  transform: scale(1.11111); }

.scale-up-hover-2:hover .scale {
  transform: scale(1.11111); }

.scale-down-2:hover {
  transform: scale(0.8); }

.animate-up-2:hover,
.animate-hover:hover .animate-up-2 {
  -moz-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.animate-right-2:hover,
.animate-hover:hover .animate-right-2 {
  -moz-transform: translate(2px, 0);
  -o-transform: translate(2px, 0);
  -ms-transform: translate(2px, 0);
  -webkit-transform: translate(2px, 0);
  transform: translate(2px, 0); }

.animate-down-2:hover,
.animate-hover:hover .animate-down-2 {
  -moz-transform: translate(0, 2px);
  -o-transform: translate(0, 2px);
  -ms-transform: translate(0, 2px);
  -webkit-transform: translate(0, 2px);
  transform: translate(0, 2px); }

.animate-left-2:hover,
.animate-hover:hover .animate-left-2 {
  -moz-transform: translate(-2px, 0);
  -o-transform: translate(-2px, 0);
  -ms-transform: translate(-2px, 0);
  -webkit-transform: translate(-2px, 0);
  transform: translate(-2px, 0); }

.animate-up-3,
.animate-right-3,
.animate-down-3,
.animate-left-3,
.scale-up-3,
.scale-down-3 {
  transition: all 0.2s ease; }

.scale-up-3:hover {
  transform: scale(1.66667); }

.scale-up-hover-3:hover .scale {
  transform: scale(1.66667); }

.scale-down-3:hover {
  transform: scale(1.2); }

.animate-up-3:hover,
.animate-hover:hover .animate-up-3 {
  -moz-transform: translate(0, -3px);
  -o-transform: translate(0, -3px);
  -ms-transform: translate(0, -3px);
  -webkit-transform: translate(0, -3px);
  transform: translate(0, -3px); }

.animate-right-3:hover,
.animate-hover:hover .animate-right-3 {
  -moz-transform: translate(3px, 0);
  -o-transform: translate(3px, 0);
  -ms-transform: translate(3px, 0);
  -webkit-transform: translate(3px, 0);
  transform: translate(3px, 0); }

.animate-down-3:hover,
.animate-hover:hover .animate-down-3 {
  -moz-transform: translate(0, 3px);
  -o-transform: translate(0, 3px);
  -ms-transform: translate(0, 3px);
  -webkit-transform: translate(0, 3px);
  transform: translate(0, 3px); }

.animate-left-3:hover,
.animate-hover:hover .animate-left-3 {
  -moz-transform: translate(-3px, 0);
  -o-transform: translate(-3px, 0);
  -ms-transform: translate(-3px, 0);
  -webkit-transform: translate(-3px, 0);
  transform: translate(-3px, 0); }

.animate-up-4,
.animate-right-4,
.animate-down-4,
.animate-left-4,
.scale-up-4,
.scale-down-4 {
  transition: all 0.2s ease; }

.scale-up-4:hover {
  transform: scale(2.22222); }

.scale-up-hover-4:hover .scale {
  transform: scale(2.22222); }

.scale-down-4:hover {
  transform: scale(1.6); }

.animate-up-4:hover,
.animate-hover:hover .animate-up-4 {
  -moz-transform: translate(0, -4px);
  -o-transform: translate(0, -4px);
  -ms-transform: translate(0, -4px);
  -webkit-transform: translate(0, -4px);
  transform: translate(0, -4px); }

.animate-right-4:hover,
.animate-hover:hover .animate-right-4 {
  -moz-transform: translate(4px, 0);
  -o-transform: translate(4px, 0);
  -ms-transform: translate(4px, 0);
  -webkit-transform: translate(4px, 0);
  transform: translate(4px, 0); }

.animate-down-4:hover,
.animate-hover:hover .animate-down-4 {
  -moz-transform: translate(0, 4px);
  -o-transform: translate(0, 4px);
  -ms-transform: translate(0, 4px);
  -webkit-transform: translate(0, 4px);
  transform: translate(0, 4px); }

.animate-left-4:hover,
.animate-hover:hover .animate-left-4 {
  -moz-transform: translate(-4px, 0);
  -o-transform: translate(-4px, 0);
  -ms-transform: translate(-4px, 0);
  -webkit-transform: translate(-4px, 0);
  transform: translate(-4px, 0); }

.animate-up-5,
.animate-right-5,
.animate-down-5,
.animate-left-5,
.scale-up-5,
.scale-down-5 {
  transition: all 0.2s ease; }

.scale-up-5:hover {
  transform: scale(2.77778); }

.scale-up-hover-5:hover .scale {
  transform: scale(2.77778); }

.scale-down-5:hover {
  transform: scale(2); }

.animate-up-5:hover,
.animate-hover:hover .animate-up-5 {
  -moz-transform: translate(0, -5px);
  -o-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px); }

.animate-right-5:hover,
.animate-hover:hover .animate-right-5 {
  -moz-transform: translate(5px, 0);
  -o-transform: translate(5px, 0);
  -ms-transform: translate(5px, 0);
  -webkit-transform: translate(5px, 0);
  transform: translate(5px, 0); }

.animate-down-5:hover,
.animate-hover:hover .animate-down-5 {
  -moz-transform: translate(0, 5px);
  -o-transform: translate(0, 5px);
  -ms-transform: translate(0, 5px);
  -webkit-transform: translate(0, 5px);
  transform: translate(0, 5px); }

.animate-left-5:hover,
.animate-hover:hover .animate-left-5 {
  -moz-transform: translate(-5px, 0);
  -o-transform: translate(-5px, 0);
  -ms-transform: translate(-5px, 0);
  -webkit-transform: translate(-5px, 0);
  transform: translate(-5px, 0); }

.animate-up-6,
.animate-right-6,
.animate-down-6,
.animate-left-6,
.scale-up-6,
.scale-down-6 {
  transition: all 0.2s ease; }

.scale-up-6:hover {
  transform: scale(3.33333); }

.scale-up-hover-6:hover .scale {
  transform: scale(3.33333); }

.scale-down-6:hover {
  transform: scale(2.4); }

.animate-up-6:hover,
.animate-hover:hover .animate-up-6 {
  -moz-transform: translate(0, -6px);
  -o-transform: translate(0, -6px);
  -ms-transform: translate(0, -6px);
  -webkit-transform: translate(0, -6px);
  transform: translate(0, -6px); }

.animate-right-6:hover,
.animate-hover:hover .animate-right-6 {
  -moz-transform: translate(6px, 0);
  -o-transform: translate(6px, 0);
  -ms-transform: translate(6px, 0);
  -webkit-transform: translate(6px, 0);
  transform: translate(6px, 0); }

.animate-down-6:hover,
.animate-hover:hover .animate-down-6 {
  -moz-transform: translate(0, 6px);
  -o-transform: translate(0, 6px);
  -ms-transform: translate(0, 6px);
  -webkit-transform: translate(0, 6px);
  transform: translate(0, 6px); }

.animate-left-6:hover,
.animate-hover:hover .animate-left-6 {
  -moz-transform: translate(-6px, 0);
  -o-transform: translate(-6px, 0);
  -ms-transform: translate(-6px, 0);
  -webkit-transform: translate(-6px, 0);
  transform: translate(-6px, 0); }

.animate-up-7,
.animate-right-7,
.animate-down-7,
.animate-left-7,
.scale-up-7,
.scale-down-7 {
  transition: all 0.2s ease; }

.scale-up-7:hover {
  transform: scale(3.88889); }

.scale-up-hover-7:hover .scale {
  transform: scale(3.88889); }

.scale-down-7:hover {
  transform: scale(2.8); }

.animate-up-7:hover,
.animate-hover:hover .animate-up-7 {
  -moz-transform: translate(0, -7px);
  -o-transform: translate(0, -7px);
  -ms-transform: translate(0, -7px);
  -webkit-transform: translate(0, -7px);
  transform: translate(0, -7px); }

.animate-right-7:hover,
.animate-hover:hover .animate-right-7 {
  -moz-transform: translate(7px, 0);
  -o-transform: translate(7px, 0);
  -ms-transform: translate(7px, 0);
  -webkit-transform: translate(7px, 0);
  transform: translate(7px, 0); }

.animate-down-7:hover,
.animate-hover:hover .animate-down-7 {
  -moz-transform: translate(0, 7px);
  -o-transform: translate(0, 7px);
  -ms-transform: translate(0, 7px);
  -webkit-transform: translate(0, 7px);
  transform: translate(0, 7px); }

.animate-left-7:hover,
.animate-hover:hover .animate-left-7 {
  -moz-transform: translate(-7px, 0);
  -o-transform: translate(-7px, 0);
  -ms-transform: translate(-7px, 0);
  -webkit-transform: translate(-7px, 0);
  transform: translate(-7px, 0); }

.animate-up-8,
.animate-right-8,
.animate-down-8,
.animate-left-8,
.scale-up-8,
.scale-down-8 {
  transition: all 0.2s ease; }

.scale-up-8:hover {
  transform: scale(4.44444); }

.scale-up-hover-8:hover .scale {
  transform: scale(4.44444); }

.scale-down-8:hover {
  transform: scale(3.2); }

.animate-up-8:hover,
.animate-hover:hover .animate-up-8 {
  -moz-transform: translate(0, -8px);
  -o-transform: translate(0, -8px);
  -ms-transform: translate(0, -8px);
  -webkit-transform: translate(0, -8px);
  transform: translate(0, -8px); }

.animate-right-8:hover,
.animate-hover:hover .animate-right-8 {
  -moz-transform: translate(8px, 0);
  -o-transform: translate(8px, 0);
  -ms-transform: translate(8px, 0);
  -webkit-transform: translate(8px, 0);
  transform: translate(8px, 0); }

.animate-down-8:hover,
.animate-hover:hover .animate-down-8 {
  -moz-transform: translate(0, 8px);
  -o-transform: translate(0, 8px);
  -ms-transform: translate(0, 8px);
  -webkit-transform: translate(0, 8px);
  transform: translate(0, 8px); }

.animate-left-8:hover,
.animate-hover:hover .animate-left-8 {
  -moz-transform: translate(-8px, 0);
  -o-transform: translate(-8px, 0);
  -ms-transform: translate(-8px, 0);
  -webkit-transform: translate(-8px, 0);
  transform: translate(-8px, 0); }

.animate-up-9,
.animate-right-9,
.animate-down-9,
.animate-left-9,
.scale-up-9,
.scale-down-9 {
  transition: all 0.2s ease; }

.scale-up-9:hover {
  transform: scale(5); }

.scale-up-hover-9:hover .scale {
  transform: scale(5); }

.scale-down-9:hover {
  transform: scale(3.6); }

.animate-up-9:hover,
.animate-hover:hover .animate-up-9 {
  -moz-transform: translate(0, -9px);
  -o-transform: translate(0, -9px);
  -ms-transform: translate(0, -9px);
  -webkit-transform: translate(0, -9px);
  transform: translate(0, -9px); }

.animate-right-9:hover,
.animate-hover:hover .animate-right-9 {
  -moz-transform: translate(9px, 0);
  -o-transform: translate(9px, 0);
  -ms-transform: translate(9px, 0);
  -webkit-transform: translate(9px, 0);
  transform: translate(9px, 0); }

.animate-down-9:hover,
.animate-hover:hover .animate-down-9 {
  -moz-transform: translate(0, 9px);
  -o-transform: translate(0, 9px);
  -ms-transform: translate(0, 9px);
  -webkit-transform: translate(0, 9px);
  transform: translate(0, 9px); }

.animate-left-9:hover,
.animate-hover:hover .animate-left-9 {
  -moz-transform: translate(-9px, 0);
  -o-transform: translate(-9px, 0);
  -ms-transform: translate(-9px, 0);
  -webkit-transform: translate(-9px, 0);
  transform: translate(-9px, 0); }

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0; }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0; }
  to {
    opacity: 0;
    transform: scale(0.95); } }

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity 0.45s, transform 0.45s; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate(0, 10px); } }

@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform-origin: perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity .5s; }
  100% {
    opacity: 1; } }

@keyframes hide-dropdown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate(0, 10px); } }

/**
 * = Navigation bars
 */
.navbar-main {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100; }
  .navbar-main .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

.navbar .navbar-nav .nav-link {
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -moz-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -o-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -ms-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1); }
  @media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link {
      font-size: 1.2rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; } }

.navbar .navbar-nav .dropdown-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.navbar .navbar-nav .nav-item .media:not(:last-child) {
  margin-bottom: 1.5rem; }

.navbar .navbar-nav .nav-item:focus,
.navbar .navbar-nav .nav-item .nav-link:focus {
  outline: none !important; }

.navbar .navbar-nav .dropdown .dropdown-menu {
  top: 100%; }

.navbar .navbar-nav .dropdown .dropdown-item {
  font-weight: 400;
  font-size: 0.875rem; }
  @media (max-width: 991.98px) {
    .navbar .navbar-nav .dropdown .dropdown-item {
      font-size: 1.2rem; } }

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu,
  .navbar .navbar-nav .dropdown-megamenu-sm {
    min-width: 580px; } }

.navbar .navbar-nav .dropdown-megamenu .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-link {
  color: #424767;
  font-weight: 400;
  font-size: 0.875rem; }

.navbar .navbar-nav .dropdown-megamenu .megamenu-item,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item {
  position: relative; }
  .navbar .navbar-nav .dropdown-megamenu .megamenu-item:hover .megamenu-link,
  .navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item:hover .megamenu-link {
    color: #C33C54; }

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu-sm {
    min-width: 420px; } }

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-menu .dropdown-toggle .nav-link-arrow {
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .navbar .navbar-nav .dropdown.show > .dropdown-toggle .nav-link-arrow {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .navbar .navbar-nav .dropdown-toggle:hover .nav-link-arrow {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

.navbar .navbar-nav .dropdown-submenu {
  position: relative; }
  .navbar .navbar-nav .dropdown-submenu .dropdown-menu {
    top: 0;
    left: calc(100% - 2px);
    margin-left: .1rem;
    margin-right: .1rem;
    opacity: 0;
    display: none; }
    .navbar .navbar-nav .dropdown-submenu .dropdown-menu.show {
      opacity: 1;
      pointer-events: all;
      display: block; }

.navbar .navbar-nav .nav-link-arrow {
  transition: transform 0.2s ease; }

.navbar-brand {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase; }
  .navbar-brand img {
    height: 30px; }

.navbar-dark .navbar-brand-light {
  display: none; }

.navbar-dark .navbar-brand {
  color: #ffffff; }

.navbar-dark > .nav-item:hover > .nav-link {
  color: #C33C54; }

.navbar-light .btn-outline-white {
  color: #0d1b48;
  border-color: #0d1b48; }
  .navbar-light .btn-outline-white:hover {
    background-color: #0d1b48;
    color: #ffffff; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2352547a' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-brand-dark {
  display: none; }

.navbar-light .navbar-brand {
  color: #4E5079; }

.navbar-theme-primary:not(.headroom) {
  background-color: #0d1b48; }

.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(5, 11, 29, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-primary .navbar-nav .dropdown-item.active, .navbar-theme-primary .navbar-nav .dropdown-item:hover,
.navbar-theme-primary .navbar-nav .list-group-item.active,
.navbar-theme-primary .navbar-nav .list-group-item:hover {
  color: #0d1b48;
  background: rgba(13, 27, 72, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-primary .navbar-nav .nav-link, .navbar-theme-primary .navbar-nav .nav-link:focus, .navbar-theme-primary .navbar-nav .nav-link.active, .navbar-theme-primary .navbar-nav .nav-link:hover,
  .navbar-theme-primary .navbar-nav .show > .nav-link,
  .navbar-theme-primary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary .navbar-nav .dropdown-item,
  .navbar-theme-primary .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary .navbar-nav .dropdown-item.active,
  .navbar-theme-primary .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary .navbar-nav .list-group-item,
  .navbar-theme-primary .navbar-nav .list-group-item:focus,
  .navbar-theme-primary .navbar-nav .list-group-item.active,
  .navbar-theme-primary .navbar-nav .list-group-item:hover {
    color: #0d1b48;
    background: transparent; }
  .navbar-theme-primary .navbar-nav .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-primary .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-primary .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-primary .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-primary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-secondary:not(.headroom) {
  background-color: #C33C54; }

.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(156, 48, 67, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-secondary .navbar-nav .dropdown-item.active, .navbar-theme-secondary .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary .navbar-nav .list-group-item.active,
.navbar-theme-secondary .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(195, 60, 84, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-secondary .navbar-nav .nav-link, .navbar-theme-secondary .navbar-nav .nav-link:focus, .navbar-theme-secondary .navbar-nav .nav-link.active, .navbar-theme-secondary .navbar-nav .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .show > .nav-link,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .dropdown-item,
  .navbar-theme-secondary .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary .navbar-nav .list-group-item,
  .navbar-theme-secondary .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary .navbar-nav .list-group-item.active,
  .navbar-theme-secondary .navbar-nav .list-group-item:hover {
    color: #C33C54;
    background: transparent; }
  .navbar-theme-secondary .navbar-nav .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-secondary .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-secondary .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-secondary .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-secondary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-success:not(.headroom) {
  background-color: #208163; }

.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(22, 88, 68, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-success .navbar-nav .dropdown-item.active, .navbar-theme-success .navbar-nav .dropdown-item:hover,
.navbar-theme-success .navbar-nav .list-group-item.active,
.navbar-theme-success .navbar-nav .list-group-item:hover {
  color: #208163;
  background: rgba(32, 129, 99, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-success .navbar-nav .nav-link, .navbar-theme-success .navbar-nav .nav-link:focus, .navbar-theme-success .navbar-nav .nav-link.active, .navbar-theme-success .navbar-nav .nav-link:hover,
  .navbar-theme-success .navbar-nav .show > .nav-link,
  .navbar-theme-success .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success .navbar-nav .show > .nav-link.active,
  .navbar-theme-success .navbar-nav .show > .nav-link:hover,
  .navbar-theme-success .navbar-nav .dropdown-item,
  .navbar-theme-success .navbar-nav .dropdown-item:focus,
  .navbar-theme-success .navbar-nav .dropdown-item.active,
  .navbar-theme-success .navbar-nav .dropdown-item:hover,
  .navbar-theme-success .navbar-nav .list-group-item,
  .navbar-theme-success .navbar-nav .list-group-item:focus,
  .navbar-theme-success .navbar-nav .list-group-item.active,
  .navbar-theme-success .navbar-nav .list-group-item:hover {
    color: #208163;
    background: transparent; }
  .navbar-theme-success .navbar-nav .nav-link.disabled,
  .navbar-theme-success .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-success .navbar-nav .dropdown-item.disabled,
  .navbar-theme-success .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-success .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-success .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-success .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-info:not(.headroom) {
  background-color: #0056B3; }

.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(0, 61, 128, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-info .navbar-nav .dropdown-item.active, .navbar-theme-info .navbar-nav .dropdown-item:hover,
.navbar-theme-info .navbar-nav .list-group-item.active,
.navbar-theme-info .navbar-nav .list-group-item:hover {
  color: #0056B3;
  background: rgba(0, 86, 179, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-info .navbar-nav .nav-link, .navbar-theme-info .navbar-nav .nav-link:focus, .navbar-theme-info .navbar-nav .nav-link.active, .navbar-theme-info .navbar-nav .nav-link:hover,
  .navbar-theme-info .navbar-nav .show > .nav-link,
  .navbar-theme-info .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info .navbar-nav .show > .nav-link.active,
  .navbar-theme-info .navbar-nav .show > .nav-link:hover,
  .navbar-theme-info .navbar-nav .dropdown-item,
  .navbar-theme-info .navbar-nav .dropdown-item:focus,
  .navbar-theme-info .navbar-nav .dropdown-item.active,
  .navbar-theme-info .navbar-nav .dropdown-item:hover,
  .navbar-theme-info .navbar-nav .list-group-item,
  .navbar-theme-info .navbar-nav .list-group-item:focus,
  .navbar-theme-info .navbar-nav .list-group-item.active,
  .navbar-theme-info .navbar-nav .list-group-item:hover {
    color: #0056B3;
    background: transparent; }
  .navbar-theme-info .navbar-nav .nav-link.disabled,
  .navbar-theme-info .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-info .navbar-nav .dropdown-item.disabled,
  .navbar-theme-info .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-info .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-info .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-info .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-warning:not(.headroom) {
  background-color: #F0B400; }

.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(189, 142, 0, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-warning .navbar-nav .dropdown-item.active, .navbar-theme-warning .navbar-nav .dropdown-item:hover,
.navbar-theme-warning .navbar-nav .list-group-item.active,
.navbar-theme-warning .navbar-nav .list-group-item:hover {
  color: #F0B400;
  background: rgba(240, 180, 0, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-warning .navbar-nav .nav-link, .navbar-theme-warning .navbar-nav .nav-link:focus, .navbar-theme-warning .navbar-nav .nav-link.active, .navbar-theme-warning .navbar-nav .nav-link:hover,
  .navbar-theme-warning .navbar-nav .show > .nav-link,
  .navbar-theme-warning .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning .navbar-nav .show > .nav-link:hover,
  .navbar-theme-warning .navbar-nav .dropdown-item,
  .navbar-theme-warning .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning .navbar-nav .dropdown-item.active,
  .navbar-theme-warning .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning .navbar-nav .list-group-item,
  .navbar-theme-warning .navbar-nav .list-group-item:focus,
  .navbar-theme-warning .navbar-nav .list-group-item.active,
  .navbar-theme-warning .navbar-nav .list-group-item:hover {
    color: #F0B400;
    background: transparent; }
  .navbar-theme-warning .navbar-nav .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .dropdown-item.disabled,
  .navbar-theme-warning .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-warning .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-warning .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-warning .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-danger:not(.headroom) {
  background-color: #be2837; }

.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(148, 31, 43, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-danger .navbar-nav .dropdown-item.active, .navbar-theme-danger .navbar-nav .dropdown-item:hover,
.navbar-theme-danger .navbar-nav .list-group-item.active,
.navbar-theme-danger .navbar-nav .list-group-item:hover {
  color: #be2837;
  background: rgba(190, 40, 55, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-danger .navbar-nav .nav-link, .navbar-theme-danger .navbar-nav .nav-link:focus, .navbar-theme-danger .navbar-nav .nav-link.active, .navbar-theme-danger .navbar-nav .nav-link:hover,
  .navbar-theme-danger .navbar-nav .show > .nav-link,
  .navbar-theme-danger .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger .navbar-nav .show > .nav-link:hover,
  .navbar-theme-danger .navbar-nav .dropdown-item,
  .navbar-theme-danger .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger .navbar-nav .dropdown-item.active,
  .navbar-theme-danger .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger .navbar-nav .list-group-item,
  .navbar-theme-danger .navbar-nav .list-group-item:focus,
  .navbar-theme-danger .navbar-nav .list-group-item.active,
  .navbar-theme-danger .navbar-nav .list-group-item:hover {
    color: #be2837;
    background: transparent; }
  .navbar-theme-danger .navbar-nav .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .dropdown-item.disabled,
  .navbar-theme-danger .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-danger .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-danger .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-danger .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-light:not(.headroom) {
  background-color: #edf0f7; }

.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(202, 211, 231, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-light .navbar-nav .dropdown-item.active, .navbar-theme-light .navbar-nav .dropdown-item:hover,
.navbar-theme-light .navbar-nav .list-group-item.active,
.navbar-theme-light .navbar-nav .list-group-item:hover {
  color: #edf0f7;
  background: rgba(237, 240, 247, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-light .navbar-nav .nav-link, .navbar-theme-light .navbar-nav .nav-link:focus, .navbar-theme-light .navbar-nav .nav-link.active, .navbar-theme-light .navbar-nav .nav-link:hover,
  .navbar-theme-light .navbar-nav .show > .nav-link,
  .navbar-theme-light .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light .navbar-nav .show > .nav-link.active,
  .navbar-theme-light .navbar-nav .show > .nav-link:hover,
  .navbar-theme-light .navbar-nav .dropdown-item,
  .navbar-theme-light .navbar-nav .dropdown-item:focus,
  .navbar-theme-light .navbar-nav .dropdown-item.active,
  .navbar-theme-light .navbar-nav .dropdown-item:hover,
  .navbar-theme-light .navbar-nav .list-group-item,
  .navbar-theme-light .navbar-nav .list-group-item:focus,
  .navbar-theme-light .navbar-nav .list-group-item.active,
  .navbar-theme-light .navbar-nav .list-group-item:hover {
    color: #edf0f7;
    background: transparent; }
  .navbar-theme-light .navbar-nav .nav-link.disabled,
  .navbar-theme-light .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-light .navbar-nav .dropdown-item.disabled,
  .navbar-theme-light .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-light .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-light .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-light .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-dark:not(.headroom) {
  background-color: #0d1b48; }

.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(5, 11, 29, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-dark .navbar-nav .dropdown-item.active, .navbar-theme-dark .navbar-nav .dropdown-item:hover,
.navbar-theme-dark .navbar-nav .list-group-item.active,
.navbar-theme-dark .navbar-nav .list-group-item:hover {
  color: #0d1b48;
  background: rgba(13, 27, 72, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-dark .navbar-nav .nav-link, .navbar-theme-dark .navbar-nav .nav-link:focus, .navbar-theme-dark .navbar-nav .nav-link.active, .navbar-theme-dark .navbar-nav .nav-link:hover,
  .navbar-theme-dark .navbar-nav .show > .nav-link,
  .navbar-theme-dark .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dark .navbar-nav .dropdown-item,
  .navbar-theme-dark .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark .navbar-nav .dropdown-item.active,
  .navbar-theme-dark .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark .navbar-nav .list-group-item,
  .navbar-theme-dark .navbar-nav .list-group-item:focus,
  .navbar-theme-dark .navbar-nav .list-group-item.active,
  .navbar-theme-dark .navbar-nav .list-group-item:hover {
    color: #0d1b48;
    background: transparent; }
  .navbar-theme-dark .navbar-nav .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dark .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-dark .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-dark .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-dark .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-tertiary:not(.headroom) {
  background-color: #26408B; }

.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(27, 46, 99, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-tertiary .navbar-nav .dropdown-item.active, .navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary .navbar-nav .list-group-item.active,
.navbar-theme-tertiary .navbar-nav .list-group-item:hover {
  color: #26408B;
  background: rgba(38, 64, 139, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-tertiary .navbar-nav .nav-link, .navbar-theme-tertiary .navbar-nav .nav-link:focus, .navbar-theme-tertiary .navbar-nav .nav-link.active, .navbar-theme-tertiary .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .dropdown-item,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary .navbar-nav .list-group-item,
  .navbar-theme-tertiary .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary .navbar-nav .list-group-item:hover {
    color: #26408B;
    background: transparent; }
  .navbar-theme-tertiary .navbar-nav .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-tertiary .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-tertiary .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-tertiary .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-tertiary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-lighten:not(.headroom) {
  background-color: #ffffff; }

.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(230, 230, 230, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-lighten .navbar-nav .dropdown-item.active, .navbar-theme-lighten .navbar-nav .dropdown-item:hover,
.navbar-theme-lighten .navbar-nav .list-group-item.active,
.navbar-theme-lighten .navbar-nav .list-group-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-lighten .navbar-nav .nav-link, .navbar-theme-lighten .navbar-nav .nav-link:focus, .navbar-theme-lighten .navbar-nav .nav-link.active, .navbar-theme-lighten .navbar-nav .nav-link:hover,
  .navbar-theme-lighten .navbar-nav .show > .nav-link,
  .navbar-theme-lighten .navbar-nav .show > .nav-link:focus,
  .navbar-theme-lighten .navbar-nav .show > .nav-link.active,
  .navbar-theme-lighten .navbar-nav .show > .nav-link:hover,
  .navbar-theme-lighten .navbar-nav .dropdown-item,
  .navbar-theme-lighten .navbar-nav .dropdown-item:focus,
  .navbar-theme-lighten .navbar-nav .dropdown-item.active,
  .navbar-theme-lighten .navbar-nav .dropdown-item:hover,
  .navbar-theme-lighten .navbar-nav .list-group-item,
  .navbar-theme-lighten .navbar-nav .list-group-item:focus,
  .navbar-theme-lighten .navbar-nav .list-group-item.active,
  .navbar-theme-lighten .navbar-nav .list-group-item:hover {
    color: #ffffff;
    background: transparent; }
  .navbar-theme-lighten .navbar-nav .nav-link.disabled,
  .navbar-theme-lighten .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-lighten .navbar-nav .dropdown-item.disabled,
  .navbar-theme-lighten .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-lighten .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-lighten .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-lighten .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-white:not(.headroom) {
  background-color: #ffffff; }

.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(230, 230, 230, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-white .navbar-nav .dropdown-item.active, .navbar-theme-white .navbar-nav .dropdown-item:hover,
.navbar-theme-white .navbar-nav .list-group-item.active,
.navbar-theme-white .navbar-nav .list-group-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-white .navbar-nav .nav-link, .navbar-theme-white .navbar-nav .nav-link:focus, .navbar-theme-white .navbar-nav .nav-link.active, .navbar-theme-white .navbar-nav .nav-link:hover,
  .navbar-theme-white .navbar-nav .show > .nav-link,
  .navbar-theme-white .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white .navbar-nav .show > .nav-link.active,
  .navbar-theme-white .navbar-nav .show > .nav-link:hover,
  .navbar-theme-white .navbar-nav .dropdown-item,
  .navbar-theme-white .navbar-nav .dropdown-item:focus,
  .navbar-theme-white .navbar-nav .dropdown-item.active,
  .navbar-theme-white .navbar-nav .dropdown-item:hover,
  .navbar-theme-white .navbar-nav .list-group-item,
  .navbar-theme-white .navbar-nav .list-group-item:focus,
  .navbar-theme-white .navbar-nav .list-group-item.active,
  .navbar-theme-white .navbar-nav .list-group-item:hover {
    color: #ffffff;
    background: transparent; }
  .navbar-theme-white .navbar-nav .nav-link.disabled,
  .navbar-theme-white .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-white .navbar-nav .dropdown-item.disabled,
  .navbar-theme-white .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-white .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-white .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-white .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray:not(.headroom) {
  background-color: #424767; }

.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(46, 50, 72, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray .navbar-nav .dropdown-item.active, .navbar-theme-gray .navbar-nav .dropdown-item:hover,
.navbar-theme-gray .navbar-nav .list-group-item.active,
.navbar-theme-gray .navbar-nav .list-group-item:hover {
  color: #424767;
  background: rgba(66, 71, 103, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray .navbar-nav .nav-link, .navbar-theme-gray .navbar-nav .nav-link:focus, .navbar-theme-gray .navbar-nav .nav-link.active, .navbar-theme-gray .navbar-nav .nav-link:hover,
  .navbar-theme-gray .navbar-nav .show > .nav-link,
  .navbar-theme-gray .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray .navbar-nav .dropdown-item,
  .navbar-theme-gray .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray .navbar-nav .dropdown-item.active,
  .navbar-theme-gray .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray .navbar-nav .list-group-item,
  .navbar-theme-gray .navbar-nav .list-group-item:focus,
  .navbar-theme-gray .navbar-nav .list-group-item.active,
  .navbar-theme-gray .navbar-nav .list-group-item:hover {
    color: #424767;
    background: transparent; }
  .navbar-theme-gray .navbar-nav .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-neutral:not(.headroom) {
  background-color: #ffffff; }

.navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(230, 230, 230, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-neutral .navbar-nav .dropdown-item.active, .navbar-theme-neutral .navbar-nav .dropdown-item:hover,
.navbar-theme-neutral .navbar-nav .list-group-item.active,
.navbar-theme-neutral .navbar-nav .list-group-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-neutral .navbar-nav .nav-link, .navbar-theme-neutral .navbar-nav .nav-link:focus, .navbar-theme-neutral .navbar-nav .nav-link.active, .navbar-theme-neutral .navbar-nav .nav-link:hover,
  .navbar-theme-neutral .navbar-nav .show > .nav-link,
  .navbar-theme-neutral .navbar-nav .show > .nav-link:focus,
  .navbar-theme-neutral .navbar-nav .show > .nav-link.active,
  .navbar-theme-neutral .navbar-nav .show > .nav-link:hover,
  .navbar-theme-neutral .navbar-nav .dropdown-item,
  .navbar-theme-neutral .navbar-nav .dropdown-item:focus,
  .navbar-theme-neutral .navbar-nav .dropdown-item.active,
  .navbar-theme-neutral .navbar-nav .dropdown-item:hover,
  .navbar-theme-neutral .navbar-nav .list-group-item,
  .navbar-theme-neutral .navbar-nav .list-group-item:focus,
  .navbar-theme-neutral .navbar-nav .list-group-item.active,
  .navbar-theme-neutral .navbar-nav .list-group-item:hover {
    color: #ffffff;
    background: transparent; }
  .navbar-theme-neutral .navbar-nav .nav-link.disabled,
  .navbar-theme-neutral .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-neutral .navbar-nav .dropdown-item.disabled,
  .navbar-theme-neutral .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-neutral .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-neutral .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-neutral .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-soft:not(.headroom) {
  background-color: #f5f9fe; }

.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(199, 221, 249, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-soft .navbar-nav .dropdown-item.active, .navbar-theme-soft .navbar-nav .dropdown-item:hover,
.navbar-theme-soft .navbar-nav .list-group-item.active,
.navbar-theme-soft .navbar-nav .list-group-item:hover {
  color: #f5f9fe;
  background: rgba(245, 249, 254, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-soft .navbar-nav .nav-link, .navbar-theme-soft .navbar-nav .nav-link:focus, .navbar-theme-soft .navbar-nav .nav-link.active, .navbar-theme-soft .navbar-nav .nav-link:hover,
  .navbar-theme-soft .navbar-nav .show > .nav-link,
  .navbar-theme-soft .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft .navbar-nav .dropdown-item,
  .navbar-theme-soft .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft .navbar-nav .dropdown-item.active,
  .navbar-theme-soft .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft .navbar-nav .list-group-item,
  .navbar-theme-soft .navbar-nav .list-group-item:focus,
  .navbar-theme-soft .navbar-nav .list-group-item.active,
  .navbar-theme-soft .navbar-nav .list-group-item:hover {
    color: #f5f9fe;
    background: transparent; }
  .navbar-theme-soft .navbar-nav .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-soft .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-soft .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-soft .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-black:not(.headroom) {
  background-color: #0d1b48; }

.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(5, 11, 29, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-black .navbar-nav .dropdown-item.active, .navbar-theme-black .navbar-nav .dropdown-item:hover,
.navbar-theme-black .navbar-nav .list-group-item.active,
.navbar-theme-black .navbar-nav .list-group-item:hover {
  color: #0d1b48;
  background: rgba(13, 27, 72, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-black .navbar-nav .nav-link, .navbar-theme-black .navbar-nav .nav-link:focus, .navbar-theme-black .navbar-nav .nav-link.active, .navbar-theme-black .navbar-nav .nav-link:hover,
  .navbar-theme-black .navbar-nav .show > .nav-link,
  .navbar-theme-black .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black .navbar-nav .show > .nav-link.active,
  .navbar-theme-black .navbar-nav .show > .nav-link:hover,
  .navbar-theme-black .navbar-nav .dropdown-item,
  .navbar-theme-black .navbar-nav .dropdown-item:focus,
  .navbar-theme-black .navbar-nav .dropdown-item.active,
  .navbar-theme-black .navbar-nav .dropdown-item:hover,
  .navbar-theme-black .navbar-nav .list-group-item,
  .navbar-theme-black .navbar-nav .list-group-item:focus,
  .navbar-theme-black .navbar-nav .list-group-item.active,
  .navbar-theme-black .navbar-nav .list-group-item:hover {
    color: #0d1b48;
    background: transparent; }
  .navbar-theme-black .navbar-nav .nav-link.disabled,
  .navbar-theme-black .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-black .navbar-nav .dropdown-item.disabled,
  .navbar-theme-black .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-black .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-black .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-black .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-purple:not(.headroom) {
  background-color: #6f42c1; }

.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(89, 51, 157, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-purple .navbar-nav .dropdown-item.active, .navbar-theme-purple .navbar-nav .dropdown-item:hover,
.navbar-theme-purple .navbar-nav .list-group-item.active,
.navbar-theme-purple .navbar-nav .list-group-item:hover {
  color: #6f42c1;
  background: rgba(111, 66, 193, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-purple .navbar-nav .nav-link, .navbar-theme-purple .navbar-nav .nav-link:focus, .navbar-theme-purple .navbar-nav .nav-link.active, .navbar-theme-purple .navbar-nav .nav-link:hover,
  .navbar-theme-purple .navbar-nav .show > .nav-link,
  .navbar-theme-purple .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple .navbar-nav .show > .nav-link:hover,
  .navbar-theme-purple .navbar-nav .dropdown-item,
  .navbar-theme-purple .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple .navbar-nav .dropdown-item.active,
  .navbar-theme-purple .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple .navbar-nav .list-group-item,
  .navbar-theme-purple .navbar-nav .list-group-item:focus,
  .navbar-theme-purple .navbar-nav .list-group-item.active,
  .navbar-theme-purple .navbar-nav .list-group-item:hover {
    color: #6f42c1;
    background: transparent; }
  .navbar-theme-purple .navbar-nav .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .dropdown-item.disabled,
  .navbar-theme-purple .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-purple .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-purple .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-purple .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-soft-green:not(.headroom) {
  background-color: #ebf9ee; }

.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(196, 237, 205, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-soft-green .navbar-nav .dropdown-item.active, .navbar-theme-soft-green .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-green .navbar-nav .list-group-item.active,
.navbar-theme-soft-green .navbar-nav .list-group-item:hover {
  color: #ebf9ee;
  background: rgba(235, 249, 238, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-soft-green .navbar-nav .nav-link, .navbar-theme-soft-green .navbar-nav .nav-link:focus, .navbar-theme-soft-green .navbar-nav .nav-link.active, .navbar-theme-soft-green .navbar-nav .nav-link:hover,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-green .navbar-nav .dropdown-item,
  .navbar-theme-soft-green .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-green .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-green .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-green .navbar-nav .list-group-item,
  .navbar-theme-soft-green .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-green .navbar-nav .list-group-item.active,
  .navbar-theme-soft-green .navbar-nav .list-group-item:hover {
    color: #ebf9ee;
    background: transparent; }
  .navbar-theme-soft-green .navbar-nav .nav-link.disabled,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft-green .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft-green .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-soft-green .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-soft-green .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-soft-green .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-100:not(.headroom) {
  background-color: #ffffff; }

.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(230, 230, 230, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-100 .navbar-nav .dropdown-item.active, .navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100 .navbar-nav .list-group-item.active,
.navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-100 .navbar-nav .nav-link, .navbar-theme-gray-100 .navbar-nav .nav-link:focus, .navbar-theme-gray-100 .navbar-nav .nav-link.active, .navbar-theme-gray-100 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100 .navbar-nav .list-group-item,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
    color: #ffffff;
    background: transparent; }
  .navbar-theme-gray-100 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-100 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-100 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-100 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-200:not(.headroom) {
  background-color: #fafbfe; }

.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(208, 217, 246, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-200 .navbar-nav .dropdown-item.active, .navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200 .navbar-nav .list-group-item.active,
.navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
  color: #fafbfe;
  background: rgba(250, 251, 254, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-200 .navbar-nav .nav-link, .navbar-theme-gray-200 .navbar-nav .nav-link:focus, .navbar-theme-gray-200 .navbar-nav .nav-link.active, .navbar-theme-gray-200 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200 .navbar-nav .list-group-item,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
    color: #fafbfe;
    background: transparent; }
  .navbar-theme-gray-200 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-200 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-200 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-200 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-300:not(.headroom) {
  background-color: #f5f9fe; }

.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(199, 221, 249, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-300 .navbar-nav .dropdown-item.active, .navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300 .navbar-nav .list-group-item.active,
.navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
  color: #f5f9fe;
  background: rgba(245, 249, 254, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-300 .navbar-nav .nav-link, .navbar-theme-gray-300 .navbar-nav .nav-link:focus, .navbar-theme-gray-300 .navbar-nav .nav-link.active, .navbar-theme-gray-300 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300 .navbar-nav .list-group-item,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
    color: #f5f9fe;
    background: transparent; }
  .navbar-theme-gray-300 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-300 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-300 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-300 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-400:not(.headroom) {
  background-color: #edf0f7; }

.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(202, 211, 231, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-400 .navbar-nav .dropdown-item.active, .navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400 .navbar-nav .list-group-item.active,
.navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
  color: #edf0f7;
  background: rgba(237, 240, 247, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-400 .navbar-nav .nav-link, .navbar-theme-gray-400 .navbar-nav .nav-link:focus, .navbar-theme-gray-400 .navbar-nav .nav-link.active, .navbar-theme-gray-400 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400 .navbar-nav .list-group-item,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
    color: #edf0f7;
    background: transparent; }
  .navbar-theme-gray-400 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-400 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-400 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-400 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-500:not(.headroom) {
  background-color: #B7C3D2; }

.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(152, 169, 190, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-500 .navbar-nav .dropdown-item.active, .navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500 .navbar-nav .list-group-item.active,
.navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
  color: #B7C3D2;
  background: rgba(183, 195, 210, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-500 .navbar-nav .nav-link, .navbar-theme-gray-500 .navbar-nav .nav-link:focus, .navbar-theme-gray-500 .navbar-nav .nav-link.active, .navbar-theme-gray-500 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500 .navbar-nav .list-group-item,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
    color: #B7C3D2;
    background: transparent; }
  .navbar-theme-gray-500 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-500 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-500 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-500 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-600:not(.headroom) {
  background-color: #93a5be; }

.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(115, 138, 171, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-600 .navbar-nav .dropdown-item.active, .navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600 .navbar-nav .list-group-item.active,
.navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
  color: #93a5be;
  background: rgba(147, 165, 190, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-600 .navbar-nav .nav-link, .navbar-theme-gray-600 .navbar-nav .nav-link:focus, .navbar-theme-gray-600 .navbar-nav .nav-link.active, .navbar-theme-gray-600 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600 .navbar-nav .list-group-item,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
    color: #93a5be;
    background: transparent; }
  .navbar-theme-gray-600 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-600 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-600 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-600 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-700:not(.headroom) {
  background-color: #52547a; }

.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(62, 63, 92, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-700 .navbar-nav .dropdown-item.active, .navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700 .navbar-nav .list-group-item.active,
.navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
  color: #52547a;
  background: rgba(82, 84, 122, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-700 .navbar-nav .nav-link, .navbar-theme-gray-700 .navbar-nav .nav-link:focus, .navbar-theme-gray-700 .navbar-nav .nav-link.active, .navbar-theme-gray-700 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700 .navbar-nav .list-group-item,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
    color: #52547a;
    background: transparent; }
  .navbar-theme-gray-700 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-700 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-700 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-700 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-gray-800:not(.headroom) {
  background-color: #4E5079; }

.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(58, 59, 90, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-gray-800 .navbar-nav .dropdown-item.active, .navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800 .navbar-nav .list-group-item.active,
.navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
  color: #4E5079;
  background: rgba(78, 80, 121, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-gray-800 .navbar-nav .nav-link, .navbar-theme-gray-800 .navbar-nav .nav-link:focus, .navbar-theme-gray-800 .navbar-nav .nav-link.active, .navbar-theme-gray-800 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800 .navbar-nav .list-group-item,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
    color: #4E5079;
    background: transparent; }
  .navbar-theme-gray-800 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-gray-800 .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-gray-800 .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-gray-800 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-facebook:not(.headroom) {
  background-color: #3b5999; }

.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(45, 68, 116, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-facebook .navbar-nav .dropdown-item.active, .navbar-theme-facebook .navbar-nav .dropdown-item:hover,
.navbar-theme-facebook .navbar-nav .list-group-item.active,
.navbar-theme-facebook .navbar-nav .list-group-item:hover {
  color: #3b5999;
  background: rgba(59, 89, 153, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-facebook .navbar-nav .nav-link, .navbar-theme-facebook .navbar-nav .nav-link:focus, .navbar-theme-facebook .navbar-nav .nav-link.active, .navbar-theme-facebook .navbar-nav .nav-link:hover,
  .navbar-theme-facebook .navbar-nav .show > .nav-link,
  .navbar-theme-facebook .navbar-nav .show > .nav-link:focus,
  .navbar-theme-facebook .navbar-nav .show > .nav-link.active,
  .navbar-theme-facebook .navbar-nav .show > .nav-link:hover,
  .navbar-theme-facebook .navbar-nav .dropdown-item,
  .navbar-theme-facebook .navbar-nav .dropdown-item:focus,
  .navbar-theme-facebook .navbar-nav .dropdown-item.active,
  .navbar-theme-facebook .navbar-nav .dropdown-item:hover,
  .navbar-theme-facebook .navbar-nav .list-group-item,
  .navbar-theme-facebook .navbar-nav .list-group-item:focus,
  .navbar-theme-facebook .navbar-nav .list-group-item.active,
  .navbar-theme-facebook .navbar-nav .list-group-item:hover {
    color: #3b5999;
    background: transparent; }
  .navbar-theme-facebook .navbar-nav .nav-link.disabled,
  .navbar-theme-facebook .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-facebook .navbar-nav .dropdown-item.disabled,
  .navbar-theme-facebook .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-facebook .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-facebook .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-facebook .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-twitter:not(.headroom) {
  background-color: #1da1f2; }

.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(12, 133, 208, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-twitter .navbar-nav .dropdown-item.active, .navbar-theme-twitter .navbar-nav .dropdown-item:hover,
.navbar-theme-twitter .navbar-nav .list-group-item.active,
.navbar-theme-twitter .navbar-nav .list-group-item:hover {
  color: #1da1f2;
  background: rgba(29, 161, 242, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-twitter .navbar-nav .nav-link, .navbar-theme-twitter .navbar-nav .nav-link:focus, .navbar-theme-twitter .navbar-nav .nav-link.active, .navbar-theme-twitter .navbar-nav .nav-link:hover,
  .navbar-theme-twitter .navbar-nav .show > .nav-link,
  .navbar-theme-twitter .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitter .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitter .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitter .navbar-nav .dropdown-item,
  .navbar-theme-twitter .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitter .navbar-nav .dropdown-item.active,
  .navbar-theme-twitter .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitter .navbar-nav .list-group-item,
  .navbar-theme-twitter .navbar-nav .list-group-item:focus,
  .navbar-theme-twitter .navbar-nav .list-group-item.active,
  .navbar-theme-twitter .navbar-nav .list-group-item:hover {
    color: #1da1f2;
    background: transparent; }
  .navbar-theme-twitter .navbar-nav .nav-link.disabled,
  .navbar-theme-twitter .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-twitter .navbar-nav .dropdown-item.disabled,
  .navbar-theme-twitter .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-twitter .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-twitter .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-twitter .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-google:not(.headroom) {
  background-color: #DB4337; }

.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(189, 45, 34, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-google .navbar-nav .dropdown-item.active, .navbar-theme-google .navbar-nav .dropdown-item:hover,
.navbar-theme-google .navbar-nav .list-group-item.active,
.navbar-theme-google .navbar-nav .list-group-item:hover {
  color: #DB4337;
  background: rgba(219, 67, 55, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-google .navbar-nav .nav-link, .navbar-theme-google .navbar-nav .nav-link:focus, .navbar-theme-google .navbar-nav .nav-link.active, .navbar-theme-google .navbar-nav .nav-link:hover,
  .navbar-theme-google .navbar-nav .show > .nav-link,
  .navbar-theme-google .navbar-nav .show > .nav-link:focus,
  .navbar-theme-google .navbar-nav .show > .nav-link.active,
  .navbar-theme-google .navbar-nav .show > .nav-link:hover,
  .navbar-theme-google .navbar-nav .dropdown-item,
  .navbar-theme-google .navbar-nav .dropdown-item:focus,
  .navbar-theme-google .navbar-nav .dropdown-item.active,
  .navbar-theme-google .navbar-nav .dropdown-item:hover,
  .navbar-theme-google .navbar-nav .list-group-item,
  .navbar-theme-google .navbar-nav .list-group-item:focus,
  .navbar-theme-google .navbar-nav .list-group-item.active,
  .navbar-theme-google .navbar-nav .list-group-item:hover {
    color: #DB4337;
    background: transparent; }
  .navbar-theme-google .navbar-nav .nav-link.disabled,
  .navbar-theme-google .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-google .navbar-nav .dropdown-item.disabled,
  .navbar-theme-google .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-google .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-google .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-google .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-instagram:not(.headroom) {
  background-color: #e4405f; }

.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(211, 30, 64, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-instagram .navbar-nav .dropdown-item.active, .navbar-theme-instagram .navbar-nav .dropdown-item:hover,
.navbar-theme-instagram .navbar-nav .list-group-item.active,
.navbar-theme-instagram .navbar-nav .list-group-item:hover {
  color: #e4405f;
  background: rgba(228, 64, 95, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-instagram .navbar-nav .nav-link, .navbar-theme-instagram .navbar-nav .nav-link:focus, .navbar-theme-instagram .navbar-nav .nav-link.active, .navbar-theme-instagram .navbar-nav .nav-link:hover,
  .navbar-theme-instagram .navbar-nav .show > .nav-link,
  .navbar-theme-instagram .navbar-nav .show > .nav-link:focus,
  .navbar-theme-instagram .navbar-nav .show > .nav-link.active,
  .navbar-theme-instagram .navbar-nav .show > .nav-link:hover,
  .navbar-theme-instagram .navbar-nav .dropdown-item,
  .navbar-theme-instagram .navbar-nav .dropdown-item:focus,
  .navbar-theme-instagram .navbar-nav .dropdown-item.active,
  .navbar-theme-instagram .navbar-nav .dropdown-item:hover,
  .navbar-theme-instagram .navbar-nav .list-group-item,
  .navbar-theme-instagram .navbar-nav .list-group-item:focus,
  .navbar-theme-instagram .navbar-nav .list-group-item.active,
  .navbar-theme-instagram .navbar-nav .list-group-item:hover {
    color: #e4405f;
    background: transparent; }
  .navbar-theme-instagram .navbar-nav .nav-link.disabled,
  .navbar-theme-instagram .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-instagram .navbar-nav .dropdown-item.disabled,
  .navbar-theme-instagram .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-instagram .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-instagram .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-instagram .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-pinterest:not(.headroom) {
  background-color: #bd081c; }

.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(140, 6, 21, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-pinterest .navbar-nav .dropdown-item.active, .navbar-theme-pinterest .navbar-nav .dropdown-item:hover,
.navbar-theme-pinterest .navbar-nav .list-group-item.active,
.navbar-theme-pinterest .navbar-nav .list-group-item:hover {
  color: #bd081c;
  background: rgba(189, 8, 28, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-pinterest .navbar-nav .nav-link, .navbar-theme-pinterest .navbar-nav .nav-link:focus, .navbar-theme-pinterest .navbar-nav .nav-link.active, .navbar-theme-pinterest .navbar-nav .nav-link:hover,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link.active,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pinterest .navbar-nav .dropdown-item,
  .navbar-theme-pinterest .navbar-nav .dropdown-item:focus,
  .navbar-theme-pinterest .navbar-nav .dropdown-item.active,
  .navbar-theme-pinterest .navbar-nav .dropdown-item:hover,
  .navbar-theme-pinterest .navbar-nav .list-group-item,
  .navbar-theme-pinterest .navbar-nav .list-group-item:focus,
  .navbar-theme-pinterest .navbar-nav .list-group-item.active,
  .navbar-theme-pinterest .navbar-nav .list-group-item:hover {
    color: #bd081c;
    background: transparent; }
  .navbar-theme-pinterest .navbar-nav .nav-link.disabled,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-pinterest .navbar-nav .dropdown-item.disabled,
  .navbar-theme-pinterest .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-pinterest .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-pinterest .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-pinterest .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-youtube:not(.headroom) {
  background-color: #cd201f; }

.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(161, 25, 24, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-youtube .navbar-nav .dropdown-item.active, .navbar-theme-youtube .navbar-nav .dropdown-item:hover,
.navbar-theme-youtube .navbar-nav .list-group-item.active,
.navbar-theme-youtube .navbar-nav .list-group-item:hover {
  color: #cd201f;
  background: rgba(205, 32, 31, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-youtube .navbar-nav .nav-link, .navbar-theme-youtube .navbar-nav .nav-link:focus, .navbar-theme-youtube .navbar-nav .nav-link.active, .navbar-theme-youtube .navbar-nav .nav-link:hover,
  .navbar-theme-youtube .navbar-nav .show > .nav-link,
  .navbar-theme-youtube .navbar-nav .show > .nav-link:focus,
  .navbar-theme-youtube .navbar-nav .show > .nav-link.active,
  .navbar-theme-youtube .navbar-nav .show > .nav-link:hover,
  .navbar-theme-youtube .navbar-nav .dropdown-item,
  .navbar-theme-youtube .navbar-nav .dropdown-item:focus,
  .navbar-theme-youtube .navbar-nav .dropdown-item.active,
  .navbar-theme-youtube .navbar-nav .dropdown-item:hover,
  .navbar-theme-youtube .navbar-nav .list-group-item,
  .navbar-theme-youtube .navbar-nav .list-group-item:focus,
  .navbar-theme-youtube .navbar-nav .list-group-item.active,
  .navbar-theme-youtube .navbar-nav .list-group-item:hover {
    color: #cd201f;
    background: transparent; }
  .navbar-theme-youtube .navbar-nav .nav-link.disabled,
  .navbar-theme-youtube .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-youtube .navbar-nav .dropdown-item.disabled,
  .navbar-theme-youtube .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-youtube .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-youtube .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-youtube .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-slack:not(.headroom) {
  background-color: #3aaf85; }

.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(45, 137, 104, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-slack .navbar-nav .dropdown-item.active, .navbar-theme-slack .navbar-nav .dropdown-item:hover,
.navbar-theme-slack .navbar-nav .list-group-item.active,
.navbar-theme-slack .navbar-nav .list-group-item:hover {
  color: #3aaf85;
  background: rgba(58, 175, 133, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-slack .navbar-nav .nav-link, .navbar-theme-slack .navbar-nav .nav-link:focus, .navbar-theme-slack .navbar-nav .nav-link.active, .navbar-theme-slack .navbar-nav .nav-link:hover,
  .navbar-theme-slack .navbar-nav .show > .nav-link,
  .navbar-theme-slack .navbar-nav .show > .nav-link:focus,
  .navbar-theme-slack .navbar-nav .show > .nav-link.active,
  .navbar-theme-slack .navbar-nav .show > .nav-link:hover,
  .navbar-theme-slack .navbar-nav .dropdown-item,
  .navbar-theme-slack .navbar-nav .dropdown-item:focus,
  .navbar-theme-slack .navbar-nav .dropdown-item.active,
  .navbar-theme-slack .navbar-nav .dropdown-item:hover,
  .navbar-theme-slack .navbar-nav .list-group-item,
  .navbar-theme-slack .navbar-nav .list-group-item:focus,
  .navbar-theme-slack .navbar-nav .list-group-item.active,
  .navbar-theme-slack .navbar-nav .list-group-item:hover {
    color: #3aaf85;
    background: transparent; }
  .navbar-theme-slack .navbar-nav .nav-link.disabled,
  .navbar-theme-slack .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-slack .navbar-nav .dropdown-item.disabled,
  .navbar-theme-slack .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-slack .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-slack .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-slack .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-dribbble:not(.headroom) {
  background-color: #ea4c89; }

.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(229, 30, 107, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-dribbble .navbar-nav .dropdown-item.active, .navbar-theme-dribbble .navbar-nav .dropdown-item:hover,
.navbar-theme-dribbble .navbar-nav .list-group-item.active,
.navbar-theme-dribbble .navbar-nav .list-group-item:hover {
  color: #ea4c89;
  background: rgba(234, 76, 137, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-dribbble .navbar-nav .nav-link, .navbar-theme-dribbble .navbar-nav .nav-link:focus, .navbar-theme-dribbble .navbar-nav .nav-link.active, .navbar-theme-dribbble .navbar-nav .nav-link:hover,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link.active,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dribbble .navbar-nav .dropdown-item,
  .navbar-theme-dribbble .navbar-nav .dropdown-item:focus,
  .navbar-theme-dribbble .navbar-nav .dropdown-item.active,
  .navbar-theme-dribbble .navbar-nav .dropdown-item:hover,
  .navbar-theme-dribbble .navbar-nav .list-group-item,
  .navbar-theme-dribbble .navbar-nav .list-group-item:focus,
  .navbar-theme-dribbble .navbar-nav .list-group-item.active,
  .navbar-theme-dribbble .navbar-nav .list-group-item:hover {
    color: #ea4c89;
    background: transparent; }
  .navbar-theme-dribbble .navbar-nav .nav-link.disabled,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dribbble .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dribbble .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-dribbble .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-dribbble .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-dribbble .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-dropbox:not(.headroom) {
  background-color: #1E90FF; }

.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(0, 119, 234, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-dropbox .navbar-nav .dropdown-item.active, .navbar-theme-dropbox .navbar-nav .dropdown-item:hover,
.navbar-theme-dropbox .navbar-nav .list-group-item.active,
.navbar-theme-dropbox .navbar-nav .list-group-item:hover {
  color: #1E90FF;
  background: rgba(30, 144, 255, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-dropbox .navbar-nav .nav-link, .navbar-theme-dropbox .navbar-nav .nav-link:focus, .navbar-theme-dropbox .navbar-nav .nav-link.active, .navbar-theme-dropbox .navbar-nav .nav-link:hover,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link.active,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dropbox .navbar-nav .dropdown-item,
  .navbar-theme-dropbox .navbar-nav .dropdown-item:focus,
  .navbar-theme-dropbox .navbar-nav .dropdown-item.active,
  .navbar-theme-dropbox .navbar-nav .dropdown-item:hover,
  .navbar-theme-dropbox .navbar-nav .list-group-item,
  .navbar-theme-dropbox .navbar-nav .list-group-item:focus,
  .navbar-theme-dropbox .navbar-nav .list-group-item.active,
  .navbar-theme-dropbox .navbar-nav .list-group-item:hover {
    color: #1E90FF;
    background: transparent; }
  .navbar-theme-dropbox .navbar-nav .nav-link.disabled,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dropbox .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dropbox .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-dropbox .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-dropbox .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-dropbox .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-twitch:not(.headroom) {
  background-color: #4B367C; }

.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(54, 39, 88, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-twitch .navbar-nav .dropdown-item.active, .navbar-theme-twitch .navbar-nav .dropdown-item:hover,
.navbar-theme-twitch .navbar-nav .list-group-item.active,
.navbar-theme-twitch .navbar-nav .list-group-item:hover {
  color: #4B367C;
  background: rgba(75, 54, 124, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-twitch .navbar-nav .nav-link, .navbar-theme-twitch .navbar-nav .nav-link:focus, .navbar-theme-twitch .navbar-nav .nav-link.active, .navbar-theme-twitch .navbar-nav .nav-link:hover,
  .navbar-theme-twitch .navbar-nav .show > .nav-link,
  .navbar-theme-twitch .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitch .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitch .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitch .navbar-nav .dropdown-item,
  .navbar-theme-twitch .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitch .navbar-nav .dropdown-item.active,
  .navbar-theme-twitch .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitch .navbar-nav .list-group-item,
  .navbar-theme-twitch .navbar-nav .list-group-item:focus,
  .navbar-theme-twitch .navbar-nav .list-group-item.active,
  .navbar-theme-twitch .navbar-nav .list-group-item:hover {
    color: #4B367C;
    background: transparent; }
  .navbar-theme-twitch .navbar-nav .nav-link.disabled,
  .navbar-theme-twitch .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-twitch .navbar-nav .dropdown-item.disabled,
  .navbar-theme-twitch .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-twitch .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-twitch .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-twitch .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-paypal:not(.headroom) {
  background-color: #ecb32c; }

.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(210, 153, 19, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-paypal .navbar-nav .dropdown-item.active, .navbar-theme-paypal .navbar-nav .dropdown-item:hover,
.navbar-theme-paypal .navbar-nav .list-group-item.active,
.navbar-theme-paypal .navbar-nav .list-group-item:hover {
  color: #ecb32c;
  background: rgba(236, 179, 44, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-paypal .navbar-nav .nav-link, .navbar-theme-paypal .navbar-nav .nav-link:focus, .navbar-theme-paypal .navbar-nav .nav-link.active, .navbar-theme-paypal .navbar-nav .nav-link:hover,
  .navbar-theme-paypal .navbar-nav .show > .nav-link,
  .navbar-theme-paypal .navbar-nav .show > .nav-link:focus,
  .navbar-theme-paypal .navbar-nav .show > .nav-link.active,
  .navbar-theme-paypal .navbar-nav .show > .nav-link:hover,
  .navbar-theme-paypal .navbar-nav .dropdown-item,
  .navbar-theme-paypal .navbar-nav .dropdown-item:focus,
  .navbar-theme-paypal .navbar-nav .dropdown-item.active,
  .navbar-theme-paypal .navbar-nav .dropdown-item:hover,
  .navbar-theme-paypal .navbar-nav .list-group-item,
  .navbar-theme-paypal .navbar-nav .list-group-item:focus,
  .navbar-theme-paypal .navbar-nav .list-group-item.active,
  .navbar-theme-paypal .navbar-nav .list-group-item:hover {
    color: #ecb32c;
    background: transparent; }
  .navbar-theme-paypal .navbar-nav .nav-link.disabled,
  .navbar-theme-paypal .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-paypal .navbar-nav .dropdown-item.disabled,
  .navbar-theme-paypal .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-paypal .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-paypal .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-paypal .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-behance:not(.headroom) {
  background-color: #0057ff; }

.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(0, 70, 204, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-behance .navbar-nav .dropdown-item.active, .navbar-theme-behance .navbar-nav .dropdown-item:hover,
.navbar-theme-behance .navbar-nav .list-group-item.active,
.navbar-theme-behance .navbar-nav .list-group-item:hover {
  color: #0057ff;
  background: rgba(0, 87, 255, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-behance .navbar-nav .nav-link, .navbar-theme-behance .navbar-nav .nav-link:focus, .navbar-theme-behance .navbar-nav .nav-link.active, .navbar-theme-behance .navbar-nav .nav-link:hover,
  .navbar-theme-behance .navbar-nav .show > .nav-link,
  .navbar-theme-behance .navbar-nav .show > .nav-link:focus,
  .navbar-theme-behance .navbar-nav .show > .nav-link.active,
  .navbar-theme-behance .navbar-nav .show > .nav-link:hover,
  .navbar-theme-behance .navbar-nav .dropdown-item,
  .navbar-theme-behance .navbar-nav .dropdown-item:focus,
  .navbar-theme-behance .navbar-nav .dropdown-item.active,
  .navbar-theme-behance .navbar-nav .dropdown-item:hover,
  .navbar-theme-behance .navbar-nav .list-group-item,
  .navbar-theme-behance .navbar-nav .list-group-item:focus,
  .navbar-theme-behance .navbar-nav .list-group-item.active,
  .navbar-theme-behance .navbar-nav .list-group-item:hover {
    color: #0057ff;
    background: transparent; }
  .navbar-theme-behance .navbar-nav .nav-link.disabled,
  .navbar-theme-behance .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-behance .navbar-nav .dropdown-item.disabled,
  .navbar-theme-behance .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-behance .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-behance .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-behance .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-reddit:not(.headroom) {
  background-color: #E84422; }

.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(195, 50, 20, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-reddit .navbar-nav .dropdown-item.active, .navbar-theme-reddit .navbar-nav .dropdown-item:hover,
.navbar-theme-reddit .navbar-nav .list-group-item.active,
.navbar-theme-reddit .navbar-nav .list-group-item:hover {
  color: #E84422;
  background: rgba(232, 68, 34, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-reddit .navbar-nav .nav-link, .navbar-theme-reddit .navbar-nav .nav-link:focus, .navbar-theme-reddit .navbar-nav .nav-link.active, .navbar-theme-reddit .navbar-nav .nav-link:hover,
  .navbar-theme-reddit .navbar-nav .show > .nav-link,
  .navbar-theme-reddit .navbar-nav .show > .nav-link:focus,
  .navbar-theme-reddit .navbar-nav .show > .nav-link.active,
  .navbar-theme-reddit .navbar-nav .show > .nav-link:hover,
  .navbar-theme-reddit .navbar-nav .dropdown-item,
  .navbar-theme-reddit .navbar-nav .dropdown-item:focus,
  .navbar-theme-reddit .navbar-nav .dropdown-item.active,
  .navbar-theme-reddit .navbar-nav .dropdown-item:hover,
  .navbar-theme-reddit .navbar-nav .list-group-item,
  .navbar-theme-reddit .navbar-nav .list-group-item:focus,
  .navbar-theme-reddit .navbar-nav .list-group-item.active,
  .navbar-theme-reddit .navbar-nav .list-group-item:hover {
    color: #E84422;
    background: transparent; }
  .navbar-theme-reddit .navbar-nav .nav-link.disabled,
  .navbar-theme-reddit .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-reddit .navbar-nav .dropdown-item.disabled,
  .navbar-theme-reddit .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-reddit .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-reddit .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-reddit .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-theme-github:not(.headroom) {
  background-color: #222222; }

.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #C33C54;
  background: rgba(9, 9, 9, 0.5); }

@media (max-width: 991.98px) {
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #C33C54; } }

.navbar-theme-github .navbar-nav .dropdown-item.active, .navbar-theme-github .navbar-nav .dropdown-item:hover,
.navbar-theme-github .navbar-nav .list-group-item.active,
.navbar-theme-github .navbar-nav .list-group-item:hover {
  color: #222222;
  background: rgba(34, 34, 34, 0.1); }

@media (max-width: 991.98px) {
  .navbar-theme-github .navbar-nav .nav-link, .navbar-theme-github .navbar-nav .nav-link:focus, .navbar-theme-github .navbar-nav .nav-link.active, .navbar-theme-github .navbar-nav .nav-link:hover,
  .navbar-theme-github .navbar-nav .show > .nav-link,
  .navbar-theme-github .navbar-nav .show > .nav-link:focus,
  .navbar-theme-github .navbar-nav .show > .nav-link.active,
  .navbar-theme-github .navbar-nav .show > .nav-link:hover,
  .navbar-theme-github .navbar-nav .dropdown-item,
  .navbar-theme-github .navbar-nav .dropdown-item:focus,
  .navbar-theme-github .navbar-nav .dropdown-item.active,
  .navbar-theme-github .navbar-nav .dropdown-item:hover,
  .navbar-theme-github .navbar-nav .list-group-item,
  .navbar-theme-github .navbar-nav .list-group-item:focus,
  .navbar-theme-github .navbar-nav .list-group-item.active,
  .navbar-theme-github .navbar-nav .list-group-item:hover {
    color: #222222;
    background: transparent; }
  .navbar-theme-github .navbar-nav .nav-link.disabled,
  .navbar-theme-github .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-github .navbar-nav .dropdown-item.disabled,
  .navbar-theme-github .navbar-nav .list-group-item.disabled {
    color: rgba(78, 80, 121, 0.3); }
  .navbar-theme-github .navbar-nav .dropdown .dropdown-menu {
    padding: 0; }
  .navbar-theme-github .navbar-nav .dropdown-item {
    font-size: 1rem;
    padding-right: 0; }
  .navbar-theme-github .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0; } }

.navbar-transparent {
  background-color: transparent;
  border: 0;
  box-shadow: none; }

@media (min-width: 992px) {
  .navbar-nav .nav-item [data-toggle="dropdown"]::after {
    transition: all 0.2s ease; }
  .navbar-nav .nav-item.show [data-toggle="dropdown"]::after {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.45rem; }
    .navbar-nav .nav-link i {
      margin-right: .3rem;
      font-size: 0.75rem; }
  .navbar-nav .nav-link-icon {
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1rem;
    border-radius: 0.45rem; }
    .navbar-nav .nav-link-icon i {
      margin-right: 0; }
  .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
    border-radius: 0.45rem; }
  .navbar-nav .dropdown-menu-right:before {
    right: 20px;
    left: auto; }
  .navbar-nav .dropdown-menu,
  .navbar-nav .dropdown-megamenu {
    left: 50%;
    transform: translateX(-50%); }
    .navbar-nav .dropdown-menu.show,
    .navbar-nav .dropdown-megamenu.show {
      opacity: 1;
      pointer-events: auto; }
    .navbar-nav .dropdown-menu.close,
    .navbar-nav .dropdown-megamenu.close {
      display: block; }
  .navbar-nav .dropdown-submenu .dropdown-menu {
    transform: none; }
  .navbar-nav .dropdown-menu {
    position: relative;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease; }
  .navbar-nav .dropdown.show > .dropdown-menu,
  .navbar-nav .dropdown-submenu.show > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto; }
  .navbar-nav .dropdown.show > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, -7px); }
  .navbar-nav .dropdown-menu-inner {
    position: relative;
    padding: 1rem; }
  .navbar-transparent .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
  .navbar-transparent .navbar-brand {
    color: rgba(255, 255, 255, 0.9); }
    .navbar-transparent .navbar-brand:hover, .navbar-transparent .navbar-brand:focus {
      color: rgba(255, 255, 255, 0.9); } }

.navbar-collapse-header {
  display: none; }

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: .625rem 0; }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    min-width: auto; }
    .navbar-nav .dropdown-menu .media svg {
      width: 30px; }
  .navbar-collapse {
    width: calc(100% - 30px);
    position: absolute;
    top: 15px;
    left: 15px;
    right: 0;
    z-index: 1050;
    overflow-y: auto;
    height: calc(100vh - 30px) !important;
    opacity: 0; }
    .navbar-collapse .navbar-toggler {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 0; }
      .navbar-collapse .navbar-toggler span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #283448; }
    .navbar-collapse .navbar-collapse-header {
      display: block;
      padding-bottom: 1rem;
      margin-bottom: 1rem; }
    .navbar-collapse .collapse-brand img {
      height: 35px; }
    .navbar-collapse .collapse-close {
      text-align: right; }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.45rem;
    background: #ffffff;
    animation: show-navbar-collapse .2s ease forwards;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse .2s ease forwards; } }

/**
 * = Sections
 */
.section {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem; }

.section-header {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 3rem; }

.section-lg {
  padding-top: 5rem;
  padding-bottom: 5rem; }

@media (min-width: 768px) {
  .section {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .section-header {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem; }
    .section-header.section-sm {
      padding-top: 4rem;
      padding-bottom: 3rem; }
  .section-xl {
    padding-top: 10rem;
    padding-bottom: 10rem; }
  .section-lg {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .section-sm {
    padding-top: 4rem;
    padding-bottom: 4rem; } }

@media (min-width: 768px) {
  .section-hero {
    height: 100vh; } }

.components-section > .form-control + .form-control {
  margin-top: .5rem; }

.components-section > .nav + .nav,
.components-section > .alert + .alert,
.components-section > .navbar + .navbar,
.components-section > .progress + .progress,
.components-section > .progress + .btn,
.components-section .badge,
.components-section .btn {
  margin-right: .5rem;
  margin-bottom: .5rem; }

.components-section .btn-group {
  margin-top: .5rem;
  margin-bottom: .5rem; }
  .components-section .btn-group .btn {
    margin: 0; }

.components-section .alert {
  margin: 0; }
  .components-section .alert + .alert {
    margin-top: 1.25rem; }

.components-section .badge {
  margin-right: .5rem; }

.components-section .modal-footer .btn {
  margin: 0; }

.presentation-box {
  position: relative; }
  .presentation-box .gadget {
    height: 500px;
    max-width: 100%; }
  .presentation-box .pricing-card,
  .presentation-box .dropdown-btn-img,
  .presentation-box .blog-card,
  .presentation-box .profile-card,
  .presentation-box .navbar-img,
  .presentation-box .modal-img,
  .presentation-box .social-btn-img {
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15);
    border-radius: 0.45rem; }
  .presentation-box .profile-card {
    left: 67%;
    top: 19%;
    height: 100px; }
  .presentation-box .navbar-img {
    left: 50%;
    top: 56%;
    height: 65px; }
  .presentation-box .modal-img {
    left: 38%;
    top: 80%;
    height: 100px; }
  .presentation-box .blog-card {
    left: 2%;
    top: 20%;
    height: 60px; }
  .presentation-box .pricing-card {
    left: 15%;
    top: 47%;
    height: 125px; }
  .presentation-box .social-btn-img {
    left: 51%;
    top: 78%;
    height: 25px; }
  .presentation-box .dropdown-btn-img {
    left: 25%;
    top: 10%;
    height: 25px; }
  @media (min-width: 768px) {
    .presentation-box .gadget {
      height: 600px;
      max-width: 100%; }
    .presentation-box .pricing-card,
    .presentation-box .dropdown-btn-img,
    .presentation-box .blog-card,
    .presentation-box .profile-card,
    .presentation-box .navbar-img,
    .presentation-box .modal-img,
    .presentation-box .social-btn-img {
      position: absolute;
      box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15);
      border-radius: 0.45rem; }
    .presentation-box .profile-card {
      left: 65%;
      top: 12%;
      height: 150px; }
    .presentation-box .navbar-img {
      left: 51%;
      top: 55%;
      height: 80px; }
    .presentation-box .modal-img {
      left: 36%;
      top: 80%;
      height: 100px; }
    .presentation-box .blog-card {
      left: 7%;
      top: 20%;
      height: 80px; }
    .presentation-box .pricing-card {
      left: 18%;
      top: 40%;
      height: 150px; }
    .presentation-box .social-btn-img {
      left: 60%;
      top: 80%;
      height: 25px; }
    .presentation-box .dropdown-btn-img {
      left: 30%;
      top: 9%;
      height: 25px; } }
  @media (min-width: 992px) {
    .presentation-box {
      width: 650px; }
      .presentation-box .gadget {
        height: auto; }
      .presentation-box .pricing-card,
      .presentation-box .dropdown-btn-img,
      .presentation-box .blog-card,
      .presentation-box .profile-card,
      .presentation-box .navbar-img,
      .presentation-box .modal-img,
      .presentation-box .social-btn-img {
        position: absolute;
        box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15);
        border-radius: 0.45rem; }
      .presentation-box .profile-card {
        left: 75%;
        top: 3%;
        height: 200px; }
      .presentation-box .navbar-img {
        left: 55%;
        top: 65%;
        height: 120px; }
      .presentation-box .modal-img {
        left: 19%;
        top: 67%;
        height: 170px; }
      .presentation-box .blog-card {
        left: -10%;
        top: -1%;
        height: 100px; }
      .presentation-box .pricing-card {
        left: 0%;
        top: 30%;
        height: 165px; }
      .presentation-box .social-btn-img {
        left: 2%;
        top: 78%;
        height: 25px; }
      .presentation-box .dropdown-btn-img {
        left: 30%;
        top: -12%;
        height: 25px; } }

.card-box {
  -webkit-perspective: 900px;
  -moz-perspective: 900px;
  -o-perspective: 900px;
  perspective: 900px;
  width: 250px;
  position: relative; }
  .card-box .card-component {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    height: 300px; }
    @media (min-width: 992px) {
      .card-box .card-component {
        height: 450px; } }
    .card-box .card-component.card-component-lg {
      height: auto;
      max-height: 800px; }
      @media (min-width: 768px) {
        .card-box .card-component.card-component-lg {
          max-height: 410px; } }
    .card-box .card-component.card-component-xs {
      height: auto;
      max-height: 230px; }
    .card-box .card-component .front {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      box-shadow: 0 1rem 3rem rgba(13, 27, 72, 0.175);
      border-radius: 0.45rem;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-transform: rotateY(24deg);
      -o-transform: rotateY(24deg);
      -ms-transform: rotateY(24deg);
      -webkit-transform: rotateY(24deg);
      transform: rotateY(24deg); }
      .card-box .card-component .front:hover {
        cursor: pointer; }
  .card-box .page-card {
    box-shadow: 0 1rem 3rem rgba(13, 27, 72, 0.175);
    border-radius: 0.45rem; }
    .card-box .page-card:hover {
      cursor: pointer; }
  @media (max-width: 991.98px) {
    .card-box {
      width: 200px; } }
  @media (min-width: 992px) {
    .card-box {
      width: 260px; } }

.copy-docs {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.2s ease; }
  .copy-docs.copied {
    background: #208163; }
  .copy-docs:hover {
    cursor: pointer; }

.index-icon {
  position: absolute;
  font-size: 38px;
  color: #dcdcdc;
  transition: 0.3s all ease; }

.index-icon-javascript {
  bottom: 80px;
  left: 40px; }

.index-icon-javascript:hover {
  color: #f1da1c; }

.index-icon-bootstrap {
  bottom: -150px;
  right: -7%; }

.index-icon-bootstrap:hover {
  color: #553d7c; }

.icon-sass:hover {
  color: #CD6799; }

.index-icon-code {
  bottom: 180px;
  left: -80px; }

.index-icon-code:hover {
  color: #ff7f66; }

.index-icon-gulp:hover {
  color: #e34a4f; }

.index-icon-gulp {
  left: 250px;
  bottom: -10px; }

.index-icon-html5 {
  right: 30px;
  bottom: 150px; }

.index-icon-html5:hover {
  color: #e54b20; }

.index-icon-css3 {
  right: 40px;
  bottom: -20px; }

.index-icon-css3:hover {
  color: #e54b20; }

.index-icon-npm {
  right: 180px;
  bottom: 20px; }

.index-icon-npm:hover {
  color: #cc3f3d; }

.index-icon-fontawesome {
  right: 340px;
  bottom: -40px; }

.index-icon-fontawesome:hover {
  color: #3499EF; }

.index-icon-illustrations {
  left: 20px;
  bottom: -40px; }

.index-icon-illustrations:hover {
  color: #C33C54; }

/**
 * = Footers
 */
.footer {
  position: relative; }
  .footer h3, .footer h5, .footer .h3, .footer .h5 {
    font-size: 1.15rem;
    margin-bottom: 1.25rem; }
  .footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none; }
    .footer ul li {
      display: inline-block; }
      .footer ul li a {
        text-decoration: none;
        position: relative;
        display: block; }
        .footer ul li a:hover {
          color: #C33C54; }
      .footer ul li .btn {
        margin: 0; }
    .footer ul.links-horizontal:first-child a {
      padding-left: 0; }
    .footer ul.links-horizontal:last-child a {
      padding-right: 0; }
    .footer ul.footer-links li {
      display: block;
      margin-left: -5px;
      margin-right: -5px; }
      .footer ul.footer-links li a {
        padding: 5px; }
        .footer ul.footer-links li a:hover {
          color: #C33C54; }
    .footer ul.icon-box span {
      line-height: 1.7; }
  .footer .social-buttons a,
  .footer .social-buttons .btn {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0.125rem 0.375rem; }
  .footer .footer-brand {
    font-size: 1.25rem;
    font-weight: 500; }
    .footer .footer-brand img, .footer .footer-brand svg {
      width: 30px; }
    .footer .footer-brand:hover, .footer .footer-brand:focus {
      color: #0d1b48; }
    .footer .footer-brand.brand-sm img, .footer .footer-brand.brand-sm svg {
      width: 25px; }
  .footer .copyright {
    font-size: 0.875rem; }
  .footer .pull-center {
    display: inline-block;
    float: none; }

/**
 * = Sidebars
 */
#doc-index:not(.collapse.show), .doc-sidebar {
  display: none; }

@media (min-width: 992px) {
  #doc-index {
    display: block; }
  .doc-sidebar {
    display: block;
    height: calc(100vh - 2rem);
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    top: 2rem; }
    .doc-sidebar .nav-link[data-toggle="collapse"] > .icon {
      transform: rotateZ(-90deg);
      position: relative;
      right: .25rem; }
    .doc-sidebar .nav .nav-item {
      font-size: 0.875rem; } }

/**
 * = Accordions
 */
.accordion .card {
  margin-bottom: 1.5rem; }
  .accordion .card:hover {
    background-color: #f5f9fe; }

.accordion-panel-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  user-select: none; }
  .accordion-panel-header .icon {
    color: #0d1b48;
    transition: all 0.2s ease; }
    .accordion-panel-header .icon span {
      font-size: 0.875rem; }
  .accordion-panel-header .icon-title {
    margin-right: 1rem; }
    .accordion-panel-header .icon-title span {
      margin-right: 1rem; }
    .accordion-panel-header .icon-title + .icon {
      height: 1rem;
      font-size: 0.875rem; }
  .accordion-panel-header[aria-expanded="true"] > .icon {
    -moz-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg); }
  .accordion-panel-header[aria-expanded="true"] > *, .accordion-panel-header:hover > * {
    color: #0d1b48; }
    .accordion-panel-header[aria-expanded="true"] > * i, .accordion-panel-header:hover > * i {
      fill: #0d1b48; }
  .accordion-panel-header:hover {
    cursor: pointer; }

/**
 * = Alerts
 */
.alert {
  padding: 1rem 1.5rem;
  border: 0;
  font-size: 1rem;
  border-radius: 0.45rem; }
  .alert.alert-sm {
    padding: .5rem 1rem; }
  .alert .alert-inner--icon {
    display: inline-block;
    font-size: 1.25rem;
    margin-right: .35rem;
    vertical-align: middle; }

.alert-heading {
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: .15rem; }

.alert-dismissible .close {
  top: 50%;
  right: 1.5rem;
  padding: 0;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  opacity: 1; }
  .alert-dismissible .close:hover, .alert-dismissible .close:focus {
    color: rgba(255, 255, 255, 0.9);
    opacity: 1 !important; }
  @media (max-width: 575.98px) {
    .alert-dismissible .close {
      top: 1rem;
      right: .5rem; } }
  .alert-dismissible .close > span:not(.sr-only) {
    font-size: 1.5rem;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6); }
  .alert-dismissible .close:hover > span:not(.sr-only), .alert-dismissible .close:focus > span:not(.sr-only) {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.9); }

.alert-primary {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48; }
  .alert-primary hr {
    border-top-color: #091332; }
  .alert-primary .alert-link {
    color: white; }

.alert-secondary {
  color: #ffffff;
  background-color: #C33C54;
  border-color: #C33C54; }
  .alert-secondary hr {
    border-top-color: #b0364c; }
  .alert-secondary .alert-link {
    color: white; }

.alert-success {
  color: #ffffff;
  background-color: #208163;
  border-color: #208163; }
  .alert-success hr {
    border-top-color: #1b6d53; }
  .alert-success .alert-link {
    color: white; }

.alert-info {
  color: #ffffff;
  background-color: #0056B3;
  border-color: #0056B3; }
  .alert-info hr {
    border-top-color: #004a9a; }
  .alert-info .alert-link {
    color: white; }

.alert-warning {
  color: #0d1b48;
  background-color: #F0B400;
  border-color: #F0B400; }
  .alert-warning hr {
    border-top-color: #d7a100; }
  .alert-warning .alert-link {
    color: white; }

.alert-danger {
  color: #ffffff;
  background-color: #be2837;
  border-color: #be2837; }
  .alert-danger hr {
    border-top-color: #a92431; }
  .alert-danger .alert-link {
    color: white; }

.alert-light {
  color: #0d1b48;
  background-color: #edf0f7;
  border-color: #edf0f7; }
  .alert-light hr {
    border-top-color: #dbe1ef; }
  .alert-light .alert-link {
    color: white; }

.alert-dark {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48; }
  .alert-dark hr {
    border-top-color: #091332; }
  .alert-dark .alert-link {
    color: white; }

.alert-tertiary {
  color: #ffffff;
  background-color: #26408B;
  border-color: #26408B; }
  .alert-tertiary hr {
    border-top-color: #213777; }
  .alert-tertiary .alert-link {
    color: white; }

.alert-lighten {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff; }
  .alert-lighten hr {
    border-top-color: #f2f2f2; }
  .alert-lighten .alert-link {
    color: white; }

.alert-white {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: white; }

.alert-gray {
  color: #ffffff;
  background-color: #424767;
  border-color: #424767; }
  .alert-gray hr {
    border-top-color: #383c57; }
  .alert-gray .alert-link {
    color: white; }

.alert-neutral {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff; }
  .alert-neutral hr {
    border-top-color: #f2f2f2; }
  .alert-neutral .alert-link {
    color: white; }

.alert-soft {
  color: #0d1b48;
  background-color: #f5f9fe;
  border-color: #f5f9fe; }
  .alert-soft hr {
    border-top-color: #deebfc; }
  .alert-soft .alert-link {
    color: white; }

.alert-black {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48; }
  .alert-black hr {
    border-top-color: #091332; }
  .alert-black .alert-link {
    color: white; }

.alert-purple {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1; }
  .alert-purple hr {
    border-top-color: #643ab0; }
  .alert-purple .alert-link {
    color: white; }

.alert-soft-green {
  color: #0d1b48;
  background-color: #ebf9ee;
  border-color: #ebf9ee; }
  .alert-soft-green hr {
    border-top-color: #d7f3dd; }
  .alert-soft-green .alert-link {
    color: white; }

.alert-gray-100 {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff; }
  .alert-gray-100 hr {
    border-top-color: #f2f2f2; }
  .alert-gray-100 .alert-link {
    color: white; }

.alert-gray-200 {
  color: #0d1b48;
  background-color: #fafbfe;
  border-color: #fafbfe; }
  .alert-gray-200 hr {
    border-top-color: #e5eafa; }
  .alert-gray-200 .alert-link {
    color: white; }

.alert-gray-300 {
  color: #0d1b48;
  background-color: #f5f9fe;
  border-color: #f5f9fe; }
  .alert-gray-300 hr {
    border-top-color: #deebfc; }
  .alert-gray-300 .alert-link {
    color: white; }

.alert-gray-400 {
  color: #0d1b48;
  background-color: #edf0f7;
  border-color: #edf0f7; }
  .alert-gray-400 hr {
    border-top-color: #dbe1ef; }
  .alert-gray-400 .alert-link {
    color: white; }

.alert-gray-500 {
  color: #0d1b48;
  background-color: #B7C3D2;
  border-color: #B7C3D2; }
  .alert-gray-500 hr {
    border-top-color: #a7b6c8; }
  .alert-gray-500 .alert-link {
    color: white; }

.alert-gray-600 {
  color: #0d1b48;
  background-color: #93a5be;
  border-color: #93a5be; }
  .alert-gray-600 hr {
    border-top-color: #8398b4; }
  .alert-gray-600 .alert-link {
    color: white; }

.alert-gray-700 {
  color: #ffffff;
  background-color: #52547a;
  border-color: #52547a; }
  .alert-gray-700 hr {
    border-top-color: #484a6b; }
  .alert-gray-700 .alert-link {
    color: white; }

.alert-gray-800 {
  color: #ffffff;
  background-color: #4E5079;
  border-color: #4E5079; }
  .alert-gray-800 hr {
    border-top-color: #444669; }
  .alert-gray-800 .alert-link {
    color: white; }

.alert-facebook {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999; }
  .alert-facebook hr {
    border-top-color: #344e87; }
  .alert-facebook .alert-link {
    color: white; }

.alert-twitter {
  color: #ffffff;
  background-color: #1da1f2;
  border-color: #1da1f2; }
  .alert-twitter hr {
    border-top-color: #0d95e8; }
  .alert-twitter .alert-link {
    color: white; }

.alert-google {
  color: #ffffff;
  background-color: #DB4337;
  border-color: #DB4337; }
  .alert-google hr {
    border-top-color: #d33326; }
  .alert-google .alert-link {
    color: white; }

.alert-instagram {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f; }
  .alert-instagram hr {
    border-top-color: #e12a4c; }
  .alert-instagram .alert-link {
    color: white; }

.alert-pinterest {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c; }
  .alert-pinterest hr {
    border-top-color: #a50718; }
  .alert-pinterest .alert-link {
    color: white; }

.alert-youtube {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f; }
  .alert-youtube hr {
    border-top-color: #b71d1c; }
  .alert-youtube .alert-link {
    color: white; }

.alert-slack {
  color: #ffffff;
  background-color: #3aaf85;
  border-color: #3aaf85; }
  .alert-slack hr {
    border-top-color: #349c76; }
  .alert-slack .alert-link {
    color: white; }

.alert-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89; }
  .alert-dribbble hr {
    border-top-color: #e7357a; }
  .alert-dribbble .alert-link {
    color: white; }

.alert-dropbox {
  color: #ffffff;
  background-color: #1E90FF;
  border-color: #1E90FF; }
  .alert-dropbox hr {
    border-top-color: #0583ff; }
  .alert-dropbox .alert-link {
    color: white; }

.alert-twitch {
  color: #ffffff;
  background-color: #4B367C;
  border-color: #4B367C; }
  .alert-twitch hr {
    border-top-color: #402e6a; }
  .alert-twitch .alert-link {
    color: white; }

.alert-paypal {
  color: #0d1b48;
  background-color: #ecb32c;
  border-color: #ecb32c; }
  .alert-paypal hr {
    border-top-color: #e9aa15; }
  .alert-paypal .alert-link {
    color: white; }

.alert-behance {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff; }
  .alert-behance hr {
    border-top-color: #004ee6; }
  .alert-behance .alert-link {
    color: white; }

.alert-reddit {
  color: #ffffff;
  background-color: #E84422;
  border-color: #E84422; }
  .alert-reddit hr {
    border-top-color: #da3817; }
  .alert-reddit .alert-link {
    color: white; }

.alert-github {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222; }
  .alert-github hr {
    border-top-color: #151515; }
  .alert-github .alert-link {
    color: white; }

/**
 * = Avatars
 */
.avatar + .avatar-content {
  display: inline-block;
  margin-left: .75rem; }

.avatar-link img {
  width: 4rem;
  height: 4rem; }

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #ffffff; }
  .avatar-group .avatar:hover {
    z-index: 3; }

.avatar-group .avatar + .avatar {
  margin-left: -1rem; }

/**
 * = Badges
 */
.badge {
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 600; }
  .badge a {
    color: #ffffff; }

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em; }
  .badge-pill.small {
    font-size: .4rem;
    line-height: 1.2; }
  .badge-pill.map-badge {
    font-size: .6rem;
    line-height: 1.4; }

.badge-inline {
  margin-right: .625rem; }
  .badge-inline + span {
    top: 2px;
    position: relative; }
    .badge-inline + span > a {
      text-decoration: underline; }

.badge-md {
  padding: .4rem .55rem; }

.badge-lg {
  padding: .65rem .85rem; }

.wi-tags a {
  display: inline-block;
  padding: 0.125rem 0.875rem;
  margin: 0.25rem;
  line-height: 2;
  font-size: 0.875rem;
  background-color: #ffffff;
  border: 0.0625rem solid #edf0f7;
  border-radius: 0.45rem; }
  .wi-tags a:hover {
    background-color: #0d1b48;
    color: #ffffff; }

.pixel-pro-badge,
.pixel-pro-badge-md {
  position: relative;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 500;
  right: -20px;
  padding: 5px 14px;
  top: -46px;
  background: #ffffff;
  border-radius: 0.45rem;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  @media (max-width: 767.98px) {
    .pixel-pro-badge,
    .pixel-pro-badge-md {
      font-size: 1rem;
      right: -13px;
      padding: 5px 10px;
      top: -23px; } }
  .pixel-pro-badge.subscription-badge,
  .pixel-pro-badge-md.subscription-badge {
    top: -19px;
    right: -12px;
    font-size: 0.75rem; }

.pixel-pro-badge-md {
  font-size: 1.25rem;
  padding: 5px 7px; }

/**
 * = Buttons
 */
.btn {
  position: relative;
  transition: all 0.2s ease;
  font-size: 1rem;
  border-radius: 0.45rem; }
  .btn-group .btn, .input-group .btn {
    margin-right: 0;
    transform: translateY(0); }
  .btn .toggle-arrow {
    transition: all 0.2s ease; }
  .btn[aria-expanded="true"] .toggle-arrow {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .btn.btn-pill {
    border-radius: 0.85rem; }

.btn-xs, .btn-xs span {
  font-size: 0.75rem; }

.btn-sm, .btn-group-sm > .btn, .btn-sm span, .btn-group-sm > .btn span {
  font-size: 0.875rem; }

.btn-lg, .btn-group-lg > .btn, .btn-lg span, .btn-group-lg > .btn span {
  font-size: 1rem; }

.btn-icon-only {
  width: 2.575rem;
  height: 2.575rem;
  padding: 0; }
  .btn-icon-onlya {
    line-height: 2.5; }
  .btn-icon-only.btn-xs {
    width: 1.7rem;
    height: 1.7rem; }
  .btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn {
    width: 2rem;
    height: 2rem; }

.spinner-border,
.spinner-brow {
  vertical-align: middle; }

.btn-loading-overlay .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0; }

.btn-loading-overlay .btn-inner-text,
.btn-loading-overlay .spinner {
  transition: all 0.2s ease; }

.btn-loading-overlay .btn-inner-text {
  opacity: 1; }

.btn-loading-overlay.btn-loading .spinner {
  opacity: 1; }

.btn-loading-overlay.btn-loading .btn-inner-text {
  opacity: 0; }

.btn-primary {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #02050e;
    border-color: #02050e; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #02050e;
    border-color: #02050e;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #050b1d;
    border-color: #030712; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #C33C54;
  border-color: #C33C54;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #8e2c3d;
    border-color: #8e2c3d; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #8e2c3d;
    border-color: #8e2c3d;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(204, 89, 110, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #C33C54;
    border-color: #C33C54; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #9c3043;
    border-color: #922d3f; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(204, 89, 110, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #208163;
  border-color: #208163;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-success:hover {
    color: #ffffff;
    background-color: #124a39;
    border-color: #124a39; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #124a39;
    border-color: #124a39;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(65, 148, 122, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #208163;
    border-color: #208163; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #165844;
    border-color: #134e3c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(65, 148, 122, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #0056B3;
  border-color: #0056B3;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-info:hover {
    color: #ffffff;
    background-color: #00356e;
    border-color: #00356e; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #00356e;
    border-color: #00356e;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(38, 111, 190, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #0056B3;
    border-color: #0056B3; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #003d80;
    border-color: #003773; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 111, 190, 0.5); }

.btn-warning {
  color: #0d1b48;
  background-color: #F0B400;
  border-color: #F0B400;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #ab8000;
    border-color: #ab8000; }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #ab8000;
    border-color: #ab8000;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(206, 157, 11, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #0d1b48;
    background-color: #F0B400;
    border-color: #F0B400; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #bd8e00;
    border-color: #b08400; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(206, 157, 11, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #be2837;
  border-color: #be2837;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #851c27;
    border-color: #851c27; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #851c27;
    border-color: #851c27;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(200, 72, 85, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #be2837;
    border-color: #be2837; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #941f2b;
    border-color: #891d28; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(200, 72, 85, 0.5); }

.btn-light {
  color: #0d1b48;
  background-color: #edf0f7;
  border-color: #edf0f7;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-light:hover {
    color: #0d1b48;
    background-color: #bdc8e2;
    border-color: #bdc8e2; }
  .btn-light:focus, .btn-light.focus {
    color: #0d1b48;
    background-color: #bdc8e2;
    border-color: #bdc8e2;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #0d1b48;
    background-color: #cad3e7;
    border-color: #c1cbe3; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #02050e;
    border-color: #02050e; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #02050e;
    border-color: #02050e;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #050b1d;
    border-color: #030712; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }

.btn-tertiary {
  color: #ffffff;
  background-color: #26408B;
  border-color: #26408B;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-tertiary:hover {
    color: #ffffff;
    background-color: #172755;
    border-color: #172755; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    color: #ffffff;
    background-color: #172755;
    border-color: #172755;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(71, 93, 156, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #ffffff;
    background-color: #26408B;
    border-color: #26408B; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #1b2e63;
    border-color: #182959; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(71, 93, 156, 0.5); }

.btn-lighten {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-lighten:hover {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd; }
  .btn-lighten:focus, .btn-lighten.focus {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-lighten.disabled, .btn-lighten:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-lighten:not(:disabled):not(.disabled):active, .btn-lighten:not(:disabled):not(.disabled).active,
  .show > .btn-lighten.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-lighten:not(:disabled):not(.disabled):active:focus, .btn-lighten:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lighten.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-white {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-white:hover {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd; }
  .btn-white:focus, .btn-white.focus {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-gray {
  color: #ffffff;
  background-color: #424767;
  border-color: #424767;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray:hover {
    color: #ffffff;
    background-color: #272a3d;
    border-color: #272a3d; }
  .btn-gray:focus, .btn-gray.focus {
    color: #ffffff;
    background-color: #272a3d;
    border-color: #272a3d;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(94, 99, 126, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #ffffff;
    background-color: #424767;
    border-color: #424767; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #2e3248;
    border-color: #292c40; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(94, 99, 126, 0.5); }

.btn-neutral {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-neutral:hover {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd; }
  .btn-neutral:focus, .btn-neutral.focus {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-neutral.disabled, .btn-neutral:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-neutral.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-soft {
  color: #0d1b48;
  background-color: #f5f9fe;
  border-color: #f5f9fe;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-soft:hover {
    color: #0d1b48;
    background-color: #b6d3f8;
    border-color: #b6d3f8; }
  .btn-soft:focus, .btn-soft.focus {
    color: #0d1b48;
    background-color: #b6d3f8;
    border-color: #b6d3f8;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }
  .btn-soft.disabled, .btn-soft:disabled {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
  .btn-soft:not(:disabled):not(.disabled):active, .btn-soft:not(:disabled):not(.disabled).active,
  .show > .btn-soft.dropdown-toggle {
    color: #0d1b48;
    background-color: #c7ddf9;
    border-color: #bbd6f8; }
    .btn-soft:not(:disabled):not(.disabled):active:focus, .btn-soft:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soft.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }

.btn-black {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #0d1b48;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-black:hover {
    color: #ffffff;
    background-color: #02050e;
    border-color: #02050e; }
  .btn-black:focus, .btn-black.focus {
    color: #ffffff;
    background-color: #02050e;
    border-color: #02050e;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #ffffff;
    background-color: #0d1b48;
    border-color: #0d1b48; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #ffffff;
    background-color: #050b1d;
    border-color: #030712; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 61, 99, 0.5); }

.btn-purple {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-purple:hover {
    color: #ffffff;
    background-color: #512f8f;
    border-color: #512f8f; }
  .btn-purple:focus, .btn-purple.focus {
    color: #ffffff;
    background-color: #512f8f;
    border-color: #512f8f;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(133, 94, 202, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #59339d;
    border-color: #533093; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(133, 94, 202, 0.5); }

.btn-soft-green {
  color: #0d1b48;
  background-color: #ebf9ee;
  border-color: #ebf9ee;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-soft-green:hover {
    color: #0d1b48;
    background-color: #b6e9c1;
    border-color: #b6e9c1; }
  .btn-soft-green:focus, .btn-soft-green.focus {
    color: #0d1b48;
    background-color: #b6e9c1;
    border-color: #b6e9c1;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(202, 216, 213, 0.5); }
  .btn-soft-green.disabled, .btn-soft-green:disabled {
    color: #0d1b48;
    background-color: #ebf9ee;
    border-color: #ebf9ee; }
  .btn-soft-green:not(:disabled):not(.disabled):active, .btn-soft-green:not(:disabled):not(.disabled).active,
  .show > .btn-soft-green.dropdown-toggle {
    color: #0d1b48;
    background-color: #c4edcd;
    border-color: #baeac4; }
    .btn-soft-green:not(:disabled):not(.disabled):active:focus, .btn-soft-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soft-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(202, 216, 213, 0.5); }

.btn-gray-100 {
  color: #0d1b48;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-100:hover {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    color: #0d1b48;
    background-color: #dddddd;
    border-color: #dddddd;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #0d1b48;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #0d1b48;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(219, 221, 228, 0.5); }

.btn-gray-200 {
  color: #0d1b48;
  background-color: #fafbfe;
  border-color: #fafbfe;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-200:hover {
    color: #0d1b48;
    background-color: #c1cdf3;
    border-color: #c1cdf3; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    color: #0d1b48;
    background-color: #c1cdf3;
    border-color: #c1cdf3;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(214, 217, 227, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #0d1b48;
    background-color: #fafbfe;
    border-color: #fafbfe; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #0d1b48;
    background-color: #d0d9f6;
    border-color: #c5d1f3; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(214, 217, 227, 0.5); }

.btn-gray-300 {
  color: #0d1b48;
  background-color: #f5f9fe;
  border-color: #f5f9fe;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-300:hover {
    color: #0d1b48;
    background-color: #b6d3f8;
    border-color: #b6d3f8; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    color: #0d1b48;
    background-color: #b6d3f8;
    border-color: #b6d3f8;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #0d1b48;
    background-color: #f5f9fe;
    border-color: #f5f9fe; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #0d1b48;
    background-color: #c7ddf9;
    border-color: #bbd6f8; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(210, 216, 227, 0.5); }

.btn-gray-400 {
  color: #0d1b48;
  background-color: #edf0f7;
  border-color: #edf0f7;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-400:hover {
    color: #0d1b48;
    background-color: #bdc8e2;
    border-color: #bdc8e2; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    color: #0d1b48;
    background-color: #bdc8e2;
    border-color: #bdc8e2;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #0d1b48;
    background-color: #edf0f7;
    border-color: #edf0f7; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #0d1b48;
    background-color: #cad3e7;
    border-color: #c1cbe3; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 208, 221, 0.5); }

.btn-gray-500 {
  color: #0d1b48;
  background-color: #B7C3D2;
  border-color: #B7C3D2;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-500:hover {
    color: #0d1b48;
    background-color: #8da0b8;
    border-color: #8da0b8; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    color: #0d1b48;
    background-color: #8da0b8;
    border-color: #8da0b8;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(158, 170, 189, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #0d1b48;
    background-color: #B7C3D2;
    border-color: #B7C3D2; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #0d1b48;
    background-color: #98a9be;
    border-color: #90a2b9; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(158, 170, 189, 0.5); }

.btn-gray-600 {
  color: #0d1b48;
  background-color: #93a5be;
  border-color: #93a5be;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-600:hover {
    color: #ffffff;
    background-color: #6881a4;
    border-color: #6881a4; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    color: #ffffff;
    background-color: #6881a4;
    border-color: #6881a4;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(127, 144, 172, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #0d1b48;
    background-color: #93a5be;
    border-color: #93a5be; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #ffffff;
    background-color: #738aab;
    border-color: #6b84a6; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(127, 144, 172, 0.5); }

.btn-gray-700 {
  color: #ffffff;
  background-color: #52547a;
  border-color: #52547a;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-700:hover {
    color: #ffffff;
    background-color: #363851;
    border-color: #363851; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    color: #ffffff;
    background-color: #363851;
    border-color: #363851;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(108, 110, 142, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #ffffff;
    background-color: #52547a;
    border-color: #52547a; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #ffffff;
    background-color: #3e3f5c;
    border-color: #383a54; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 110, 142, 0.5); }

.btn-gray-800 {
  color: #ffffff;
  background-color: #4E5079;
  border-color: #4E5079;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-gray-800:hover {
    color: #ffffff;
    background-color: #33344f;
    border-color: #33344f; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    color: #ffffff;
    background-color: #33344f;
    border-color: #33344f;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(105, 106, 141, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #ffffff;
    background-color: #4E5079;
    border-color: #4E5079; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #ffffff;
    background-color: #3a3b5a;
    border-color: #353652; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(105, 106, 141, 0.5); }

.btn-facebook {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-facebook:hover {
    color: #ffffff;
    background-color: #283c67;
    border-color: #283c67; }
  .btn-facebook:focus, .btn-facebook.focus {
    color: #ffffff;
    background-color: #283c67;
    border-color: #283c67;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(88, 114, 168, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #ffffff;
    background-color: #3b5999;
    border-color: #3b5999; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #ffffff;
    background-color: #2d4474;
    border-color: #293e6b; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(88, 114, 168, 0.5); }

.btn-twitter {
  color: #ffffff;
  background-color: #1da1f2;
  border-color: #1da1f2;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-twitter:hover {
    color: #ffffff;
    background-color: #0b7bbf;
    border-color: #0b7bbf; }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #ffffff;
    background-color: #0b7bbf;
    border-color: #0b7bbf;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(63, 175, 244, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #ffffff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(63, 175, 244, 0.5); }

.btn-google {
  color: #ffffff;
  background-color: #DB4337;
  border-color: #DB4337;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-google:hover {
    color: #ffffff;
    background-color: #ae2a1f;
    border-color: #ae2a1f; }
  .btn-google:focus, .btn-google.focus {
    color: #ffffff;
    background-color: #ae2a1f;
    border-color: #ae2a1f;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(224, 95, 85, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    color: #ffffff;
    background-color: #DB4337;
    border-color: #DB4337; }
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #ffffff;
    background-color: #bd2d22;
    border-color: #b22b20; }
    .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(224, 95, 85, 0.5); }

.btn-instagram {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-instagram:hover {
    color: #ffffff;
    background-color: #c41c3b;
    border-color: #c41c3b; }
  .btn-instagram:focus, .btn-instagram.focus {
    color: #ffffff;
    background-color: #c41c3b;
    border-color: #c41c3b;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(232, 93, 119, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #ffffff;
    background-color: #d31e40;
    border-color: #c81c3d; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(232, 93, 119, 0.5); }

.btn-pinterest {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-pinterest:hover {
    color: #ffffff;
    background-color: #7b0512;
    border-color: #7b0512; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    color: #ffffff;
    background-color: #7b0512;
    border-color: #7b0512;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(199, 45, 62, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #ffffff;
    background-color: #8c0615;
    border-color: #800513; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(199, 45, 62, 0.5); }

.btn-youtube {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-youtube:hover {
    color: #ffffff;
    background-color: #911716;
    border-color: #911716; }
  .btn-youtube:focus, .btn-youtube.focus {
    color: #ffffff;
    background-color: #911716;
    border-color: #911716;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(213, 65, 65, 0.5); }
  .btn-youtube.disabled, .btn-youtube:disabled {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-youtube.dropdown-toggle {
    color: #ffffff;
    background-color: #a11918;
    border-color: #961717; }
    .btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(213, 65, 65, 0.5); }

.btn-slack {
  color: #ffffff;
  background-color: #3aaf85;
  border-color: #3aaf85;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-slack:hover {
    color: #ffffff;
    background-color: #297b5e;
    border-color: #297b5e; }
  .btn-slack:focus, .btn-slack.focus {
    color: #ffffff;
    background-color: #297b5e;
    border-color: #297b5e;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(88, 187, 151, 0.5); }
  .btn-slack.disabled, .btn-slack:disabled {
    color: #ffffff;
    background-color: #3aaf85;
    border-color: #3aaf85; }
  .btn-slack:not(:disabled):not(.disabled):active, .btn-slack:not(:disabled):not(.disabled).active,
  .show > .btn-slack.dropdown-toggle {
    color: #ffffff;
    background-color: #2d8968;
    border-color: #2a7f61; }
    .btn-slack:not(:disabled):not(.disabled):active:focus, .btn-slack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(88, 187, 151, 0.5); }

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dribbble:hover {
    color: #ffffff;
    background-color: #d81963;
    border-color: #d81963; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    color: #ffffff;
    background-color: #d81963;
    border-color: #d81963;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(237, 103, 155, 0.5); }
  .btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #ffffff;
    background-color: #e51e6b;
    border-color: #dc1a65; }
    .btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(237, 103, 155, 0.5); }

.btn-dropbox {
  color: #ffffff;
  background-color: #1E90FF;
  border-color: #1E90FF;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dropbox:hover {
    color: #ffffff;
    background-color: #006ed8;
    border-color: #006ed8; }
  .btn-dropbox:focus, .btn-dropbox.focus {
    color: #ffffff;
    background-color: #006ed8;
    border-color: #006ed8;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(64, 161, 255, 0.5); }
  .btn-dropbox.disabled, .btn-dropbox:disabled {
    color: #ffffff;
    background-color: #1E90FF;
    border-color: #1E90FF; }
  .btn-dropbox:not(:disabled):not(.disabled):active, .btn-dropbox:not(:disabled):not(.disabled).active,
  .show > .btn-dropbox.dropdown-toggle {
    color: #ffffff;
    background-color: #0077ea;
    border-color: #0070dd; }
    .btn-dropbox:not(:disabled):not(.disabled):active:focus, .btn-dropbox:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dropbox.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(64, 161, 255, 0.5); }

.btn-twitch {
  color: #ffffff;
  background-color: #4B367C;
  border-color: #4B367C;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-twitch:hover {
    color: #ffffff;
    background-color: #2e214c;
    border-color: #2e214c; }
  .btn-twitch:focus, .btn-twitch.focus {
    color: #ffffff;
    background-color: #2e214c;
    border-color: #2e214c;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(102, 84, 144, 0.5); }
  .btn-twitch.disabled, .btn-twitch:disabled {
    color: #ffffff;
    background-color: #4B367C;
    border-color: #4B367C; }
  .btn-twitch:not(:disabled):not(.disabled):active, .btn-twitch:not(:disabled):not(.disabled).active,
  .show > .btn-twitch.dropdown-toggle {
    color: #ffffff;
    background-color: #362758;
    border-color: #302350; }
    .btn-twitch:not(:disabled):not(.disabled):active:focus, .btn-twitch:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitch.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(102, 84, 144, 0.5); }

.btn-paypal {
  color: #0d1b48;
  background-color: #ecb32c;
  border-color: #ecb32c;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-paypal:hover {
    color: #ffffff;
    background-color: #c28d11;
    border-color: #c28d11; }
  .btn-paypal:focus, .btn-paypal.focus {
    color: #ffffff;
    background-color: #c28d11;
    border-color: #c28d11;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 156, 48, 0.5); }
  .btn-paypal.disabled, .btn-paypal:disabled {
    color: #0d1b48;
    background-color: #ecb32c;
    border-color: #ecb32c; }
  .btn-paypal:not(:disabled):not(.disabled):active, .btn-paypal:not(:disabled):not(.disabled).active,
  .show > .btn-paypal.dropdown-toggle {
    color: #0d1b48;
    background-color: #d29913;
    border-color: #c69112; }
    .btn-paypal:not(:disabled):not(.disabled):active:focus, .btn-paypal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-paypal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 156, 48, 0.5); }

.btn-behance {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-behance:hover {
    color: #ffffff;
    background-color: #0040ba;
    border-color: #0040ba; }
  .btn-behance:focus, .btn-behance.focus {
    color: #ffffff;
    background-color: #0040ba;
    border-color: #0040ba;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(38, 112, 255, 0.5); }
  .btn-behance.disabled, .btn-behance:disabled {
    color: #ffffff;
    background-color: #0057ff;
    border-color: #0057ff; }
  .btn-behance:not(:disabled):not(.disabled):active, .btn-behance:not(:disabled):not(.disabled).active,
  .show > .btn-behance.dropdown-toggle {
    color: #ffffff;
    background-color: #0046cc;
    border-color: #0041bf; }
    .btn-behance:not(:disabled):not(.disabled):active:focus, .btn-behance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 112, 255, 0.5); }

.btn-reddit {
  color: #ffffff;
  background-color: #E84422;
  border-color: #E84422;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-reddit:hover {
    color: #ffffff;
    background-color: #b32e13;
    border-color: #b32e13; }
  .btn-reddit:focus, .btn-reddit.focus {
    color: #ffffff;
    background-color: #b32e13;
    border-color: #b32e13;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(235, 96, 67, 0.5); }
  .btn-reddit.disabled, .btn-reddit:disabled {
    color: #ffffff;
    background-color: #E84422;
    border-color: #E84422; }
  .btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-reddit.dropdown-toggle {
    color: #ffffff;
    background-color: #c33214;
    border-color: #b72f13; }
    .btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(235, 96, 67, 0.5); }

.btn-github {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-github:hover {
    color: #ffffff;
    background-color: black;
    border-color: black; }
  .btn-github:focus, .btn-github.focus {
    color: #ffffff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(67, 67, 67, 0.5); }
  .btn-github.disabled, .btn-github:disabled {
    color: #ffffff;
    background-color: #222222;
    border-color: #222222; }
  .btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
  .show > .btn-github.dropdown-toggle {
    color: #ffffff;
    background-color: #090909;
    border-color: #020202; }
    .btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(67, 67, 67, 0.5); }

.btn-facebook {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-facebook:hover {
    color: #ffffff;
    background-color: #283c67;
    border-color: #283c67; }
  .btn-facebook:focus, .btn-facebook.focus {
    color: #ffffff;
    background-color: #283c67;
    border-color: #283c67;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(88, 114, 168, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #ffffff;
    background-color: #3b5999;
    border-color: #3b5999; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #ffffff;
    background-color: #2d4474;
    border-color: #293e6b; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(88, 114, 168, 0.5); }

.btn-twitter {
  color: #ffffff;
  background-color: #1da1f2;
  border-color: #1da1f2;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-twitter:hover {
    color: #ffffff;
    background-color: #0b7bbf;
    border-color: #0b7bbf; }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #ffffff;
    background-color: #0b7bbf;
    border-color: #0b7bbf;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(63, 175, 244, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #ffffff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(63, 175, 244, 0.5); }

.btn-google {
  color: #ffffff;
  background-color: #DB4337;
  border-color: #DB4337;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-google:hover {
    color: #ffffff;
    background-color: #ae2a1f;
    border-color: #ae2a1f; }
  .btn-google:focus, .btn-google.focus {
    color: #ffffff;
    background-color: #ae2a1f;
    border-color: #ae2a1f;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(224, 95, 85, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    color: #ffffff;
    background-color: #DB4337;
    border-color: #DB4337; }
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #ffffff;
    background-color: #bd2d22;
    border-color: #b22b20; }
    .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(224, 95, 85, 0.5); }

.btn-instagram {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-instagram:hover {
    color: #ffffff;
    background-color: #c41c3b;
    border-color: #c41c3b; }
  .btn-instagram:focus, .btn-instagram.focus {
    color: #ffffff;
    background-color: #c41c3b;
    border-color: #c41c3b;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(232, 93, 119, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #ffffff;
    background-color: #d31e40;
    border-color: #c81c3d; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(232, 93, 119, 0.5); }

.btn-pinterest {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-pinterest:hover {
    color: #ffffff;
    background-color: #7b0512;
    border-color: #7b0512; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    color: #ffffff;
    background-color: #7b0512;
    border-color: #7b0512;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(199, 45, 62, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #ffffff;
    background-color: #8c0615;
    border-color: #800513; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(199, 45, 62, 0.5); }

.btn-youtube {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-youtube:hover {
    color: #ffffff;
    background-color: #911716;
    border-color: #911716; }
  .btn-youtube:focus, .btn-youtube.focus {
    color: #ffffff;
    background-color: #911716;
    border-color: #911716;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(213, 65, 65, 0.5); }
  .btn-youtube.disabled, .btn-youtube:disabled {
    color: #ffffff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-youtube.dropdown-toggle {
    color: #ffffff;
    background-color: #a11918;
    border-color: #961717; }
    .btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(213, 65, 65, 0.5); }

.btn-slack {
  color: #ffffff;
  background-color: #3aaf85;
  border-color: #3aaf85;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-slack:hover {
    color: #ffffff;
    background-color: #297b5e;
    border-color: #297b5e; }
  .btn-slack:focus, .btn-slack.focus {
    color: #ffffff;
    background-color: #297b5e;
    border-color: #297b5e;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(88, 187, 151, 0.5); }
  .btn-slack.disabled, .btn-slack:disabled {
    color: #ffffff;
    background-color: #3aaf85;
    border-color: #3aaf85; }
  .btn-slack:not(:disabled):not(.disabled):active, .btn-slack:not(:disabled):not(.disabled).active,
  .show > .btn-slack.dropdown-toggle {
    color: #ffffff;
    background-color: #2d8968;
    border-color: #2a7f61; }
    .btn-slack:not(:disabled):not(.disabled):active:focus, .btn-slack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(88, 187, 151, 0.5); }

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dribbble:hover {
    color: #ffffff;
    background-color: #d81963;
    border-color: #d81963; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    color: #ffffff;
    background-color: #d81963;
    border-color: #d81963;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(237, 103, 155, 0.5); }
  .btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #ffffff;
    background-color: #e51e6b;
    border-color: #dc1a65; }
    .btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(237, 103, 155, 0.5); }

.btn-dropbox {
  color: #ffffff;
  background-color: #1E90FF;
  border-color: #1E90FF;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-dropbox:hover {
    color: #ffffff;
    background-color: #006ed8;
    border-color: #006ed8; }
  .btn-dropbox:focus, .btn-dropbox.focus {
    color: #ffffff;
    background-color: #006ed8;
    border-color: #006ed8;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(64, 161, 255, 0.5); }
  .btn-dropbox.disabled, .btn-dropbox:disabled {
    color: #ffffff;
    background-color: #1E90FF;
    border-color: #1E90FF; }
  .btn-dropbox:not(:disabled):not(.disabled):active, .btn-dropbox:not(:disabled):not(.disabled).active,
  .show > .btn-dropbox.dropdown-toggle {
    color: #ffffff;
    background-color: #0077ea;
    border-color: #0070dd; }
    .btn-dropbox:not(:disabled):not(.disabled):active:focus, .btn-dropbox:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dropbox.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(64, 161, 255, 0.5); }

.btn-twitch {
  color: #ffffff;
  background-color: #4B367C;
  border-color: #4B367C;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-twitch:hover {
    color: #ffffff;
    background-color: #2e214c;
    border-color: #2e214c; }
  .btn-twitch:focus, .btn-twitch.focus {
    color: #ffffff;
    background-color: #2e214c;
    border-color: #2e214c;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(102, 84, 144, 0.5); }
  .btn-twitch.disabled, .btn-twitch:disabled {
    color: #ffffff;
    background-color: #4B367C;
    border-color: #4B367C; }
  .btn-twitch:not(:disabled):not(.disabled):active, .btn-twitch:not(:disabled):not(.disabled).active,
  .show > .btn-twitch.dropdown-toggle {
    color: #ffffff;
    background-color: #362758;
    border-color: #302350; }
    .btn-twitch:not(:disabled):not(.disabled):active:focus, .btn-twitch:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitch.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(102, 84, 144, 0.5); }

.btn-paypal {
  color: #0d1b48;
  background-color: #ecb32c;
  border-color: #ecb32c;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-paypal:hover {
    color: #ffffff;
    background-color: #c28d11;
    border-color: #c28d11; }
  .btn-paypal:focus, .btn-paypal.focus {
    color: #ffffff;
    background-color: #c28d11;
    border-color: #c28d11;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(203, 156, 48, 0.5); }
  .btn-paypal.disabled, .btn-paypal:disabled {
    color: #0d1b48;
    background-color: #ecb32c;
    border-color: #ecb32c; }
  .btn-paypal:not(:disabled):not(.disabled):active, .btn-paypal:not(:disabled):not(.disabled).active,
  .show > .btn-paypal.dropdown-toggle {
    color: #0d1b48;
    background-color: #d29913;
    border-color: #c69112; }
    .btn-paypal:not(:disabled):not(.disabled):active:focus, .btn-paypal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-paypal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 156, 48, 0.5); }

.btn-behance {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-behance:hover {
    color: #ffffff;
    background-color: #0040ba;
    border-color: #0040ba; }
  .btn-behance:focus, .btn-behance.focus {
    color: #ffffff;
    background-color: #0040ba;
    border-color: #0040ba;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(38, 112, 255, 0.5); }
  .btn-behance.disabled, .btn-behance:disabled {
    color: #ffffff;
    background-color: #0057ff;
    border-color: #0057ff; }
  .btn-behance:not(:disabled):not(.disabled):active, .btn-behance:not(:disabled):not(.disabled).active,
  .show > .btn-behance.dropdown-toggle {
    color: #ffffff;
    background-color: #0046cc;
    border-color: #0041bf; }
    .btn-behance:not(:disabled):not(.disabled):active:focus, .btn-behance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 112, 255, 0.5); }

.btn-reddit {
  color: #ffffff;
  background-color: #E84422;
  border-color: #E84422;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-reddit:hover {
    color: #ffffff;
    background-color: #b32e13;
    border-color: #b32e13; }
  .btn-reddit:focus, .btn-reddit.focus {
    color: #ffffff;
    background-color: #b32e13;
    border-color: #b32e13;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(235, 96, 67, 0.5); }
  .btn-reddit.disabled, .btn-reddit:disabled {
    color: #ffffff;
    background-color: #E84422;
    border-color: #E84422; }
  .btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-reddit.dropdown-toggle {
    color: #ffffff;
    background-color: #c33214;
    border-color: #b72f13; }
    .btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(235, 96, 67, 0.5); }

.btn-github {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }
  .btn-github:hover {
    color: #ffffff;
    background-color: black;
    border-color: black; }
  .btn-github:focus, .btn-github.focus {
    color: #ffffff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06), 0 0 0 0.25rem rgba(67, 67, 67, 0.5); }
  .btn-github.disabled, .btn-github:disabled {
    color: #ffffff;
    background-color: #222222;
    border-color: #222222; }
  .btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
  .show > .btn-github.dropdown-toggle {
    color: #ffffff;
    background-color: #090909;
    border-color: #020202; }
    .btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(67, 67, 67, 0.5); }

/**
 * = Breadcrumbs
 */
.breadcrumb-transparent {
  background: transparent;
  padding: 0; }

.breadcrumb-primary {
  background: #0d1b48; }
  .breadcrumb-primary .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-primary.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-primary.breadcrumb-transparent .breadcrumb-item.active {
      color: #0d1b48; }

.breadcrumb-secondary {
  background: #C33C54; }
  .breadcrumb-secondary .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-secondary.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-secondary.breadcrumb-transparent .breadcrumb-item.active {
      color: #C33C54; }

.breadcrumb-success {
  background: #208163; }
  .breadcrumb-success .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-success.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-success.breadcrumb-transparent .breadcrumb-item.active {
      color: #208163; }

.breadcrumb-info {
  background: #0056B3; }
  .breadcrumb-info .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-info.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-info.breadcrumb-transparent .breadcrumb-item.active {
      color: #0056B3; }

.breadcrumb-warning {
  background: #F0B400; }
  .breadcrumb-warning .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-warning.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-warning.breadcrumb-transparent .breadcrumb-item.active {
      color: #F0B400; }

.breadcrumb-danger {
  background: #be2837; }
  .breadcrumb-danger .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-danger.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-danger.breadcrumb-transparent .breadcrumb-item.active {
      color: #be2837; }

.breadcrumb-light {
  background: #edf0f7; }
  .breadcrumb-light .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-light.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-light.breadcrumb-transparent .breadcrumb-item.active {
      color: #edf0f7; }

.breadcrumb-dark {
  background: #0d1b48; }
  .breadcrumb-dark .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-dark.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-dark.breadcrumb-transparent .breadcrumb-item.active {
      color: #0d1b48; }

.breadcrumb-tertiary {
  background: #26408B; }
  .breadcrumb-tertiary .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-tertiary.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-tertiary.breadcrumb-transparent .breadcrumb-item.active {
      color: #26408B; }

.breadcrumb-lighten {
  background: #ffffff; }
  .breadcrumb-lighten .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-lighten.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-lighten.breadcrumb-transparent .breadcrumb-item.active {
      color: #ffffff; }

.breadcrumb-white {
  background: #ffffff; }
  .breadcrumb-white .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-white.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-white.breadcrumb-transparent .breadcrumb-item.active {
      color: #ffffff; }

.breadcrumb-gray {
  background: #424767; }
  .breadcrumb-gray .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray.breadcrumb-transparent .breadcrumb-item.active {
      color: #424767; }

.breadcrumb-neutral {
  background: #ffffff; }
  .breadcrumb-neutral .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-neutral.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-neutral.breadcrumb-transparent .breadcrumb-item.active {
      color: #ffffff; }

.breadcrumb-soft {
  background: #f5f9fe; }
  .breadcrumb-soft .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-soft.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-soft.breadcrumb-transparent .breadcrumb-item.active {
      color: #f5f9fe; }

.breadcrumb-black {
  background: #0d1b48; }
  .breadcrumb-black .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-black.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-black.breadcrumb-transparent .breadcrumb-item.active {
      color: #0d1b48; }

.breadcrumb-purple {
  background: #6f42c1; }
  .breadcrumb-purple .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-purple.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-purple.breadcrumb-transparent .breadcrumb-item.active {
      color: #6f42c1; }

.breadcrumb-soft-green {
  background: #ebf9ee; }
  .breadcrumb-soft-green .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-soft-green.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-soft-green.breadcrumb-transparent .breadcrumb-item.active {
      color: #ebf9ee; }

.breadcrumb-gray-100 {
  background: #ffffff; }
  .breadcrumb-gray-100 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-100.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-100.breadcrumb-transparent .breadcrumb-item.active {
      color: #ffffff; }

.breadcrumb-gray-200 {
  background: #fafbfe; }
  .breadcrumb-gray-200 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-200.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-200.breadcrumb-transparent .breadcrumb-item.active {
      color: #fafbfe; }

.breadcrumb-gray-300 {
  background: #f5f9fe; }
  .breadcrumb-gray-300 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-300.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-300.breadcrumb-transparent .breadcrumb-item.active {
      color: #f5f9fe; }

.breadcrumb-gray-400 {
  background: #edf0f7; }
  .breadcrumb-gray-400 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-400.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-400.breadcrumb-transparent .breadcrumb-item.active {
      color: #edf0f7; }

.breadcrumb-gray-500 {
  background: #B7C3D2; }
  .breadcrumb-gray-500 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-500.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-500.breadcrumb-transparent .breadcrumb-item.active {
      color: #B7C3D2; }

.breadcrumb-gray-600 {
  background: #93a5be; }
  .breadcrumb-gray-600 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-600.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-600.breadcrumb-transparent .breadcrumb-item.active {
      color: #93a5be; }

.breadcrumb-gray-700 {
  background: #52547a; }
  .breadcrumb-gray-700 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-700.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-700.breadcrumb-transparent .breadcrumb-item.active {
      color: #52547a; }

.breadcrumb-gray-800 {
  background: #4E5079; }
  .breadcrumb-gray-800 .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-gray-800.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-gray-800.breadcrumb-transparent .breadcrumb-item.active {
      color: #4E5079; }

.breadcrumb-facebook {
  background: #3b5999; }
  .breadcrumb-facebook .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-facebook.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-facebook.breadcrumb-transparent .breadcrumb-item.active {
      color: #3b5999; }

.breadcrumb-twitter {
  background: #1da1f2; }
  .breadcrumb-twitter .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-twitter.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-twitter.breadcrumb-transparent .breadcrumb-item.active {
      color: #1da1f2; }

.breadcrumb-google {
  background: #DB4337; }
  .breadcrumb-google .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-google.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-google.breadcrumb-transparent .breadcrumb-item.active {
      color: #DB4337; }

.breadcrumb-instagram {
  background: #e4405f; }
  .breadcrumb-instagram .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-instagram.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-instagram.breadcrumb-transparent .breadcrumb-item.active {
      color: #e4405f; }

.breadcrumb-pinterest {
  background: #bd081c; }
  .breadcrumb-pinterest .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-pinterest.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-pinterest.breadcrumb-transparent .breadcrumb-item.active {
      color: #bd081c; }

.breadcrumb-youtube {
  background: #cd201f; }
  .breadcrumb-youtube .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-youtube.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-youtube.breadcrumb-transparent .breadcrumb-item.active {
      color: #cd201f; }

.breadcrumb-slack {
  background: #3aaf85; }
  .breadcrumb-slack .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-slack.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-slack.breadcrumb-transparent .breadcrumb-item.active {
      color: #3aaf85; }

.breadcrumb-dribbble {
  background: #ea4c89; }
  .breadcrumb-dribbble .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-dribbble.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-dribbble.breadcrumb-transparent .breadcrumb-item.active {
      color: #ea4c89; }

.breadcrumb-dropbox {
  background: #1E90FF; }
  .breadcrumb-dropbox .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-dropbox.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-dropbox.breadcrumb-transparent .breadcrumb-item.active {
      color: #1E90FF; }

.breadcrumb-twitch {
  background: #4B367C; }
  .breadcrumb-twitch .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-twitch.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-twitch.breadcrumb-transparent .breadcrumb-item.active {
      color: #4B367C; }

.breadcrumb-paypal {
  background: #ecb32c; }
  .breadcrumb-paypal .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-paypal.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-paypal.breadcrumb-transparent .breadcrumb-item.active {
      color: #ecb32c; }

.breadcrumb-behance {
  background: #0057ff; }
  .breadcrumb-behance .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-behance.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-behance.breadcrumb-transparent .breadcrumb-item.active {
      color: #0057ff; }

.breadcrumb-reddit {
  background: #E84422; }
  .breadcrumb-reddit .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-reddit.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-reddit.breadcrumb-transparent .breadcrumb-item.active {
      color: #E84422; }

.breadcrumb-github {
  background: #222222; }
  .breadcrumb-github .breadcrumb-item.active {
    color: #ffffff; }
  .breadcrumb-github.breadcrumb-transparent {
    background: transparent; }
    .breadcrumb-github.breadcrumb-transparent .breadcrumb-item.active {
      color: #222222; }

.breadcrumb-text-light .breadcrumb-item, .breadcrumb-text-light .breadcrumb-item a {
  color: #ffffff; }

.breadcrumb-text-light .breadcrumb-item:before {
  color: #ffffff; }

/**
 * = Cards
 */
.card-img,
.card-img-top {
  border-radius: 0; }

.card-img,
.card-img-bottom {
  border-radius: 0; }

.card .card-blockquote {
  position: relative;
  padding: 2rem; }
  .card .card-blockquote .svg-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 95px;
    top: -94px;
    left: 0; }

.card .profile-thumbnail {
  width: 10rem;
  height: 10rem; }
  .card .profile-thumbnail img {
    border: 0.125rem solid; }
  .card .profile-thumbnail.small-thumbnail {
    width: 8rem;
    height: 8rem; }

.card .profile-cover {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: 175px; }

.card .card-footer .btn-block {
  border-radius: 0; }

.card .price-list .list-group-item span {
  width: 23px;
  font-size: 0.875rem; }

.card.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.card.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.card-lift-hover:hover {
  transform: translateY(-20px);
  transition: all 0.2s ease; }
  @media (prefers-reduced-motion: reduce) {
    .card-lift-hover:hover {
      transition: none; } }

a.card:hover {
  background-color: #f5f9fe; }

a.no-hover:hover {
  background: none; }

/**
 * = Profile cards
*/
.rotating-card .card-rotate {
  background: transparent;
  box-shadow: none; }
  .rotating-card .card-rotate:after {
    display: none; }

.rotating-card .card {
  -webkit-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -moz-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -o-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -ms-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative; }
  .rotating-card .card .back, .rotating-card .card .front {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    border-radius: 0.45rem;
    top: 0;
    left: 0; }
    .rotating-card .card .back .card-body, .rotating-card .card .front .card-body {
      justify-content: center;
      align-content: center;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .rotating-card .card .back .card-body .card-subtitle, .rotating-card .card .front .card-body .card-subtitle {
        color: #0d1b48; }
      .rotating-card .card .back .card-body .social-buttons, .rotating-card .card .front .card-body .social-buttons {
        margin-bottom: 0;
        padding: 0;
        list-style: none; }
        .rotating-card .card .back .card-body .social-buttons li, .rotating-card .card .front .card-body .social-buttons li {
          display: inline-block; }
          .rotating-card .card .back .card-body .social-buttons li a.btn, .rotating-card .card .front .card-body .social-buttons li a.btn {
            padding: 0.25rem 0.375rem; }
  .rotating-card .card .front {
    z-index: 2;
    position: relative; }
    .rotating-card .card .front .profile-image {
      width: 9rem;
      height: 9rem;
      margin: 0 auto; }
      .rotating-card .card .front .profile-image img {
        border-width: 0.125rem;
        border-style: solid;
        padding: .25rem; }
  .rotating-card .card .back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    z-index: 5;
    text-align: center;
    width: 100%;
    height: 100%; }
    .rotating-card .card .back.back-background:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.56);
      border-radius: 0.45rem; }
    .rotating-card .card .back.back-background .card-body {
      position: relative;
      z-index: 2; }
    .rotating-card .card .back .card-footer .btn {
      margin: 0; }
    .rotating-card .card .back .card-body {
      padding-left: 15px;
      padding-right: 15px; }

.rotating-card:not(.manual-flip):hover .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.rotating-card.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.card-profile .rotating-card .front {
  text-align: left; }

.back-background .card-body {
  min-height: auto;
  padding-top: 15px;
  padding-bottom: 15px; }

.back-background,
.front-background {
  background-position: center center;
  background-size: cover; }

/*       Fix bug for IE      */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rotating-card-container .card .front,
  .rotating-card-container .card .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible; }
  .rotating-card-container .card .back {
    visibility: hidden;
    transition: visibility 0.3s cubic-bezier(0.34, 1.45, 0.7, 1); }
  .rotating-card-container .card .front {
    z-index: 4; }
  .rotating-card-container:not(.manual-flip):hover .card .back,
  .rotating-card-container.manual-flip.hover .card .back {
    z-index: 5;
    visibility: visible; } }

.page-preview {
  display: block;
  position: relative; }
  .page-preview .show-on-hover {
    position: absolute;
    bottom: -25px;
    background: rgba(13, 27, 72, 0.85);
    padding: 10px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: calc(100% + 28px);
    left: -14px;
    opacity: 0;
    transition: 0.2s; }
    @media (prefers-reduced-motion: reduce) {
      .page-preview .show-on-hover {
        transition: none; } }
  .page-preview:hover .show-on-hover {
    z-index: 99;
    opacity: 1; }

/**
 * = Bootstrap carousels
 */
.carousel-caption, .carousel-caption h5 {
  color: #ffffff; }

.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #ffffff; }

.carousel-indicators .active {
  opacity: 1;
  background: #ffffff; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: auto;
  height: auto;
  font-weight: 900; }

.carousel-control-prev-icon {
  background-image: none; }
  .carousel-control-prev-icon::before {
    content: '\f060';
    font-family: "Font Awesome 5 Free";
    font-size: 2rem; }

.carousel-control-next-icon {
  background-image: none; }
  .carousel-control-next-icon:before {
    font-family: "Font Awesome 5 Free";
    content: "\f061";
    font-size: 2rem; }

.sr-only {
  width: 90px;
  height: 37px;
  padding: 4px;
  background-color: #208163; }

/**
 * = Close
 */
.close {
  transition: all 0.2s ease; }
  .close > span:not(.sr-only) {
    display: block;
    height: 1.25rem;
    width: 1.25rem;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    border-radius: 50%;
    font-size: 1.25rem;
    transition: all 0.2s ease; }
  .close:hover, .close:focus {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.9);
    outline: none; }
    .close:hover span:not(.sr-only), .close:focus span:not(.sr-only) {
      background-color: transparent; }

/**
 * = Counters
 */
.counter-alternate::after {
  content: '\f067';
  font-family: "Font Awesome 5 Free";
  position: absolute;
  font-size: 2.25rem;
  color: #0d1b48;
  font-weight: 900; }

/**
 * = Custom forms
 */
.custom-control-label:before {
  box-shadow: none;
  transition: all 0.3s ease-in-out; }

.custom-control-label span {
  position: relative;
  top: 2px; }

.custom-control-label {
  margin-bottom: 0; }

.custom-control-input:active ~ .custom-control-label::before {
  border-color: #0d1b48; }

.custom-file-label {
  background-color: #ffffff; }
  .custom-file-label::after {
    height: calc(1.5em + 1.2rem);
    background-color: #f3f3f5; }

.custom-select {
  font-size: 1rem;
  box-shadow: none; }
  .custom-select.custom-select-shadow {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    transition: box-shadow .15s ease; }
    .custom-select.custom-select-shadow:focus {
      box-shadow: 0.1rem 0.1rem 0 rgba(250, 251, 254, 0.5); }
  .custom-select:hover {
    cursor: pointer; }

.rating-star label {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  color: #edf0f7; }
  .rating-star label:before {
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: all .2s ease;
    font-size: 1.0625rem; }

.rating-star input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.rating-star > input:checked ~ label {
  color: #F0B400; }

.rating-star > label:hover {
  color: #F0B400; }

.rating-star > label:hover ~ label {
  color: #F0B400; }

/**
 * = Dropdowns
 */
.dropdown-menu {
  min-width: 12rem; }
  .dropdown-menu .dropdown-header,
  .dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    font-size: 1rem; }
  .dropdown-menu .dropdown-header {
    color: #424767;
    font-weight: 500; }
  .dropdown-menu .dropdown-item {
    color: #4E5079;
    transition: all 0.2s ease;
    font-weight: 400; }
    .dropdown-menu .dropdown-itema:hover {
      color: #424767; }
  .show .dropdown-menu {
    animation: show-dropdown .2s ease forwards; }

[data-toggle]:hover {
  cursor: pointer; }

.dropdown-toggle:after, .dropright .dropdown-toggle:after, .dropleft .dropdown-toggle:before, .dropup .dropdown-toggle:after {
  display: none; }

.dropdown-menu-sm {
  min-width: 100px;
  border: 0.45rem; }

.dropdown-menu-md {
  min-width: 180px;
  border: 0.45rem; }

.dropdown-menu-lg {
  min-width: 260px;
  border-radius: 0.45rem; }

.dropdown-menu-xl {
  min-width: 450px;
  border-radius: 0.45rem; }

/**
 * = Forms
 */
label {
  font-size: 0.875rem; }

.form-control {
  font-size: 1rem;
  border-radius: 0.45rem;
  box-shadow: none; }
  .form-control:focus {
    border-color: #B7C3D2;
    background-color: #ffffff; }
    .form-control:focus:placeholder {
      color: #4E5079; }
  .form-control:disabled {
    cursor: not-allowed; }
  .form-control:active {
    border-color: #B7C3D2; }

/* Textareas */
textarea[resize="none"] {
  resize: none !important; }

textarea[resize="both"] {
  resize: both !important; }

textarea[resize="vertical"] {
  resize: vertical !important; }

textarea[resize="horizontal"] {
  resize: horizontal !important; }

/* shadow styles */
.form-control-muted {
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: none; }
  .form-control-muted:focus {
    background-color: #ffffff; }

.form-group {
  position: relative; }
  .form-group.form-group-lg .form-control {
    font-size: 1.125rem;
    height: 3.425rem; }
  .form-group.form-group-lg .input-group-text {
    font-size: 1.125rem;
    padding: 0.6rem 1rem;
    transition: all 0.3s ease-in-out; }

.form-control-xl {
  height: calc(2.25em + 1.75rem + 0.0625rem);
  padding: 0.875rem 0.875rem;
  line-height: 1.5;
  font-size: 1.125rem;
  border-radius: 0.45rem; }

.form-check {
  padding-left: 0; }
  .form-check.form-check-lg .form-check-label {
    padding-left: 2rem;
    font-size: 1rem;
    line-height: 23px; }
  .form-check.form-check-lg .form-check-label::before,
  .form-check.form-check-lg .form-check-label::after {
    width: 21px;
    height: 21px; }
  .form-check.form-check-lg input[type="checkbox"]:checked + .form-check-label::after {
    font-size: .8rem;
    line-height: 22px; }
  .form-check.form-check-lg input[type="radio"] + .form-check-label::before,
  .form-check.form-check-lg input[type="radio"] + .form-check-label::after {
    width: 21px;
    height: 21px; }
  .form-check.form-check-lg input[type="radio"]:checked + .form-check-label::after {
    width: 9px;
    height: 9px;
    top: 9px;
    left: 6px; }

.form-check .form-check-label {
  display: inline-block;
  position: relative;
  padding-left: 1.375rem;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0;
  transition: all 0.2s ease;
  color: #424767; }
  @media (prefers-reduced-motion: reduce) {
    .form-check .form-check-label {
      transition: none; } }
  .form-check .form-check-label:hover {
    cursor: pointer; }

.radio .form-check-sign {
  padding-left: 28px; }

.form-check input[type="radio"] + .form-check-inline .form-check-label {
  padding-left: 5px;
  margin-right: 10px; }

.form-check .form-check-label::before,
.form-check .form-check-label::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  top: 0;
  background-color: #edf0f7;
  border: 1px solid #B7C3D2;
  transition: all 0.2s ease;
  border-radius: 0.85rem; }
  @media (prefers-reduced-motion: reduce) {
    .form-check .form-check-label::before,
    .form-check .form-check-label::after {
      transition: none; } }
  .form-check .form-check-label::before:hover,
  .form-check .form-check-label::after:hover {
    cursor: pointer; }

.form-check.inbox-check .form-check-label::before {
  top: -12px;
  border-radius: 3px; }

.form-check.inbox-check .form-check-label::after {
  top: -12px;
  border-radius: 3px; }

.form-check .form-check-sign-white:before {
  background-color: #ffffff; }

.form-check input[type="checkbox"]:checked + .form-check-sign::before,
.form-check input[type="checkbox"]:checked + .form-check-sign::before {
  border: none; }

.form-check .form-check-label::after {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  top: 0px;
  text-align: center;
  opacity: 0;
  color: #ffffff;
  font-weight: 900;
  border: 0; }

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
  color: #B7C3D2;
  cursor: not-allowed; }

.form-check input[type="checkbox"],
.form-check input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden; }

.form-check input[type="checkbox"]:checked + .form-check-label::after {
  background-color: #0d1b48;
  opacity: 1;
  font-size: .6rem;
  margin-top: 0;
  line-height: 1.6; }

.form-check input[type="checkbox"]:disabled + .form-check-label::after {
  color: rgba(13, 27, 72, 0.7); }

.form-check input[type="checkbox"] + .form-check-label::after {
  opacity: 0; }

.form-control input[type="checkbox"]:disabled + .form-check-label::before,
.checkbox input[type="checkbox"]:disabled + .form-check-label::after {
  cursor: not-allowed; }

.form-check input[type="checkbox"]:disabled + .form-check-label,
.form-check input[type="radio"]:disabled + .form-check-label {
  pointer-events: none; }

.form-check input[type="radio"] + .form-check-label {
  padding-top: 3px; }

.form-check input[type="radio"] + .form-check-label::before,
.form-check input[type="radio"] + .form-check-label::after {
  content: " ";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 3px;
  padding: 1px;
  transition: opacity 0.3s linear; }
  @media (prefers-reduced-motion: reduce) {
    .form-check input[type="radio"] + .form-check-label::before,
    .form-check input[type="radio"] + .form-check-label::after {
      transition: none; } }

.form-check input[type="radio"] + .form-check-label:after,
.form-check input[type="radio"] {
  opacity: 0; }

.form-check input[type="radio"]:checked + .form-check-label::after {
  width: 8px;
  height: 8px;
  top: 8px;
  left: 5px;
  background-color: #ffffff;
  border-color: #ffffff;
  opacity: 1; }

.form-check input[type="radio"]:checked + .form-check-label::before {
  background-color: #0d1b48; }

.form-check input[type="radio"]:disabled + .form-check-label::before {
  color: rgba(13, 27, 72, 0.7); }

.form-check input[type="radio"]:disabled + .form-check-label::before,
.form-check input[type="radio"]:disabled + .form-check-label::after {
  color: #B7C3D2; }

.round-check .form-check-sign::before,
.round-check .form-check-sign::after {
  border-radius: 50%; }

.focused .input-group-text {
  border-color: #B7C3D2;
  background-color: #ffffff; }

.focused .form-control {
  border-color: #B7C3D2;
  background-color: #ffffff; }

.full-search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f5f9fe;
  opacity: 0;
  z-index: 999;
  transition: all 0.25s ease-in-out;
  -moz-transform: translate(0%, -100%) scale(0, 0);
  -o-transform: translate(0%, -100%) scale(0, 0);
  -ms-transform: translate(0%, -100%) scale(0, 0);
  -webkit-transform: translate(0%, -100%) scale(0, 0);
  transform: translate(0%, -100%) scale(0, 0); }
  @media (prefers-reduced-motion: reduce) {
    .full-search {
      transition: none; } }

.full-search.open {
  -moz-transform: translate(0%, 0%) scale(1, 1);
  -o-transform: translate(0%, 0%) scale(1, 1);
  -ms-transform: translate(0%, 0%) scale(1, 1);
  -webkit-transform: translate(0%, 0%) scale(1, 1);
  transform: translate(0%, 0%) scale(1, 1);
  opacity: 1; }

.full-search input[type="search"] {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #0d1b48;
  background: #f5f9fe;
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
  border: 0.0625rem solid #edf0f7;
  padding: .5rem 0 1rem 0;
  outline: 0; }
  .full-search input[type="search"]:focus {
    border: 0.0625rem solid #edf0f7; }
  .full-search input[type="search"]::placeholder {
    color: #4E5079;
    font-size: 2.75rem;
    opacity: 1; }

.full-search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #ffffff;
  opacity: 1;
  padding: 10px 17px;
  font-size: 2rem;
  border-radius: 0.45rem; }

.file-field input[type="file"] {
  position: absolute;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  padding-bottom: 30px; }

.file-field span {
  cursor: pointer; }

/**
 * = Icon boxes
 */
.icon {
  text-align: center;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .icon span, .icon svg {
    font-size: 2rem; }
  .icon.icon-xs span, .icon.icon-xs svg {
    font-size: 0.875rem; }
  .icon.icon-sm span, .icon.icon-sm svg {
    font-size: 1.25rem; }
  @media (min-width: 992px) {
    .icon.icon-lg span, .icon.icon-lg svg {
      font-size: 2.75rem; } }
  @media (min-width: 992px) {
    .icon.icon-xl span, .icon.icon-xl svg {
      font-size: 5rem; } }
  .icon.rounded-circle .icon-bordered {
    border-radius: 50%; }
  .icon.organic-radius .icon-bordered {
    border-radius: 63% 37% 30% 1.4 45% 55% 50%; }

.icon-shape {
  width: 4.25rem;
  height: 4.25rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.45rem; }
  .icon-shape span, .icon-shape svg {
    font-size: 2rem; }
  .icon-shape.icon-sm {
    width: 3rem;
    height: 3rem; }
    .icon-shape.icon-sm span, .icon-shape.icon-sm svg {
      font-size: 1.25rem; }
  @media (min-width: 992px) {
    .icon-shape.icon-lg {
      width: 6rem;
      height: 6rem; }
      .icon-shape.icon-lg span, .icon-shape.icon-lg svg {
        font-size: 2.75rem; } }

.shape-xs {
  width: 10px;
  height: 10px; }

.github-big-icon {
  position: absolute;
  right: -255px;
  top: 75px; }
  .github-big-icon span {
    font-size: 800px;
    opacity: .1; }

.icon-shape-primary {
  color: #0d1b48;
  background-color: rgba(13, 27, 72, 0.1); }

.icon-primary span {
  color: #0d1b48; }

.fill-primary {
  fill: #0d1b48; }

.icon-shape-secondary {
  color: #C33C54;
  background-color: rgba(195, 60, 84, 0.1); }

.icon-secondary span {
  color: #C33C54; }

.fill-secondary {
  fill: #C33C54; }

.icon-shape-success {
  color: #208163;
  background-color: rgba(32, 129, 99, 0.1); }

.icon-success span {
  color: #208163; }

.fill-success {
  fill: #208163; }

.icon-shape-info {
  color: #0056B3;
  background-color: rgba(0, 86, 179, 0.1); }

.icon-info span {
  color: #0056B3; }

.fill-info {
  fill: #0056B3; }

.icon-shape-warning {
  color: #F0B400;
  background-color: rgba(240, 180, 0, 0.1); }

.icon-warning span {
  color: #F0B400; }

.fill-warning {
  fill: #F0B400; }

.icon-shape-danger {
  color: #be2837;
  background-color: rgba(190, 40, 55, 0.1); }

.icon-danger span {
  color: #be2837; }

.fill-danger {
  fill: #be2837; }

.icon-shape-light {
  color: #edf0f7;
  background-color: rgba(237, 240, 247, 0.1); }

.icon-light span {
  color: #edf0f7; }

.fill-light {
  fill: #edf0f7; }

.icon-shape-dark {
  color: #0d1b48;
  background-color: rgba(13, 27, 72, 0.1); }

.icon-dark span {
  color: #0d1b48; }

.fill-dark {
  fill: #0d1b48; }

.icon-shape-tertiary {
  color: #26408B;
  background-color: rgba(38, 64, 139, 0.1); }

.icon-tertiary span {
  color: #26408B; }

.fill-tertiary {
  fill: #26408B; }

.icon-shape-lighten {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1); }

.icon-lighten span {
  color: #ffffff; }

.fill-lighten {
  fill: #ffffff; }

.icon-shape-white {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1); }

.icon-white span {
  color: #ffffff; }

.fill-white {
  fill: #ffffff; }

.icon-shape-gray {
  color: #424767;
  background-color: rgba(66, 71, 103, 0.1); }

.icon-gray span {
  color: #424767; }

.fill-gray {
  fill: #424767; }

.icon-shape-neutral {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1); }

.icon-neutral span {
  color: #ffffff; }

.fill-neutral {
  fill: #ffffff; }

.icon-shape-soft {
  color: #f5f9fe;
  background-color: rgba(245, 249, 254, 0.1); }

.icon-soft span {
  color: #f5f9fe; }

.fill-soft {
  fill: #f5f9fe; }

.icon-shape-black {
  color: #0d1b48;
  background-color: rgba(13, 27, 72, 0.1); }

.icon-black span {
  color: #0d1b48; }

.fill-black {
  fill: #0d1b48; }

.icon-shape-purple {
  color: #6f42c1;
  background-color: rgba(111, 66, 193, 0.1); }

.icon-purple span {
  color: #6f42c1; }

.fill-purple {
  fill: #6f42c1; }

.icon-shape-soft-green {
  color: #ebf9ee;
  background-color: rgba(235, 249, 238, 0.1); }

.icon-soft-green span {
  color: #ebf9ee; }

.fill-soft-green {
  fill: #ebf9ee; }

.icon-shape-gray-100 {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1); }

.icon-gray-100 span {
  color: #ffffff; }

.fill-gray-100 {
  fill: #ffffff; }

.icon-shape-gray-200 {
  color: #fafbfe;
  background-color: rgba(250, 251, 254, 0.1); }

.icon-gray-200 span {
  color: #fafbfe; }

.fill-gray-200 {
  fill: #fafbfe; }

.icon-shape-gray-300 {
  color: #f5f9fe;
  background-color: rgba(245, 249, 254, 0.1); }

.icon-gray-300 span {
  color: #f5f9fe; }

.fill-gray-300 {
  fill: #f5f9fe; }

.icon-shape-gray-400 {
  color: #edf0f7;
  background-color: rgba(237, 240, 247, 0.1); }

.icon-gray-400 span {
  color: #edf0f7; }

.fill-gray-400 {
  fill: #edf0f7; }

.icon-shape-gray-500 {
  color: #B7C3D2;
  background-color: rgba(183, 195, 210, 0.1); }

.icon-gray-500 span {
  color: #B7C3D2; }

.fill-gray-500 {
  fill: #B7C3D2; }

.icon-shape-gray-600 {
  color: #93a5be;
  background-color: rgba(147, 165, 190, 0.1); }

.icon-gray-600 span {
  color: #93a5be; }

.fill-gray-600 {
  fill: #93a5be; }

.icon-shape-gray-700 {
  color: #52547a;
  background-color: rgba(82, 84, 122, 0.1); }

.icon-gray-700 span {
  color: #52547a; }

.fill-gray-700 {
  fill: #52547a; }

.icon-shape-gray-800 {
  color: #4E5079;
  background-color: rgba(78, 80, 121, 0.1); }

.icon-gray-800 span {
  color: #4E5079; }

.fill-gray-800 {
  fill: #4E5079; }

.icon-shape-facebook {
  color: #3b5999;
  background-color: rgba(59, 89, 153, 0.1); }

.icon-facebook span {
  color: #3b5999; }

.fill-facebook {
  fill: #3b5999; }

.icon-shape-twitter {
  color: #1da1f2;
  background-color: rgba(29, 161, 242, 0.1); }

.icon-twitter span {
  color: #1da1f2; }

.fill-twitter {
  fill: #1da1f2; }

.icon-shape-google {
  color: #DB4337;
  background-color: rgba(219, 67, 55, 0.1); }

.icon-google span {
  color: #DB4337; }

.fill-google {
  fill: #DB4337; }

.icon-shape-instagram {
  color: #e4405f;
  background-color: rgba(228, 64, 95, 0.1); }

.icon-instagram span {
  color: #e4405f; }

.fill-instagram {
  fill: #e4405f; }

.icon-shape-pinterest {
  color: #bd081c;
  background-color: rgba(189, 8, 28, 0.1); }

.icon-pinterest span {
  color: #bd081c; }

.fill-pinterest {
  fill: #bd081c; }

.icon-shape-youtube {
  color: #cd201f;
  background-color: rgba(205, 32, 31, 0.1); }

.icon-youtube span {
  color: #cd201f; }

.fill-youtube {
  fill: #cd201f; }

.icon-shape-slack {
  color: #3aaf85;
  background-color: rgba(58, 175, 133, 0.1); }

.icon-slack span {
  color: #3aaf85; }

.fill-slack {
  fill: #3aaf85; }

.icon-shape-dribbble {
  color: #ea4c89;
  background-color: rgba(234, 76, 137, 0.1); }

.icon-dribbble span {
  color: #ea4c89; }

.fill-dribbble {
  fill: #ea4c89; }

.icon-shape-dropbox {
  color: #1E90FF;
  background-color: rgba(30, 144, 255, 0.1); }

.icon-dropbox span {
  color: #1E90FF; }

.fill-dropbox {
  fill: #1E90FF; }

.icon-shape-twitch {
  color: #4B367C;
  background-color: rgba(75, 54, 124, 0.1); }

.icon-twitch span {
  color: #4B367C; }

.fill-twitch {
  fill: #4B367C; }

.icon-shape-paypal {
  color: #ecb32c;
  background-color: rgba(236, 179, 44, 0.1); }

.icon-paypal span {
  color: #ecb32c; }

.fill-paypal {
  fill: #ecb32c; }

.icon-shape-behance {
  color: #0057ff;
  background-color: rgba(0, 87, 255, 0.1); }

.icon-behance span {
  color: #0057ff; }

.fill-behance {
  fill: #0057ff; }

.icon-shape-reddit {
  color: #E84422;
  background-color: rgba(232, 68, 34, 0.1); }

.icon-reddit span {
  color: #E84422; }

.fill-reddit {
  fill: #E84422; }

.icon-shape-github {
  color: #222222;
  background-color: rgba(34, 34, 34, 0.1); }

.icon-github span {
  color: #222222; }

.fill-github {
  fill: #222222; }

/**
 * = Images
 */
.image-lg {
  height: 12rem; }

.image-md {
  height: 5rem; }

.image-sm {
  height: 3rem; }

.image-xs {
  height: 2.5rem; }

.img-thumbnail {
  border-width: 0.125rem;
  box-shadow: none; }

.full-image {
  height: 100%; }

.video-poster {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  .video-poster a {
    position: absolute; }

@media (min-width: 576px) {
  .effect-img-2 {
    position: absolute;
    right: 5rem;
    top: 19%;
    z-index: 2;
    margin: 0; }
  .effect-img-1, .effect-img-2 {
    width: 350px;
    height: auto; } }

@media (max-width: 991.98px) {
  .effect-img-2 {
    right: .425rem;
    top: 0; } }

/**
 * = Input groups
 */
.input-group {
  border-radius: 0.45rem;
  transition: all 0.2s ease; }
  .input-group .form-control {
    box-shadow: none; }
    .input-group .form-control:focus {
      box-shadow: none; }
    .input-group .form-control.is-invalid {
      border-top-right-radius: 0.45rem;
      border-bottom-right-radius: 0.45rem; }

.input-group-text {
  font-size: 0.875rem;
  transition: all 0.3s ease-in-out; }

.input-group-prepend {
  margin-right: -2px; }

/**
 * = List groups
 */
.list-group-item {
  background: transparent; }
  .list-group-item.dashboard-menu:hover {
    background-color: #f5f9fe; }

.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.45rem; }

.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -.1rem 1.2rem 0 -.2rem; }

.list-group-sm .list-group-item {
  padding: 0.625rem 0.875rem;
  border-bottom: 0.0625rem solid #f9f7f7; }
  .list-group-sm .list-group-item:first-child {
    border-radius: 0; }
  .list-group-sm .list-group-item:last-child {
    border-radius: 0; }
  .list-group-sm .list-group-item.active {
    border-color: #f9f7f7;
    border-left: 0;
    border-right: 0;
    font-weight: 500; }

.news-list .row > [class*='col'] {
  padding: 0 0.25rem; }

.news-list > li:not(:last-child) {
  margin-bottom: .75rem; }

.news-list img {
  min-height: 100%;
  object-fit: cover; }

/**
 * = Maps
 */
.map {
  height: 400px;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%); }

.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%; }

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%); }
  .gmap_canvas #gmap_canvas {
    width: 100%;
    height: 500px; }

#mapListings {
  width: 100%;
  height: 100%; }
  @media (max-width: 1199.98px) {
    #mapListings {
      width: calc(100vw - 65px); } }

#mapListings2 {
  width: 100%;
  height: 100vh;
  z-index: 1; }

.leaflet-popup-content-wrapper {
  padding: 8px 8px 8px 0; }

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 0 24px rgba(27, 22, 66, 0.04), 0 44px 74px rgba(27, 22, 66, 0.06); }

.leaflet-container a.leaflet-popup-close-button {
  padding: 7px 15px 0 0; }

.logo-map {
  position: absolute;
  right: 0;
  z-index: 3; }

.search-map {
  position: absolute;
  z-index: 3;
  bottom: 30px;
  width: 100%; }

/**
 * = Modals
 */
.modal.static-example {
  position: relative;
  display: block; }

.modal-header .close {
  padding: .825rem; }

.modal-content {
  border: 0;
  border-radius: 0.45rem; }

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0; }

.modal-fluid .modal-content {
  border-radius: 0; }

.modal-primary .modal-title {
  color: #ffffff; }

.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-primary .modal-content {
  background-color: #0d1b48;
  color: #ffffff; }
  .modal-primary .modal-content .heading {
    color: #ffffff; }

.modal-primary .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-secondary .modal-title {
  color: #ffffff; }

.modal-secondary .modal-header,
.modal-secondary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-secondary .modal-content {
  background-color: #C33C54;
  color: #ffffff; }
  .modal-secondary .modal-content .heading {
    color: #ffffff; }

.modal-secondary .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-success .modal-title {
  color: #ffffff; }

.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-success .modal-content {
  background-color: #208163;
  color: #ffffff; }
  .modal-success .modal-content .heading {
    color: #ffffff; }

.modal-success .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-info .modal-title {
  color: #ffffff; }

.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-info .modal-content {
  background-color: #0056B3;
  color: #ffffff; }
  .modal-info .modal-content .heading {
    color: #ffffff; }

.modal-info .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-warning .modal-title {
  color: #0d1b48; }

.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-warning .modal-content {
  background-color: #F0B400;
  color: #0d1b48; }
  .modal-warning .modal-content .heading {
    color: #0d1b48; }

.modal-warning .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-danger .modal-title {
  color: #ffffff; }

.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-danger .modal-content {
  background-color: #be2837;
  color: #ffffff; }
  .modal-danger .modal-content .heading {
    color: #ffffff; }

.modal-danger .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-light .modal-title {
  color: #0d1b48; }

.modal-light .modal-header,
.modal-light .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-light .modal-content {
  background-color: #edf0f7;
  color: #0d1b48; }
  .modal-light .modal-content .heading {
    color: #0d1b48; }

.modal-light .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-dark .modal-title {
  color: #ffffff; }

.modal-dark .modal-header,
.modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-dark .modal-content {
  background-color: #0d1b48;
  color: #ffffff; }
  .modal-dark .modal-content .heading {
    color: #ffffff; }

.modal-dark .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-tertiary .modal-title {
  color: #ffffff; }

.modal-tertiary .modal-header,
.modal-tertiary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-tertiary .modal-content {
  background-color: #26408B;
  color: #ffffff; }
  .modal-tertiary .modal-content .heading {
    color: #ffffff; }

.modal-tertiary .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-lighten .modal-title {
  color: #0d1b48; }

.modal-lighten .modal-header,
.modal-lighten .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-lighten .modal-content {
  background-color: #ffffff;
  color: #0d1b48; }
  .modal-lighten .modal-content .heading {
    color: #0d1b48; }

.modal-lighten .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-white .modal-title {
  color: #0d1b48; }

.modal-white .modal-header,
.modal-white .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-white .modal-content {
  background-color: #ffffff;
  color: #0d1b48; }
  .modal-white .modal-content .heading {
    color: #0d1b48; }

.modal-white .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray .modal-title {
  color: #ffffff; }

.modal-gray .modal-header,
.modal-gray .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-gray .modal-content {
  background-color: #424767;
  color: #ffffff; }
  .modal-gray .modal-content .heading {
    color: #ffffff; }

.modal-gray .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-neutral .modal-title {
  color: #0d1b48; }

.modal-neutral .modal-header,
.modal-neutral .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-neutral .modal-content {
  background-color: #ffffff;
  color: #0d1b48; }
  .modal-neutral .modal-content .heading {
    color: #0d1b48; }

.modal-neutral .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-soft .modal-title {
  color: #0d1b48; }

.modal-soft .modal-header,
.modal-soft .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-soft .modal-content {
  background-color: #f5f9fe;
  color: #0d1b48; }
  .modal-soft .modal-content .heading {
    color: #0d1b48; }

.modal-soft .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-black .modal-title {
  color: #ffffff; }

.modal-black .modal-header,
.modal-black .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-black .modal-content {
  background-color: #0d1b48;
  color: #ffffff; }
  .modal-black .modal-content .heading {
    color: #ffffff; }

.modal-black .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-purple .modal-title {
  color: #ffffff; }

.modal-purple .modal-header,
.modal-purple .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-purple .modal-content {
  background-color: #6f42c1;
  color: #ffffff; }
  .modal-purple .modal-content .heading {
    color: #ffffff; }

.modal-purple .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-soft-green .modal-title {
  color: #0d1b48; }

.modal-soft-green .modal-header,
.modal-soft-green .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-soft-green .modal-content {
  background-color: #ebf9ee;
  color: #0d1b48; }
  .modal-soft-green .modal-content .heading {
    color: #0d1b48; }

.modal-soft-green .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-100 .modal-title {
  color: #0d1b48; }

.modal-gray-100 .modal-header,
.modal-gray-100 .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-gray-100 .modal-content {
  background-color: #ffffff;
  color: #0d1b48; }
  .modal-gray-100 .modal-content .heading {
    color: #0d1b48; }

.modal-gray-100 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-200 .modal-title {
  color: #0d1b48; }

.modal-gray-200 .modal-header,
.modal-gray-200 .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-gray-200 .modal-content {
  background-color: #fafbfe;
  color: #0d1b48; }
  .modal-gray-200 .modal-content .heading {
    color: #0d1b48; }

.modal-gray-200 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-300 .modal-title {
  color: #0d1b48; }

.modal-gray-300 .modal-header,
.modal-gray-300 .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-gray-300 .modal-content {
  background-color: #f5f9fe;
  color: #0d1b48; }
  .modal-gray-300 .modal-content .heading {
    color: #0d1b48; }

.modal-gray-300 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-400 .modal-title {
  color: #0d1b48; }

.modal-gray-400 .modal-header,
.modal-gray-400 .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-gray-400 .modal-content {
  background-color: #edf0f7;
  color: #0d1b48; }
  .modal-gray-400 .modal-content .heading {
    color: #0d1b48; }

.modal-gray-400 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-500 .modal-title {
  color: #0d1b48; }

.modal-gray-500 .modal-header,
.modal-gray-500 .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-gray-500 .modal-content {
  background-color: #B7C3D2;
  color: #0d1b48; }
  .modal-gray-500 .modal-content .heading {
    color: #0d1b48; }

.modal-gray-500 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-600 .modal-title {
  color: #0d1b48; }

.modal-gray-600 .modal-header,
.modal-gray-600 .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-gray-600 .modal-content {
  background-color: #93a5be;
  color: #0d1b48; }
  .modal-gray-600 .modal-content .heading {
    color: #0d1b48; }

.modal-gray-600 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-700 .modal-title {
  color: #ffffff; }

.modal-gray-700 .modal-header,
.modal-gray-700 .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-gray-700 .modal-content {
  background-color: #52547a;
  color: #ffffff; }
  .modal-gray-700 .modal-content .heading {
    color: #ffffff; }

.modal-gray-700 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-gray-800 .modal-title {
  color: #ffffff; }

.modal-gray-800 .modal-header,
.modal-gray-800 .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-gray-800 .modal-content {
  background-color: #4E5079;
  color: #ffffff; }
  .modal-gray-800 .modal-content .heading {
    color: #ffffff; }

.modal-gray-800 .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-facebook .modal-title {
  color: #ffffff; }

.modal-facebook .modal-header,
.modal-facebook .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-facebook .modal-content {
  background-color: #3b5999;
  color: #ffffff; }
  .modal-facebook .modal-content .heading {
    color: #ffffff; }

.modal-facebook .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-twitter .modal-title {
  color: #ffffff; }

.modal-twitter .modal-header,
.modal-twitter .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-twitter .modal-content {
  background-color: #1da1f2;
  color: #ffffff; }
  .modal-twitter .modal-content .heading {
    color: #ffffff; }

.modal-twitter .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-google .modal-title {
  color: #ffffff; }

.modal-google .modal-header,
.modal-google .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-google .modal-content {
  background-color: #DB4337;
  color: #ffffff; }
  .modal-google .modal-content .heading {
    color: #ffffff; }

.modal-google .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-instagram .modal-title {
  color: #ffffff; }

.modal-instagram .modal-header,
.modal-instagram .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-instagram .modal-content {
  background-color: #e4405f;
  color: #ffffff; }
  .modal-instagram .modal-content .heading {
    color: #ffffff; }

.modal-instagram .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-pinterest .modal-title {
  color: #ffffff; }

.modal-pinterest .modal-header,
.modal-pinterest .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-pinterest .modal-content {
  background-color: #bd081c;
  color: #ffffff; }
  .modal-pinterest .modal-content .heading {
    color: #ffffff; }

.modal-pinterest .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-youtube .modal-title {
  color: #ffffff; }

.modal-youtube .modal-header,
.modal-youtube .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-youtube .modal-content {
  background-color: #cd201f;
  color: #ffffff; }
  .modal-youtube .modal-content .heading {
    color: #ffffff; }

.modal-youtube .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-slack .modal-title {
  color: #ffffff; }

.modal-slack .modal-header,
.modal-slack .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-slack .modal-content {
  background-color: #3aaf85;
  color: #ffffff; }
  .modal-slack .modal-content .heading {
    color: #ffffff; }

.modal-slack .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-dribbble .modal-title {
  color: #ffffff; }

.modal-dribbble .modal-header,
.modal-dribbble .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-dribbble .modal-content {
  background-color: #ea4c89;
  color: #ffffff; }
  .modal-dribbble .modal-content .heading {
    color: #ffffff; }

.modal-dribbble .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-dropbox .modal-title {
  color: #ffffff; }

.modal-dropbox .modal-header,
.modal-dropbox .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-dropbox .modal-content {
  background-color: #1E90FF;
  color: #ffffff; }
  .modal-dropbox .modal-content .heading {
    color: #ffffff; }

.modal-dropbox .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-twitch .modal-title {
  color: #ffffff; }

.modal-twitch .modal-header,
.modal-twitch .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-twitch .modal-content {
  background-color: #4B367C;
  color: #ffffff; }
  .modal-twitch .modal-content .heading {
    color: #ffffff; }

.modal-twitch .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-paypal .modal-title {
  color: #0d1b48; }

.modal-paypal .modal-header,
.modal-paypal .modal-footer {
  border-color: rgba(13, 27, 72, 0.075); }

.modal-paypal .modal-content {
  background-color: #ecb32c;
  color: #0d1b48; }
  .modal-paypal .modal-content .heading {
    color: #0d1b48; }

.modal-paypal .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-behance .modal-title {
  color: #ffffff; }

.modal-behance .modal-header,
.modal-behance .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-behance .modal-content {
  background-color: #0057ff;
  color: #ffffff; }
  .modal-behance .modal-content .heading {
    color: #ffffff; }

.modal-behance .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-reddit .modal-title {
  color: #ffffff; }

.modal-reddit .modal-header,
.modal-reddit .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-reddit .modal-content {
  background-color: #E84422;
  color: #ffffff; }
  .modal-reddit .modal-content .heading {
    color: #ffffff; }

.modal-reddit .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-github .modal-title {
  color: #ffffff; }

.modal-github .modal-header,
.modal-github .modal-footer {
  border-color: rgba(255, 255, 255, 0.075); }

.modal-github .modal-content {
  background-color: #222222;
  color: #ffffff; }
  .modal-github .modal-content .heading {
    color: #ffffff; }

.modal-github .close > span:not(.sr-only) {
  color: #ffffff; }

.modal-full.modal-dialog {
  max-width: none;
  width: calc(100% - 60px); }
  @media (max-width: 1199.98px) {
    .modal-full.modal-dialog {
      width: calc(100% - 20px); } }

.modal-full .modal-content,
.modal-full .modal-body {
  height: calc(100vh - 60px); }

/**
 * = Navs
 */
.nav-link {
  color: #4E5079; }
  .nav-link:hover, .nav-link.active {
    color: #0d1b48; }
    .nav-link:hover img, .nav-link.active img {
      opacity: inherit;
      transition: all 0.2s ease; }
  .nav-link span {
    position: relative; }
  .nav-link img {
    opacity: .5; }

.nav-tabs .nav-item {
  margin-bottom: 0; }

.nav-tabs .nav-link {
  border: 0;
  padding: 1rem 1rem; }

.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem; }

.nav-pills .nav-link {
  padding: 0.75rem 0.85rem;
  transition: all 0.2s ease;
  box-shadow: none;
  border: 1px solid #edf0f7; }
  .nav-pills .nav-link.avatar-link {
    border: 0; }
  .nav-pills .nav-link:hover {
    border-color: #0d1b48; }

.nav-pills.small-pills .nav-link {
  width: 40px;
  height: 40px;
  padding: 0.7rem 0.75rem; }
  .nav-pills.small-pills .nav-link .nav-link-icon {
    line-height: 20px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #0d1b48;
  border-color: #ffffff; }

.nav-pills.rounded .nav-link {
  border-radius: 30px; }

.nav-pills.rounded.vertical-tab .nav-link {
  margin-bottom: .625rem;
  min-width: 100px; }

.nav-pills.rounded.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0; }

.nav-pills.bordered-pill-md .nav-link {
  border: 0.125rem solid #fafbfe;
  font-weight: 500; }

.nav-pills.vertical-tab .nav-link {
  margin-bottom: .625rem; }

.nav-pills.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0; }

@media (max-width: 575.98px) {
  .nav-pills .nav-item {
    margin-bottom: 1rem; } }

@media (max-width: 767.98px) {
  .nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 0; } }

.nav-pill-circle .nav-link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  height: 80px;
  width: 80px;
  padding: 0;
  box-shadow: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center; }
  .nav-pill-circle .nav-link.avatar-link {
    box-shadow: none; }

.nav-pill-circle.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0; }

.nav-pill-circle .nav-link-icon span, .nav-pill-circle .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block; }

.nav-wrapper {
  padding: 1rem 0;
  border-top-left-radius: 0.45rem;
  border-top-right-radius: 0.45rem; }
  .nav-wrapper + .card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.45rem;
    border-bottom-left-radius: 0.45rem; }

.tab-content > .tab-pane {
  display: none; }
  .tab-content > .tab-pane pre {
    padding: 0;
    margin: 0; }

.tab-content > .active {
  display: block; }

.filters__filter {
  border: 0.0625rem solid #edf0f7;
  margin-right: .5rem;
  border-radius: 0.85rem; }
  .filters__filter:hover {
    background-color: #f5f9fe; }

/**
 * = Owl carousels
 */
.owl-theme .owl-nav {
  margin-top: 1.25rem;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    display: inline-block;
    margin: 5px;
    padding: 4px 7px;
    background: #edf0f7;
    cursor: pointer; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background-color: transparent;
      color: #0d1b48;
      text-decoration: none; }
    .owl-theme .owl-nav [class*='owl-']:focus {
      outline: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 25px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 5px;
      background: transparent;
      border: 2px solid #0d1b48;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 50%; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #0d1b48; }
    .owl-theme .owl-dots .owl-dot:focus {
      outline: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  color: #93a5be; }

.owl-carousel .owl-item img.avatar-sm {
  height: 4rem;
  width: 4rem; }

.owl-carousel .owl-nav button.owl-prev {
  margin-right: 1rem; }

.owl-carousel .owl-nav button.owl-prev {
  margin-left: 1rem; }

/**
 * = Paginations
 */
.circle-pagination .page-link,
.circle-pagination span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%; }

/**
 * = Popovers
 */
.popover {
  border: 0; }

.popover-header {
  font-weight: 500; }

.popover-primary {
  background-color: #0d1b48; }
  .popover-primary .popover-header {
    background-color: #0d1b48;
    color: #ffffff; }
  .popover-primary .popover-body {
    color: #ffffff; }
  .popover-primary .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0d1b48; }
  .popover-primary.bs-popover-right .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0d1b48; }
  .popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0d1b48; }
  .popover-primary.bs-popover-left .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0d1b48; }

.popover-secondary {
  background-color: #C33C54; }
  .popover-secondary .popover-header {
    background-color: #C33C54;
    color: #ffffff; }
  .popover-secondary .popover-body {
    color: #ffffff; }
  .popover-secondary .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #C33C54; }
  .popover-secondary.bs-popover-right .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #C33C54; }
  .popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #C33C54; }
  .popover-secondary.bs-popover-left .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #C33C54; }

.popover-success {
  background-color: #208163; }
  .popover-success .popover-header {
    background-color: #208163;
    color: #ffffff; }
  .popover-success .popover-body {
    color: #ffffff; }
  .popover-success .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #208163; }
  .popover-success.bs-popover-right .arrow::after, .popover-success.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #208163; }
  .popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #208163; }
  .popover-success.bs-popover-left .arrow::after, .popover-success.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #208163; }

.popover-info {
  background-color: #0056B3; }
  .popover-info .popover-header {
    background-color: #0056B3;
    color: #ffffff; }
  .popover-info .popover-body {
    color: #ffffff; }
  .popover-info .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0056B3; }
  .popover-info.bs-popover-right .arrow::after, .popover-info.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0056B3; }
  .popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0056B3; }
  .popover-info.bs-popover-left .arrow::after, .popover-info.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0056B3; }

.popover-warning {
  background-color: #F0B400; }
  .popover-warning .popover-header {
    background-color: #F0B400;
    color: #0d1b48; }
  .popover-warning .popover-body {
    color: #0d1b48; }
  .popover-warning .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #F0B400; }
  .popover-warning.bs-popover-right .arrow::after, .popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #F0B400; }
  .popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #F0B400; }
  .popover-warning.bs-popover-left .arrow::after, .popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #F0B400; }

.popover-danger {
  background-color: #be2837; }
  .popover-danger .popover-header {
    background-color: #be2837;
    color: #ffffff; }
  .popover-danger .popover-body {
    color: #ffffff; }
  .popover-danger .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #be2837; }
  .popover-danger.bs-popover-right .arrow::after, .popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #be2837; }
  .popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #be2837; }
  .popover-danger.bs-popover-left .arrow::after, .popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #be2837; }

.popover-light {
  background-color: #edf0f7; }
  .popover-light .popover-header {
    background-color: #edf0f7;
    color: #0d1b48; }
  .popover-light .popover-body {
    color: #0d1b48; }
  .popover-light .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-light.bs-popover-top .arrow::after, .popover-light.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #edf0f7; }
  .popover-light.bs-popover-right .arrow::after, .popover-light.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #edf0f7; }
  .popover-light.bs-popover-bottom .arrow::after, .popover-light.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #edf0f7; }
  .popover-light.bs-popover-left .arrow::after, .popover-light.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #edf0f7; }

.popover-dark {
  background-color: #0d1b48; }
  .popover-dark .popover-header {
    background-color: #0d1b48;
    color: #ffffff; }
  .popover-dark .popover-body {
    color: #ffffff; }
  .popover-dark .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0d1b48; }
  .popover-dark.bs-popover-right .arrow::after, .popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0d1b48; }
  .popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0d1b48; }
  .popover-dark.bs-popover-left .arrow::after, .popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0d1b48; }

.popover-tertiary {
  background-color: #26408B; }
  .popover-tertiary .popover-header {
    background-color: #26408B;
    color: #ffffff; }
  .popover-tertiary .popover-body {
    color: #ffffff; }
  .popover-tertiary .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-tertiary.bs-popover-top .arrow::after, .popover-tertiary.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #26408B; }
  .popover-tertiary.bs-popover-right .arrow::after, .popover-tertiary.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #26408B; }
  .popover-tertiary.bs-popover-bottom .arrow::after, .popover-tertiary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #26408B; }
  .popover-tertiary.bs-popover-left .arrow::after, .popover-tertiary.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #26408B; }

.popover-lighten {
  background-color: #ffffff; }
  .popover-lighten .popover-header {
    background-color: #ffffff;
    color: #0d1b48; }
  .popover-lighten .popover-body {
    color: #0d1b48; }
  .popover-lighten .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-lighten.bs-popover-top .arrow::after, .popover-lighten.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ffffff; }
  .popover-lighten.bs-popover-right .arrow::after, .popover-lighten.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ffffff; }
  .popover-lighten.bs-popover-bottom .arrow::after, .popover-lighten.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ffffff; }
  .popover-lighten.bs-popover-left .arrow::after, .popover-lighten.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ffffff; }

.popover-white {
  background-color: #ffffff; }
  .popover-white .popover-header {
    background-color: #ffffff;
    color: #0d1b48; }
  .popover-white .popover-body {
    color: #0d1b48; }
  .popover-white .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-white.bs-popover-top .arrow::after, .popover-white.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ffffff; }
  .popover-white.bs-popover-right .arrow::after, .popover-white.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ffffff; }
  .popover-white.bs-popover-bottom .arrow::after, .popover-white.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ffffff; }
  .popover-white.bs-popover-left .arrow::after, .popover-white.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ffffff; }

.popover-gray {
  background-color: #424767; }
  .popover-gray .popover-header {
    background-color: #424767;
    color: #ffffff; }
  .popover-gray .popover-body {
    color: #ffffff; }
  .popover-gray .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-gray.bs-popover-top .arrow::after, .popover-gray.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #424767; }
  .popover-gray.bs-popover-right .arrow::after, .popover-gray.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #424767; }
  .popover-gray.bs-popover-bottom .arrow::after, .popover-gray.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #424767; }
  .popover-gray.bs-popover-left .arrow::after, .popover-gray.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #424767; }

.popover-neutral {
  background-color: #ffffff; }
  .popover-neutral .popover-header {
    background-color: #ffffff;
    color: #0d1b48; }
  .popover-neutral .popover-body {
    color: #0d1b48; }
  .popover-neutral .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-neutral.bs-popover-top .arrow::after, .popover-neutral.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ffffff; }
  .popover-neutral.bs-popover-right .arrow::after, .popover-neutral.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ffffff; }
  .popover-neutral.bs-popover-bottom .arrow::after, .popover-neutral.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ffffff; }
  .popover-neutral.bs-popover-left .arrow::after, .popover-neutral.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ffffff; }

.popover-soft {
  background-color: #f5f9fe; }
  .popover-soft .popover-header {
    background-color: #f5f9fe;
    color: #0d1b48; }
  .popover-soft .popover-body {
    color: #0d1b48; }
  .popover-soft .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-soft.bs-popover-top .arrow::after, .popover-soft.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #f5f9fe; }
  .popover-soft.bs-popover-right .arrow::after, .popover-soft.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #f5f9fe; }
  .popover-soft.bs-popover-bottom .arrow::after, .popover-soft.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #f5f9fe; }
  .popover-soft.bs-popover-left .arrow::after, .popover-soft.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #f5f9fe; }

.popover-black {
  background-color: #0d1b48; }
  .popover-black .popover-header {
    background-color: #0d1b48;
    color: #ffffff; }
  .popover-black .popover-body {
    color: #ffffff; }
  .popover-black .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-black.bs-popover-top .arrow::after, .popover-black.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0d1b48; }
  .popover-black.bs-popover-right .arrow::after, .popover-black.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0d1b48; }
  .popover-black.bs-popover-bottom .arrow::after, .popover-black.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0d1b48; }
  .popover-black.bs-popover-left .arrow::after, .popover-black.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0d1b48; }

.popover-purple {
  background-color: #6f42c1; }
  .popover-purple .popover-header {
    background-color: #6f42c1;
    color: #ffffff; }
  .popover-purple .popover-body {
    color: #ffffff; }
  .popover-purple .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-purple.bs-popover-top .arrow::after, .popover-purple.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #6f42c1; }
  .popover-purple.bs-popover-right .arrow::after, .popover-purple.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #6f42c1; }
  .popover-purple.bs-popover-bottom .arrow::after, .popover-purple.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #6f42c1; }
  .popover-purple.bs-popover-left .arrow::after, .popover-purple.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #6f42c1; }

.popover-soft-green {
  background-color: #ebf9ee; }
  .popover-soft-green .popover-header {
    background-color: #ebf9ee;
    color: #0d1b48; }
  .popover-soft-green .popover-body {
    color: #0d1b48; }
  .popover-soft-green .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-soft-green.bs-popover-top .arrow::after, .popover-soft-green.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ebf9ee; }
  .popover-soft-green.bs-popover-right .arrow::after, .popover-soft-green.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ebf9ee; }
  .popover-soft-green.bs-popover-bottom .arrow::after, .popover-soft-green.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ebf9ee; }
  .popover-soft-green.bs-popover-left .arrow::after, .popover-soft-green.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ebf9ee; }

.popover-gray-100 {
  background-color: #ffffff; }
  .popover-gray-100 .popover-header {
    background-color: #ffffff;
    color: #0d1b48; }
  .popover-gray-100 .popover-body {
    color: #0d1b48; }
  .popover-gray-100 .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-gray-100.bs-popover-top .arrow::after, .popover-gray-100.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ffffff; }
  .popover-gray-100.bs-popover-right .arrow::after, .popover-gray-100.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ffffff; }
  .popover-gray-100.bs-popover-bottom .arrow::after, .popover-gray-100.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ffffff; }
  .popover-gray-100.bs-popover-left .arrow::after, .popover-gray-100.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ffffff; }

.popover-gray-200 {
  background-color: #fafbfe; }
  .popover-gray-200 .popover-header {
    background-color: #fafbfe;
    color: #0d1b48; }
  .popover-gray-200 .popover-body {
    color: #0d1b48; }
  .popover-gray-200 .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-gray-200.bs-popover-top .arrow::after, .popover-gray-200.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #fafbfe; }
  .popover-gray-200.bs-popover-right .arrow::after, .popover-gray-200.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #fafbfe; }
  .popover-gray-200.bs-popover-bottom .arrow::after, .popover-gray-200.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #fafbfe; }
  .popover-gray-200.bs-popover-left .arrow::after, .popover-gray-200.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #fafbfe; }

.popover-gray-300 {
  background-color: #f5f9fe; }
  .popover-gray-300 .popover-header {
    background-color: #f5f9fe;
    color: #0d1b48; }
  .popover-gray-300 .popover-body {
    color: #0d1b48; }
  .popover-gray-300 .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-gray-300.bs-popover-top .arrow::after, .popover-gray-300.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #f5f9fe; }
  .popover-gray-300.bs-popover-right .arrow::after, .popover-gray-300.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #f5f9fe; }
  .popover-gray-300.bs-popover-bottom .arrow::after, .popover-gray-300.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #f5f9fe; }
  .popover-gray-300.bs-popover-left .arrow::after, .popover-gray-300.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #f5f9fe; }

.popover-gray-400 {
  background-color: #edf0f7; }
  .popover-gray-400 .popover-header {
    background-color: #edf0f7;
    color: #0d1b48; }
  .popover-gray-400 .popover-body {
    color: #0d1b48; }
  .popover-gray-400 .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-gray-400.bs-popover-top .arrow::after, .popover-gray-400.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #edf0f7; }
  .popover-gray-400.bs-popover-right .arrow::after, .popover-gray-400.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #edf0f7; }
  .popover-gray-400.bs-popover-bottom .arrow::after, .popover-gray-400.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #edf0f7; }
  .popover-gray-400.bs-popover-left .arrow::after, .popover-gray-400.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #edf0f7; }

.popover-gray-500 {
  background-color: #B7C3D2; }
  .popover-gray-500 .popover-header {
    background-color: #B7C3D2;
    color: #0d1b48; }
  .popover-gray-500 .popover-body {
    color: #0d1b48; }
  .popover-gray-500 .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-gray-500.bs-popover-top .arrow::after, .popover-gray-500.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #B7C3D2; }
  .popover-gray-500.bs-popover-right .arrow::after, .popover-gray-500.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #B7C3D2; }
  .popover-gray-500.bs-popover-bottom .arrow::after, .popover-gray-500.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #B7C3D2; }
  .popover-gray-500.bs-popover-left .arrow::after, .popover-gray-500.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #B7C3D2; }

.popover-gray-600 {
  background-color: #93a5be; }
  .popover-gray-600 .popover-header {
    background-color: #93a5be;
    color: #0d1b48; }
  .popover-gray-600 .popover-body {
    color: #0d1b48; }
  .popover-gray-600 .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-gray-600.bs-popover-top .arrow::after, .popover-gray-600.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #93a5be; }
  .popover-gray-600.bs-popover-right .arrow::after, .popover-gray-600.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #93a5be; }
  .popover-gray-600.bs-popover-bottom .arrow::after, .popover-gray-600.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #93a5be; }
  .popover-gray-600.bs-popover-left .arrow::after, .popover-gray-600.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #93a5be; }

.popover-gray-700 {
  background-color: #52547a; }
  .popover-gray-700 .popover-header {
    background-color: #52547a;
    color: #ffffff; }
  .popover-gray-700 .popover-body {
    color: #ffffff; }
  .popover-gray-700 .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-gray-700.bs-popover-top .arrow::after, .popover-gray-700.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #52547a; }
  .popover-gray-700.bs-popover-right .arrow::after, .popover-gray-700.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #52547a; }
  .popover-gray-700.bs-popover-bottom .arrow::after, .popover-gray-700.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #52547a; }
  .popover-gray-700.bs-popover-left .arrow::after, .popover-gray-700.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #52547a; }

.popover-gray-800 {
  background-color: #4E5079; }
  .popover-gray-800 .popover-header {
    background-color: #4E5079;
    color: #ffffff; }
  .popover-gray-800 .popover-body {
    color: #ffffff; }
  .popover-gray-800 .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-gray-800.bs-popover-top .arrow::after, .popover-gray-800.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #4E5079; }
  .popover-gray-800.bs-popover-right .arrow::after, .popover-gray-800.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #4E5079; }
  .popover-gray-800.bs-popover-bottom .arrow::after, .popover-gray-800.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #4E5079; }
  .popover-gray-800.bs-popover-left .arrow::after, .popover-gray-800.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #4E5079; }

.popover-facebook {
  background-color: #3b5999; }
  .popover-facebook .popover-header {
    background-color: #3b5999;
    color: #ffffff; }
  .popover-facebook .popover-body {
    color: #ffffff; }
  .popover-facebook .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-facebook.bs-popover-top .arrow::after, .popover-facebook.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #3b5999; }
  .popover-facebook.bs-popover-right .arrow::after, .popover-facebook.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #3b5999; }
  .popover-facebook.bs-popover-bottom .arrow::after, .popover-facebook.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #3b5999; }
  .popover-facebook.bs-popover-left .arrow::after, .popover-facebook.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #3b5999; }

.popover-twitter {
  background-color: #1da1f2; }
  .popover-twitter .popover-header {
    background-color: #1da1f2;
    color: #ffffff; }
  .popover-twitter .popover-body {
    color: #ffffff; }
  .popover-twitter .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-twitter.bs-popover-top .arrow::after, .popover-twitter.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #1da1f2; }
  .popover-twitter.bs-popover-right .arrow::after, .popover-twitter.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #1da1f2; }
  .popover-twitter.bs-popover-bottom .arrow::after, .popover-twitter.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #1da1f2; }
  .popover-twitter.bs-popover-left .arrow::after, .popover-twitter.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #1da1f2; }

.popover-google {
  background-color: #DB4337; }
  .popover-google .popover-header {
    background-color: #DB4337;
    color: #ffffff; }
  .popover-google .popover-body {
    color: #ffffff; }
  .popover-google .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-google.bs-popover-top .arrow::after, .popover-google.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #DB4337; }
  .popover-google.bs-popover-right .arrow::after, .popover-google.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #DB4337; }
  .popover-google.bs-popover-bottom .arrow::after, .popover-google.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #DB4337; }
  .popover-google.bs-popover-left .arrow::after, .popover-google.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #DB4337; }

.popover-instagram {
  background-color: #e4405f; }
  .popover-instagram .popover-header {
    background-color: #e4405f;
    color: #ffffff; }
  .popover-instagram .popover-body {
    color: #ffffff; }
  .popover-instagram .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-instagram.bs-popover-top .arrow::after, .popover-instagram.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #e4405f; }
  .popover-instagram.bs-popover-right .arrow::after, .popover-instagram.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #e4405f; }
  .popover-instagram.bs-popover-bottom .arrow::after, .popover-instagram.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #e4405f; }
  .popover-instagram.bs-popover-left .arrow::after, .popover-instagram.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #e4405f; }

.popover-pinterest {
  background-color: #bd081c; }
  .popover-pinterest .popover-header {
    background-color: #bd081c;
    color: #ffffff; }
  .popover-pinterest .popover-body {
    color: #ffffff; }
  .popover-pinterest .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-pinterest.bs-popover-top .arrow::after, .popover-pinterest.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #bd081c; }
  .popover-pinterest.bs-popover-right .arrow::after, .popover-pinterest.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #bd081c; }
  .popover-pinterest.bs-popover-bottom .arrow::after, .popover-pinterest.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #bd081c; }
  .popover-pinterest.bs-popover-left .arrow::after, .popover-pinterest.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #bd081c; }

.popover-youtube {
  background-color: #cd201f; }
  .popover-youtube .popover-header {
    background-color: #cd201f;
    color: #ffffff; }
  .popover-youtube .popover-body {
    color: #ffffff; }
  .popover-youtube .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-youtube.bs-popover-top .arrow::after, .popover-youtube.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #cd201f; }
  .popover-youtube.bs-popover-right .arrow::after, .popover-youtube.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #cd201f; }
  .popover-youtube.bs-popover-bottom .arrow::after, .popover-youtube.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #cd201f; }
  .popover-youtube.bs-popover-left .arrow::after, .popover-youtube.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #cd201f; }

.popover-slack {
  background-color: #3aaf85; }
  .popover-slack .popover-header {
    background-color: #3aaf85;
    color: #ffffff; }
  .popover-slack .popover-body {
    color: #ffffff; }
  .popover-slack .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-slack.bs-popover-top .arrow::after, .popover-slack.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #3aaf85; }
  .popover-slack.bs-popover-right .arrow::after, .popover-slack.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #3aaf85; }
  .popover-slack.bs-popover-bottom .arrow::after, .popover-slack.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #3aaf85; }
  .popover-slack.bs-popover-left .arrow::after, .popover-slack.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #3aaf85; }

.popover-dribbble {
  background-color: #ea4c89; }
  .popover-dribbble .popover-header {
    background-color: #ea4c89;
    color: #ffffff; }
  .popover-dribbble .popover-body {
    color: #ffffff; }
  .popover-dribbble .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-dribbble.bs-popover-top .arrow::after, .popover-dribbble.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ea4c89; }
  .popover-dribbble.bs-popover-right .arrow::after, .popover-dribbble.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ea4c89; }
  .popover-dribbble.bs-popover-bottom .arrow::after, .popover-dribbble.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ea4c89; }
  .popover-dribbble.bs-popover-left .arrow::after, .popover-dribbble.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ea4c89; }

.popover-dropbox {
  background-color: #1E90FF; }
  .popover-dropbox .popover-header {
    background-color: #1E90FF;
    color: #ffffff; }
  .popover-dropbox .popover-body {
    color: #ffffff; }
  .popover-dropbox .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-dropbox.bs-popover-top .arrow::after, .popover-dropbox.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #1E90FF; }
  .popover-dropbox.bs-popover-right .arrow::after, .popover-dropbox.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #1E90FF; }
  .popover-dropbox.bs-popover-bottom .arrow::after, .popover-dropbox.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #1E90FF; }
  .popover-dropbox.bs-popover-left .arrow::after, .popover-dropbox.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #1E90FF; }

.popover-twitch {
  background-color: #4B367C; }
  .popover-twitch .popover-header {
    background-color: #4B367C;
    color: #ffffff; }
  .popover-twitch .popover-body {
    color: #ffffff; }
  .popover-twitch .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-twitch.bs-popover-top .arrow::after, .popover-twitch.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #4B367C; }
  .popover-twitch.bs-popover-right .arrow::after, .popover-twitch.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #4B367C; }
  .popover-twitch.bs-popover-bottom .arrow::after, .popover-twitch.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #4B367C; }
  .popover-twitch.bs-popover-left .arrow::after, .popover-twitch.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #4B367C; }

.popover-paypal {
  background-color: #ecb32c; }
  .popover-paypal .popover-header {
    background-color: #ecb32c;
    color: #0d1b48; }
  .popover-paypal .popover-body {
    color: #0d1b48; }
  .popover-paypal .popover-header {
    border-color: rgba(13, 27, 72, 0.2); }
  .popover-paypal.bs-popover-top .arrow::after, .popover-paypal.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ecb32c; }
  .popover-paypal.bs-popover-right .arrow::after, .popover-paypal.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ecb32c; }
  .popover-paypal.bs-popover-bottom .arrow::after, .popover-paypal.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ecb32c; }
  .popover-paypal.bs-popover-left .arrow::after, .popover-paypal.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ecb32c; }

.popover-behance {
  background-color: #0057ff; }
  .popover-behance .popover-header {
    background-color: #0057ff;
    color: #ffffff; }
  .popover-behance .popover-body {
    color: #ffffff; }
  .popover-behance .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-behance.bs-popover-top .arrow::after, .popover-behance.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0057ff; }
  .popover-behance.bs-popover-right .arrow::after, .popover-behance.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0057ff; }
  .popover-behance.bs-popover-bottom .arrow::after, .popover-behance.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0057ff; }
  .popover-behance.bs-popover-left .arrow::after, .popover-behance.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0057ff; }

.popover-reddit {
  background-color: #E84422; }
  .popover-reddit .popover-header {
    background-color: #E84422;
    color: #ffffff; }
  .popover-reddit .popover-body {
    color: #ffffff; }
  .popover-reddit .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-reddit.bs-popover-top .arrow::after, .popover-reddit.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #E84422; }
  .popover-reddit.bs-popover-right .arrow::after, .popover-reddit.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #E84422; }
  .popover-reddit.bs-popover-bottom .arrow::after, .popover-reddit.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #E84422; }
  .popover-reddit.bs-popover-left .arrow::after, .popover-reddit.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #E84422; }

.popover-github {
  background-color: #222222; }
  .popover-github .popover-header {
    background-color: #222222;
    color: #ffffff; }
  .popover-github .popover-body {
    color: #ffffff; }
  .popover-github .popover-header {
    border-color: rgba(255, 255, 255, 0.2); }
  .popover-github.bs-popover-top .arrow::after, .popover-github.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #222222; }
  .popover-github.bs-popover-right .arrow::after, .popover-github.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #222222; }
  .popover-github.bs-popover-bottom .arrow::after, .popover-github.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #222222; }
  .popover-github.bs-popover-left .arrow::after, .popover-github.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #222222; }

/**
 * = Progress bars
 */
.progress-wrapper {
  position: relative; }

.progress-bar {
  box-shadow: none;
  height: auto;
  border-radius: 0.45rem; }

.progress {
  height: 4px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.45rem;
  background-color: #fafbfe;
  font-size: 0.75rem;
  font-weight: 500;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2); }

.progress-xl {
  height: 0.8rem; }

.progress-lg {
  height: 0.6rem; }

.progress-sm {
  height: 0.2rem; }

.progress-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: .5rem; }
  .progress-info .progress-label span {
    display: inline-block;
    color: #0d1b48;
    font-size: 0.875rem;
    font-weight: 500; }
  .progress-info .progress-percentage {
    text-align: right; }
    .progress-info .progress-percentage span {
      display: inline-block;
      color: #424767;
      font-size: 0.75rem;
      font-weight: 500; }

.info-xl .progress-label span,
.info-xl .progress-percentage span {
  font-size: 0.875rem; }

.info-xl .progress-percentage {
  text-align: right; }

.progress-tooltip {
  background: #0d1b48;
  color: #ffffff;
  font-weight: 500;
  padding: .25rem .375rem;
  line-height: 1;
  font-size: 0.75rem;
  position: relative;
  border-radius: 0.1rem; }
  .progress-tooltip:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 10px;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: #424767;
    border-width: 6px; }

@-webkit-keyframes animate-positive {
  0% {
    width: 0%; } }

@keyframes animate-positive {
  0% {
    width: 0%; } }

.progress-info .bg-primary::after {
  border-top-color: #0d1b48; }

.progress-info .bg-secondary::after {
  border-top-color: #C33C54; }

.progress-info .bg-success::after {
  border-top-color: #208163; }

.progress-info .bg-info::after {
  border-top-color: #0056B3; }

.progress-info .bg-warning::after {
  border-top-color: #F0B400; }

.progress-info .bg-danger::after {
  border-top-color: #be2837; }

.progress-info .bg-light::after {
  border-top-color: #edf0f7; }

.progress-info .bg-dark::after {
  border-top-color: #0d1b48; }

.progress-info .bg-tertiary::after {
  border-top-color: #26408B; }

.progress-info .bg-lighten::after {
  border-top-color: #ffffff; }

.progress-info .bg-white::after {
  border-top-color: #ffffff; }

.progress-info .bg-gray::after {
  border-top-color: #424767; }

.progress-info .bg-neutral::after {
  border-top-color: #ffffff; }

.progress-info .bg-soft::after {
  border-top-color: #f5f9fe; }

.progress-info .bg-black::after {
  border-top-color: #0d1b48; }

.progress-info .bg-purple::after {
  border-top-color: #6f42c1; }

.progress-info .bg-soft-green::after {
  border-top-color: #ebf9ee; }

.progress-info .bg-gray-100::after {
  border-top-color: #ffffff; }

.progress-info .bg-gray-200::after {
  border-top-color: #fafbfe; }

.progress-info .bg-gray-300::after {
  border-top-color: #f5f9fe; }

.progress-info .bg-gray-400::after {
  border-top-color: #edf0f7; }

.progress-info .bg-gray-500::after {
  border-top-color: #B7C3D2; }

.progress-info .bg-gray-600::after {
  border-top-color: #93a5be; }

.progress-info .bg-gray-700::after {
  border-top-color: #52547a; }

.progress-info .bg-gray-800::after {
  border-top-color: #4E5079; }

.progress-info .bg-facebook::after {
  border-top-color: #3b5999; }

.progress-info .bg-twitter::after {
  border-top-color: #1da1f2; }

.progress-info .bg-google::after {
  border-top-color: #DB4337; }

.progress-info .bg-instagram::after {
  border-top-color: #e4405f; }

.progress-info .bg-pinterest::after {
  border-top-color: #bd081c; }

.progress-info .bg-youtube::after {
  border-top-color: #cd201f; }

.progress-info .bg-slack::after {
  border-top-color: #3aaf85; }

.progress-info .bg-dribbble::after {
  border-top-color: #ea4c89; }

.progress-info .bg-dropbox::after {
  border-top-color: #1E90FF; }

.progress-info .bg-twitch::after {
  border-top-color: #4B367C; }

.progress-info .bg-paypal::after {
  border-top-color: #ecb32c; }

.progress-info .bg-behance::after {
  border-top-color: #0057ff; }

.progress-info .bg-reddit::after {
  border-top-color: #E84422; }

.progress-info .bg-github::after {
  border-top-color: #222222; }

.preloader {
  display: flex;
  align-items: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity .2s linear;
  width: 100%;
  z-index: 9999; }
  .preloader img {
    height: 30px; }

.preloadermap {
  display: flex;
  align-items: center;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity .2s linear;
  width: 100%;
  z-index: 9999; }
  .preloadermap img {
    height: 30px; }

.preloadermap {
  height: calc(100vh - 60px); }

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg); }
  to {
    -webkit-transform: rotateY(-360deg); } }

/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg); } }

.preloader .rotate-letter,
.preloadermap .rotate-letter {
  position: absolute;
  height: 19px;
  top: 5px;
  left: 22px;
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1.7s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1.7s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/**
 * = Shapes
 */
.shape-primary {
  background-color: #0d1b48; }
  .shape-primary .step-number {
    background-color: #0d1b48; }

.shape-secondary {
  background-color: #C33C54; }
  .shape-secondary .step-number {
    background-color: #C33C54; }

.shape-success {
  background-color: #208163; }
  .shape-success .step-number {
    background-color: #208163; }

.shape-info {
  background-color: #0056B3; }
  .shape-info .step-number {
    background-color: #0056B3; }

.shape-warning {
  background-color: #F0B400; }
  .shape-warning .step-number {
    background-color: #F0B400; }

.shape-danger {
  background-color: #be2837; }
  .shape-danger .step-number {
    background-color: #be2837; }

.shape-light {
  background-color: #edf0f7; }
  .shape-light .step-number {
    background-color: #edf0f7; }

.shape-dark {
  background-color: #0d1b48; }
  .shape-dark .step-number {
    background-color: #0d1b48; }

.shape-tertiary {
  background-color: #26408B; }
  .shape-tertiary .step-number {
    background-color: #26408B; }

.shape-lighten {
  background-color: #ffffff; }
  .shape-lighten .step-number {
    background-color: #ffffff; }

.shape-white {
  background-color: #ffffff; }
  .shape-white .step-number {
    background-color: #ffffff; }

.shape-gray {
  background-color: #424767; }
  .shape-gray .step-number {
    background-color: #424767; }

.shape-neutral {
  background-color: #ffffff; }
  .shape-neutral .step-number {
    background-color: #ffffff; }

.shape-soft {
  background-color: #f5f9fe; }
  .shape-soft .step-number {
    background-color: #f5f9fe; }

.shape-black {
  background-color: #0d1b48; }
  .shape-black .step-number {
    background-color: #0d1b48; }

.shape-purple {
  background-color: #6f42c1; }
  .shape-purple .step-number {
    background-color: #6f42c1; }

.shape-soft-green {
  background-color: #ebf9ee; }
  .shape-soft-green .step-number {
    background-color: #ebf9ee; }

.shape-gray-100 {
  background-color: #ffffff; }
  .shape-gray-100 .step-number {
    background-color: #ffffff; }

.shape-gray-200 {
  background-color: #fafbfe; }
  .shape-gray-200 .step-number {
    background-color: #fafbfe; }

.shape-gray-300 {
  background-color: #f5f9fe; }
  .shape-gray-300 .step-number {
    background-color: #f5f9fe; }

.shape-gray-400 {
  background-color: #edf0f7; }
  .shape-gray-400 .step-number {
    background-color: #edf0f7; }

.shape-gray-500 {
  background-color: #B7C3D2; }
  .shape-gray-500 .step-number {
    background-color: #B7C3D2; }

.shape-gray-600 {
  background-color: #93a5be; }
  .shape-gray-600 .step-number {
    background-color: #93a5be; }

.shape-gray-700 {
  background-color: #52547a; }
  .shape-gray-700 .step-number {
    background-color: #52547a; }

.shape-gray-800 {
  background-color: #4E5079; }
  .shape-gray-800 .step-number {
    background-color: #4E5079; }

.shape-facebook {
  background-color: #3b5999; }
  .shape-facebook .step-number {
    background-color: #3b5999; }

.shape-twitter {
  background-color: #1da1f2; }
  .shape-twitter .step-number {
    background-color: #1da1f2; }

.shape-google {
  background-color: #DB4337; }
  .shape-google .step-number {
    background-color: #DB4337; }

.shape-instagram {
  background-color: #e4405f; }
  .shape-instagram .step-number {
    background-color: #e4405f; }

.shape-pinterest {
  background-color: #bd081c; }
  .shape-pinterest .step-number {
    background-color: #bd081c; }

.shape-youtube {
  background-color: #cd201f; }
  .shape-youtube .step-number {
    background-color: #cd201f; }

.shape-slack {
  background-color: #3aaf85; }
  .shape-slack .step-number {
    background-color: #3aaf85; }

.shape-dribbble {
  background-color: #ea4c89; }
  .shape-dribbble .step-number {
    background-color: #ea4c89; }

.shape-dropbox {
  background-color: #1E90FF; }
  .shape-dropbox .step-number {
    background-color: #1E90FF; }

.shape-twitch {
  background-color: #4B367C; }
  .shape-twitch .step-number {
    background-color: #4B367C; }

.shape-paypal {
  background-color: #ecb32c; }
  .shape-paypal .step-number {
    background-color: #ecb32c; }

.shape-behance {
  background-color: #0057ff; }
  .shape-behance .step-number {
    background-color: #0057ff; }

.shape-reddit {
  background-color: #E84422; }
  .shape-reddit .step-number {
    background-color: #E84422; }

.shape-github {
  background-color: #222222; }
  .shape-github .step-number {
    background-color: #222222; }

.organic-radius {
  border-radius: 63% 37% 30% 70% / 50% 45% 55% 50%; }

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem; }

.avatar-md {
  width: 2rem;
  height: 2rem; }

.avatar-lg {
  width: 3rem;
  height: 3rem; }

.color-shape {
  width: 7rem;
  height: 7rem; }

/**steps
 * = Accordions
 */
.step {
  position: relative;
  margin-bottom: 4rem; }
  .step .step-shape {
    display: inline-flex;
    width: 7rem;
    height: 7rem;
    text-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .step .step-shape .step-icon span, .step .step-shape .step-icon svg {
      font-size: 2rem; }
    .step .step-shape .step-number {
      position: absolute;
      font-weight: 500;
      top: -10px;
      width: 32px;
      height: 32px;
      border-width: 0.125rem;
      border-style: solid;
      line-height: 1.7; }
    .step .step-shape.bordered {
      border-width: 0.125rem;
      border-style: solid; }
      .step .step-shape.bordered .step-number {
        background-color: #ffffff;
        top: -12px; }
    .step .step-shape.rounded-circle .step-number {
      margin-right: 1.275rem; }
    .step .step-shape.rounded .step-number {
      border-radius: 0.45rem; }
    .step .step-shape.organic-radius .step-number {
      margin-right: 1.25rem;
      border-radius: 63% 37% 30% 1.4 45% 55% 50%; }
  .step .icon-lg {
    font-size: 2.75rem; }

.layer-primary {
  color: rgba(13, 27, 72, 0.15); }

.layer-secondary {
  color: rgba(195, 60, 84, 0.15); }

.layer-success {
  color: rgba(32, 129, 99, 0.15); }

.layer-info {
  color: rgba(0, 86, 179, 0.15); }

.layer-warning {
  color: rgba(240, 180, 0, 0.15); }

.layer-danger {
  color: rgba(190, 40, 55, 0.15); }

.layer-light {
  color: rgba(237, 240, 247, 0.15); }

.layer-dark {
  color: rgba(13, 27, 72, 0.15); }

.layer-tertiary {
  color: rgba(38, 64, 139, 0.15); }

.layer-lighten {
  color: rgba(255, 255, 255, 0.15); }

.layer-white {
  color: rgba(255, 255, 255, 0.15); }

.layer-gray {
  color: rgba(66, 71, 103, 0.15); }

.layer-neutral {
  color: rgba(255, 255, 255, 0.15); }

.layer-soft {
  color: rgba(245, 249, 254, 0.15); }

.layer-black {
  color: rgba(13, 27, 72, 0.15); }

.layer-purple {
  color: rgba(111, 66, 193, 0.15); }

.layer-soft-green {
  color: rgba(235, 249, 238, 0.15); }

.layer-gray-100 {
  color: rgba(255, 255, 255, 0.15); }

.layer-gray-200 {
  color: rgba(250, 251, 254, 0.15); }

.layer-gray-300 {
  color: rgba(245, 249, 254, 0.15); }

.layer-gray-400 {
  color: rgba(237, 240, 247, 0.15); }

.layer-gray-500 {
  color: rgba(183, 195, 210, 0.15); }

.layer-gray-600 {
  color: rgba(147, 165, 190, 0.15); }

.layer-gray-700 {
  color: rgba(82, 84, 122, 0.15); }

.layer-gray-800 {
  color: rgba(78, 80, 121, 0.15); }

.layer-facebook {
  color: rgba(59, 89, 153, 0.15); }

.layer-twitter {
  color: rgba(29, 161, 242, 0.15); }

.layer-google {
  color: rgba(219, 67, 55, 0.15); }

.layer-instagram {
  color: rgba(228, 64, 95, 0.15); }

.layer-pinterest {
  color: rgba(189, 8, 28, 0.15); }

.layer-youtube {
  color: rgba(205, 32, 31, 0.15); }

.layer-slack {
  color: rgba(58, 175, 133, 0.15); }

.layer-dribbble {
  color: rgba(234, 76, 137, 0.15); }

.layer-dropbox {
  color: rgba(30, 144, 255, 0.15); }

.layer-twitch {
  color: rgba(75, 54, 124, 0.15); }

.layer-paypal {
  color: rgba(236, 179, 44, 0.15); }

.layer-behance {
  color: rgba(0, 87, 255, 0.15); }

.layer-reddit {
  color: rgba(232, 68, 34, 0.15); }

.layer-github {
  color: rgba(34, 34, 34, 0.15); }

/**
 * = Typography
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500; }

p, ol li, ul li {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6; }

a, .text-action {
  color: #C33C54;
  font-weight: 400;
  transition: all 0.2s ease; }
  a:hover, .text-action:hover {
    color: #9c3043;
    cursor: pointer; }

article h1, article h2, article h3, article h4, article h5, article h6 {
  margin: 2.5rem 0 2rem 0; }

article p, article blockquote {
  font-size: 1.27rem;
  margin-bottom: 2rem; }

article ul li, article ol li {
  font-size: 1.27rem;
  margin-bottom: 1.5rem; }

article ul, article ol {
  margin-bottom: 2.5rem; }

article a {
  color: #C33C54;
  text-decoration: underline; }
  article a:hover {
    color: #C33C54;
    text-decoration: none; }

blockquote {
  font-size: 1.27rem;
  background: #f5f9fc;
  border-radius: 10px;
  padding: 25px;
  font-style: italic; }

.lead + .btn-wrapper {
  margin-top: 3rem; }

@media (max-width: 991.98px) {
  .lead {
    font-size: 1rem; } }

.text-italic {
  font-style: italic !important; }

.description {
  font-size: 0.875rem; }

.heading {
  letter-spacing: 0.025em;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500; }

.heading-title {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 500;
  text-transform: uppercase; }

.heading-section {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 500;
  text-transform: uppercase; }
  .heading-section img {
    display: block;
    width: 72px;
    height: 72px;
    margin-bottom: 1.5rem; }
  .heading-section.text-center img {
    margin-left: auto;
    margin-right: auto; }

.display-1,
.display-2,
.display-3,
.display-4 {
  font-weight: 500;
  color: #0d1b48; }
  .display-1 span,
  .display-2 span,
  .display-3 span,
  .display-4 span {
    font-weight: 300; }

@media (max-width: 991.98px) {
  .display-2 {
    font-size: 2.5rem; } }

@media (max-width: 767.98px) {
  .display-2 {
    font-size: 1.875rem; } }

@media (max-width: 1199.98px) {
  .display-3 {
    font-size: 1.875rem; } }

@media (max-width: 767.98px) {
  .display-4 {
    font-size: 1.5rem; } }

.form-title {
  font-size: 1.125rem; }

.blockquote {
  font-family: 'Sanchez', serif;
  font-style: italic;
  font-weight: 400; }
  .blockquote .blockquote-footer {
    font-size: 1.25rem;
    font-weight: 600; }

.font-small {
  font-size: 0.875rem;
  font-weight: 300; }

.font-base {
  font-size: 1rem;
  font-weight: 300; }

.font-medium {
  font-size: 1.25rem;
  font-weight: 300; }

.text-primary, .text-primary:hover {
  color: #0d1b48 !important; }

.text-primary a:not(.btn), .text-primary h1, .text-primary h2, .text-primary h3, .text-primary h4, .text-primary h5, .text-primary h6, .text-primary .h1, .text-primary .h2, .text-primary .h3, .text-primary .h4, .text-primary .h5, .text-primary .h6, .text-primary .display-1, .text-primary .display-2, .text-primary .display-3, .text-primary .display-4 {
  color: #0d1b48; }

.text-primary .text-muted {
  color: rgba(13, 27, 72, 0.7) !important; }

.text-secondary, .text-secondary:hover {
  color: #C33C54 !important; }

.text-secondary a:not(.btn), .text-secondary h1, .text-secondary h2, .text-secondary h3, .text-secondary h4, .text-secondary h5, .text-secondary h6, .text-secondary .h1, .text-secondary .h2, .text-secondary .h3, .text-secondary .h4, .text-secondary .h5, .text-secondary .h6, .text-secondary .display-1, .text-secondary .display-2, .text-secondary .display-3, .text-secondary .display-4 {
  color: #C33C54; }

.text-secondary .text-muted {
  color: rgba(195, 60, 84, 0.7) !important; }

.text-success, .text-success:hover {
  color: #208163 !important; }

.text-success a:not(.btn), .text-success h1, .text-success h2, .text-success h3, .text-success h4, .text-success h5, .text-success h6, .text-success .h1, .text-success .h2, .text-success .h3, .text-success .h4, .text-success .h5, .text-success .h6, .text-success .display-1, .text-success .display-2, .text-success .display-3, .text-success .display-4 {
  color: #208163; }

.text-success .text-muted {
  color: rgba(32, 129, 99, 0.7) !important; }

.text-info, .text-info:hover {
  color: #0056B3 !important; }

.text-info a:not(.btn), .text-info h1, .text-info h2, .text-info h3, .text-info h4, .text-info h5, .text-info h6, .text-info .h1, .text-info .h2, .text-info .h3, .text-info .h4, .text-info .h5, .text-info .h6, .text-info .display-1, .text-info .display-2, .text-info .display-3, .text-info .display-4 {
  color: #0056B3; }

.text-info .text-muted {
  color: rgba(0, 86, 179, 0.7) !important; }

.text-warning, .text-warning:hover {
  color: #F0B400 !important; }

.text-warning a:not(.btn), .text-warning h1, .text-warning h2, .text-warning h3, .text-warning h4, .text-warning h5, .text-warning h6, .text-warning .h1, .text-warning .h2, .text-warning .h3, .text-warning .h4, .text-warning .h5, .text-warning .h6, .text-warning .display-1, .text-warning .display-2, .text-warning .display-3, .text-warning .display-4 {
  color: #F0B400; }

.text-warning .text-muted {
  color: rgba(240, 180, 0, 0.7) !important; }

.text-danger, .text-danger:hover {
  color: #be2837 !important; }

.text-danger a:not(.btn), .text-danger h1, .text-danger h2, .text-danger h3, .text-danger h4, .text-danger h5, .text-danger h6, .text-danger .h1, .text-danger .h2, .text-danger .h3, .text-danger .h4, .text-danger .h5, .text-danger .h6, .text-danger .display-1, .text-danger .display-2, .text-danger .display-3, .text-danger .display-4 {
  color: #be2837; }

.text-danger .text-muted {
  color: rgba(190, 40, 55, 0.7) !important; }

.text-light, .text-light:hover {
  color: #edf0f7 !important; }

.text-light a:not(.btn), .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6, .text-light .display-1, .text-light .display-2, .text-light .display-3, .text-light .display-4 {
  color: #edf0f7; }

.text-light .text-muted {
  color: rgba(237, 240, 247, 0.7) !important; }

.text-dark, .text-dark:hover {
  color: #0d1b48 !important; }

.text-dark a:not(.btn), .text-dark h1, .text-dark h2, .text-dark h3, .text-dark h4, .text-dark h5, .text-dark h6, .text-dark .h1, .text-dark .h2, .text-dark .h3, .text-dark .h4, .text-dark .h5, .text-dark .h6, .text-dark .display-1, .text-dark .display-2, .text-dark .display-3, .text-dark .display-4 {
  color: #0d1b48; }

.text-dark .text-muted {
  color: rgba(13, 27, 72, 0.7) !important; }

.text-tertiary, .text-tertiary:hover {
  color: #26408B !important; }

.text-tertiary a:not(.btn), .text-tertiary h1, .text-tertiary h2, .text-tertiary h3, .text-tertiary h4, .text-tertiary h5, .text-tertiary h6, .text-tertiary .h1, .text-tertiary .h2, .text-tertiary .h3, .text-tertiary .h4, .text-tertiary .h5, .text-tertiary .h6, .text-tertiary .display-1, .text-tertiary .display-2, .text-tertiary .display-3, .text-tertiary .display-4 {
  color: #26408B; }

.text-tertiary .text-muted {
  color: rgba(38, 64, 139, 0.7) !important; }

.text-lighten, .text-lighten:hover {
  color: #ffffff !important; }

.text-lighten a:not(.btn), .text-lighten h1, .text-lighten h2, .text-lighten h3, .text-lighten h4, .text-lighten h5, .text-lighten h6, .text-lighten .h1, .text-lighten .h2, .text-lighten .h3, .text-lighten .h4, .text-lighten .h5, .text-lighten .h6, .text-lighten .display-1, .text-lighten .display-2, .text-lighten .display-3, .text-lighten .display-4 {
  color: #ffffff; }

.text-lighten .text-muted {
  color: rgba(255, 255, 255, 0.7) !important; }

.text-white, .text-white:hover {
  color: #ffffff !important; }

.text-white a:not(.btn), .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6, .text-white .display-1, .text-white .display-2, .text-white .display-3, .text-white .display-4 {
  color: #ffffff; }

.text-white .text-muted {
  color: rgba(255, 255, 255, 0.7) !important; }

.text-gray, .text-gray:hover {
  color: #424767 !important; }

.text-gray a:not(.btn), .text-gray h1, .text-gray h2, .text-gray h3, .text-gray h4, .text-gray h5, .text-gray h6, .text-gray .h1, .text-gray .h2, .text-gray .h3, .text-gray .h4, .text-gray .h5, .text-gray .h6, .text-gray .display-1, .text-gray .display-2, .text-gray .display-3, .text-gray .display-4 {
  color: #424767; }

.text-gray .text-muted {
  color: rgba(66, 71, 103, 0.7) !important; }

.text-neutral, .text-neutral:hover {
  color: #ffffff !important; }

.text-neutral a:not(.btn), .text-neutral h1, .text-neutral h2, .text-neutral h3, .text-neutral h4, .text-neutral h5, .text-neutral h6, .text-neutral .h1, .text-neutral .h2, .text-neutral .h3, .text-neutral .h4, .text-neutral .h5, .text-neutral .h6, .text-neutral .display-1, .text-neutral .display-2, .text-neutral .display-3, .text-neutral .display-4 {
  color: #ffffff; }

.text-neutral .text-muted {
  color: rgba(255, 255, 255, 0.7) !important; }

.text-soft, .text-soft:hover {
  color: #f5f9fe !important; }

.text-soft a:not(.btn), .text-soft h1, .text-soft h2, .text-soft h3, .text-soft h4, .text-soft h5, .text-soft h6, .text-soft .h1, .text-soft .h2, .text-soft .h3, .text-soft .h4, .text-soft .h5, .text-soft .h6, .text-soft .display-1, .text-soft .display-2, .text-soft .display-3, .text-soft .display-4 {
  color: #f5f9fe; }

.text-soft .text-muted {
  color: rgba(245, 249, 254, 0.7) !important; }

.text-black, .text-black:hover {
  color: #0d1b48 !important; }

.text-black a:not(.btn), .text-black h1, .text-black h2, .text-black h3, .text-black h4, .text-black h5, .text-black h6, .text-black .h1, .text-black .h2, .text-black .h3, .text-black .h4, .text-black .h5, .text-black .h6, .text-black .display-1, .text-black .display-2, .text-black .display-3, .text-black .display-4 {
  color: #0d1b48; }

.text-black .text-muted {
  color: rgba(13, 27, 72, 0.7) !important; }

.text-purple, .text-purple:hover {
  color: #6f42c1 !important; }

.text-purple a:not(.btn), .text-purple h1, .text-purple h2, .text-purple h3, .text-purple h4, .text-purple h5, .text-purple h6, .text-purple .h1, .text-purple .h2, .text-purple .h3, .text-purple .h4, .text-purple .h5, .text-purple .h6, .text-purple .display-1, .text-purple .display-2, .text-purple .display-3, .text-purple .display-4 {
  color: #6f42c1; }

.text-purple .text-muted {
  color: rgba(111, 66, 193, 0.7) !important; }

.text-soft-green, .text-soft-green:hover {
  color: #ebf9ee !important; }

.text-soft-green a:not(.btn), .text-soft-green h1, .text-soft-green h2, .text-soft-green h3, .text-soft-green h4, .text-soft-green h5, .text-soft-green h6, .text-soft-green .h1, .text-soft-green .h2, .text-soft-green .h3, .text-soft-green .h4, .text-soft-green .h5, .text-soft-green .h6, .text-soft-green .display-1, .text-soft-green .display-2, .text-soft-green .display-3, .text-soft-green .display-4 {
  color: #ebf9ee; }

.text-soft-green .text-muted {
  color: rgba(235, 249, 238, 0.7) !important; }

.text-gray-100, .text-gray-100:hover {
  color: #ffffff !important; }

.text-gray-100 a:not(.btn), .text-gray-100 h1, .text-gray-100 h2, .text-gray-100 h3, .text-gray-100 h4, .text-gray-100 h5, .text-gray-100 h6, .text-gray-100 .h1, .text-gray-100 .h2, .text-gray-100 .h3, .text-gray-100 .h4, .text-gray-100 .h5, .text-gray-100 .h6, .text-gray-100 .display-1, .text-gray-100 .display-2, .text-gray-100 .display-3, .text-gray-100 .display-4 {
  color: #ffffff; }

.text-gray-100 .text-muted {
  color: rgba(255, 255, 255, 0.7) !important; }

.text-gray-200, .text-gray-200:hover {
  color: #fafbfe !important; }

.text-gray-200 a:not(.btn), .text-gray-200 h1, .text-gray-200 h2, .text-gray-200 h3, .text-gray-200 h4, .text-gray-200 h5, .text-gray-200 h6, .text-gray-200 .h1, .text-gray-200 .h2, .text-gray-200 .h3, .text-gray-200 .h4, .text-gray-200 .h5, .text-gray-200 .h6, .text-gray-200 .display-1, .text-gray-200 .display-2, .text-gray-200 .display-3, .text-gray-200 .display-4 {
  color: #fafbfe; }

.text-gray-200 .text-muted {
  color: rgba(250, 251, 254, 0.7) !important; }

.text-gray-300, .text-gray-300:hover {
  color: #f5f9fe !important; }

.text-gray-300 a:not(.btn), .text-gray-300 h1, .text-gray-300 h2, .text-gray-300 h3, .text-gray-300 h4, .text-gray-300 h5, .text-gray-300 h6, .text-gray-300 .h1, .text-gray-300 .h2, .text-gray-300 .h3, .text-gray-300 .h4, .text-gray-300 .h5, .text-gray-300 .h6, .text-gray-300 .display-1, .text-gray-300 .display-2, .text-gray-300 .display-3, .text-gray-300 .display-4 {
  color: #f5f9fe; }

.text-gray-300 .text-muted {
  color: rgba(245, 249, 254, 0.7) !important; }

.text-gray-400, .text-gray-400:hover {
  color: #edf0f7 !important; }

.text-gray-400 a:not(.btn), .text-gray-400 h1, .text-gray-400 h2, .text-gray-400 h3, .text-gray-400 h4, .text-gray-400 h5, .text-gray-400 h6, .text-gray-400 .h1, .text-gray-400 .h2, .text-gray-400 .h3, .text-gray-400 .h4, .text-gray-400 .h5, .text-gray-400 .h6, .text-gray-400 .display-1, .text-gray-400 .display-2, .text-gray-400 .display-3, .text-gray-400 .display-4 {
  color: #edf0f7; }

.text-gray-400 .text-muted {
  color: rgba(237, 240, 247, 0.7) !important; }

.text-gray-500, .text-gray-500:hover {
  color: #B7C3D2 !important; }

.text-gray-500 a:not(.btn), .text-gray-500 h1, .text-gray-500 h2, .text-gray-500 h3, .text-gray-500 h4, .text-gray-500 h5, .text-gray-500 h6, .text-gray-500 .h1, .text-gray-500 .h2, .text-gray-500 .h3, .text-gray-500 .h4, .text-gray-500 .h5, .text-gray-500 .h6, .text-gray-500 .display-1, .text-gray-500 .display-2, .text-gray-500 .display-3, .text-gray-500 .display-4 {
  color: #B7C3D2; }

.text-gray-500 .text-muted {
  color: rgba(183, 195, 210, 0.7) !important; }

.text-gray-600, .text-gray-600:hover {
  color: #93a5be !important; }

.text-gray-600 a:not(.btn), .text-gray-600 h1, .text-gray-600 h2, .text-gray-600 h3, .text-gray-600 h4, .text-gray-600 h5, .text-gray-600 h6, .text-gray-600 .h1, .text-gray-600 .h2, .text-gray-600 .h3, .text-gray-600 .h4, .text-gray-600 .h5, .text-gray-600 .h6, .text-gray-600 .display-1, .text-gray-600 .display-2, .text-gray-600 .display-3, .text-gray-600 .display-4 {
  color: #93a5be; }

.text-gray-600 .text-muted {
  color: rgba(147, 165, 190, 0.7) !important; }

.text-gray-700, .text-gray-700:hover {
  color: #52547a !important; }

.text-gray-700 a:not(.btn), .text-gray-700 h1, .text-gray-700 h2, .text-gray-700 h3, .text-gray-700 h4, .text-gray-700 h5, .text-gray-700 h6, .text-gray-700 .h1, .text-gray-700 .h2, .text-gray-700 .h3, .text-gray-700 .h4, .text-gray-700 .h5, .text-gray-700 .h6, .text-gray-700 .display-1, .text-gray-700 .display-2, .text-gray-700 .display-3, .text-gray-700 .display-4 {
  color: #52547a; }

.text-gray-700 .text-muted {
  color: rgba(82, 84, 122, 0.7) !important; }

.text-gray-800, .text-gray-800:hover {
  color: #4E5079 !important; }

.text-gray-800 a:not(.btn), .text-gray-800 h1, .text-gray-800 h2, .text-gray-800 h3, .text-gray-800 h4, .text-gray-800 h5, .text-gray-800 h6, .text-gray-800 .h1, .text-gray-800 .h2, .text-gray-800 .h3, .text-gray-800 .h4, .text-gray-800 .h5, .text-gray-800 .h6, .text-gray-800 .display-1, .text-gray-800 .display-2, .text-gray-800 .display-3, .text-gray-800 .display-4 {
  color: #4E5079; }

.text-gray-800 .text-muted {
  color: rgba(78, 80, 121, 0.7) !important; }

.text-facebook, .text-facebook:hover {
  color: #3b5999 !important; }

.text-facebook a:not(.btn), .text-facebook h1, .text-facebook h2, .text-facebook h3, .text-facebook h4, .text-facebook h5, .text-facebook h6, .text-facebook .h1, .text-facebook .h2, .text-facebook .h3, .text-facebook .h4, .text-facebook .h5, .text-facebook .h6, .text-facebook .display-1, .text-facebook .display-2, .text-facebook .display-3, .text-facebook .display-4 {
  color: #3b5999; }

.text-facebook .text-muted {
  color: rgba(59, 89, 153, 0.7) !important; }

.text-twitter, .text-twitter:hover {
  color: #1da1f2 !important; }

.text-twitter a:not(.btn), .text-twitter h1, .text-twitter h2, .text-twitter h3, .text-twitter h4, .text-twitter h5, .text-twitter h6, .text-twitter .h1, .text-twitter .h2, .text-twitter .h3, .text-twitter .h4, .text-twitter .h5, .text-twitter .h6, .text-twitter .display-1, .text-twitter .display-2, .text-twitter .display-3, .text-twitter .display-4 {
  color: #1da1f2; }

.text-twitter .text-muted {
  color: rgba(29, 161, 242, 0.7) !important; }

.text-google, .text-google:hover {
  color: #DB4337 !important; }

.text-google a:not(.btn), .text-google h1, .text-google h2, .text-google h3, .text-google h4, .text-google h5, .text-google h6, .text-google .h1, .text-google .h2, .text-google .h3, .text-google .h4, .text-google .h5, .text-google .h6, .text-google .display-1, .text-google .display-2, .text-google .display-3, .text-google .display-4 {
  color: #DB4337; }

.text-google .text-muted {
  color: rgba(219, 67, 55, 0.7) !important; }

.text-instagram, .text-instagram:hover {
  color: #e4405f !important; }

.text-instagram a:not(.btn), .text-instagram h1, .text-instagram h2, .text-instagram h3, .text-instagram h4, .text-instagram h5, .text-instagram h6, .text-instagram .h1, .text-instagram .h2, .text-instagram .h3, .text-instagram .h4, .text-instagram .h5, .text-instagram .h6, .text-instagram .display-1, .text-instagram .display-2, .text-instagram .display-3, .text-instagram .display-4 {
  color: #e4405f; }

.text-instagram .text-muted {
  color: rgba(228, 64, 95, 0.7) !important; }

.text-pinterest, .text-pinterest:hover {
  color: #bd081c !important; }

.text-pinterest a:not(.btn), .text-pinterest h1, .text-pinterest h2, .text-pinterest h3, .text-pinterest h4, .text-pinterest h5, .text-pinterest h6, .text-pinterest .h1, .text-pinterest .h2, .text-pinterest .h3, .text-pinterest .h4, .text-pinterest .h5, .text-pinterest .h6, .text-pinterest .display-1, .text-pinterest .display-2, .text-pinterest .display-3, .text-pinterest .display-4 {
  color: #bd081c; }

.text-pinterest .text-muted {
  color: rgba(189, 8, 28, 0.7) !important; }

.text-youtube, .text-youtube:hover {
  color: #cd201f !important; }

.text-youtube a:not(.btn), .text-youtube h1, .text-youtube h2, .text-youtube h3, .text-youtube h4, .text-youtube h5, .text-youtube h6, .text-youtube .h1, .text-youtube .h2, .text-youtube .h3, .text-youtube .h4, .text-youtube .h5, .text-youtube .h6, .text-youtube .display-1, .text-youtube .display-2, .text-youtube .display-3, .text-youtube .display-4 {
  color: #cd201f; }

.text-youtube .text-muted {
  color: rgba(205, 32, 31, 0.7) !important; }

.text-slack, .text-slack:hover {
  color: #3aaf85 !important; }

.text-slack a:not(.btn), .text-slack h1, .text-slack h2, .text-slack h3, .text-slack h4, .text-slack h5, .text-slack h6, .text-slack .h1, .text-slack .h2, .text-slack .h3, .text-slack .h4, .text-slack .h5, .text-slack .h6, .text-slack .display-1, .text-slack .display-2, .text-slack .display-3, .text-slack .display-4 {
  color: #3aaf85; }

.text-slack .text-muted {
  color: rgba(58, 175, 133, 0.7) !important; }

.text-dribbble, .text-dribbble:hover {
  color: #ea4c89 !important; }

.text-dribbble a:not(.btn), .text-dribbble h1, .text-dribbble h2, .text-dribbble h3, .text-dribbble h4, .text-dribbble h5, .text-dribbble h6, .text-dribbble .h1, .text-dribbble .h2, .text-dribbble .h3, .text-dribbble .h4, .text-dribbble .h5, .text-dribbble .h6, .text-dribbble .display-1, .text-dribbble .display-2, .text-dribbble .display-3, .text-dribbble .display-4 {
  color: #ea4c89; }

.text-dribbble .text-muted {
  color: rgba(234, 76, 137, 0.7) !important; }

.text-dropbox, .text-dropbox:hover {
  color: #1E90FF !important; }

.text-dropbox a:not(.btn), .text-dropbox h1, .text-dropbox h2, .text-dropbox h3, .text-dropbox h4, .text-dropbox h5, .text-dropbox h6, .text-dropbox .h1, .text-dropbox .h2, .text-dropbox .h3, .text-dropbox .h4, .text-dropbox .h5, .text-dropbox .h6, .text-dropbox .display-1, .text-dropbox .display-2, .text-dropbox .display-3, .text-dropbox .display-4 {
  color: #1E90FF; }

.text-dropbox .text-muted {
  color: rgba(30, 144, 255, 0.7) !important; }

.text-twitch, .text-twitch:hover {
  color: #4B367C !important; }

.text-twitch a:not(.btn), .text-twitch h1, .text-twitch h2, .text-twitch h3, .text-twitch h4, .text-twitch h5, .text-twitch h6, .text-twitch .h1, .text-twitch .h2, .text-twitch .h3, .text-twitch .h4, .text-twitch .h5, .text-twitch .h6, .text-twitch .display-1, .text-twitch .display-2, .text-twitch .display-3, .text-twitch .display-4 {
  color: #4B367C; }

.text-twitch .text-muted {
  color: rgba(75, 54, 124, 0.7) !important; }

.text-paypal, .text-paypal:hover {
  color: #ecb32c !important; }

.text-paypal a:not(.btn), .text-paypal h1, .text-paypal h2, .text-paypal h3, .text-paypal h4, .text-paypal h5, .text-paypal h6, .text-paypal .h1, .text-paypal .h2, .text-paypal .h3, .text-paypal .h4, .text-paypal .h5, .text-paypal .h6, .text-paypal .display-1, .text-paypal .display-2, .text-paypal .display-3, .text-paypal .display-4 {
  color: #ecb32c; }

.text-paypal .text-muted {
  color: rgba(236, 179, 44, 0.7) !important; }

.text-behance, .text-behance:hover {
  color: #0057ff !important; }

.text-behance a:not(.btn), .text-behance h1, .text-behance h2, .text-behance h3, .text-behance h4, .text-behance h5, .text-behance h6, .text-behance .h1, .text-behance .h2, .text-behance .h3, .text-behance .h4, .text-behance .h5, .text-behance .h6, .text-behance .display-1, .text-behance .display-2, .text-behance .display-3, .text-behance .display-4 {
  color: #0057ff; }

.text-behance .text-muted {
  color: rgba(0, 87, 255, 0.7) !important; }

.text-reddit, .text-reddit:hover {
  color: #E84422 !important; }

.text-reddit a:not(.btn), .text-reddit h1, .text-reddit h2, .text-reddit h3, .text-reddit h4, .text-reddit h5, .text-reddit h6, .text-reddit .h1, .text-reddit .h2, .text-reddit .h3, .text-reddit .h4, .text-reddit .h5, .text-reddit .h6, .text-reddit .display-1, .text-reddit .display-2, .text-reddit .display-3, .text-reddit .display-4 {
  color: #E84422; }

.text-reddit .text-muted {
  color: rgba(232, 68, 34, 0.7) !important; }

.text-github, .text-github:hover {
  color: #222222 !important; }

.text-github a:not(.btn), .text-github h1, .text-github h2, .text-github h3, .text-github h4, .text-github h5, .text-github h6, .text-github .h1, .text-github .h2, .text-github .h3, .text-github .h4, .text-github .h5, .text-github .h6, .text-github .display-1, .text-github .display-2, .text-github .display-3, .text-github .display-4 {
  color: #222222; }

.text-github .text-muted {
  color: rgba(34, 34, 34, 0.7) !important; }

.folder-structure li {
  font-size: 1.2rem; }

/**
 * = Timelines
 */
.timeline {
  width: 100%; }
  .timeline.timeline-one .timeline-item {
    position: relative;
    width: 50%;
    margin-left: 50%;
    padding-bottom: 2.75rem;
    padding-left: 2.75rem;
    text-align: left; }
    @media (max-width: 767.98px) {
      .timeline.timeline-one .timeline-item {
        width: 100%;
        margin-left: 0; } }
    .timeline.timeline-one .timeline-item img {
      margin-bottom: 1.25rem; }
    .timeline.timeline-one .timeline-item:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      margin-top: 0.425rem;
      margin-left: -0.5rem;
      border: 0.0625rem solid #edf0f7;
      border-radius: 50%; }
    .timeline.timeline-one .timeline-item:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: -0.1875rem;
      width: 0.0625rem;
      margin-top: 1.875rem;
      margin-left: -0.0625rem;
      background-color: #edf0f7; }
    .timeline.timeline-one .timeline-item:nth-child(2n+1) {
      margin-left: 0;
      padding-right: 2.75rem;
      padding-left: 0;
      text-align: right; }
      @media (max-width: 767.98px) {
        .timeline.timeline-one .timeline-item:nth-child(2n+1) {
          padding-right: 0;
          padding-left: 2.75rem;
          text-align: left; } }
      .timeline.timeline-one .timeline-item:nth-child(2n+1):before, .timeline.timeline-one .timeline-item:nth-child(2n+1):after {
        right: 0;
        left: auto; }
        @media (max-width: 767.98px) {
          .timeline.timeline-one .timeline-item:nth-child(2n+1):before, .timeline.timeline-one .timeline-item:nth-child(2n+1):after {
            right: auto;
            left: 0; } }
      .timeline.timeline-one .timeline-item:nth-child(2n+1):before {
        margin-right: -0.5rem; }
      .timeline.timeline-one .timeline-item:nth-child(2n+1):after {
        margin-right: -0.0625rem; }
  .timeline.timeline-one.dark .timeline-item:before {
    border: 0.0625rem solid #B7C3D2; }
  .timeline.timeline-one.dark .timeline-item:after {
    background-color: #B7C3D2; }
  .timeline.timeline-two .timeline-item {
    position: relative;
    background-color: #ffffff;
    padding: 2rem;
    text-align: left;
    margin-bottom: 2.75rem; }
    .timeline.timeline-two .timeline-item:before, .timeline.timeline-two .timeline-item:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0; }
    .timeline.timeline-two .timeline-item:before {
      border: 4px double #edf0f7;
      content: "";
      left: auto;
      width: 16px;
      height: 16px;
      margin-right: -2rem;
      border-radius: 50%; }
    .timeline.timeline-two .timeline-item:after {
      background-color: #edf0f7;
      width: 0.0625rem;
      height: 100%;
      margin-top: 2rem;
      left: auto;
      margin-right: -1.575rem; }
    .timeline.timeline-two .timeline-item .post-meta a,
    .timeline.timeline-two .timeline-item .post-meta span {
      font-size: 0.875rem;
      padding-right: .125rem; }
  .timeline.timeline-four {
    margin: 1.25rem 0;
    position: relative; }
    .timeline.timeline-four::before {
      content: "";
      display: block;
      width: 0.0625rem;
      height: 100%;
      background: #edf0f7;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
      @media (max-width: 767.98px) {
        .timeline.timeline-four::before {
          margin: 0;
          left: 0; } }
    .timeline.timeline-four .timeline-item {
      margin-bottom: 2.75rem;
      position: relative; }
      .timeline.timeline-four .timeline-item::after {
        content: "";
        display: block;
        clear: both; }
      .timeline.timeline-four .timeline-item:hover .date-outer::before {
        left: 6px; }
      .timeline.timeline-four .timeline-item:hover .date-outer::after {
        left: -6px; }
      .timeline.timeline-four .timeline-item:nth-child(2n) .date-content {
        float: right; }
      .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
        padding: 0.625rem 3.125rem 0.625rem 0;
        text-align: right; }
        @media (max-width: 991.98px) {
          .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
            padding: 0.5rem 1.75rem 0.5rem 0; } }
        @media (max-width: 767.98px) {
          .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
            width: 95%;
            padding: 0.5rem 0;
            text-align: center; } }
    .timeline.timeline-four .icon {
      width: 16px;
      height: 16px;
      margin: auto;
      position: absolute;
      background-color: #ffffff;
      border-radius: 50%;
      border: 0.0625rem solid #edf0f7;
      margin-top: 1rem;
      top: 0;
      left: 0;
      right: 0; }
      @media (max-width: 767.98px) {
        .timeline.timeline-four .icon {
          margin: auto -7px;
          top: 3rem; } }
    .timeline.timeline-four .date-content {
      width: 50%;
      float: left;
      position: relative; }
      @media (max-width: 767.98px) {
        .timeline.timeline-four .date-content {
          width: 95%;
          float: right; } }
    .timeline.timeline-four .date-outer {
      width: 150px;
      height: 150px;
      text-align: center;
      margin: auto;
      z-index: 1; }
      .timeline.timeline-four .date-outer::before {
        content: "";
        width: 150px;
        height: 150px;
        margin: 0 auto;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background: #ffffff;
        border: 0.0625rem solid #26408B;
        left: -6px;
        -webkit-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        -moz-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        -o-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        -ms-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1); }
        @media (max-width: 767.98px) {
          .timeline.timeline-four .date-outer::before {
            width: 130px;
            height: 130px; } }
      .timeline.timeline-four .date-outer::after {
        content: "";
        width: 150px;
        height: 150px;
        margin: 0 auto;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        border: 0.0625rem solid #0d1b48;
        left: 6px;
        -webkit-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        -moz-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        -o-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        -ms-transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
        transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1); }
        @media (max-width: 767.98px) {
          .timeline.timeline-four .date-outer::after {
            width: 130px;
            height: 130px; } }
    .timeline.timeline-four .date {
      width: 100%;
      margin: auto;
      position: absolute;
      top: 40%;
      left: 0; }
      @media (max-width: 767.98px) {
        .timeline.timeline-four .date {
          top: 27%; } }
    .timeline.timeline-four .year {
      display: block;
      font-weight: 600;
      font-size: 1rem; }
    .timeline.timeline-four .icon {
      font-size: 1.5rem; }
    .timeline.timeline-four .timeline-content {
      width: 50%;
      padding: 0.625rem 0 0.625rem 3.125rem;
      float: right; }
      @media (max-width: 991.98px) {
        .timeline.timeline-four .timeline-content {
          padding: 0.5rem 0 0.5rem 1.75rem; } }
      @media (max-width: 767.98px) {
        .timeline.timeline-four .timeline-content {
          width: 95%;
          padding: 0.5rem 0;
          text-align: center; } }
  .timeline.timeline-five .row .avatar-separator {
    width: 30px;
    height: 30px; }
  .timeline.timeline-five .row .col.middle-line {
    border-right: 0.0625rem solid #edf0f7; }
  .timeline.timeline-six .row .col.middle-line {
    border-right: 0.0625rem solid #edf0f7; }
  .timeline.timeline-six .row .avatar-separator img {
    border: 0.0625rem solid #edf0f7;
    padding: .125rem; }

.vertical-timeline .row {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .vertical-timeline .row.timeline-inner div {
    padding: 0;
    height: 38px; }
  .vertical-timeline .row.timeline-inner hr {
    border-top: 0.0625rem solid #edf0f7;
    margin: 0;
    top: 1.0625rem;
    position: relative; }
  .vertical-timeline .row.timeline-inner .col-md-2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: hidden; }
  .vertical-timeline .row.timeline-inner .corner {
    border: 0.0625rem solid #edf0f7;
    width: 100%;
    position: relative; }
  .vertical-timeline .row.timeline-inner .top-right {
    left: calc(50% - 1px);
    top: -50%; }
  .vertical-timeline .row.timeline-inner .left-bottom {
    left: -50%;
    top: calc(50% - 2px); }
  .vertical-timeline .row.timeline-inner .top-left {
    left: -50%;
    top: -50%; }
  .vertical-timeline .row.timeline-inner .right-bottom {
    left: 50%;
    top: calc(50% - 2px); }
  @media (max-width: 767.98px) {
    .vertical-timeline .row.timeline-inner .right-bottom {
      top: auto;
      bottom: calc(50% - 6px);
      left: calc(50% - 4px); }
    .vertical-timeline .row.timeline-inner .top-left {
      top: auto;
      bottom: -50%; } }
  @media (max-width: 767.98px) {
    .vertical-timeline .row .col-md-6 {
      text-align: center !important;
      margin-top: 3rem; } }
  .vertical-timeline .row .col-md-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center; }
    .vertical-timeline .row .col-md-2:after {
      content: '';
      position: absolute;
      border-left: 0.0625rem solid #edf0f7;
      z-index: 1; }
  .vertical-timeline .row .col-md-2.bottom:after {
    height: 50%;
    top: 50%; }
  .vertical-timeline .row .col-md-2.full:after {
    height: 100%;
    left: calc(50% - 1px); }
  .vertical-timeline .row .col-md-2.top:after {
    height: 50%;
    left: 50%;
    top: 0; }
  .vertical-timeline .row .shape {
    background-color: #edf0f7;
    width: 50px;
    height: 50px;
    z-index: 2; }
  .vertical-timeline .row .right {
    margin-right: 0.1875rem; }
  @media (max-width: 767.98px) {
    .vertical-timeline .row .rectangle {
      line-height: 3;
      margin-bottom: 2rem; }
    .vertical-timeline .row .right {
      margin-bottom: 0; } }

.tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15); }

.tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(13, 27, 72, 0.15); }

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: .5em;
  background: #0d1b48;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: all 0.2s ease; }

.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #0d1b48; }

.chartist-tooltip.tooltip-show {
  opacity: 1; }

.ct-area, .ct-line {
  pointer-events: none; }

.ct-bar {
  stroke-linecap: round; }

.ct-label {
  fill: #424767;
  color: #424767;
  font-size: 0.75rem;
  margin-top: 6px;
  font-weight: 500; }

.ct-slice-pie {
  stroke: #ffffff;
  stroke-width: 3px; }

.ct-chart-donut .ct-series {
  stroke: #ffffff;
  stroke-width: 3px; }

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  font-size: 0.75rem;
  font-weight: 500; }
