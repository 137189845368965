/**
 * = Shapes
 */


// Shape colors
@each $color, $value in $theme-colors {
    .shape-#{$color}  {
        background-color: $value;
        .step-number{
            background-color:$value;
        }
    }
}

.organic-radius{
    border-radius:63% 37% 30% 70% / 50% 45% 55% 50%;
}

.avatar-sm {
    width: $avatar-sm-y;
    height: $avatar-sm-x;
}

.avatar-md {
    width: $avatar-md-y;
    height: $avatar-md-x;
}

.avatar-lg {
    width: $avatar-lg-y;
    height: $avatar-lg-x;
}

.color-shape {
    width: 7rem;
    height: 7rem;
}