/*

=========================================================
* Spaces - Bootstrap Directory Listing Template
=========================================================

* Product Page: https://themesberg.com/product/bootstrap-themes/spaces-bootstrap-directory-listing-template
* Copyright 2020 Themesberg EULA (https://www.themesberg.com/licensing)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

// Bootstrap mixins and functions
@import "bootstrap/mixins";
@import "bootstrap/functions";

// Change variables here
@import "spaces/variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Vendor
@import "spaces/vendor";

// Spaces mixins & functions
@import "spaces/mixins";
@import "spaces/functions";

// Utilities
@import "spaces/reboot";
@import "spaces/utilities";

// Layout
@import "spaces/layout";

// Components
@import "spaces/components";

// write your custom styles here!