.preloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity .2s linear;
    width: 100%;
    z-index: 9999;

    img {
        height: 30px;
    }
}

.preloadermap {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s linear;
    width: 100%;
    z-index: 9999;

    img {
        height: 30px;
    }
}

.preloadermap {
    height: calc(100vh - 60px);
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
    from { -webkit-transform: rotateY(0deg);    }
    to   { -webkit-transform: rotateY(-360deg); }
}

/* all other browsers */
@keyframes spinner {
    from {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -moz-transform: rotateY(-360deg);
        -ms-transform: rotateY(-360deg);
        transform: rotateY(-360deg);
    }
}

.preloader .rotate-letter,
.preloadermap .rotate-letter {
    position: absolute;
    height: 19px;
    top: 5px;
    left: 22px;

    -webkit-animation-name: spinner;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 1.7s;

    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1.7s;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
